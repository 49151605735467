/**
 * 画像
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import React from 'react';
import styled from 'styled-components';

interface WrapProps {
  width?: string;
  height?: string;
  shadow?: boolean;
}
const Wrap = styled.img<WrapProps>`
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  background-size: contain;
  ${({ shadow }) => shadow && 'box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);'}
`;

interface CircleWrapProps {
  src: string;
  width?: string;
  height?: string;
  shadow?: boolean;
}
const CircleWrap = styled.div<CircleWrapProps>`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ shadow }) => shadow && 'box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);'}
`;

interface SquareWrapProps {
  src: string;
  width?: string;
  height?: string;
  shadow?: boolean;
}
const SquareWrap = styled.div<SquareWrapProps>`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ shadow }) => shadow && 'box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);'}
`;

interface ImageProps {
  src: string;
  width?: string;
  height?: string;
  shadow?: boolean;
  circle?: boolean;
  square?: boolean;
}
/**
 * 【原子】 画像
 * @param {string} src - 【必須】 画像URL
 * @param {string} width - 画像幅
 * @param {string} height - 画像高さ
 * @param {boolean} shadow - 画像に陰をつける
 * @param {boolean} circle - 円形にくりぬく
 * @param {boolean} square - 四角にくりぬく
 * @returns コンポーネント
 */
export const Image: React.FC<ImageProps> = ({
  src,
  width,
  height,
  shadow,
  circle,
  square,
}: ImageProps) => {
  if (circle) {
    return (
      <CircleWrap src={src} width={width} height={height} shadow={shadow} />
    );
  } else if (square) {
    return (
      <SquareWrap src={src} width={width} height={height} shadow={shadow} />
    );
  } else {
    return <Wrap src={src} width={width} height={height} shadow={shadow} />;
  }
};
