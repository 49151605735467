/**
 * ポイント履歴パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  PointHistoryListDataTypes,
  UrlName,
  UrlPath,
} from 'commons';
import { Link, Text } from 'components/atoms';
import { PageSelector, Panel } from 'components/molecules';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  pointHistoryListSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 32px 0 0;
`;

interface HistoryAreaProps {
  colorPalette: ColorPalette;
}
const HistoryArea = styled.div<HistoryAreaProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.3)};
  border-radius: 8px;
  padding: 4px 0 8px;
`;

const PointWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-weight: bold;
`;

interface HeaderPageSelectorWrapProps {
  width: string;
}
const HeaderPageSelectorWrap = styled.div<HeaderPageSelectorWrapProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ width }) => width};
  margin: 0 0 16px;
`;

const FooterPageSelectorWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 32px;
`;

/**
 * 【有機体】 ポイント履歴パネル
 * @returns コンポーネント
 */
export const PointHistoryPanel: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const pointHistoryList = useTypeSelector(pointHistoryListSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [selectPage, setSelectPage] = useState<number>(1);
  const maxPage = useMemo(() => {
    return Math.ceil(pointHistoryList.length / CustomerConsts.HISTORY_LENGTH);
  }, [pointHistoryList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, pageSelectorWidth: '320px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, pageSelectorWidth: '256px' };
  }, [displaySize]);

  return (
    <Wrap>
      {pointHistoryList.length !== 0 && (
        <HeaderPageSelectorWrap width={css.panelWidth}>
          <Text fontSize={css.fontSizeSmall} lineHeight={1} letterSpacing={0}>
            {(
              CustomerConsts.HISTORY_LENGTH * (selectPage - 1) +
              1
            ).toLocaleString('ja-JP')}
            〜
            {selectPage === maxPage
              ? pointHistoryList.length.toLocaleString('ja-JP')
              : (CustomerConsts.HISTORY_LENGTH * selectPage).toLocaleString(
                  'ja-JP',
                )}
            件を表示
          </Text>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </HeaderPageSelectorWrap>
      )}
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
        margin="0 0 24px"
      >
        {loadingMessage === '' && pointHistoryList.length === 0 && (
          <Text fontSize={css.fontSize} textAlign="center" padding="32px 0">
            ポイント履歴はありません
          </Text>
        )}
        {pointHistoryList.map(
          (data: PointHistoryListDataTypes, index) =>
            index >= CustomerConsts.HISTORY_LENGTH * (selectPage - 1) &&
            index < CustomerConsts.HISTORY_LENGTH * selectPage && (
              <Fragment key={`${data.point}-${data.reflectDate}`}>
                <Text fontSize={css.fontSize} margin="8px 0 0">
                  {data.reflectDate
                    ? new Date(data.reflectDate)
                        .toLocaleString('ja-JP')
                        .slice(0, -3)
                    : '―'}
                </Text>
                <HistoryArea colorPalette={colorPalette}>
                  <Text fontSize={css.fontSize} textAlign="center">
                    {data.details}
                  </Text>
                  {data.point && data.point > 0 ? (
                    <PointWrap>
                      <Text
                        color={colorPalette.positive}
                        fontSize={css.fontSizeLarge}
                        textAlign="center"
                        lineHeight={1}
                        bold
                      >
                        +{Math.abs(data.point || 0)}
                      </Text>
                      <Text>pt</Text>
                    </PointWrap>
                  ) : (
                    <>
                      {data.point === 0 ? (
                        <PointWrap>
                          <Text
                            color={colorPalette.darkTone}
                            fontSize={css.fontSizeLarge}
                            textAlign="center"
                            lineHeight={1}
                            bold
                          >
                            {Math.abs(data.point)}
                          </Text>
                          <Text>pt</Text>
                        </PointWrap>
                      ) : (
                        <PointWrap>
                          <Text
                            color={colorPalette.negative}
                            fontSize={css.fontSizeLarge}
                            textAlign="center"
                            lineHeight={1}
                            bold
                          >
                            -{Math.abs(data.point || 0)}
                          </Text>
                          <Text>pt</Text>
                        </PointWrap>
                      )}
                    </>
                  )}
                </HistoryArea>
              </Fragment>
            ),
        )}
      </Panel>
      {pointHistoryList.length !== 0 && (
        <FooterPageSelectorWrap>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </FooterPageSelectorWrap>
      )}
      <Text margin="32px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
