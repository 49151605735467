/**
 * GAのページビューイベントを埋め込む
 */
import React, { useEffect } from 'react';

interface GoogleAnalyticsPageViewEventProps {
  sendToString: string;
}
/**
 * 【特殊コンポーネント】 GAのページビューイベントを埋め込む
 */
export const GoogleAnalyticsPageViewEvent: React.FC<
  GoogleAnalyticsPageViewEventProps
> = ({ sendToString }) => {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID) {
      window.gtag('event', 'conversion', {
        send_to: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID}/${sendToString}`,
      });
    }
  }, []);

  return null;
};
