/**
 * メールアドレス修正認証表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import {
  ContentsBlock,
  ContentsTitle,
  EmailEditConfirmPanel,
} from 'components/organisms';
import React from 'react';

/**
 * 【テンプレート】 メールアドレス修正認証表示エリア
 * @returns コンポーネント
 */
export const EmailEditConfirmArea: React.FC = () => {
  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.EMAIL_EDIT_CONFIRM}</ContentsTitle>
      <EmailEditConfirmPanel />
    </ContentsBlock>
  );
};
