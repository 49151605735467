/**
 * 会員情報表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import { ContentsBlock, ContentsTitle } from 'components/organisms';
import { ProfilePanel } from 'components/organisms/koronaworld';
import React from 'react';

/**
 * 【テンプレート】 会員情報表示エリア
 * @returns コンポーネント
 */
export const ProfileArea: React.FC = () => {
  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.PROFILE}</ContentsTitle>
      <ProfilePanel />
    </ContentsBlock>
  );
};
