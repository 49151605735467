/**
 * ラジオボタン
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, validateValue } from 'commons';
import { ValidatorMessage } from 'components/atoms';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

interface WrapProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  padding?: string;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const ItemWrap = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 16px 0 0;
`;

interface InputProps {
  width?: string;
  height?: string;
}
const Input = styled.input<InputProps>`
  padding: 0;
  margin: 0;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
`;

const Label = styled.label`
  margin: 0 0 0 4px;
`;

export interface RadioButtonItemDataType {
  label: string;
  value: string;
}

const getLabel = (
  valueList: RadioButtonItemDataType[],
  value: string,
): string => {
  const labels: string[] = [];
  for (let i = 0; i < valueList.length; i += 1) {
    if (valueList[i].value === value) {
      labels.push(valueList[i].label);
      return valueList[i].label;
    }
  }
  return '';
};

interface RadioButtonProps {
  colorPalette: ColorPalette;
  valueList: RadioButtonItemDataType[];
  initialValue: string;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  fontSize?: number;
  errorFontSize?: number;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  disabled?: boolean;
  onChange?: (value: string, isError: boolean, label: string) => void;
  activeFlag?: boolean;
}
/**
 * 【分子】 ラジオボタン
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {ComboBoxItemDataType[]} valueList - 【必須】 選択リスト
 * @param {string} initialValue -  【必須】 初期値
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {number} fontSize - フォントサイズ
 * @param {number} errorFontSize - エラーフォントサイズ
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @param {boolean} disabled - 非活性
 * @param {(value: string, isError: boolean, label: string) => void} onChange - 変更処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @returns コンポーネント
 */
export const RadioButton: React.FC<RadioButtonProps> = ({
  colorPalette,
  valueList,
  initialValue,
  validatorRules,
  validatorMessage,
  fontSize,
  errorFontSize,
  width,
  height,
  padding,
  margin,
  disabled = false,
  onChange,
  activeFlag,
}: RadioButtonProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const objectCash = useRef({ valueList, validatorRules, validatorMessage });
  const idList = useMemo(
    () => objectCash.current.valueList.map(() => uuidV4()),
    [objectCash.current.valueList],
  );

  // オブジェクトの変更判定を厳密に行う(予期せぬ再レンダリングを阻止)
  useEffect(() => {
    if (!isEqual(objectCash.current.valueList, valueList)) {
      objectCash.current.valueList = valueList;
    }
    if (!isEqual(objectCash.current.validatorRules, validatorRules)) {
      objectCash.current.validatorRules = validatorRules;
    }
    if (!isEqual(objectCash.current.validatorMessage, validatorMessage)) {
      objectCash.current.validatorMessage = validatorMessage;
    }
  }, [valueList, validatorRules, validatorMessage]);

  // リストが更新された場合に初期化する
  useEffect(() => {
    setValue(initialValue);
    const result = validateValue({
      value: initialValue,
      validatorRules: objectCash.current.validatorRules,
      validatorMessage: objectCash.current.validatorMessage,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange &&
      onChange(
        initialValue,
        result.isError,
        getLabel(objectCash.current.valueList, initialValue),
      );
  }, [objectCash.current.valueList]);

  // バリデーションルールが更新された際に再判定する
  useEffect(() => {
    const result = validateValue({
      value,
      validatorRules: objectCash.current.validatorRules,
      validatorMessage: objectCash.current.validatorMessage,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange &&
      onChange(
        value,
        result.isError,
        getLabel(objectCash.current.valueList, value),
      );
  }, [objectCash.current.validatorRules, objectCash.current.validatorMessage]);

  // エラー表示フラグを強制的にONにする
  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: value,
        validatorRules: objectCash.current.validatorRules,
        validatorMessage: objectCash.current.validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <>
      <Wrap
        colorPalette={colorPalette}
        fontSize={fontSize}
        padding={padding}
        margin={margin}
      >
        <ValidatorMessage
          colorPalette={colorPalette}
          fontSize={errorFontSize}
          isDisplay={isActive && isError}
          message={errorMessage}
        >
          {objectCash.current.valueList.map((data, index) => {
            return (
              <ItemWrap key={data.value}>
                <Input
                  key={data.value}
                  type="radio"
                  id={idList[index]}
                  value={data.value}
                  checked={data.value === value}
                  width={width}
                  height={height}
                  disabled={disabled}
                  onChange={() => {
                    setValue(data.value);
                    const { isError, errorMessage } = validateValue({
                      value: data.value,
                      validatorRules: objectCash.current.validatorRules,
                      validatorMessage: objectCash.current.validatorMessage,
                    });
                    setIsError(isError);
                    setErrorMessage(errorMessage);
                    setIsActive(true);
                    onChange && onChange(data.value, isError, data.label);
                  }}
                />
                <Label htmlFor={idList[index]}>{data.label}</Label>
              </ItemWrap>
            );
          })}
        </ValidatorMessage>
      </Wrap>
    </>
  );
};
