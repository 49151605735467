/**
 * モーダル
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, setModalOpenClassToBody } from 'commons';
import { GoogleFontsIcon, Overlay, Separator, Text } from 'components/atoms';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

interface WrapProps {
  width?: string;
  height?: string;
}
const Wrap = styled.div<WrapProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
`;

interface CloseButtonProps {
  colorPalette: ColorPalette;
}
const CloseButton = styled.a<CloseButtonProps>`
  box-sizing: border-box;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;
  color: ${({ colorPalette }) => colorPalette.lightTone};
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ colorPalette }) => colorPalette.negative};
  }
`;

interface WindowProps {
  colorPalette: ColorPalette;
  disabledCloseButton?: boolean;
}
const Window = styled.div<WindowProps>`
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  border-radius: 16px;
  width: ${({ disabledCloseButton }) =>
    disabledCloseButton ? '100%' : 'calc(100% - 48px)'};
  height: 100%;
  margin: ${({ disabledCloseButton }) =>
    disabledCloseButton ? '24px 8px 120px' : '24px 0 120px 8px'};
`;

interface HeaderProps {
  colorPalette: ColorPalette;
}
const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 8px 6px;
  margin: -1px 0 0;
  background-color: ${({ colorPalette }) => colorPalette.concept};
  border-radius: 16px 16px 0 0;
  color: ${({ colorPalette }) => colorPalette.lightTone};
`;

interface ContentsProps {
  padding?: string;
}
const Contents = styled.div<ContentsProps>`
  overflow: hidden;
  width: 100%;
  ${({ padding }) => padding && `padding: ${padding};`}
`;

interface ModalProps {
  children?: ReactNode;
  colorPalette: ColorPalette;
  opened: boolean;
  zIndex?: number;
  headerTitle?: string;
  headerTitleSize?: number;
  headerIconName?: string;
  headerIconSize?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  disabledCloseButton?: boolean;
  onClose?: () => void;
}
/**
 * 【分子】 モーダル
 * @param {ReactNode} children - 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {boolean} opened - 【必須】 表示状態
 * @param {number} zIndex - z-index (初期値:999)
 * @param {string} headerTitle - ヘッダー文
 * @param {number} headerTitleSize - ヘッダー文サイズ [px] (初期値:16)
 * @param {string} headerIconName - GoogleFontsアイコン名
 * @ https://fonts.google.com/icons?selected=Material+Icons
 * @param {number} headerIconSize - アイコンサイズ [px] (初期値:24)
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} padding - パディング
 * @param {boolean} disabledCloseButton - ×ボタン非表示
 * @param {() => void} onClose - モーダルを閉じるイベント
 * @returns コンポーネント
 */
export const Modal: React.FC<ModalProps> = ({
  children,
  colorPalette,
  opened,
  zIndex = 999,
  headerTitle,
  headerTitleSize = 16,
  headerIconName,
  headerIconSize = 32,
  width,
  height,
  padding,
  disabledCloseButton = false,
  onClose,
}: ModalProps) => {
  const [overlayRef, setOverlayRef] =
    useState<React.RefObject<HTMLDivElement>>();
  const [uniqueId] = useState<string>(uuidV4());

  useEffect(() => {
    // モーダル内のスクロールを先頭に戻す
    overlayRef?.current?.scrollTo(0, 0);
    // Bodyのスクロールを非表示にしてマウスホイールで動かないようにする
    setModalOpenClassToBody(opened, uniqueId);
    return () => {
      setModalOpenClassToBody(false, uniqueId);
    };
  }, [opened]);

  return (
    <Overlay
      colorPalette={colorPalette}
      zIndex={zIndex}
      displayed={opened}
      setRef={setOverlayRef}
    >
      <Wrap width={width} height={height}>
        <Window
          colorPalette={colorPalette}
          disabledCloseButton={disabledCloseButton}
        >
          {headerTitle && (
            <Header colorPalette={colorPalette}>
              {headerIconName && (
                <>
                  <GoogleFontsIcon
                    name={headerIconName}
                    size={headerIconSize}
                  />
                  <Separator width="16px" />
                </>
              )}
              <Text fontSize={headerTitleSize} bold>
                {headerTitle}
              </Text>
            </Header>
          )}
          <Contents padding={padding}>{children}</Contents>
        </Window>
        {disabledCloseButton === false && (
          <CloseButton colorPalette={colorPalette} onClick={onClose}>
            <GoogleFontsIcon name="close" size={48} />
          </CloseButton>
        )}
      </Wrap>
    </Overlay>
  );
};
