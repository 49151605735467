/**
 * オーバーレイ
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  zIndex: number;
  displayed?: boolean;
}
const Wrap = styled.div<WrapProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  overflow: auto;
  display: ${({ displayed }) => (displayed ? 'block' : 'none')};
  background-color: ${({ colorPalette }) => colorPalette.overlay};
  width: 100vw;
  height: 100vh;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

interface OverlayProps {
  children?: ReactNode;
  colorPalette: ColorPalette;
  zIndex?: number;
  displayed?: boolean;
  onClick?: () => void;
  setRef?: React.Dispatch<React.RefObject<HTMLDivElement>>;
}
/**
 * 【原子】 オーバーレイ
 * @param {ReactNode} children - 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {number} zIndex - z-index (初期値:999)
 * @param {boolean} displayed - 表示フラグ
 * @param {() => void} onClick - クリックイベント
 * @param {React.Dispatch<React.RefObject<HTMLDivElement>>} setRef - Ref(非推奨)
 * @returns コンポーネント
 */
export const Overlay: React.FC<OverlayProps> = ({
  children,
  colorPalette,
  zIndex = 999,
  displayed,
  onClick,
  setRef,
}: OverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setRef) {
      setRef(overlayRef);
    }
  }, [setRef]);

  return (
    <Wrap
      colorPalette={colorPalette}
      zIndex={zIndex}
      displayed={displayed}
      onClick={onClick}
      ref={overlayRef}
    >
      <FlexWrap>{children}</FlexWrap>
    </Wrap>
  );
};
