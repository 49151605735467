/**
 * 会員情報修正パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  MAIL_MAGAZINE_VALUE_LIST,
  Placeholder,
  postAddressFromPostalCode,
  SEX_VALUE_LIST,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import {
  CheckBoxForm,
  ComboBoxForm,
  DateForm,
  InputTextForm,
  RadioForm,
} from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  EventSlice,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface CommercialWrapProps {
  colorPalette: ColorPalette;
}
const CommercialWrap = styled.div<CommercialWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.concept, 0.2)};
  border-radius: 8px;
  padding: 4px 16px;
`;

const validatorRules = {
  name: ['required'],
  kanaName: ['required', 'katakana_with_space'],
  tel: ['required', 'phone_no_hyphen'],
  birthDate: ['valid_date'],
  postalCode: ['required', 'zip'],
  addressAll: ['required'],
  sex: ['required'],
  theater: ['required'],
};

/**
 * 【有機体】 会員情報修正パネル
 * @returns コンポーネント
 */
export const ProfileEditPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [addressRef, setAddressRef] =
    useState<React.RefObject<HTMLInputElement>>();
  const [active, setActive] = useState<boolean>(false);
  const errorList = useRef<{
    name: boolean;
    kanaName: boolean;
    tel: boolean;
    birthDate: boolean;
    postalCode: boolean;
    all: boolean;
    sex: boolean;
    theater: boolean;
  }>({
    name: false,
    kanaName: false,
    tel: false,
    birthDate: false,
    postalCode: false,
    all: false,
    sex: false,
    theater: false,
  });
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <InputTextForm
          initialValue={
            membershipData.name !== undefined ? membershipData.name || '' : null
          }
          validatorRules={validatorRules.name}
          placeholder={Placeholder.NAME}
          disabled
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { name: value };
            errorList.current.name = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          お名前
        </InputTextForm>
        <InputTextForm
          initialValue={
            membershipData.kanaName !== undefined
              ? membershipData.kanaName || ''
              : null
          }
          validatorRules={validatorRules.kanaName}
          placeholder={Placeholder.KANA_NAME}
          disabled
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { kanaName: value };
            errorList.current.kanaName = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          お名前（カタカナ）
        </InputTextForm>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'tel' : 'text'}
          initialValue={
            membershipData.tel !== undefined ? membershipData.tel || '' : null
          }
          validatorRules={validatorRules.tel}
          placeholder={Placeholder.TEL}
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { tel: value };
            errorList.current.tel = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
          required
        >
          電話番号
        </InputTextForm>
        <Text fontSize={css.fontSizeSmall}>
          ※「-」ハイフンなしで入力してください
        </Text>
        <DateForm
          initialValue={
            membershipData.birthDate !== undefined
              ? membershipData.birthDate || ''
              : null
          }
          validatorRules={validatorRules.birthDate}
          yearFrom={1900}
          yearTo={new Date().getFullYear()}
          disabled
          onChange={(value, isError) => {
            const replaceData = { birthDate: value };
            errorList.current.birthDate = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          誕生日
        </DateForm>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'number' : 'text'}
          initialValue={
            membershipData.address?.postalCode !== undefined
              ? membershipData.address.postalCode || ''
              : null
          }
          validatorRules={validatorRules.postalCode}
          placeholder={Placeholder.POSTAL_CODE}
          maxLength={7}
          onChange={(value, isError) => {
            const replaceData = {
              postalCode: value,
            };
            errorList.current.postalCode = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          onBlur={async (value, isError) => {
            const replaceData = {
              postalCode: value,
            };
            errorList.current.postalCode = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
            if (!isError && value.length === 7) {
              //住所補完処理
              dispatch(
                BasicSlice.actions.changeLoadingMessage('住所情報を取得中...'),
              );
              try {
                const response = await postAddressFromPostalCode({
                  postalCode: value,
                });
                if (addressRef?.current) {
                  addressRef.current.value = `${response.prefectures || ''}${
                    response.city || ''
                  }${response.town || ''}`;
                  addressRef.current.focus();
                  addressRef.current.blur();
                }
              } catch (error) {
                dispatch(
                  BasicSlice.actions.changeErrorMessage(
                    '住所情報の取得に失敗しました。手動で入力してください。',
                  ),
                );
              }
              dispatch(BasicSlice.actions.changeLoadingMessage(''));
            }
          }}
          required
        >
          郵便番号
        </InputTextForm>
        <Text fontSize={css.fontSizeSmall}>
          ※「-」ハイフンなしの7桁で入力してください
        </Text>
        <InputTextForm
          initialValue={
            membershipData.address?.all !== undefined
              ? membershipData.address.all || ''
              : null
          }
          validatorRules={validatorRules.addressAll}
          placeholder={Placeholder.ADRDRESS_ALL}
          maxLength={1000}
          setRef={setAddressRef}
          onChange={(value, isError) => {
            const replaceData = { all: value };
            errorList.current.all = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          onBlur={(value, isError) => {
            const replaceData = { all: value };
            errorList.current.all = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          required
          activeFlag={active}
        >
          住所
        </InputTextForm>
        <RadioForm
          initialValue={
            membershipData.sex !== undefined ? membershipData.sex || '' : null
          }
          validatorRules={validatorRules.sex}
          valueList={SEX_VALUE_LIST}
          disabled
          onChange={(value, isError) => {
            const replaceData = { sex: value };
            errorList.current.sex = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          性別
        </RadioForm>
        <CheckBoxForm
          initialValue={
            membershipData.newsletter !== undefined
              ? membershipData.newsletter
                ? ['true']
                : []
              : null
          }
          valueList={MAIL_MAGAZINE_VALUE_LIST}
          onChange={(value) => {
            const replaceData = {
              newsletter: value[0] === 'true' ? true : false,
            };
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          メルマガ
        </CheckBoxForm>
        <CommercialWrap colorPalette={colorPalette}>
          <Text fontSize={css.fontSizeSmall}>
            メルマガ会員には、最新の映画情報やお得な限定クーポンなどをお届けいたします。
          </Text>
        </CommercialWrap>
        <ComboBoxForm
          initialValue={
            membershipData.attributes?.theater !== undefined
              ? membershipData.attributes.theater || ''
              : null
          }
          validatorRules={validatorRules.theater}
          valueList={CustomerConsts.COMBO_BOX_VALUE_LIST_1}
          onChange={(value, isError) => {
            const replaceData = { theater: value };
            errorList.current.theater = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAttributes(
                replaceData,
              ),
            );
          }}
          required
          activeFlag={active}
        >
          マイシアター
        </ComboBoxForm>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={() => {
            if (
              errorList.current.name ||
              errorList.current.kanaName ||
              errorList.current.tel ||
              errorList.current.birthDate ||
              errorList.current.postalCode ||
              errorList.current.all ||
              errorList.current.sex ||
              errorList.current.theater
            ) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力または選択した情報に誤りがあります',
                ),
              );
            } else {
              navigate(UrlPath.PROFILE_EDIT_CONFIRM);
            }
          }}
        >
          確認
        </Button>
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.PROFILE)}
        >
          {UrlName.PROFILE}画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
