/**
 * 汎用関数を管理
 */

import {
  DisplaySize,
  LocalStorageKey,
  MODAL_IDENTIFIER_CLASS_NAME,
  postCustomerTokenClear,
} from 'commons';
import { RadioButtonItemDataType } from 'components/molecules';
import html2canvas from 'html2canvas';
import { useEffect, useRef } from 'react';

/**
 * 【汎用関数】 画面表示サイズの取得
 * @param {number} width - 【必須】 ウインドウの横幅
 * @returns 画面表示サイズ
 */
export const getDisplaySize = (width: number): DisplaySize => {
  if (width <= DisplaySize.SMALL) {
    return DisplaySize.SMALL;
  }
  return DisplaySize.LARGE;
};

/**
 * 【汎用関数】 ヘクサコード(#FFFFFF)の形からrgbaに変換する
 * @param {string} hex - 【必須】 色コード(#FFFFFF)
 * @param {number} alpha - 【必須】 アルファ値(0.5)
 * @returns {string} rgba値
 */
export const hexToRgba = (hex: string, alpha: number): string => {
  let color = hex;
  if (hex.slice(0, 1) === '#') {
    color = hex.slice(1);
  }
  if (color.length === 3)
    color =
      color.slice(0, 1) +
      color.slice(0, 1) +
      color.slice(1, 2) +
      color.slice(1, 2) +
      color.slice(2, 3) +
      color.slice(2, 3);
  return `rgba(${[color.slice(0, 2), color.slice(2, 4), color.slice(4, 6)]
    .map((str: string) => {
      return parseInt(str, 16);
    })
    .join(',')},${alpha})`;
};

/**
 * 【汎用関数】 モーダル表示時のBodyのスクロールの表示切り替え
 * @param {boolean} isOpen - 【必須】 モーダル表示時フラグ
 * @param {string} uniqueId - 【必須】 モーダルの識別文字列
 */
export const setModalOpenClassToBody = (
  isOpen: boolean,
  uniqueId: string,
): void => {
  const body = document.querySelector('body');
  if (body) {
    isOpen
      ? body.classList.add(MODAL_IDENTIFIER_CLASS_NAME + uniqueId)
      : body.classList.remove(MODAL_IDENTIFIER_CLASS_NAME + uniqueId);
    body.style.overflow =
      body.className.indexOf(MODAL_IDENTIFIER_CLASS_NAME) >= 0 ? 'hidden' : '';
  }
};

/**
 * 【汎用関数】 ローカルストレージに複数値をまとめて保存する
 * @param {{key: string; value: string}[]} items - 【必須】 対象のキーと値
 */
export const setLocalStorageItems = (
  items: { key: string; value: string }[],
) => {
  items.forEach((item) => {
    localStorage.setItem(item.key, item.value);
  });
};

/**
 * 【汎用関数】 カスタマーAPIが認証済みか確認
 * @return {boolean} true:認証済み
 */
export const isCustomerApiAuth = (): boolean => {
  if (!localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN)) {
    return false;
  }
  if (!localStorage.getItem(LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN)) {
    return false;
  }
  if (!localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN_TYPE)) {
    return false;
  }
  const userApiRefreshTokenDate = localStorage.getItem(
    LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN_DATE,
  );
  if (!userApiRefreshTokenDate) {
    return false;
  } else {
    // 最後の認証から15日経過していたらログインに戻す(暫定)
    const tempDate = new Date(userApiRefreshTokenDate);
    tempDate.setDate(tempDate.getDate() + 15);
    if (new Date().getTime() > tempDate.getTime()) {
      return false;
    }
  }
  return true;
};

/**
 * 【汎用関数】 曜日取得関数
 * @param {string} date - 【必須】 new Date()で取得できる日付文字列
 * @return {string} 曜日(漢字1文字)
 */
export const getWeekString = (date: string): string => {
  return ['日', '月', '火', '水', '木', '金', '土'][new Date(date).getDay()];
};

/**
 * 【汎用関数】 React18対応1回実行限定のuseEffectカスタムフック
 * @param {React.EffectCallback} effect - 実行したいマウント処理
 */
export const useDidMount = (effect: React.EffectCallback) => {
  const isFirst = useRef<boolean>(true);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      effect();
    }
  }, []);
};

/**
 * 【汎用関数】 ローカルストレージのログアウト時に消すべきものを削除
 */
export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageKey.MEMBER_ID);
  localStorage.removeItem(LocalStorageKey.NAME);
  localStorage.removeItem(LocalStorageKey.TOTAL_POINTS);
  localStorage.removeItem(LocalStorageKey.VALID_PERIOD_TO);
  localStorage.removeItem(LocalStorageKey.LOGIN_STATUS);
  localStorage.removeItem(LocalStorageKey.LOGOUT_STATUS);
  localStorage.removeItem(LocalStorageKey.CUSTOMER_API_TOKEN);
  localStorage.removeItem(LocalStorageKey.CUSTOMER_API_TOKEN_TYPE);
  localStorage.removeItem(LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN);
  localStorage.removeItem(LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN_DATE);
  localStorage.removeItem(LocalStorageKey.PREVIOUS_NO);
  localStorage.removeItem(LocalStorageKey.REGISTER_COUPON_NO);
  localStorage.removeItem(LocalStorageKey.NAME_SEPARATED);
  localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
  return;
};

/**
 * 【汎用関数】 ログイン情報の削除して処理を実行(同期処理するためページ遷移はcallbackですること)
 * @param {() => void} callback - 実行する処理
 */
export const loginStatusClear = (callback: () => void) => {
  setTimeout(async () => {
    clearLocalStorage();
    try {
      await postCustomerTokenClear();
    } catch (error) {
      // TODO: dispatchを呼び出して適切に処理する
    }
    callback();
  }, 1);
};

/**
 * 【汎用関数】 ポップアップの次回表示しないが有効か確認する
 * @param {string} popupName - ポップアップ名
 * @return {boolean} - trueで表示しない
 */
export const checkPopupDisabled = (popupName: string): boolean => {
  const memberId = localStorage.getItem(LocalStorageKey.MEMBER_ID) || '';
  const popupStopList: string[] = JSON.parse(
    localStorage.getItem(memberId + LocalStorageKey.POPUP_STOP_LIST) || '[]',
  );
  return popupStopList.includes(popupName);
};

/**
 * 【汎用関数】 ポップアップの次回表示しないを登録する
 * @param {string} popupName - ポップアップ名
 */
export const setPopupDisabled = (popupName: string) => {
  const memberId = localStorage.getItem(LocalStorageKey.MEMBER_ID) || '';
  const popupStopList: string[] = JSON.parse(
    localStorage.getItem(memberId + LocalStorageKey.POPUP_STOP_LIST) || '[]',
  );
  if (!popupStopList.includes(popupName)) {
    popupStopList.push(popupName);
    localStorage.setItem(
      memberId + LocalStorageKey.POPUP_STOP_LIST,
      JSON.stringify(popupStopList),
    );
  }
};

/**
 * 【汎用関数】 ポップアップの次回表示しないを削除する
 * @param {string} popupName - ポップアップ名
 */
export const removePopupDisabled = (popupName: string) => {
  const memberId = localStorage.getItem(LocalStorageKey.MEMBER_ID) || '';
  const popupStopList: string[] = JSON.parse(
    localStorage.getItem(memberId + LocalStorageKey.POPUP_STOP_LIST) || '[]',
  );
  if (popupStopList.includes(popupName)) {
    localStorage.setItem(
      memberId + LocalStorageKey.POPUP_STOP_LIST,
      JSON.stringify(
        popupStopList.filter((value) => {
          return value != popupName;
        }),
      ),
    );
  }
};

/**
 * 【汎用関数】 ラジオボックスのリストからラベルを取得する
 * @param {RadioButtonItemDataType[]} list - ラジオボックスのリスト
 * @param {string} value - 取得したい値
 */
export const getRadioLabel = (
  list: RadioButtonItemDataType[],
  value: string,
): string => {
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].value === value) {
      return list[i].label;
    }
  }
  return '';
};

/**
 * 【汎用関数】 BlobをFileに変換する
 * @param {Blob[]} data - blobデータ
 * @param {string} name - ファイル名
 */
export const convertBlobToFile = (data: Blob[], name: string): File => {
  return new File(data, name, { type: 'image/jpeg' });
};

/**
 * 【汎用関数】 HTMLをPNG画像としてダウンロードする
 * @param {string} elementId - 画像化するHTMLタグのID
 * @param {string} pngFileName - ダウンロードされるPNGファイル名(拡張子不要)
 */
export const downloadHtmlToPng = async (
  elementId: string,
  pngFileName: string,
): Promise<boolean> => {
  const element = document.getElementById(elementId);
  if (element) {
    try {
      const canvasElement = await html2canvas(element);
      const canvasImage = canvasElement.toDataURL('image/png', 1.0);
      const downloadElement = document.createElement('a');
      downloadElement.style.display = 'none';
      downloadElement.download = `${pngFileName}.png`;
      downloadElement.href = canvasImage;
      downloadElement.click();
      downloadElement.remove();
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

/**
 * 【汎用関数】 HTMLをPNG画像として共有する
 * @param {string} elementId - 画像化するHTMLタグのID
 * @param {string} pngFileName - ダウンロードされるPNGファイル名(拡張子不要)
 */
export const shareHtmlToPng = async (
  elementId: string,
  pngFileName: string,
): Promise<boolean> => {
  const element = document.getElementById(elementId);
  if (element) {
    try {
      const canvasElement = await html2canvas(element);
      canvasElement.toBlob(async (image) => {
        if (image) {
          await navigator.share({
            text: pngFileName,
            files: [
              new File([image], `${pngFileName}.png`, { type: 'image/png' }),
            ],
          });
        }
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

/**
 * 【汎用関数】 1ヶ月前の月か判定
 * @param {Date} firstDate - 基準月の日付データ
 * @param {Date} secondDate - 判定したい月の日付データ
 */
export const isOneMonthBeforeByMonthOnly = (
  firstDate: Date,
  secondDate: Date,
): boolean => {
  // 第二引数が1月で第一引数が12月の場合に対応
  return firstDate.getMonth() === (secondDate.getMonth() + 11) % 12;
};

/**
 * 【汎用関数】 19歳未満か判定
 * @param {string} currentDateString - 基準の日付(ISO)
 * @param {string} birthDateString - 生年月日(ISO)
 */
export const isUnder19 = (
  currentDateString?: string,
  birthDateString?: string,
): boolean => {
  if (!currentDateString || !birthDateString) {
    return false;
  }
  // 年齢を計算する
  const currentDate = new Date(currentDateString);
  const birthDate = new Date(birthDateString);
  const age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();
  const dayDifference = currentDate.getDate() - birthDate.getDate();

  // 18歳未満かどうかを判定する
  if (age < 19) {
    return true;
  } else if (age === 19) {
    if (monthDifference < 0) {
      return true;
    } else if (monthDifference === 0 && dayDifference < 0) {
      return true;
    }
  }

  return false;
};

/**
 * 【汎用関数】 税抜き価格と消費税を計算する処理
 * @param {string} inclusivePrice - 税込み価格
 * @param {string} taxRate - 消費税率(小数)
 */
export const calculateTaxExcludedPrice = (
  inclusivePrice: number,
  taxRate: number,
): { taxExcludedPrice: number; tax: number } => {
  const taxExcludedPrice = Math.floor(inclusivePrice / (1 + taxRate));
  const tax = inclusivePrice - taxExcludedPrice;
  return { taxExcludedPrice, tax };
};
