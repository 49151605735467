/**
 * ポイント履歴表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import {
  ContentsBlock,
  ContentsTitle,
  PointHistoryPanel,
  PointPanel,
} from 'components/organisms';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

/**
 * 【テンプレート】 ポイント履歴表示エリア
 * @returns コンポーネント
 */
export const PointHistoryArea: React.FC = () => {
  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.POINT_HISTORY}</ContentsTitle>
      <Wrap>
        <PointPanel />
        <PointHistoryPanel />
      </Wrap>
    </ContentsBlock>
  );
};
