/**
 * 会員証パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Button, Image, Separator, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  migrationCinemasunshineMemberData,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const QrCodeArea = styled.div`
  text-align: center;
  padding: 20px 0 12px;
`;

const QrCode = styled.img`
  width: 200px;
  height: 200px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
`;

interface TitleWrapPrpos {
  width: string;
}
const TitleWrap = styled.div<TitleWrapPrpos>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ width }) => width};
`;

interface MemberNoWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const MemberNoWrap = styled.div<MemberNoWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  padding: ${({ padding }) => padding};
`;

interface PeriodWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const PeriodWrap = styled.div<PeriodWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  padding: ${({ padding }) => padding};
`;

interface PointAreaProps {
  colorPalette: ColorPalette;
  padding: string;
}
const PointArea = styled.div<PointAreaProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  padding: ${({ padding }) => padding};
`;

interface BaseLineWrapProps {
  width: string;
}
const BaseLineWrap = styled.div<BaseLineWrapProps>`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: ${({ width }) => width};
`;

/**
 * 【有機体】 会員証パネル
 * @returns コンポーネント
 */
export const CardPanel: React.FC = () => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          titleWidth: '200px',
          valueWidth: '240px',
          memberNoSeparator: '16px',
          memberNoPadding: '8px 0 8px',
          periodPadding: '8px 0 12px',
          pointPadding: '8px 0 12px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          titleWidth: '128px',
          valueWidth: 'calc(100% - 128px)',
          memberNoSeparator: '8px',
          memberNoPadding: '8px 0 8px 8px',
          periodPadding: '8px 0 12px 8px',
          pointPadding: '8px 0 12px 8px',
        };
  }, [displaySize]);

  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  useEffect(() => {
    QRCode.toDataURL(membershipData.memberNo || '0', (err, url) => {
      setQrCodeImg(url);
    });
    if (
      membershipData.isMigration &&
      !membershipData.attributes?.isPointsAndBookingsMigration
    ) {
      setOpened(true);
    }
  }, [membershipData]);

  return (
    <Panel colorPalette={colorPalette} width={css.panelWidth} padding="0">
      <QrCodeArea>
        <QrCode src={membershipData.memberNo && qrCodeImg} />
      </QrCodeArea>

      <MemberNoWrap colorPalette={colorPalette} padding={css.memberNoPadding}>
        <TitleWrap width={css.titleWidth}>
          <Image
            src="/svg/member_icon.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            padding="0 0 0 8px"
          >
            会員番号
          </Text>
        </TitleWrap>
        <Text
          fontSize={css.fontSizeMiddle}
          lineHeight={1}
          letterSpacing={0}
          width={css.valueWidth}
          bold
        >
          {membershipData?.memberNo?.substring(0, 4) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(4, 7) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(7, 10) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(10, 14) || '―'}
        </Text>
      </MemberNoWrap>

      <PeriodWrap colorPalette={colorPalette} padding={css.periodPadding}>
        <TitleWrap width={css.titleWidth}>
          <Image
            src="/svg/calendar_icon.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            padding="0 0 0 8px"
            textAlign="center"
          >
            有効期限
          </Text>
        </TitleWrap>
        <Text
          fontSize={css.fontSizeMiddle}
          lineHeight={1}
          letterSpacing={0}
          width={css.valueWidth}
          bold
        >
          {membershipData.validPeriodTo
            ? new Date(membershipData.validPeriodTo)
                .toLocaleString('ja-JP')
                .slice(0, -3)
            : '―'}
        </Text>
      </PeriodWrap>

      <PointArea colorPalette={colorPalette} padding={css.pointPadding}>
        <TitleWrap width={css.titleWidth}>
          <Image
            src="/svg/point.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            padding="0 0 0 8px"
            textAlign="center"
          >
            有効ポイント
          </Text>
        </TitleWrap>
        <BaseLineWrap width={css.valueWidth}>
          <Text
            color={colorPalette.concept}
            fontSize={css.fontSizeLarge}
            lineHeight={1}
            letterSpacing={0}
            bold
          >
            {membershipData.totalPoints?.toLocaleString('ja-JP') || '―'}
          </Text>
          <Text
            fontSize={css.fontSizeMiddle}
            lineHeight={1}
            letterSpacing={0}
            bold
          >
            pt
          </Text>
        </BaseLineWrap>
      </PointArea>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        opened={opened}
        headerTitle="旧ポイント移行"
        headerTitleSize={css.fontSize}
        onClose={() => {
          setOpened(false);
        }}
      >
        <Text fontSize={css.fontSize} textAlign="center" margin="16px 0 0">
          新会員への登録ありがとうございます。
          <br />
          以下の「旧ポイント移行」ボタンを押していただき移行を完了して下さい。
          <br />
          移行完了していただくまで旧ポイントと鑑賞履歴が反映されません。
          <br />
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setOpened(false);
            dispatch(migrationCinemasunshineMemberData());
          }}
        >
          旧ポイント移行
        </Button>
      </Modal>
    </Panel>
  );
};
