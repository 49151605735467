/**
 * 壁紙
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, DisplaySize } from 'commons';
import React, { ReactNode, useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  fontSize: number;
  backgroundImageSrc?: string;
}
const Wrap = styled.div<WrapProps>`
  display: block;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  ${({ backgroundImageSrc }) =>
    `background-image: url("${backgroundImageSrc}");`}
  min-width: 100%;
  min-height: 100vh;
  transition: 0.3s ease-in-out;
  font-size: ${({ fontSize }) => fontSize}px;
`;

const FlexArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

interface WallpaperProps {
  children?: ReactNode;
  backgroundImageSrc?: string;
}
/**
 * 【特殊コンポーネント】 壁紙
 * @param {ReactNode} children - 子要素
 * @param {string} backgroundImageSrc - 背景画像指定時
 * @returns コンポーネント
 */
export const Wallpaper: React.FC<WallpaperProps> = ({
  children,
  backgroundImageSrc,
}: WallpaperProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { fontSize: 20 }
      : { fontSize: 16 };
  }, [displaySize]);

  return (
    <Wrap
      colorPalette={colorPalette}
      fontSize={css.fontSize}
      backgroundImageSrc={backgroundImageSrc}
    >
      <FlexArea>{children}</FlexArea>
    </Wrap>
  );
};
