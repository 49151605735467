/**
 * ローディングバー
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  width: string;
  height: string;
}
const Wrap = styled.div<WrapProps>`
  background: ${({ colorPalette }) => colorPalette.grayTone};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ height }) => `calc(${height} / 2)`};
`;

interface PercentageBarProps {
  colorPalette: ColorPalette;
  percentage: number;
  height: string;
  transitionTime: number;
}
const PercentageBar = styled.div<PercentageBarProps>`
  background: ${({ colorPalette }) => colorPalette.concept};
  width: ${({ percentage }) => percentage}%;
  height: ${({ height }) => height};
  border-radius: ${({ height }) => `calc(${height} / 2)`};
  transition: width ${({ transitionTime }) => transitionTime}s ease-in-out;
`;

interface LoadingBarProps {
  colorPalette: ColorPalette;
  percentage: number;
  width?: string;
  height?: string;
  transitionTime?: number;
  defaultValue?: number;
}
/**
 * 【原子】 ローディングバー
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {number} percentage - 【必須】 読み込み率[%]
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {number} transitionTime - アニメーションの時間[秒]
 * @param {number} defaultValue - ゲージの初期表示状態
 * @returns コンポーネント
 */
export const LoadingBar: React.FC<LoadingBarProps> = ({
  colorPalette,
  percentage,
  width = '100%',
  height = '16px',
  transitionTime = 1,
  defaultValue = 0,
}: LoadingBarProps) => {
  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    if (percentage >= 0 && percentage <= 100) {
      setValue(percentage);
    }
  }, [percentage]);

  return (
    <Wrap colorPalette={colorPalette} width={width} height={height}>
      <PercentageBar
        colorPalette={colorPalette}
        percentage={value}
        height={height}
        transitionTime={transitionTime}
      />
    </Wrap>
  );
};
