/**
 * 領収書表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlPath } from 'commons';
import { Button } from 'components/atoms';
import { ContentsBlock, InvoicePanel } from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  invoiceFeeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 16px;
`;

/**
 * 【テンプレート】 領収書表示エリア
 * @returns コンポーネント
 */
export const InvoicePrintArea: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const invoiceFee = useTypeSelector(invoiceFeeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, buttonMargin: '0 16px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, buttonMargin: '0 16px' };
  }, [displaySize]);

  return invoiceFee === '' || invoiceFee === '0' ? null : (
    <ContentsBlock>
      <ButtonWrap id="invoiceNoPrint">
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin={css.buttonMargin}
          onClick={() => {
            window.print();
          }}
        >
          再印刷
        </Button>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin={css.buttonMargin}
          onClick={() => {
            navigate(UrlPath.INVOICE);
          }}
        >
          戻る
        </Button>
      </ButtonWrap>
      <InvoicePanel />
    </ContentsBlock>
  );
};
