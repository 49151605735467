/**
 * 会員情報修正パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  Placeholder,
  postAddressFromPostalCode,
  SEX_VALUE_LIST,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import { DateForm, InputTextForm, RadioForm } from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  EventSlice,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const InputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    flex-grow: 1;
  }
`;

const validatorRules = {
  firstName: ['required', 'without_space'],
  lastName: ['required', 'without_space'],
  firstKanaName: ['required', 'without_space', 'katakana'],
  lastKanaName: ['required', 'without_space', 'katakana'],
  tel: ['phone'],
  birthDate: ['valid_date'],
  postalCode: ['required', 'zip'],
  addressAll: ['required'],
  sex: ['required'],
};

/**
 * 【有機体】 会員情報修正パネル
 * @returns コンポーネント
 */
export const ProfileEditPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [addressRef, setAddressRef] =
    useState<React.RefObject<HTMLInputElement>>();
  const [active, setActive] = useState<boolean>(false);
  const errorList = useRef<{
    firstName: boolean;
    lastName: boolean;
    firstKanaName: boolean;
    lastKanaName: boolean;
    tel: boolean;
    birthDate: boolean;
    postalCode: boolean;
    all: boolean;
    sex: boolean;
  }>({
    firstName: false,
    lastName: false,
    firstKanaName: false,
    lastKanaName: false,
    tel: false,
    birthDate: false,
    postalCode: false,
    all: false,
    sex: false,
  });
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <InputWrap>
          <InputTextForm
            initialValue={
              membershipData.lastName !== undefined
                ? membershipData.lastName || ''
                : null
            }
            validatorRules={validatorRules.lastName}
            placeholder={Placeholder.LAST_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { lastName: value };
              errorList.current.lastName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
            required
          >
            姓
          </InputTextForm>
          <Separator width="16px" />
          <InputTextForm
            initialValue={
              membershipData.firstName !== undefined
                ? membershipData.firstName || ''
                : null
            }
            validatorRules={validatorRules.firstName}
            placeholder={Placeholder.FIRST_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { firstName: value };
              errorList.current.firstName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
            required
          >
            名
          </InputTextForm>
        </InputWrap>
        <InputWrap>
          <InputTextForm
            initialValue={
              membershipData.lastKanaName !== undefined
                ? membershipData.lastKanaName || ''
                : null
            }
            validatorRules={validatorRules.lastKanaName}
            placeholder={Placeholder.LAST_KANA_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { lastKanaName: value };
              errorList.current.lastKanaName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
            required
          >
            姓(カタカナ)
          </InputTextForm>
          <Separator width="16px" />
          <InputTextForm
            initialValue={
              membershipData.firstKanaName !== undefined
                ? membershipData.firstKanaName || ''
                : null
            }
            validatorRules={validatorRules.firstKanaName}
            placeholder={Placeholder.FIRST_KANA_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { firstKanaName: value };
              errorList.current.firstKanaName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
            required
          >
            名(カタカナ)
          </InputTextForm>
        </InputWrap>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'tel' : 'text'}
          initialValue={
            membershipData.tel !== undefined ? membershipData.tel || '' : null
          }
          validatorRules={validatorRules.tel}
          placeholder={Placeholder.TEL}
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { tel: value };
            errorList.current.tel = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          電話番号
        </InputTextForm>
        <DateForm
          initialValue={
            membershipData.birthDate !== undefined
              ? membershipData.birthDate || ''
              : null
          }
          validatorRules={validatorRules.birthDate}
          yearFrom={1900}
          yearTo={new Date().getFullYear()}
          disabled
          onChange={(value, isError) => {
            const replaceData = { birthDate: value };
            errorList.current.birthDate = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          誕生日
        </DateForm>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'number' : 'text'}
          initialValue={
            membershipData.address?.postalCode !== undefined
              ? membershipData.address.postalCode || ''
              : null
          }
          validatorRules={validatorRules.postalCode}
          placeholder={Placeholder.POSTAL_CODE}
          maxLength={7}
          onChange={(value, isError) => {
            const replaceData = {
              postalCode: value,
            };
            errorList.current.postalCode = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          onBlur={async (value, isError) => {
            const replaceData = {
              postalCode: value,
            };
            errorList.current.postalCode = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
            if (!isError && value.length === 7) {
              //住所補完処理
              dispatch(
                BasicSlice.actions.changeLoadingMessage('住所情報を取得中...'),
              );
              try {
                const response = await postAddressFromPostalCode({
                  postalCode: value,
                });
                if (addressRef?.current) {
                  addressRef.current.value = `${response.prefectures || ''}${
                    response.city || ''
                  }${response.town || ''}`;
                  addressRef.current.focus();
                  addressRef.current.blur();
                }
              } catch (error) {
                dispatch(
                  BasicSlice.actions.changeErrorMessage(
                    '住所情報の取得に失敗しました。手動で入力してください。',
                  ),
                );
              }
              dispatch(BasicSlice.actions.changeLoadingMessage(''));
            }
          }}
          required
          activeFlag={active}
        >
          郵便番号
        </InputTextForm>
        <Text fontSize={css.fontSizeSmall}>
          ※「-」ハイフンなしの7桁で入力してください
        </Text>
        <InputTextForm
          initialValue={
            membershipData.address?.all !== undefined
              ? membershipData.address.all || ''
              : null
          }
          validatorRules={validatorRules.addressAll}
          placeholder={Placeholder.ADRDRESS_ALL}
          maxLength={1000}
          setRef={setAddressRef}
          onChange={(value, isError) => {
            const replaceData = { all: value };
            errorList.current.all = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          onBlur={(value, isError) => {
            const replaceData = { all: value };
            errorList.current.all = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAddress(replaceData),
            );
          }}
          required
          activeFlag={active}
        >
          住所
        </InputTextForm>
        <RadioForm
          initialValue={
            membershipData.sex !== undefined ? membershipData.sex || '' : null
          }
          validatorRules={validatorRules.sex}
          valueList={SEX_VALUE_LIST}
          disabled
          onChange={(value, isError) => {
            const replaceData = { sex: value };
            errorList.current.sex = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
        >
          性別
        </RadioForm>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={() => {
            if (
              errorList.current.firstName ||
              errorList.current.lastName ||
              errorList.current.firstKanaName ||
              errorList.current.lastKanaName ||
              errorList.current.tel ||
              errorList.current.birthDate ||
              errorList.current.postalCode ||
              errorList.current.all ||
              errorList.current.sex
            ) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力または選択した情報に誤りがあります',
                ),
              );
            } else {
              navigate(UrlPath.PROFILE_EDIT_CONFIRM);
            }
          }}
        >
          確認
        </Button>
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.PROFILE)}
        >
          {UrlName.PROFILE}画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
