/* eslint-disable max-len */
/**
 * 利用規約エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Button, Enhancement, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 160px;
`;

const ListWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

interface NumberAreaWrap {
  width: string;
}
const NumberArea = styled.div<NumberAreaWrap>`
  width: ${({ width }) => width};
`;

interface ParagraphAreaWrap {
  width: string;
}
const ParagraphArea = styled.div<ParagraphAreaWrap>`
  width: calc(100% - ${({ width }) => width});
`;

/**
 * 【テンプレート】 利用規約エリア
 * @returns コンポーネント
 */
export const UserPolicyArea: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          buttonMargin: '24px 0 0',
          textMargin: '24px 0 0',
          paragraphMargin: '0 0 0 24px',
          numberWidth: '32px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          buttonMargin: '16px 0 0',
          textMargin: '16px 0 0',
          paragraphMargin: '0 0 0 16px',
          numberWidth: '24px',
        };
  }, [displaySize]);

  // prettier-ignore
  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold underline>
            AQUARIUM×ART átoa 顔認証付きパスポート『átoa 顔 PASS（3ヶ月有効）』利用規約
          </Enhancement>
          <ListWrap>
            <NumberArea width={css.numberWidth}>1.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              ご購入後のキャンセルや変更はできません。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>2.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              生物の健康状態や気象状況、災害などにより、予告なく営業時間の変更や休館する場合がございます。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>3.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS（3 ヶ月有効）』（以下「átoa 顔PASS」）は、ご購入日から３ヶ月間(90 日間)有効です。
              なお、有効期間中、天候などの理由により予告なく休業、営業時間の変更、スケジュールの変更または中止をする場合がございます。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>4.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』は、大人のみ(中学生以上)のみの販売といたします。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>5.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』は通年販売予定のプランです。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>6.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』を利用しての AQUARIUM×ART átoa（以下「アトア」）への入場時に事前に登録済みの顔認証データによる照合を毎回行います。
              入場時は入場口に設置した専用端末にてお顔の照合を いたします。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}></NumberArea>
            <ParagraphArea width={css.numberWidth}>
              ※顏認証データを照合する際に、眼鏡・サングラス・帽子・マスク等を外していただくようお願いする場合がございます。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>7.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』は、有効期間内における平日・土日祝日・特定日を含むアトアの通常営業時間帯のみご利用できます。
              別途チケットが必要な周辺施設とのセット券、その他イベント時（年末カウントダウンなど）にはご利用できません。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>8.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』にてご入場の際は、 アトアの混雑状況による入場制限によりご入場をお待ちいただく場合、 あるいは入場制限によりご入場をお断りさせていただく場合がございます。
              なお、混雑時の優先入場などはございません。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>9.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』は、お顔を登録されたご本人様のみ有効です。
              なお、入場時に登録されたご本人様と特定できない場合は、ご入場をお断りすることがございます。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>10.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』の不正利用、またはアトア内での法令違反、もしくは公序良俗に反すると思われる行為や、他の入場者などの迷惑となる行為を認めた場合は、『átoa 顔 PASS』の利用停止を求めます。
              その際の返金対応などはいたしません。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>11.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              お申込みの情報に変更がある場合は、アトア（078-771-9393）までご連絡ください。
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>12.</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              『átoa 顔 PASS』ご利用条件は、価格や有効日などの重要な条項を除き、 予告なく当社の判断により変更される場合がございます。
              その場合は変更後の条件が適用されるものといたします。
            </ParagraphArea>
          </ListWrap>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold underline>
            個人情報の取り扱いについて
          </Enhancement>
          <br />
          ご登録いただいた個人情報は、アトアが適切に管理を行い、以下の目的にのみ利用いたします。
          また、ご登録者本人の同意なく第三者へ開示・提供することはございません。
          <ListWrap>
            <NumberArea width={css.numberWidth}>・</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              AQUARIUM×ART átoa 『átoa 顔 PASS』の管理
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>・</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              個人を特定しない統計情報
            </ParagraphArea>
          </ListWrap>
          <ListWrap>
            <NumberArea width={css.numberWidth}>・</NumberArea>
            <ParagraphArea width={css.numberWidth}>
              法令に基づき公的機関から開示要請があった場合の情報提供
            </ParagraphArea>
          </ListWrap>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold underline>
            お問い合せ先
          </Enhancement>
          <br />
          AQUARIUM×ART átoa（アトア）受付時間 10:00～19:00（休館日を除く）
          <br />
          TEL： 078-771-9393 / MAIL： info-atoa@aquament.co.jp
        </Text>
      </Panel>
      <Button
        colorPalette={colorPalette}
        width={css.buttonWidth}
        margin={css.buttonMargin}
        onClick={() => {
          if (history.length <= 1) {
            window.close();
          } else {
            history.back();
          }
        }}
      >
        戻る
      </Button>
    </Wrap>
  );
};
