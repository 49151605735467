import moment from 'moment';
import Validator from 'validatorjs';

/** 【メッセージ】バリデーションエラーメッセージ */
export const VALIDATE_ERROR_MESSAGE = {
  /* 共通 */
  REQUIRED: '必須項目です',
  REGEX: '不正な入力値です',
  MAX: ':max桁以下を入力してください',
  MIN: ':min桁以上を入力してください',
  NUMERIC: '数値を入力してください',
  INTEGER: '整数を入力してください',
  ALPHA: '英字で入力してください',
  ALPHA_NUM: '英数字で入力してください',
  EMAIL: 'メールアドレスの形式で入力してください',
  RANGE: ':min〜:maxを入力してください',
  NOT_IN: '必須項目です',
  /* テキストフィールド(数値) */
  TEXT_NUMBER_FIELD_MAX: ':max以下を入力してください',
  TEXT_NUMBER_FIELD_MIN: ':min以上を入力してください',
  /* テキストフィールド(文字) */
  TEXT_STRING_FIELD_MAX: ':max文字以下を入力してください',
  TEXT_STRING_FIELD_MIN: ':min文字以上を入力してください',
  /* パスワードフィールド */
  PASSWORD_FIELD_MAX: ':max文字以下を入力してください',
  PASSWORD_FIELD_MIN: ':min文字以上を入力してください',
  /* テキストエリア */
  TEXT_AREA_MAX: ':max文字以下を入力してください',
  TEXT_AREA_MIN: ':min文字以上を入力してください',
  /* ラジオボタングループ */
  RADIO_GROUP_MIN: '必須項目です',
  /* チェックボックスグループ */
  CHECKBOX_GROUP_MAX: ':max個以下を選択してください',
  CHECKBOX_GROUP_MIN: ':min個以上を選択してください',
  /* 年月日 */
  DATE_INVALID: '正しい日付ではありません',
  DATE_AFTER: '本日以降の日付は入力できません',
  DATE_BEFORE: '本日より以前の日付は入力できません',
  DATE_TWENTY: '20歳未満の入力はできません',
  DATE_YEARS_AND_OVER: ':years_and_over歳未満の入力はできません',
  /* スライダー */
  SLIDER_MAX: ':max以下を入力してください',
  SLIDER_MIN: ':min以上を入力してください',
  /* スライダーリスト */
  SLIDER_GROUP_MAX: '全項目の合計を:max以下にしてください',
  SLIDER_GROUP_MIN: '全項目の合計を:min以上にしてください',
  SLIDER_GROUP_IN: '全項目の合計を:inにしてください',
  /* ファイルアップロード */
  UPLOAD_MAX: 'ファイル数を:max個以下にしてください',
  UPLOAD_MIN: 'ファイル数を:min個以上にしてください',
  UPLOAD_IN: 'ファイル数を:in個にしてください',
  /* 電話番号 */
  PHONE: '電話番号を入力してください',
  /* 郵便番号 */
  ZIP: '郵便番号を入力してください',
  /* メールアドレス文字 */
  EMAIL_STRING: 'メールアドレスに使えない文字が含まれています',
  /* 登録無料クーポン */
  REGISTER_COUPON: '14桁のクーポン番号を入力してください',
  /* カタカナ */
  KATAKANA: 'カタカナで入力してください',
  /* スペース禁止 */
  WITHOUT_SPASE: '空白文字(スペース)は入力できません',
  /* 整数部の最大桁数 */
  MAX_INTEGER_DIGIT: '整数桁は:max_integer_digit桁以下にしてください',
  /* 小数部の最大桁数 */
  MAX_DECIMAL_DIGIT: '小数桁は:max_decimal_digit桁以下にしてください',
  /* 会員番号 */
  MEMBER_NO: '会員番号を入力してください',
};

Validator.useLang('ja');

// 不正日付のカスタムバリデーションを追加
Validator.register(
  'valid_date',
  (value) => {
    if (
      typeof value === 'string' &&
      value.indexOf('_') === -1 &&
      value.length === 10
    ) {
      return moment(value, 'YYYY/MM/DD').isValid();
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.DATE_INVALID,
);

// 電話番号
Validator.register(
  'phone',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[+\d]{1}\d+-?\d+-?\d+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.PHONE,
);

// 電話番号(ハイフン無し)
Validator.register(
  'phone_no_hyphen',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[+\d]{1}\d+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.PHONE,
);

// 郵便番号
Validator.register(
  'zip',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^\d{3}-?\d{4}$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.ZIP,
);

// 登録無料クーポン
Validator.register(
  'email_string',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[a-zA-Z0-9_.+-@]+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.EMAIL_STRING,
);

// 登録無料クーポン
Validator.register(
  'register_coupon',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^\d{14}$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.REGISTER_COUPON,
);

// 会員ID
Validator.register(
  'member_no',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^\d{13}$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MEMBER_NO,
);

// 旧会員ID
Validator.register(
  'previous_no',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^\d{13}$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MEMBER_NO,
);

// カタカナ
Validator.register(
  'katakana',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[ァ-ヴー]+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.KATAKANA,
);

// カタカナ+スペース
Validator.register(
  'katakana_with_space',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/^[ァ-ヴー\s]+$/) !== null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.KATAKANA,
);

// スペースNG
Validator.register(
  'without_space',
  (value) => {
    if (typeof value === 'string') {
      return value.match(/\s/) === null;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.WITHOUT_SPASE,
);

// 整数桁数
// 最初に0があった場合でも桁数として考える
// 何も入力がない場合はtrue
// 指数表記はエラー
// 数値に変換できない場合はエラー
// .1などの書き方は一旦認めない(parseInt('.1')はNaNとなるため)
Validator.register(
  'max_integer_digit',
  (value, max) => {
    if (typeof value === 'string') {
      if (value === '') {
        return true;
      }
      if (Number.isNaN(Number(value))) {
        return false;
      }
      if (value.indexOf('e') !== -1) {
        return false;
      }
      const beforeDecimal = value.split('.')[1];
      const val = parseInt(beforeDecimal, 10);
      const len = parseInt(max, 10);
      if (Number.isNaN(len) || Number.isNaN(val)) {
        return false;
      }
      if (val < 0) {
        return beforeDecimal.length <= len + 1;
      }
      return beforeDecimal.length <= len;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MAX_INTEGER_DIGIT,
);

// 小数桁数
// 後ろに0があった場合でも桁数として数える
// 何も入力がない場合はtrue
// 指数表記はエラー
// 数値に変換できない場合はエラー
Validator.register(
  'max_decimal_digit',
  (value, max) => {
    if (typeof value === 'string') {
      if (value === '') {
        return true;
      }
      if (Number.isNaN(Number(value))) {
        return false;
      }
      if (value.indexOf('e') !== -1) {
        return false;
      }
      const afterDecimal = value.split('.')[1];
      if (!afterDecimal || afterDecimal === '') {
        return true;
      }
      const val = parseInt(afterDecimal, 10);
      const len = parseInt(max, 10);
      if (Number.isNaN(len) || Number.isNaN(val)) {
        return false;
      }
      return afterDecimal.length <= len;
    }
    return false;
  },
  VALIDATE_ERROR_MESSAGE.MAX_DECIMAL_DIGIT,
);

export interface ValidateErrorProps {
  isError: boolean;
  errorMessage: string;
}

export const VALIDATOR_DEFAULT_MESSAGE = {
  required: VALIDATE_ERROR_MESSAGE.REQUIRED,
  regex: VALIDATE_ERROR_MESSAGE.REGEX,
  max: VALIDATE_ERROR_MESSAGE.MAX,
  min: VALIDATE_ERROR_MESSAGE.MIN,
  numeric: VALIDATE_ERROR_MESSAGE.NUMERIC,
  integer: VALIDATE_ERROR_MESSAGE.INTEGER,
  alpha: VALIDATE_ERROR_MESSAGE.ALPHA,
  alpha_num: VALIDATE_ERROR_MESSAGE.ALPHA_NUM,
  email: VALIDATE_ERROR_MESSAGE.EMAIL,
  not_in: VALIDATE_ERROR_MESSAGE.NOT_IN,
};

export interface ValidateValueProps {
  value: string;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
}

/**
 * 【汎用関数】バリデーション実施関数
 * @param {string} value - [必須] 比較値
 * @param {string[]} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 */
export const validateValue = ({
  value,
  validatorRules,
  validatorMessage,
}: ValidateValueProps): ValidateErrorProps => {
  let isError = false;
  let errorMessage = '';
  if (validatorRules) {
    const validation = new Validator(
      { value },
      { value: validatorRules },
      {
        ...VALIDATOR_DEFAULT_MESSAGE,
        ...validatorMessage,
      },
    );
    isError = validation.fails() === true;
    errorMessage = isError ? validation.errors.errors.value[0] : '';
  }
  return { isError, errorMessage };
};

export type ValidateCheckboxProps = {
  values: string[];
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
};
/**
 * 【汎用関数】チェックボックスバリデーション実施関数
 * @param {boolean[]} values - [必須] 比較値
 * @param {{value: string[]}} validatorRules - バリデーション内容
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ上書き用
 */
export const validateCheckbox = ({
  values,
  validatorRules,
  validatorMessage,
}: ValidateCheckboxProps): ValidateErrorProps => {
  let isError = false;
  let errorMessage = '';
  if (validatorRules) {
    const validation = new Validator(
      { value: values.length > 0 ? values.length : '' },
      { value: validatorRules },
      {
        ...VALIDATOR_DEFAULT_MESSAGE,
        max: VALIDATE_ERROR_MESSAGE.CHECKBOX_GROUP_MAX,
        ...validatorMessage,
      },
    );
    isError = validation.fails() === true;
    errorMessage = isError ? validation.errors.errors.value[0] : '';
  }
  return { isError, errorMessage };
};
