/**
 * パネルメニュー
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
} from 'commons';
import { Image, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  maxWidth: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0 8px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
`;

const IconWrap = styled.div`
  text-align: center;
  margin: 0;
`;

interface RegisterIconProps {
  width: string;
  height: string;
}
const RegisterIcon = styled.img<RegisterIconProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: absolute;
  top: -2px;
  right: -2px;
`;

/**
 * 【有機体】 パネルメニュー
 * @returns コンポーネント
 */
export const PanelMenu: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          width: '128px',
          padding: '16px 4px 8px',
          margin: '16px 0 0',
          registerIconSize: '160px',
          panelWidth: '640px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          width: '31%',
          padding: '16px 8px 12px',
          margin: '16px 0 0',
          registerIconSize: '120px',
          panelWidth: '100%',
        };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} maxWidth={css.panelWidth}>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.AQUARIUM_HISTORY)}
      >
        <IconWrap>
          <Image src="/svg/aquarium_history.svg" width="48px" height="48px" />
        </IconWrap>
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSizeSmall}
          textAlign="center"
          bold
        >
          {UrlName.AQUARIUM_HISTORY}
        </Text>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.PROFILE)}
      >
        <IconWrap>
          <Image src="/svg/setting.svg" width="48px" height="48px" />
        </IconWrap>
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSizeSmall}
          textAlign="center"
          bold
        >
          {UrlName.PROFILE}
        </Text>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        position="relative"
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.FACE_REGISTER)}
      >
        {membershipData.facePhotoCreatedAt && (
          <RegisterIcon
            src="/svg/registered.svg"
            width={css.registerIconSize}
            height={css.registerIconSize}
          />
        )}
        <IconWrap>
          <Image src="/svg/face_photo.svg" width="48px" height="48px" />
        </IconWrap>
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSizeSmall}
          textAlign="center"
          bold
        >
          {UrlName.FACE_REGISTER}
        </Text>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.RENEW)}
      >
        <IconWrap>
          <Image src="/svg/update.svg" width="48px" height="48px" />
        </IconWrap>
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSizeSmall}
          textAlign="center"
          bold
        >
          {UrlName.RENEW}
        </Text>
      </Panel>
    </Wrap>
  );
};
