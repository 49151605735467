/**
 * 領収書印刷ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents, InvoicePrintArea } from 'components/templates';
import React from 'react';
import { didMountInvoicePrintPage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 領収書印刷ページ
 * @returns コンポーネント
 */
export const InvoicePrint: React.FC = () => {
  const dispatch = useTypeDispatch();

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.INVOICE_PRINT;
    dispatch(didMountInvoicePrintPage());
  });

  return (
    <Contents>
      <InvoicePrintArea />
    </Contents>
  );
};
