/**
 * 有効期限更新パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  getWeekString,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Enhancement, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import { MemberQrPanel } from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  FeeListSelector,
  membershipDataSelector,
  updateValidPeriod,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

/**
 * 【有機体】 有効期限更新パネル
 * @returns コンポーネント
 */
export const RenewPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const feeList = useTypeSelector(FeeListSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const date = useMemo(() => {
    if (
      membershipData.validPeriodTo &&
      membershipData.updatablePeriodTo &&
      membershipData.updatablePeriodFrom
    ) {
      return {
        toDay: new Date(),
        validPeriodTo: new Date(membershipData.validPeriodTo),
        validPeriodToWeekString: getWeekString(membershipData.validPeriodTo),
        updatablePeriodFrom: new Date(membershipData.updatablePeriodFrom),
        updatablePeriodTo: new Date(membershipData.updatablePeriodTo),
      };
    } else {
      return {
        toDay: new Date(),
        validPeriodTo: undefined,
        validPeriodToWeekString: undefined,
        updatablePeriodFrom: undefined,
        updatablePeriodTo: undefined,
      };
    }
  }, [membershipData]);
  const price = useMemo(() => {
    const fee = feeList.membershipFeeList?.find(
      (fee) => fee.code === membershipData.membership?.code,
    );
    return fee?.value ? fee.value : NaN;
  }, [feeList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          textMargin: '0 0 24px',
          textAlign: 'center',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          textMargin: '0 0 16px',
          textAlign: 'left',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center">
          お客様の会員有効期限は
        </Text>
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSizeLarge}
          textAlign="center"
          bold
        >
          {date.validPeriodTo
            ? date.validPeriodTo.toLocaleDateString('ja-JP')
            : '―'}
          {date.validPeriodToWeekString
            ? `(${date.validPeriodToWeekString})`
            : '(―)'}
        </Text>
        {date.validPeriodTo &&
        date.updatablePeriodFrom &&
        date.validPeriodTo > date.toDay ? (
          <>
            <Text
              fontSize={css.fontSize}
              textAlign="center"
              margin={css.textMargin}
            >
              までとなります。
            </Text>
            <Text fontSize={css.fontSize} textAlign={css.textAlign}>
              上記有効期限までに会員の更新を行ってください。
              <br />
              更新は、
              <Enhancement underline bold>
                {date.updatablePeriodFrom
                  ? date.updatablePeriodFrom.toLocaleDateString('ja-JP')
                  : '―'}
              </Enhancement>
              以降に可能となります。
            </Text>
          </>
        ) : (
          <>
            <Text
              fontSize={css.fontSize}
              textAlign="center"
              margin={css.textMargin}
            >
              に切れました。
            </Text>
            <Text fontSize={css.fontSize} textAlign={css.textAlign}>
              <Enhancement underline bold>
                {date.updatablePeriodTo
                  ? date.updatablePeriodTo.toLocaleDateString('ja-JP')
                  : '―'}
              </Enhancement>
              までに会員の更新を行ってください。
            </Text>
          </>
        )}
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="16px auto 8px"
          disabled={
            date.updatablePeriodFrom && date.updatablePeriodFrom > date.toDay
          }
          onClick={() => {
            dispatch(
              updateValidPeriod({
                renewFee: !Number.isNaN(price) ? price : undefined,
              }),
            );
          }}
        >
          更新する
          <br />
          (更新費用{!Number.isNaN(price) ? price.toLocaleString('ja-JP') : '―'}
          円)
        </Button>
        <Text fontSize={css.fontSizeSmall} textAlign={css.textAlign}>
          有効期間を過ぎても更新を行わない場合は、特典その他サービスの提供は失効いたします。
        </Text>
        <MemberQrPanel />
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
