/**
 * ヘッダー内メニューボタン
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { Image } from 'components/atoms';
import React, { useMemo } from 'react';
import {
  BasicSlice,
  mainMenuOpenSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  padding: 4px 0 0;
  cursor: pointer;
`;

interface HeaderMenuButtonProps {
  isDark?: boolean;
  isCircle?: boolean;
}
/**
 * 【有機体】 ヘッダー内メニューボタン
 * @param {boolean} isDark - 色を黒にする
 * @returns コンポーネント
 */
export const HeaderMenuButton: React.FC<HeaderMenuButtonProps> = ({
  isDark = false,
  isCircle = false,
}: HeaderMenuButtonProps) => {
  const dispatch = useTypeDispatch();
  const mainMenuOpen = useTypeSelector(mainMenuOpenSelector);
  const src = useMemo(() => {
    if (isCircle) {
      return {
        menu: '/svg/menu_circle_dark.svg',
        menuSize: '40px',
        close: '/svg/close_dark.svg',
      };
    }
    if (isDark) {
      return {
        menu: '/svg/menu_dark.svg',
        menuSize: '32px',
        close: '/svg/close_dark.svg',
      };
    }
    return {
      menu: '/svg/menu.svg',
      menuSize: '32px',
      close: '/svg/close.svg',
    };
  }, [isDark]);

  return (
    <>
      {mainMenuOpen ? (
        <Wrap
          onClick={() => {
            dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          }}
        >
          <Image src={src.close} width="32px" height="32px" />
        </Wrap>
      ) : (
        <Wrap
          onClick={() => {
            dispatch(BasicSlice.actions.changeMainMenuOpen(true));
          }}
        >
          <Image src={src.menu} width={src.menuSize} height={src.menuSize} />
        </Wrap>
      )}
    </>
  );
};
