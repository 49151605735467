/**
 * コンテンツタイトル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Text } from 'components/atoms';
import React, { ReactNode, useMemo } from 'react';
import { displaySizeSelector, useTypeSelector } from 'stores';

interface ContentsTitleProps {
  children?: ReactNode;
}
/**
 * 【有機体】 コンテンツタイトル
 * @param {ReactNode} children - 子要素
 * @returns コンポーネント
 */
export const ContentsTitle: React.FC<ContentsTitleProps> = ({
  children,
}: ContentsTitleProps) => {
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Text fontSize={css.fontSizeMiddle} lineHeight={1} textAlign="center">
      {children}
    </Text>
  );
};
