/**
 * 姓名分離修正パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  LocalStorageKey,
  LoginStatus,
  Placeholder,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import {
  DirectionMessagePanel,
  InputTextForm,
  MemberQrPanel,
  MessageModal,
} from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  EventSlice,
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  submitMembershipDataSelector,
  updateProfile,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px;
  width: 100%;
  max-width: calc(100%);
`;

const InputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    flex-grow: 1;
  }
`;

const validatorRules = {
  firstName: ['required', 'without_space'],
  lastName: ['required', 'without_space'],
  firstKanaName: ['required', 'without_space', 'katakana'],
  lastKanaName: ['required', 'without_space', 'katakana'],
};

/**
 * 【有機体】 姓名分離修正パネル
 * @returns コンポーネント
 */
export const NameSeparationEditPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const submitMembershipData = useTypeSelector(submitMembershipDataSelector);
  const [active, setActive] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const errorList = useRef<{
    firstName: boolean;
    lastName: boolean;
    firstKanaName: boolean;
    lastKanaName: boolean;
  }>({
    firstName: true,
    lastName: true,
    firstKanaName: true,
    lastKanaName: true,
  });
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <ContentsWrap>
        <DirectionMessagePanel>
          インターネットでご予約の際、購入者情報の姓欄にフルネームが記載される状態を解消するため、お手数ですが、姓と名を分けて再登録をお願いいたします。
        </DirectionMessagePanel>
      </ContentsWrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <InputWrap>
          <InputTextForm
            initialValue={
              membershipData.lastName !== undefined
                ? membershipData.lastName || ''
                : null
            }
            validatorRules={validatorRules.lastName}
            placeholder={Placeholder.LAST_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { lastName: value };
              errorList.current.lastName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
          >
            姓
          </InputTextForm>
          <Separator width="16px" />
          <InputTextForm
            initialValue={membershipData.firstName !== undefined ? '' : null}
            validatorRules={validatorRules.firstName}
            placeholder={Placeholder.FIRST_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { firstName: value };
              errorList.current.firstName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
          >
            名
          </InputTextForm>
        </InputWrap>
        <InputWrap>
          <InputTextForm
            initialValue={
              membershipData.lastKanaName !== undefined
                ? membershipData.lastKanaName || ''
                : null
            }
            validatorRules={validatorRules.lastKanaName}
            placeholder={Placeholder.LAST_KANA_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { lastKanaName: value };
              errorList.current.lastKanaName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
          >
            姓(カタカナ)
          </InputTextForm>
          <Separator width="16px" />
          <InputTextForm
            initialValue={
              membershipData.firstKanaName !== undefined ? '' : null
            }
            validatorRules={validatorRules.firstKanaName}
            placeholder={Placeholder.FIRST_KANA_NAME}
            maxLength={200}
            errorFontSize={css.fontSizeTiny}
            onChange={(value, isError) => {
              const replaceData = { firstKanaName: value };
              errorList.current.firstKanaName = isError;
              dispatch(
                EventSlice.actions.changeSubmitMembershipData(replaceData),
              );
            }}
            activeFlag={active}
          >
            名(カタカナ)
          </InputTextForm>
        </InputWrap>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={() => {
            if (
              errorList.current.firstName ||
              errorList.current.lastName ||
              errorList.current.firstKanaName ||
              errorList.current.lastKanaName
            ) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力した情報に誤りがあります',
                ),
              );
            } else {
              setOpened(true);
            }
          }}
        >
          確認
        </Button>
        <MemberQrPanel />
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            localStorage.setItem(
              LocalStorageKey.LOGIN_STATUS,
              LoginStatus.RELOGIN,
            );
            navigate(UrlPath.LOGOUT);
          }}
        >
          {UrlName.LOGIN}画面に戻る
        </Link>
      </Text>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        headerTitle="姓名再登録確認"
        headerTitleSize={css.fontSize}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
          {submitMembershipData.lastName}
          <Separator width={`${css.fontSize}px`} />
          {submitMembershipData.firstName}({submitMembershipData.lastKanaName}
          <Separator width={`${css.fontSize}px`} />
          {submitMembershipData.firstKanaName})
        </Text>
        <Text
          fontSize={css.fontSize}
          lineHeight={1.3}
          textAlign="center"
          margin="12px 0 0"
        >
          上記で再登録を行ないます。
          <br />
          よろしいですか？
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setOpened(false);
            dispatch(updateProfile());
          }}
        >
          はい
        </Button>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          color={colorPalette.negative}
          width={css.buttonWidth}
          margin="24px auto 16px"
          onClick={() => {
            setOpened(false);
          }}
        >
          戻る
        </Button>
      </Modal>
      <MessageModal
        onClose={() => {
          localStorage.setItem(LocalStorageKey.NAME_SEPARATED, 'true');
          navigate(UrlPath.CARD);
        }}
      />
    </Wrap>
  );
};
