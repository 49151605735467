/**
 * 会員ID入力フォーム
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  validateValue,
} from 'commons';
import { RequiredIcon, Text, ValidatorMessage } from 'components/atoms';
import { InputText } from 'components/molecules';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const InputWrap = styled.div`
  display: flex;
  margin: 0 0 8px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

interface PreviousNoFormProps {
  children?: ReactNode;
  initialValue: string | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  activeFlag?: boolean;
  required?: boolean;
}
/**
 * 【分子】 旧会員ID入力フォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string} initialValue - 【必須】 初期値(nullで非表示)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @param {boolean} required - 必須項目指定
 * @returns コンポーネント
 */
export const PreviousNoForm: React.FC<PreviousNoFormProps> = ({
  children,
  initialValue,
  validatorRules,
  validatorMessage,
  activeFlag,
  required = false,
}: PreviousNoFormProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const values = useRef<string>(initialValue || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(
    initialValue ? true : false,
  );
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '8px 12px',
          margin: '24px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '8px 12px',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: values.current,
        validatorRules,
        validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <>
      {initialValue !== null ? (
        <Wrap colorPalette={colorPalette} margin={css.margin}>
          <TitleWrap>
            {required && (
              <RequiredIcon
                colorPalette={colorPalette}
                fontSize={css.requiredFontSize}
                padding={css.requiredPadding}
                margin={css.requiredMargin}
              />
            )}
            <Text fontSize={css.fontSize} bold>
              {children}
            </Text>
          </TitleWrap>
          <ValidatorMessage
            colorPalette={colorPalette}
            fontSize={css.fontSizeSmall}
            isDisplay={isActive && isError}
            message={errorMessage}
          >
            <InputWrap>
              <InputText
                colorPalette={colorPalette}
                initialValue={initialValue || ''}
                fontSize={css.fontSize}
                width="100%"
                padding={css.padding}
                maxLength={99}
                disabled
              />
            </InputWrap>
          </ValidatorMessage>
        </Wrap>
      ) : null}
    </>
  );
};
