/**
 * ブラウザ情報の取得
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import platform from 'platform';
import React, { useEffect, useMemo } from 'react';
import { BasicSlice, useTypeDispatch } from 'stores';

/**
 * 【特殊コンポーネント】 ブラウザ情報の取得
 */
export const CheckBrowser: React.FC = () => {
  const dispatch = useTypeDispatch();

  const os = useMemo(() => {
    if (
      platform.os?.family === 'OS X' &&
      'ontouchend' in document &&
      platform.name === 'Safari'
    ) {
      return {
        name: 'iOS',
        version: platform.os.version || '判別不能',
      };
    }
    if (platform.os?.family === 'Windows' && platform.os.version === '10') {
      return { name: 'Windows', version: platform.os.version };
    }
    return {
      name: platform.os?.family || '判別不能',
      version: platform.os?.version || '判別不能',
    };
  }, [platform.os?.family, platform.os?.version, platform.name]);

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      dispatch(
        BasicSlice.actions.changeBrowser({
          name: platform.name || '判別不能',
          version: platform.version || '判別不能',
          os,
          pwa: true,
        }),
      );
    } else {
      dispatch(
        BasicSlice.actions.changeBrowser({
          name: platform.name || '判別不能',
          version: platform.version || '判別不能',
          os,
          pwa: false,
        }),
      );
    }
    return () => {
      dispatch(
        BasicSlice.actions.changeBrowser({
          name: platform.name || '判別不能',
          version: platform.version || '判別不能',
          os,
          pwa: false,
        }),
      );
    };
  }, [platform.name, os]);

  return null;
};
