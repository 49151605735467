/**
 * 利用規約エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Button, Enhancement, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 160px;
`;

/**
 * 【テンプレート】 利用規約エリア
 * @returns コンポーネント
 */
export const UserPolicyArea: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          panelMargin: '24px 0 0',
          buttonMargin: '24px 0 0',
          textMargin: '24px 0 0',
          paragraphMargin: '0 0 0 24px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          panelMargin: '16px 0 0',
          buttonMargin: '16px 0 0',
          textMargin: '16px 0 0',
          paragraphMargin: '0 0 0 16px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center" bold>
          利用規約について
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          本規約は、佐々木興業株式会社（以下「当社」）が独自に規定するものであり、
          当社の運営する「シネマサンシャイン」・「ディノスシネマ」が提供するサービスに提供されるものとします。
          <br />
          当社が提供するシネマサンシャイン・ディノスシネマをご利用になる場合には、本規約に従っていただく必要があります。
          ご利用の皆様はシネマサンシャイン・ディノスシネマの利用をもって、本規約の内容を承諾いただけたものとみなします。
          <br />
          本規約は必要に応じて適宜見直しを行ないますので、ご利用の際には本ページに掲載されている最新の利用規約をご確認下さい。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>1. 個人登録情報の取り扱いについて</Enhancement>
          <br />
          個人情報に関してはシネマサンシャイン・ディノスシネマにて最大限の注意を払い管理いたします。
          個人情報の取り扱いについては、プライバシーポリシーにまとめてありますので、そちらもご覧下さい。
          <br />
          またシネマサンシャイン・ディノスシネマの営業譲渡が行なわれる際は、譲渡を受けた獲得者に移管いたします。
          なお、退会申請には速やかに応じ、個人情報を削除いたします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>2. ID及びパスワードの管理について</Enhancement>
          <br />
          ID及びパスワードの管理は、登録ユーザーの責任において行なっていただきます。
          ID及びパスワードを利用して行なわれた行為は、そのIDを保有している登録ユーザーの責任とみなします。
          <br />
          第三者への漏洩の疑いがある場合は、直ちにシネマサンシャイン・ディノスシネマまでご連絡下さい。
          シネマサンシャイン・ディノスシネマはID及びパスワードの不正使用などから生じた損害について一切責任を負いません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>3. 登録事項の変更について</Enhancement>
          <br />
          ユーザー登録の必須項目に変更があった際には、速やかに情報の変更をお願いいたします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>4. 禁止事項</Enhancement>
          <br />
          他の利用者や第三者を誹謗中傷する行為や、不当に足を引っ張ろうとする行為。
          <br />
          公正な場を不当に歪めようとする行為。
          <br />
          運営者が、不適切と判断した行為。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>
            5. 登録ユーザーの資格保留又は削除について
          </Enhancement>
          <br />
          シネマサンシャイン・ディノスシネマは、上記禁止事項に違反した登録ユーザーに対し、
          資格を保留・削除する権利を保有します。
          また、コンテンツを削除する権利も保有します。
          その判断に関しては、運営者の裁量にて行使されます。
          これにより損害が発生した場合でもシネマサンシャイン・ディノスシネマは責任を負いません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>6. サービスの中断</Enhancement>
          <br />
          シネマサンシャイン・ディノスシネマは、設備的事情等のやむを得ない場合に限って、
          利用者に事前通知なくサービスを一時的に中断することがあります。
          その際に利用者に生じた不利益や損失などに対して、一切の責任を負わないものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>7. 営業の中止について</Enhancement>
          <br />
          シネマサンシャイン・ディノスシネマは、利用者にWebサイト上またはメールによる通知の上、サービスの全部または一部を中止できるものとします。
          サービスの終了に伴い生じる利用者の不利益や損害などに対して、一切の責任を負わないものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>8. 免責事項</Enhancement>
          <br />
          シネマサンシャイン・ディノスシネマは、本サイトの利用に際して利用者が生じた不利益や損害などに対して、一切の責任を負わないものとします。
          <br />
          利用者が本サイトから得る情報などについての一切は、その受け手の責任において判断するものとし、
          シネマサンシャイン・ディノスシネマは、いかなる保証も行なわないものにします。
          <br />
          利用者が使用する機器・ソフトウェアについて、シネマサンシャイン・ディノスシネマは、その動作保証は一切行なわないものとします。
          <br />
          シネマサンシャイン・ディノスシネマをきっかけに契約に至った案件に関して、その後に生じたトラブルなど、
          シネマサンシャイン・ディノスシネマでは一切の責任を負わないものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>9. 準拠法および裁判管轄について</Enhancement>
          <br />
          本規約には、日本法が適用されます。
          また、本規約に関連する利用者とシネマサンシャイン・ディノスシネマ間の紛争については、東京地方裁判所を第一審専属管轄裁判所とします。
        </Text>
      </Panel>

      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
        margin={css.panelMargin}
      >
        <Text fontSize={css.fontSize} textAlign="center" bold>
          シネマサンシャインリワード 会員利用規約
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          佐々木興業株式会社（以下、「当社」といいます）は、
          当社の会員組織「シネマサンシャインリワード」のサービス（以下「本サービス」といいます）について、以下の通り規約（以下「本規約」といいます）を定めます。
          <br />
          シネマサンシャインリワード会員（以下「会員」といいます。）とは、本規約に同意の上、当社が定める手続きによって入会を申込み、当社が申込みを承諾した個人をいいます。
          なお、日本国内に住所のある個人のみ入会の申込みをすることができます。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          本規約の内容は予告なく変更される場合があります。
          本規約の変更後に本会員が本サービスを利用した場合は、変更後の本規約の内容に同意したものとみなされます。
          なお、本規約の最新の内容は、シネマサンシャインホームページ内において確認することができます。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜入会・会員資格等について＞</Enhancement>
          <br />
          シネマサンシャインリワードへの入会を希望する場合は、本規約に同意した上で、必要な会員情報を登録することにより、ユーザーアカウント登録を行ってください。
          本規約に同意しない場合は、入会はできません。
          <br />
          シネマサンシャインリワードへの入会は、ユーザーアカウント登録時に入会金500円（消費税込み）を支払った時点で成立します。
          なお、入会金の支払は、当社指定のクレジットカードでの決済が承認された時点で成立します。
          <br />
          暴力団等反社会勢力（＜会員の表明・確約について＞において定義）に該当すると認められる方は入会することはできません。
          <br />
          本サービスは、会員本人のみが利用することができます。
          会員は、重複して入会の申し込みをすることができません。
          <br />
          本会員は、ユーザーアカウント並びにこれに基づく権利義務（サービスや特典等を含みます）を第三者に譲渡又は貸与することはできません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜特典について＞</Enhancement>
          <br />
          入会時、鑑賞ポイント（以下「ポイント」といいます。）を1ポイントサービス進呈します。
          <br />
          入会後、本会員が会員ログインの上、インターネット予約で日時指定の有料映画鑑賞券を購入した場合に、同一作品同一回決済1回につき１ポイントが当該会員に付与されます。
          ただし、一部の特別上映やイベント上映等、当社が指定した有料映画鑑賞券については、ポイントが付与されない場合がございます。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          本会員は、次の数のポイントを使用することにより、次の特典を受けることができます。
          ただし、本会員が、特典として、IMAX®シアター、4DX®シアター又は3D上映等の特殊な形式で上映される作品の有料鑑賞券を購入する場合は、
          別途差額料金が発生します（別途差額料金を当該会員に負担して頂きます）。
          また、当社が指定する一部の映画は、特典の対象外となる場合があります。
          なお、本会員が特典を利用して有料鑑賞券を購入した場合には、当該券種のみの決済分についてポイントは付与されません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          (1) ２ポイント消費：1,200円で映画を１回鑑賞することができます。
          <br />
          (2) ６ポイント消費：無料で映画を１回鑑賞することができます。
          <br />
          (1)(2)の特典を利用する場合、マイページ内でポイントをクーポンに交換することで当該の券種が選択可能になります。
          本会員は、映画館シネマサンシャイン・ディノスシネマのうち当社が指定する特定の映画館でのみ、特典を利用することができます。
          なお、特典が利用可能な映画館は、会員マイページにおいて確認することができます。
          <br />
          複数のユーザーアカウント間でのポイントの移行や合算はできません。
          <br />
          ポイントの現金への換金はできません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜本人確認について＞</Enhancement>
          <br />
          本サービスを利用して購入した有料映画鑑賞券を使用する場合、又は、本会員が本サービスの特典を利用する場合に、
          当社は、本サービスの利用者が本会員本人であることを確認するために、本サービスの利用者に本人確認資料の提示を求める場合があります。
          本サービスの利用者は、当社から本人確認資料の提示を求められた場合は、これに応じなければなりません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜ポイントの有効期間について＞</Enhancement>
          <br />
          本会員に付与されたポイントは、当該会員がシネマサンシャインリワードの会員資格を失うまでの間、有効に利用することができます。
          本会員が会員資格を失った場合は、その理由の如何にかかわらず、かつ当該会員がポイントを付与された時期にかかわらず、
          当該会員が保有していたポイントは無効となります。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員資格の有効期間・更新について＞</Enhancement>
          <br />
          本会員の会員資格の有効期間は、入会日から１年間です。
          入会後は、有効期間の途中にて会員資格を喪失した場合でも、その理由の如何を問わず、入会金は返金しません。
          会員は当社所定の手続きにより、更新年会費500円（消費税込み）を支払うことで有効期間の更新をすることができます。
          更新期間は、有効期間満了の3か月前から1年後までです。
          更新により、更新前の有効期限日より1年間、有効期間を延長いたします。（翌年以降の延長も同様です）。
          <br />
          更新後は、更新後の有効期間の途中にて会員資格を喪失した場合でも、その理由の如何を問わず、更新年会費は返金しません。
          <br />
          本会員が会員資格の有効期間を更新した場合は、当該会員が保有するポイントの有効期間も、会員資格と同じ期間まで延長更新されます。
          また、延長更新時、ポイントを1ポイントサービス進呈します。
          <br />
          有効期間満了後1年以内に更新手続をされなかった場合は、会員登録を抹消します。
          会員登録が抹消された場合には、それまでに会員証に登録された情報、当該会員が保有していていたポイントは全て失効します。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員情報の変更について＞</Enhancement>
          <br />
          本会員がシネマサンシャインリワードに登録している当該会員の情報について変更があった場合、
          本会員は、速やかに、会員専用ページにログインして変更手続をするものとします。
          変更手続をしなかったことにより本会員が被った損害等については、当社は補償その他一切の責任を負わないものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜退会について＞</Enhancement>
          <br />
          本会員が退会を希望する場合、会員専用ページ内のメニューから退会手続をするものとします。
          退会手続を完了した時点で退会が成立します。
          退会が成立した場合は、その理由の如何を問わず、当該会員が保有していた全てのポイントが無効となり、
          シネマサンシャインリワードに登録されていた当該会員の情報は全て消去されます。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員資格の喪失について＞</Enhancement>
          <br />
          本会員について、次のいずれかに該当する事由が生じた場合は、当社からの何らの通知、催告等を必要とせずに、当該会員は直ちに会員資格を喪失します。
          この場合、当社は、当該会員による本サービスの利用を停止することができます。
          この場合、当社は、当該会員に対し、入会金、更新年会費の返金をしません。
          また、この場合、当該会員は弊社に対し、損害賠償請求その他一切の請求はできないものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          (1)
          本人以外の者のユーザーアカウントによって、シネマサンシャインリワードにおいて提供されるサービスを利用した場合
          <br />
          (2)
          本人以外の者に、自己のユーザーアカウントによって、本アプリシネマサンシャインリワードにおいて提供されるサービスを利用させた場合
          <br />
          (3) 本規約に違反した場合
          <br />
          (4) その他、本サービスを不正に利用した場合
          <br />
          (5) 本会員が死亡した場合
          <br />
          (6)
          本会員が＜会員の表明・確約について＞に違反していると弊社が認めた場合
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員の表明・確約について＞</Enhancement>
          <br />
          本会員は、弊社に対し、次の各号に掲げる事項を表明・保証するものとします。
          <br />
          (1)
          当該会員が、シネマサンシャインリワードへの入会時から会員資格喪失時までのいつの時点においても、
          暴力団、暴力団員、暴力団関係企業・団体又はその関係者、その他の反社会勢力又はその所属員（以下「暴力団等反社会勢力」といいます。）に該当しないこと。
          <br />
          (2)
          当該会員が、シネマサンシャインリワードへの入会時から会員資格喪失時までのいつの時点においても、
          自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、
          不当に暴力団等反社会勢力を利用していると認められる関係を有していないこと。
          <br />
          (3)
          当該会員が、シネマサンシャインリワードへの入会時から会員資格喪失時までのいつの時点においても、
          暴力団等反社会勢力に対して資金等を供与し、又は便宜を供与するなどの関与をしていると認められる関係を有していないこと。
          <br />
          (4)
          当該会員が、シネマサンシャインリワードへの入会時から会員資格喪失時までのいつの時点においても、
          自ら又は第三者を利用して次の各号の一に該当する行為を行わないこと。
          <Text margin={css.paragraphMargin}>
            ① 暴力的な要求行為
            <br />
            ② 法的な責任を超えた不当な要求行為
            <br />
            ③ 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
            <br />
            ④
            風説を流布し、偽計を用い又は威力を用いて弊社若しくは第三者の信用を毀損し、又は弊社若しくは第三者の業務を妨害する行為
            <br />⑤ その他前各号に準ずる行為
          </Text>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜個人情報の取扱いについて＞</Enhancement>
          <br />
          弊社は、本会員のプライバシーを尊重し、以下の考え方に基づき個人情報を適切に管理します。
          <br />
          本会員がシネマサンシャインリワードに登録した当該会員の情報（以下「会員情報」といいます。）は、個人情報保護法に定める個人情報として弊社が取得及び管理します。
          <br />
          弊社は、会員情報を、会員リスト管理、弊社から会員への情報提供、マーケティング分析、アンケート調査及び景品等の送付、その他提供するサービスのために利用します。
          <br />
          弊社は、上記目的の範囲内で、会員情報を弊社選定委託業者に委託することがあります。
          <br />
          会員情報については、上記に加えて、弊社のホームページに掲載されている弊社のプライバシーポリシーが適用されます
          （
          <Link
            colorPalette={colorPalette}
            href="http://www.cinemasunshine.co.jp/privacy/"
            forNewTab
          >
            http://www.cinemasunshine.co.jp/privacy/
          </Link>
          ）。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜準拠法・管轄について＞</Enhancement>
          <br />
          本規約は日本法に準拠し、これに基づいて解釈されるものとします。
          <br />
          本規約又はシネマサンシャインリワードに関して本サービスの利用者又は本会員と当社との間で発生した一切の紛争については、
          東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜その他＞</Enhancement>
          <br />
          本サービスの内容やご利用条件については、事前の予告なく変更される場合があります。詳しくは当社のホームページをご確認ください。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          佐々木興業株式会社
        </Text>
      </Panel>
      <Button
        colorPalette={colorPalette}
        width={css.buttonWidth}
        margin={css.buttonMargin}
        onClick={() => {
          if (history.length <= 1) {
            window.close();
          } else {
            history.back();
          }
        }}
      >
        戻る
      </Button>
    </Wrap>
  );
};
