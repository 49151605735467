/**
 * GAのグローバルサイトタグを埋め込む
 */
import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * 【特殊コンポーネント】 GAのグローバルサイトタグを埋め込む
 */
export const GoogleAnalytics: React.FC = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID) {
    return (
      <Helmet>
        <script
          async
          src={
            'https://www.googletagmanager.com/gtag/js?id=' +
            process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID
          }
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID}');
          `}
        </script>
      </Helmet>
    );
  } else {
    return null;
  }
};
