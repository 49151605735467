/**
 * 会員ランク表示パーツ
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  MembersRankType,
} from 'commons';
import {
  Button,
  Enhancement,
  Image,
  Link,
  LoadingBar,
  Text,
} from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0 12px;
`;

interface ImageWrapProps {
  width: string;
}
const ImageWrap = styled.div<ImageWrapProps>`
  box-sizing: content-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
`;

interface RankWrapProps {
  margin: string;
}
const RankWrap = styled.div<RankWrapProps>`
  margin: ${({ margin }) => margin};
`;

interface NextWrapProps {
  margin: string;
}
const NextWrap = styled.div<NextWrapProps>`
  margin: ${({ margin }) => margin};
`;

const LodingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * 【有機体】 会員ランク表示パーツ
 * @returns コンポーネント
 */
export const MembersRankParts: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imageSize: '80px',
          wrapMargin: '0 0 0 16px',
          barWidth: membershipData.rank?.nextHigherRank?.name?.ja
            ? 'calc(100% - 40px)'
            : '100%',
          barHeight: '16px',
          imageSrc: '/aeoncinema/rank_info_pc.png',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imageSize: '56px',
          wrapMargin: '0 0 0 12px',
          barWidth: membershipData.rank?.nextHigherRank?.name?.ja
            ? 'calc(100% - 32px)'
            : '100%',
          barHeight: '12px',
          imageSrc: '/aeoncinema/rank_info_sp.png',
        };
  }, [displaySize, membershipData.rank?.nextHigherRank?.name?.ja]);
  const rankImageSrc = useMemo(() => {
    switch (membershipData.rank?.type) {
      case MembersRankType.SILVER:
        return '/svg/rank_silver.svg';
      case MembersRankType.GOLD:
        return '/svg/rank_gold.svg';
      case MembersRankType.PLATINUM:
        return '/svg/rank_platinum.svg';
      case MembersRankType.DIAMOND:
        return '/svg/rank_diamond.svg';
      default:
        return '';
    }
  }, [membershipData.rank?.type]);
  const bookingCountForRank = useMemo(() => {
    return membershipData.rank?.valueCalculatedForRank !== undefined
      ? membershipData.rank?.valueCalculatedForRank
      : -1;
  }, [membershipData.rank?.valueCalculatedForRank]);
  const requiredCount = useMemo(() => {
    return membershipData.rank?.nextHigherRank?.requiredValue !== undefined
      ? membershipData.rank?.nextHigherRank?.requiredValue
      : -1;
  }, [membershipData.rank?.nextHigherRank?.requiredValue]);
  const percentage = useMemo(() => {
    if (membershipData.rank?.nextHigherRank?.name?.ja) {
      return bookingCountForRank >= 0 && requiredCount > 0
        ? Math.ceil((bookingCountForRank / requiredCount) * 100)
        : 0;
    }
    return membershipData.serverTime ? 100 : 0;
  }, [
    membershipData.rank?.nextHigherRank?.name?.ja,
    membershipData.serverTime,
    bookingCountForRank,
    requiredCount,
  ]);
  const endOfMontht = useMemo(() => {
    if (membershipData.serverTime) {
      const date = new Date(membershipData.serverTime);
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
      return date.toLocaleDateString('ja-JP');
    }
    return '―';
  }, [membershipData.serverTime]);

  return (
    <Wrap colorPalette={colorPalette}>
      <ImageWrap width={css.imageSize}>
        {rankImageSrc && (
          <Image
            src={rankImageSrc}
            width={css.imageSize}
            height={css.imageSize}
          />
        )}
      </ImageWrap>

      <RankWrap margin={css.wrapMargin}>
        <Text
          fontSize={css.fontSizeSmall}
          textAlign="center"
          letterSpacing={0}
          lineHeight={1}
        >
          現在の会員ランク
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" lineHeight={1.9} bold>
          {membershipData.rank?.name?.ja || ''}
        </Text>
        <Text
          fontSize={css.fontSizeSmall}
          textAlign="center"
          letterSpacing={0}
          lineHeight={1}
        >
          <Link
            colorPalette={colorPalette}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            会員ランクとは?
          </Link>
        </Text>
      </RankWrap>

      <NextWrap margin={css.wrapMargin}>
        <Text fontSize={css.fontSizeSmall} letterSpacing={0}>
          来月のランクは{membershipData.rank?.nextMonthRank?.name?.ja || '―'}!
        </Text>
        <LodingWrap>
          <LoadingBar
            colorPalette={colorPalette}
            percentage={percentage}
            width={css.barWidth}
            height={css.barHeight}
          />
          {membershipData.rank?.nextHigherRank?.name?.ja && (
            <Text
              fontSize={css.fontSizeSmall}
              letterSpacing={0}
              padding="0 0 4px"
              textAlign="center"
              bold
            >
              {bookingCountForRank >= 0 ? bookingCountForRank : '―'}/
              {requiredCount >= 0 ? requiredCount : '―'}
            </Text>
          )}
        </LodingWrap>
        {membershipData.rank?.nextHigherRank?.name?.ja && (
          <Text fontSize={css.fontSizeSmall} letterSpacing={0} lineHeight={1.2}>
            {endOfMontht}までに
            <Enhancement color={colorPalette.negative} bold>
              {requiredCount - bookingCountForRank}
              回鑑賞
            </Enhancement>
            で<br />
            <Enhancement bold>
              {membershipData.rank.nextHigherRank.name.ja}
            </Enhancement>
            にランクアップ!
          </Text>
        )}
      </NextWrap>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        headerTitle="会員ランクとは？"
        headerTitleSize={css.fontSize}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Text fontSize={css.fontSize} margin="0 0 8px">
          会員ランクは、「ワタシアター」のお客様がご利用いただけるサービスです。
          <br />
          会員ランクに応じてクーポン等のお得な特典がたくさんあります。
          <br />
          会員ランクは直近4ヶ月の鑑賞回数で判定されます。
          <br />
          それぞれの会員ランクの条件は以下のとおりです。
        </Text>
        <Image src={css.imageSrc} width="100%" />
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="16px auto 8px"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          閉じる
        </Button>
      </Modal>
    </Wrap>
  );
};
