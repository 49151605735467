/**
 * テキストボックス
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, hexToRgba, validateValue } from 'commons';
import { ValidatorMessage } from 'components/atoms';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface InputProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  padding?: string;
  isActive: boolean;
  isError: boolean;
}
const Input = styled.input<InputProps>`
  border: 1px solid
    ${({ isActive, isError, colorPalette }) =>
      isActive && isError ? colorPalette.negative : colorPalette.grayTone};
  border-radius: 12px;
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  width: 100%;
  height: 100%;
  ${({ padding }) => padding && `padding: ${padding};`}
  &:disabled {
    background-color: ${({ colorPalette }) =>
      hexToRgba(colorPalette.grayTone, 0.5)};
  }
`;

interface InputTextProps {
  colorPalette: ColorPalette;
  initialValue: string;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  inputType?: string;
  fontSize?: number;
  errorFontSize?: number;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  setRef?: React.Dispatch<React.RefObject<HTMLInputElement>>;
  onChange?: (value: string, isError: boolean) => void;
  onBlur?: (value: string, isError: boolean) => void;
  activeFlag?: boolean;
}
/**
 * 【分子】 テキストボックス
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {string} initialValue - 【必須】 初期値
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {string} inputType - 入力タイプ(上書き)
 * @param {number} fontSize - フォントサイズ
 * @param {number} errorFontSize - エラーフォントサイズ
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @param {string} placeholder - サンプル入力値
 * @param {boolean} required - 必須項目指定
 * @param {boolean} disabled - 非活性
 * @param {number} maxLength - 最大文字数
 * @param {React.Dispatch<React.RefObject<HTMLDivElement>>} setRef - ref取得(非推奨)
 * @param {(value: string, isError: boolean) => void} onChange - 変更処理
 * @param {(value: string, isError: boolean) => void} onBlur - フォーカスアウト処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @returns コンポーネント
 */
export const InputText: React.FC<InputTextProps> = ({
  colorPalette,
  initialValue,
  validatorRules,
  validatorMessage,
  inputType = 'text',
  fontSize,
  errorFontSize,
  width,
  height,
  padding,
  margin,
  placeholder = '',
  disabled = false,
  maxLength,
  setRef,
  onChange,
  onBlur,
  activeFlag,
}: InputTextProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(initialValue);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [validatorRulesCash, setValidatorRulesCash] = useState(validatorRules);
  const [validatorMessageCash, setValidatorMessageCash] =
    useState(validatorMessage);

  // オブジェクトの変更判定を厳密に行う(予期せぬ再レンダリングを阻止)
  useEffect(() => {
    if (!isEqual(validatorRulesCash, validatorRules)) {
      setValidatorRulesCash(validatorRules);
    }
    if (!isEqual(validatorMessageCash, validatorMessage)) {
      setValidatorMessageCash(validatorMessage);
    }
  }, [validatorRules, validatorMessage]);

  useEffect(() => {
    if (setRef) {
      setRef(ref);
    }
  }, [setRef]);

  // バリデーションルールが更新された際に再判定する
  useEffect(() => {
    const result = validateValue({
      value,
      validatorRules: validatorRulesCash,
      validatorMessage: validatorMessageCash,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange && onChange(value, result.isError);
  }, [validatorRulesCash, validatorMessageCash]);

  // エラー表示フラグを強制的にONにする
  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: value,
        validatorRules: validatorRulesCash,
        validatorMessage: validatorMessageCash,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <ValidatorMessage
      colorPalette={colorPalette}
      fontSize={errorFontSize}
      width={width}
      height={height}
      margin={margin}
      isDisplay={isActive && isError}
      message={errorMessage}
      isInputBox
    >
      <Input
        type={inputType}
        colorPalette={colorPalette}
        value={value}
        fontSize={fontSize}
        padding={padding}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        isActive={isActive}
        isError={isError}
        ref={ref}
        onChange={(e) => {
          setValue(e.target.value);
          const result = validateValue({
            value: e.target.value,
            validatorRules: validatorRulesCash,
            validatorMessage: validatorMessageCash,
          });
          setIsError(result.isError);
          setErrorMessage(result.errorMessage);
          onChange && onChange(e.target.value, result.isError);
        }}
        onBlur={(e) => {
          setValue(e.target.value);
          const result = validateValue({
            value: e.target.value,
            validatorRules: validatorRulesCash,
            validatorMessage: validatorMessageCash,
          });
          setIsError(result.isError);
          setErrorMessage(result.errorMessage);
          setIsActive(true);
          onBlur && onBlur(e.target.value, result.isError);
        }}
      />
    </ValidatorMessage>
  );
};
