/**
 * PWAアプリインストール説明パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  PWA_SUPPORTED_BROWSER,
  PWA_SUPPORTED_OS,
} from 'commons';
import { Button, Image, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  BasicSlice,
  browserSelector,
  colorPaletteSelector,
  displaySizeSelector,
  startPwaInstallSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;
/**
 * 【有機体】 PWAアプリインストール説明パネル
 * @returns コンポーネント
 */
export const AppInstallManualPanel: React.FC = () => {
  const dispatch = useTypeDispatch();
  const browser = useTypeSelector(browserSelector);
  const startPwaInstall = useTypeSelector(startPwaInstallSelector);
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const isInstall = useMemo(() => {
    return (
      PWA_SUPPORTED_OS.includes(
        `${browser.os.name} ${browser.os.version.split('.')[0]}`,
      ) && PWA_SUPPORTED_BROWSER.includes(browser.name)
    );
  }, [browser.name, browser.os.name, browser.os.version]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      {isInstall ? (
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
        >
          <Text fontSize={css.fontSize} color={colorPalette.positive} bold>
            シネマサンシャインリワード(WEB会員)をお使いのスマートフォンにインストールしてアプリとしてご利用いただけます。
          </Text>
          {browser.os.name === 'iOS' ? (
            <>
              <Text fontSize={css.fontSize} margin="16px 0 0" bold>
                iPhone(iOS)インストール手順
              </Text>
              <Text fontSize={css.fontSize} margin="24px 0 0">
                1. 画像を参考にお使いのiPhoneの「共有」ボタンをタップ
              </Text>
              <Image src="/img/app_install_iphone01.png" width="100%" />
              <Text fontSize={css.fontSize} margin="24px 0 0">
                2. 表示された一覧から「ホーム画面に追加」をタップ
              </Text>
              <Image src="/img/app_install_iphone02.png" width="100%" />
              <Text fontSize={css.fontSize} margin="24px 0 0">
                3. 画面右上の「追加」をタップ
              </Text>
              <Image src="/img/app_install_iphone03.png" width="100%" />
              <Text fontSize={css.fontSize} margin="24px 0 0">
                「共有」ボタンが表示されない場合は、Safari(iPhone標準ブラウザ)にて当ページにアクセスしお試し下さい。
              </Text>
            </>
          ) : (
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin="16px auto 0"
              onClick={() => {
                dispatch(BasicSlice.actions.changeStartPwaInstall(true));
              }}
              disabled={startPwaInstall}
            >
              {startPwaInstall ? 'インストール済み' : 'インストール'}
            </Button>
          )}
          <Text
            fontSize={css.fontSizeSmall}
            color={colorPalette.negative}
            margin="16px 0 0"
          >
            ※インストール後もシネマサンシャインリワード(WEB会員)を、WEBとアプリ両方からご利用いただけます
          </Text>
        </Panel>
      ) : (
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
        >
          <Text fontSize={css.fontSize} color={colorPalette.negative} bold>
            お使いのスマートフォンまたはブラウザではインストールしてご利用いただけません
          </Text>
          <Text fontSize={css.fontSize} bold>
            ■ 対応スマートフォン一覧
          </Text>
          {PWA_SUPPORTED_OS.map((os) => (
            <Text key={os} fontSize={css.fontSize}>
              {os}
            </Text>
          ))}
          <Text fontSize={css.fontSize} bold>
            ■ 対応ブラウザ一覧
          </Text>
          {PWA_SUPPORTED_BROWSER.map((browser) => (
            <Text key={browser} fontSize={css.fontSize}>
              {browser}
            </Text>
          ))}
        </Panel>
      )}
    </Wrap>
  );
};
