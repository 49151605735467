/**
 * 利用規約ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents } from 'components/templates';
import { UserPolicyArea } from 'components/templates/koronaworld';
import React from 'react';

/**
 * 【ページ】 利用規約ページ
 * @returns コンポーネント
 */
export const UserPolicy: React.FC = () => {
  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.USER_POLICY;
  });

  return (
    <>
      <Contents>
        <UserPolicyArea />
      </Contents>
    </>
  );
};
