/**
 * 会員証ページポップアップ表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
  Z_INDEX,
  checkPopupDisabled,
  isOneMonthBeforeByMonthOnly,
  removePopupDisabled,
  setPopupDisabled,
} from 'commons';
import { Button, Enhancement, Link, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CheckBoxForm } from 'components/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 32px;
`;

const CARD_001 = {
  key: 'kY7zCXRn',
  fromDate: new Date('2024-05-01T00:00:00+09:00').getTime(),
  toDate: new Date('2100-01-01T00:00:00+09:00').getTime(),
};

/**
 * 【テンプレート】 会員証ページポップアップ表示エリア
 * @returns コンポーネント
 */
export const CardPopup: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
        };
  }, [displaySize]);

  useEffect(() => {
    if (membershipData.serverTime) {
      const date = new Date(membershipData.serverTime).getTime();
      if (!checkPopupDisabled(CARD_001.key)) {
        const today = membershipData.serverTime
          ? new Date(membershipData.serverTime)
          : new Date();
        const birthday = membershipData.birthDate
          ? new Date(membershipData.birthDate)
          : new Date();
        if (
          date >= CARD_001.fromDate &&
          date < CARD_001.toDate &&
          !membershipData.newsletter &&
          isOneMonthBeforeByMonthOnly(today, birthday)
        ) {
          setOpened(true);
        }
      }
    }
  }, [membershipData]);

  if (loadingMessage) {
    return null;
  } else {
    return (
      <>
        <Modal
          colorPalette={colorPalette}
          opened={opened}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.popup}
          headerTitle="バースデークーポン配布について"
          headerTitleSize={css.fontSize}
          onClose={() => {
            setOpened(false);
          }}
        >
          <Wrap>
            <Text fontSize={css.fontSize} margin="0 0 16px">
              2024年6月からお誕生日月にバースデークーポンが配布されます。
            </Text>
            <Text fontSize={css.fontSize} margin="0 0 16px">
              現在メルマガ受信をしないため受け取ることができません。
              <br />
              <Enhancement color={colorPalette.negative} bold>
                受け取るためには{UrlName.PROFILE_EDIT}
                から「メルマガを受信する」に設定変更をお願いします。
              </Enhancement>
            </Text>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin="0 0 16px"
              onClick={() => {
                setOpened(false);
                navigate(UrlPath.PROFILE_EDIT);
              }}
            >
              {UrlName.PROFILE_EDIT}画面へ進む
            </Button>
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSizeMiddle}
              bold
              onClick={() => {
                setOpened(false);
              }}
            >
              閉じる
            </Link>

            {opened && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(CARD_001.key);
                    } else {
                      removePopupDisabled(CARD_001.key);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>
      </>
    );
  }
};
