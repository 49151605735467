/**
 * ポイントヘッダー
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  Z_INDEX,
} from 'commons';
import { Image, Text } from 'components/atoms';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
}
const Wrap = styled.div<WrapProps>`
  position: sticky;
  top: 48px;
  left: 0;
  z-index: ${Z_INDEX.header};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.darkTone};
  width: 100%;
  height: 32px;
  padding: 0px 16px;
`;

/**
 * 【テンプレート】 ポイントヘッダー
 * @param {ReactNode} children - 子要素
 * @returns コンポーネント
 */
export const PointHeader: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, imageSize: '20px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, imageSize: '16px' };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette}>
      <Text
        fontSize={css.fontSize}
        color={colorPalette.lightTone}
        margin="0 16px 0 0"
        lineHeight={1}
      >
        {membershipData.lastName}
        {membershipData.firstName}さん
      </Text>
      <Image
        src="/svg/point_white.svg"
        width={css.imageSize}
        height={css.imageSize}
      />
      <Text
        fontSize={css.fontSize}
        color={colorPalette.lightTone}
        margin="0 0 0 4px"
        lineHeight={1}
      >
        {membershipData.totalPoints?.toLocaleString('ja-JP') || '―'}pt
      </Text>
    </Wrap>
  );
};
