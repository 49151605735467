/**
 * moleculeテンプレート
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DATE_SEPARATOR,
  DisplaySize,
  validateValue,
} from 'commons';
import {
  RequiredIcon,
  Separator,
  Text,
  ValidatorMessage,
} from 'components/atoms';
import { ComboBox, ComboBoxItemDataType } from 'components/molecules';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}

const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const SelectArea = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ComboBoxWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px 8px 0;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

interface DateFormProps {
  children?: ReactNode;
  initialValue: string | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  yearFrom: number;
  yearTo: number;
  defaultYear?: number;
  disabled?: boolean;
  onChange?: (value: string, isError: boolean) => void;
  activeFlag?: boolean;
  required?: boolean;
}
/**
 * 【分子】 年月日フォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string} initialValue - 【必須】 初期値(new Date(xxx)で変換可能な形式)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {number} yearFrom - 【必須】 設定可能年(先頭)
 * @param {number} yearTo - 【必須】 設定可能年(末尾)
 * @param {number} defaultYear -  年の初期値
 * @param {boolean} disabled - 非活性
 * @param {(value: string) => void} onChange - 変更処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @param {boolean} required - 必須項目指定
 * @returns コンポーネント
 */
export const DateForm: React.FC<DateFormProps> = ({
  children,
  initialValue,
  validatorRules,
  validatorMessage,
  yearFrom,
  yearTo,
  defaultYear = 2000,
  disabled = false,
  onChange,
  activeFlag,
  required = false,
}: DateFormProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const value = useRef<{ year: string; month: string; day: string }>({
    year: '2000',
    month: '1',
    day: '1',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean[]>(
    initialValue ? [true, true, true] : [true, false, false],
  );
  const initialDate = useMemo(() => {
    if (initialValue) {
      const date = new Date(initialValue)
        .toLocaleDateString('ja-JP')
        .split(DATE_SEPARATOR);
      value.current.year = date[0];
      value.current.month = ('00' + date[1]).slice(-2);
      value.current.day = ('00' + date[2]).slice(-2);
      return {
        year: date[0],
        month: ('00' + date[1]).slice(-2),
        day: ('00' + date[2]).slice(-2),
      };
    } else {
      return {
        year: String(defaultYear),
        month: '__',
        day: '__',
      };
    }
  }, [initialValue]);
  const yearList = useMemo(() => {
    const result: ComboBoxItemDataType[] = [{ value: '____', label: '' }];
    if (yearFrom < yearTo) {
      for (let i = yearFrom; i <= yearTo; i += 1) {
        result.push({ value: String(i), label: String(i) });
      }
    } else {
      for (let i = yearFrom; i >= yearTo; i -= 1) {
        result.push({ value: String(i), label: String(i) });
      }
    }
    return result;
  }, [yearFrom, yearTo]);
  const monthList = useMemo(() => {
    const result: ComboBoxItemDataType[] = [{ value: '__', label: '' }];
    for (let i = 1; i <= 12; i += 1) {
      result.push({ value: ('00' + String(i)).slice(-2), label: String(i) });
    }
    return result;
  }, []);
  const dayList = useMemo(() => {
    const result: ComboBoxItemDataType[] = [{ value: '__', label: '' }];
    for (let i = 1; i <= 31; i += 1) {
      result.push({ value: ('00' + String(i)).slice(-2), label: String(i) });
    }
    return result;
  }, []);
  const onChangeDate = useCallback(
    ({ year, month, day }: { year?: string; month?: string; day?: string }) => {
      value.current.year = year || value.current.year;
      value.current.month = month || value.current.month;
      value.current.day = day || value.current.day;
      const date = `${value.current.year}/${value.current.month}/${value.current.day}`;
      const result = validateValue({
        value: date,
        validatorRules,
        validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
      setIsActive([
        year && year !== '____' ? true : isActive[0],
        month && month !== '__' ? true : isActive[1],
        day && day !== '__' ? true : isActive[2],
      ]);
      onChange && onChange(date, result.isError);
    },
    [onChange, isActive[0], isActive[1], isActive[2]],
  );
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '12px 24px',
          margin: '24px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '10px 12px',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  useEffect(() => {
    if (!isError) {
      const date = `${value.current.year}/${value.current.month}/${value.current.day}`;
      const result = validateValue({
        value: date,
        validatorRules,
        validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive([true, true, true]);
    }
  }, [activeFlag]);

  return initialValue !== null ? (
    <>
      <Wrap colorPalette={colorPalette} margin={css.margin}>
        <TitleWrap>
          {required && (
            <RequiredIcon
              colorPalette={colorPalette}
              fontSize={css.requiredFontSize}
              padding={css.requiredPadding}
              margin={css.requiredMargin}
            />
          )}
          <Text fontSize={css.fontSize} bold>
            {children}
          </Text>
        </TitleWrap>

        <ValidatorMessage
          colorPalette={colorPalette}
          fontSize={css.fontSizeSmall}
          isDisplay={isActive[0] && isActive[1] && isActive[2] && isError}
          message={errorMessage}
        >
          <SelectArea>
            <ComboBoxWrap>
              <ComboBox
                colorPalette={colorPalette}
                initialValue={initialDate.year}
                valueList={yearList}
                fontSize={css.fontSize}
                padding={css.padding}
                disabled={disabled}
                onChange={(year) => {
                  onChangeDate({ year });
                }}
              />
              <Text fontSize={css.fontSize} bold>
                <Separator width="4px" />年
              </Text>
            </ComboBoxWrap>
            <ComboBoxWrap>
              <ComboBox
                colorPalette={colorPalette}
                initialValue={initialDate.month}
                valueList={monthList}
                fontSize={css.fontSize}
                padding={css.padding}
                disabled={disabled}
                onChange={(month) => {
                  onChangeDate({ month });
                }}
              />
              <Text fontSize={css.fontSize} bold>
                <Separator width="4px" />月
              </Text>
            </ComboBoxWrap>
            <ComboBoxWrap>
              <ComboBox
                colorPalette={colorPalette}
                initialValue={initialDate.day}
                valueList={dayList}
                fontSize={css.fontSize}
                padding={css.padding}
                disabled={disabled}
                onChange={(day) => {
                  onChangeDate({ day });
                }}
              />
              <Text fontSize={css.fontSize} bold>
                <Separator width="4px" />日
              </Text>
            </ComboBoxWrap>
          </SelectArea>
        </ValidatorMessage>
      </Wrap>
    </>
  ) : null;
};
