/**
 * 新規会員登録確認パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlPath } from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FeeListSelector,
  colorPaletteSelector,
  displaySizeSelector,
  registerDigitalCouponMember,
  registerDigitalMember,
  registerPhysicsCardMember,
  submitMembershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px 32px;
`;

interface RecordProps {
  minHiehgt: string;
}
const Record = styled.div<RecordProps>`
  margin: 16px 0;
  min-height: ${({ minHiehgt }) => minHiehgt};
`;

interface RegisterConfirmPanelProps {
  isPhysicsCardMember?: boolean;
  hasCoupon?: boolean;
}
/**
 * 【有機体】 新規会員登録確認パネル
 * @param {boolean} isPhysicsCardMember - カード会員フラグ
 * @param {boolean} hasCoupon - 無料クーポンフラグ
 * @returns コンポーネント
 */
export const RegisterConfirmPanel: React.FC<RegisterConfirmPanelProps> = ({
  isPhysicsCardMember,
  hasCoupon,
}) => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const submitMembershipData = useTypeSelector(submitMembershipDataSelector);
  const feeList = useTypeSelector(FeeListSelector);
  const membershipName = useMemo(() => {
    const fee = feeList.membershipFeeList?.find(
      (fee) => fee.code === submitMembershipData.membershipCode,
    );
    return fee?.value && fee?.value
      ? `${fee.name} ${fee.value.toLocaleString('ja-JP')}円`
      : '';
  }, [feeList]);
  const fee = useMemo(() => {
    const fee = feeList.membershipFeeList?.find(
      (fee) => fee.code === submitMembershipData.membershipCode,
    );
    return fee?.value ? fee.value : 0;
  }, [feeList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          valueMargin: '0 0 0 36px',
          minHeight: '72px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          valueMargin: '0 0 0 16px',
          minHeight: '56px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
          新規会員登録
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="8px 0 0">
          入力内容を確認して下さい
        </Text>
        {isPhysicsCardMember && (
          <Record minHiehgt={css.minHeight}>
            <Text fontSize={css.fontSize} lineHeight={1} bold>
              会員番号
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
              {submitMembershipData?.memberNoArray?.join('').substring(0, 4) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray?.join('').substring(4, 7) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray?.join('').substring(7, 10) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray
                ?.join('')
                .substring(10, 14) || '―'}
            </Text>
          </Record>
        )}
        {hasCoupon && (
          <Record minHiehgt={css.minHeight}>
            <Text fontSize={css.fontSize} lineHeight={1} bold>
              クーポン番号入力
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
              {submitMembershipData.registerCoupon || ''}
            </Text>
          </Record>
        )}
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            お名前(カタカナ)
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.lastName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstName || ''}(
            {submitMembershipData.lastKanaName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstKanaName || ''})
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            電話番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.tel || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            誕生日
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.birthDate || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            郵便番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.postalCode}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            住所
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.all}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            性別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.sex}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            顔PASS種別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipName}
          </Text>
        </Record>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 16px"
          onClick={() => {
            if (isPhysicsCardMember) {
              dispatch(registerPhysicsCardMember());
            } else if (hasCoupon) {
              dispatch(registerDigitalCouponMember());
            } else {
              dispatch(registerDigitalMember({ registrationFee: fee }));
            }
          }}
        >
          送信
        </Button>
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            if (isPhysicsCardMember) {
              navigate(UrlPath.PHYSICS_CARD_REGISTER);
            } else if (hasCoupon) {
              navigate(UrlPath.DIGITAL_REGISTER_COUPON);
            } else {
              navigate(UrlPath.DIGITAL_REGISTER);
            }
          }}
        >
          入力画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
