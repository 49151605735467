/**
 * メンテナンスページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { Contents, MaintenanceArea } from 'components/templates';
import React from 'react';

/**
 * 【ページ】 メンテナンスページ
 * @returns コンポーネント
 */
export const Maintenance: React.FC = () => {
  return (
    <Contents>
      <MaintenanceArea />
    </Contents>
  );
};
