/**
 * 各ページの情報を管理・操作
 */

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CustomerConsts } from 'App';
import {
  CouponShareDataTypes,
  CouponShareResultTypes,
  DELETE_MEMBER_WAIT,
  DigitalCompleteResultTypes,
  DigitalCreateGmoCompleteResultTypes,
  InvoiceDataTypes,
  LocalStorageKey,
  MemberCouponListDataTypes,
  MembershipDataAddressTypes,
  MembershipDataAttributesTypes,
  MembershipDataTypes,
  PaidCouponsIssueCompleteResultTypes,
  ValidPeriodCompleteResultTypes,
  ValidPeriodGmoCompleteResultTypes,
  getMembers,
  postCinemasunshineMigrationMemberCreate,
  postCouponsApply,
  postCouponsIssueInviteCoupon,
  postCouponsShare,
  postDigitalComplete,
  postDigitalCreate,
  postDigitalCreateFree,
  postDigitalCreateGmoComplete,
  postDigitalCreateWithCoupon,
  postFaceDelete,
  postFaceUpload,
  postMemberDelete,
  postMembersEdit,
  postMigrateBookingsAndPoints,
  postMigrationMemberAlexCinemasCreate,
  postMigrationMemberCreate,
  postPaidCouponsIssue,
  postPaidCouponsIssueComplete,
  postPhysicsCardMemberCreate,
  postPointCouponsIssue,
  postUpdateEmail,
  postUpdateEmailComplete,
  postValidPeriod,
  postValidPeriodComplete,
  postValidPeriodFree,
  postValidPeriodGmoComplete,
} from 'commons';
import { DispatchState, SelectorState } from 'stores';

export interface EventState {
  eventServerTime: string;
  eventLoadingMessage: string;
  eventErrorMessage: string;
  eventResultMessage: string;
  submitMembershipData: MembershipDataTypes;
  approvalDelete: boolean;
  couponShareData: CouponShareDataTypes;
  paymentSuccess: boolean;
  resultMemberCouponList: MemberCouponListDataTypes[];
  invoiceData: InvoiceDataTypes;
  invoiceFee: string;
}

const initialState: EventState = {
  eventServerTime: '',
  eventLoadingMessage: '',
  eventErrorMessage: '',
  eventResultMessage: '',
  submitMembershipData: {
    memberNo: '',
    memberNoArray: ['', '', '', ''],
    previousNo: '',
    previousNoArray: ['', '', '', ''],
    previousValidPeriodTo: '',
    totalPoints: 0,
    validPeriodTo: '',
    name: '',
    kanaName: '',
    firstName: '',
    lastName: '',
    firstKanaName: '',
    lastKanaName: '',
    tel: '',
    sex: '',
    student: false,
    school: '',
    newsletter: true,
    birthDate: '',
    address: {
      all: '',
      city: '',
      postalCode: '',
      prefectures: '',
      town: '',
    },
    attributes: {
      theaters: [],
      favorite: '',
      frequency: '',
      with: '',
      theater: '',
      favorite1: '',
      favorite2: '',
      isPointsAndBookingsMigration: false,
      createFeeBillingType: '',
      nameSeparated: CustomerConsts.NAME_SEPARATED,
      demonstration: false,
    },
    registerCoupon: '',
    status: undefined,
    membershipCode: '',
  },
  approvalDelete: false,
  couponShareData: {
    email: '',
    coupon: {},
    ticketColor: '',
    result: {},
  },
  paymentSuccess: false,
  resultMemberCouponList: [],
  invoiceData: {},
  invoiceFee: '',
};

/**
 * カード会員新規登録処理
 */
export const registerPhysicsCardMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerPhysicsCardMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postPhysicsCardMemberCreate({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * デジタル会員新規登録処理
 */
export const registerDigitalMember = createAsyncThunk<
  { paymentUrl: string; registrationFee?: number },
  { registrationFee?: number },
  { dispatch: DispatchState; state: SelectorState }
>('registerDigitalMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const { paymentUrl } = await postDigitalCreate({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return { paymentUrl, registrationFee: props.registrationFee };
});

/**
 * デジタル会員新規登録完了処理
 * @param {string} encryptValue - 完了用トークン
 */
export const completeDigitalMember = createAsyncThunk<
  { digitalCompleteResultData: DigitalCompleteResultTypes },
  { encryptValue: string },
  { dispatch: DispatchState; state: SelectorState }
>('completeDigitalMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const digitalCompleteResultData = await postDigitalComplete({
    encryptValue: props.encryptValue,
  });
  return { digitalCompleteResultData };
});

/**
 * デジタル会員新規登録処理(無料クーポン)
 */
export const registerDigitalCouponMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerDigitalCouponMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postDigitalCreateWithCoupon({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * デジタル会員新規登録処理(高校生以下無料)
 */
export const registerDigitalFreeMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerDigitalFreeMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postDigitalCreateFree({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * 旧会員新規登録処理(移行)
 */
export const registerMigrationMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerMigrationMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postMigrationMemberCreate({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * 旧会員新規登録処理(移行)【alexcinemas専用】
 */
export const registerAlexCinemasMigrationMember = createAsyncThunk<
  { paymentUrl: string },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerAlexCinemasMigrationMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const { paymentUrl } = await postMigrationMemberAlexCinemasCreate({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return { paymentUrl };
});

/**
 * 旧会員新規登録処理(移行)【sskts専用】
 * @returns 戻り値
 */
export const registerCinemasunshineMigrationMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('registerCinemasunshineMigrationMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postCinemasunshineMigrationMemberCreate({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * デジタル会員新規登録完了処理【sskts専用】
 * @param {string} encryptValue - 完了用トークン
 * @returns 戻り値
 */
export const completeCinemasunshineDigitalMember = createAsyncThunk<
  { digitalCreateGmoCompleteResultData: DigitalCreateGmoCompleteResultTypes },
  { encryptValue: string },
  { dispatch: DispatchState; state: SelectorState }
>('completeCinemasunshineDigitalMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const digitalCreateGmoCompleteResultData = await postDigitalCreateGmoComplete(
    {
      encryptValue: props.encryptValue,
    },
  );
  return { digitalCreateGmoCompleteResultData };
});

/**
 * 会員情報更新処理
 */
export const updateProfile = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('updateProfile/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('会員情報更新中...'),
  );
  await postMembersEdit({
    submitMembershipData: thunkApi.getState().event.submitMembershipData,
  });
  return true;
});

/**
 * メールアドレス更新処理
 * @param {string} email - メールアドレス
 */
export const updateEmail = createAsyncThunk<
  string,
  { email: string },
  { dispatch: DispatchState; state: SelectorState }
>('updateEmail/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('メールアドレス更新中...'),
  );
  await postUpdateEmail({ email: props.email });
  return props.email;
});

/**
 * メールアドレス更新完了処理
 * @param {string} code - 認証コード
 */
export const updateEmailComplete = createAsyncThunk<
  boolean,
  { code: string },
  { dispatch: DispatchState; state: SelectorState }
>('updateEmailComplete/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('認証コード送信中...'),
  );
  await postUpdateEmailComplete({ code: props.code });
  return true;
});

/**
 * 有効期限更新処理
 */
export const updateValidPeriod = createAsyncThunk<
  { paymentUrl: string; renewFee?: number },
  { renewFee?: number },
  { dispatch: DispatchState; state: SelectorState }
>('updateValidPeriod/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const { paymentUrl } = await postValidPeriod();
  return { paymentUrl, renewFee: props.renewFee };
});

/**
 * 有効期限更新完了処理
 * @param {string} encryptValue - トークンコード
 */
export const completeValidPeriod = createAsyncThunk<
  { validPeriodCompleteResultData: ValidPeriodCompleteResultTypes },
  { encryptValue: string },
  { dispatch: DispatchState; state: SelectorState }
>('completeValidPeriod/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const validPeriodCompleteResultData = await postValidPeriodComplete({
    encryptValue: props.encryptValue,
  });
  await getMembers(); //localstrage有効期限情報更新
  return { validPeriodCompleteResultData };
});

/**
 * 有効期限更新完了処理【sskts専用】
 * @param {string} encryptValue - トークンコード
 */
export const completeCinemasunshineValidPeriod = createAsyncThunk<
  { validPeriodGmoCompleteResultData: ValidPeriodGmoCompleteResultTypes },
  { encryptValue: string },
  { dispatch: DispatchState; state: SelectorState }
>('completeCinemasunshineValidPeriod/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const validPeriodGmoCompleteResultData = await postValidPeriodGmoComplete({
    encryptValue: props.encryptValue,
  });
  await getMembers(); //localstrage有効期限情報更新
  return { validPeriodGmoCompleteResultData };
});

/**
 * 有効期限無料更新処理
 */
export const updateValidPeriodFree = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('updateValidPeriodFree/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  await postValidPeriodFree();
  return true;
});

/**
 * 退会処理
 * @param {string} code - トークンコード
 */
export const deleteMember = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('deleteMember/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeApprovalDelete(true));
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('退会準備中...'),
  );
  await new Promise((resolve) => setTimeout(resolve, DELETE_MEMBER_WAIT));
  if (!thunkApi.getState().event.approvalDelete) {
    return false;
  }
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('退会処理中...'),
  );
  await postMemberDelete();
  return true;
});

/**
 * 旧会員情報移行処理【sskts専用】
 */
export const migrationCinemasunshineMemberData = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('migrationCinemasunshineMemberData/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('取り込み中...'),
  );
  await postMigrateBookingsAndPoints();
  return true;
});

/**
 * 顔認証用の画像登録処理
 * @param {File} file - 登録する顔写真ファイル
 */
export const postFaceImageRegister = createAsyncThunk<
  boolean,
  { file: File },
  { dispatch: DispatchState; state: SelectorState }
>('postFaceImageRegister/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('顔写真登録処理中...'),
  );
  await postFaceUpload({ file: props.file });
  return true;
});

/**
 * 顔認証用の画像削除処理
 * @param {File} file - 登録する顔写真ファイル
 */
export const postFaceImageErasure = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('postFaceImageErasure/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('顔写真削除処理中...'),
  );
  await postFaceDelete();
  return true;
});

/**
 * 紹介クーポン発行処理
 */
export const createInviteCoupon = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('createInviteCoupon/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('紹介クーポン発行中...'),
  );
  await postCouponsIssueInviteCoupon();
  return true;
});

/**
 * クーポン着券処理
 */
export const applyMemberCoupons = createAsyncThunk<
  boolean,
  { transactionId?: string },
  { dispatch: DispatchState; state: SelectorState }
>('applyMemberCoupons/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('着券処理中...'),
  );
  const memberNo = thunkApi.getState().page.membershipData.memberNo;
  await postCouponsApply({
    memberNo: memberNo || '',
    transactionId: props.transactionId || '',
  });
  return true;
});

/**
 * クーポンシェア送信処理
 */
export const sendShareCouponMail = createAsyncThunk<
  { couponShareResult: CouponShareResultTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('sendShareCouponMail/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('シェア画像作成中...'),
  );
  const couponShareData = thunkApi.getState().event.couponShareData;
  const couponShareResult = await postCouponsShare({
    email: couponShareData.email || '',
    transactionId: couponShareData.coupon?.transactionId || '',
  });
  return { couponShareResult };
});

/**
 * 有償クーポン決済処理
 * @param {string} couponId - クーポンID
 */
export const paymentPaidCouponsIssue = createAsyncThunk<
  { paymentUrl: string; couponFee: number },
  { couponId: string; couponFee: number },
  { dispatch: DispatchState; state: SelectorState }
>('sendPaidCoupon/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const { paymentUrl } = await postPaidCouponsIssue({
    couponId: props.couponId,
  });
  return { paymentUrl: paymentUrl || '', couponFee: props.couponFee };
});

/**
 * ポイントクーポン取得処理
 * @param {string} couponId - クーポンID
 * @param {number} count - 取得枚数
 */
export const updatePointCouponToCoupon = createAsyncThunk<
  boolean,
  { couponId: string; count: number },
  { dispatch: DispatchState; state: SelectorState }
>('updatePointCouponToCoupon/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    EventSlice.actions.changeEventLoadingMessage('クーポン発行中...'),
  );
  await postPointCouponsIssue({
    couponInfo: [{ couponId: props.couponId, count: props.count }],
  });
  return true;
});

/**
 * 有償クーポン決済完了処理
 * @param {string} encryptValue - 完了ID
 */
export const completePaidCouponsIssue = createAsyncThunk<
  { PaidCouponsIssueCompleteResultData: PaidCouponsIssueCompleteResultTypes },
  { encryptValue: string },
  { dispatch: DispatchState; state: SelectorState }
>('completePaidCouponsIssue/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(EventSlice.actions.changeEventLoadingMessage('送信中...'));
  const PaidCouponsIssueCompleteResultData = await postPaidCouponsIssueComplete(
    {
      encryptValue: props.encryptValue,
    },
  );
  return { PaidCouponsIssueCompleteResultData };
});

export const EventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    initializeState: (state) => {
      state.eventServerTime = initialState.eventServerTime;
      state.eventLoadingMessage = initialState.eventLoadingMessage;
      state.eventErrorMessage = initialState.eventErrorMessage;
      state.eventResultMessage = initialState.eventResultMessage;
      state.submitMembershipData = initialState.submitMembershipData;
      state.approvalDelete = initialState.approvalDelete;
      state.couponShareData = initialState.couponShareData;
      state.paymentSuccess = initialState.paymentSuccess;
      state.resultMemberCouponList = initialState.resultMemberCouponList;
      state.invoiceData = initialState.invoiceData;
      state.invoiceFee = initialState.invoiceFee;
    },
    changeEventLoadingMessage: (state, action: PayloadAction<string>) => {
      state.eventLoadingMessage = action.payload;
    },
    changeEventErrorMessage: (state, action: PayloadAction<string>) => {
      state.eventErrorMessage = action.payload;
    },
    changeEventResultMessage: (state, action: PayloadAction<string>) => {
      state.eventResultMessage = action.payload;
    },
    changeSubmitMembershipData: (
      state,
      action: PayloadAction<MembershipDataTypes>,
    ) => {
      state.submitMembershipData = {
        ...state.submitMembershipData,
        ...action.payload,
      };
    },
    changeSubmitMembershipDataMemberIdArray: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      if (state.submitMembershipData.memberNoArray) {
        state.submitMembershipData.memberNoArray[action.payload.index] =
          action.payload.value;
      }
    },
    changeSubmitMembershipDataPreviousNoArray: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      if (state.submitMembershipData.previousNoArray) {
        state.submitMembershipData.previousNoArray[action.payload.index] =
          action.payload.value;
      }
    },
    changeSubmitMembershipDataAddress: (
      state,
      action: PayloadAction<MembershipDataAddressTypes>,
    ) => {
      state.submitMembershipData.address = {
        ...state.submitMembershipData.address,
        ...action.payload,
      };
    },
    changeSubmitMembershipDataAttributes: (
      state,
      action: PayloadAction<MembershipDataAttributesTypes>,
    ) => {
      state.submitMembershipData.attributes = {
        ...state.submitMembershipData.attributes,
        ...action.payload,
      };
    },
    changeApprovalDelete: (state, action: PayloadAction<boolean>) => {
      state.approvalDelete = action.payload;
    },
    changeCouponShareData: (
      state,
      action: PayloadAction<CouponShareDataTypes>,
    ) => {
      state.couponShareData = {
        ...state.couponShareData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // registerPhysicsCardMember
    builder.addCase(registerPhysicsCardMember.fulfilled, (state) => {
      state.eventResultMessage = '会員登録が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(registerPhysicsCardMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // registerDigitalMember
    builder.addCase(registerDigitalMember.fulfilled, (state, action) => {
      localStorage.setItem(
        LocalStorageKey.INVOICE_FEE,
        String(
          action.payload.registrationFee || CustomerConsts.REGISTRATION_FEE,
        ),
      );
      window.location.href = action.payload.paymentUrl;
      state.eventLoadingMessage = '';
    });
    builder.addCase(registerDigitalMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // completeDigitalMember
    builder.addCase(completeDigitalMember.fulfilled, (state, action) => {
      state.invoiceFee =
        localStorage.getItem(LocalStorageKey.INVOICE_FEE) || '';
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.eventServerTime =
        action.payload.digitalCompleteResultData.serverTime || '';
      state.invoiceData =
        action.payload.digitalCompleteResultData.invoice || {};
      state.eventResultMessage = '会員登録が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(completeDigitalMember.rejected, (state, action) => {
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // registerDigitalCouponMember
    builder.addCase(registerDigitalCouponMember.fulfilled, (state) => {
      localStorage.removeItem(LocalStorageKey.REGISTER_COUPON_NO);
      state.eventResultMessage = '会員登録が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(registerDigitalCouponMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // registerDigitalFreeMember
    builder.addCase(registerDigitalFreeMember.fulfilled, (state) => {
      state.eventResultMessage = '会員登録が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(registerDigitalFreeMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // registerMigrationMember
    builder.addCase(registerMigrationMember.fulfilled, (state) => {
      state.eventResultMessage = '会員登録が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(registerMigrationMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // registerAlexCinemasMigrationMember
    builder.addCase(
      registerAlexCinemasMigrationMember.fulfilled,
      (state, action) => {
        window.location.href = action.payload.paymentUrl;
        state.eventLoadingMessage = '';
      },
    );
    builder.addCase(
      registerAlexCinemasMigrationMember.rejected,
      (state, action) => {
        state.eventErrorMessage = action.error.message || 'API Error';
        state.eventLoadingMessage = '';
      },
    );

    // registerCinemasunshineMigrationMember
    builder.addCase(
      registerCinemasunshineMigrationMember.fulfilled,
      (state) => {
        state.eventResultMessage = '会員登録が完了しました';
        state.eventLoadingMessage = '';
      },
    );
    builder.addCase(
      registerCinemasunshineMigrationMember.rejected,
      (state, action) => {
        state.eventErrorMessage = action.error.message || 'API Error';
        state.eventLoadingMessage = '';
      },
    );

    // completeCinemasunshineDigitalMember
    builder.addCase(
      completeCinemasunshineDigitalMember.fulfilled,
      (state, action) => {
        state.invoiceFee =
          localStorage.getItem(LocalStorageKey.INVOICE_FEE) || '';
        localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
        state.eventServerTime =
          action.payload.digitalCreateGmoCompleteResultData.serverTime || '';
        state.invoiceData =
          action.payload.digitalCreateGmoCompleteResultData.invoice || {};
        state.eventResultMessage = '会員登録が完了しました';
        state.eventLoadingMessage = '';
      },
    );
    builder.addCase(
      completeCinemasunshineDigitalMember.rejected,
      (state, action) => {
        localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
        state.eventErrorMessage = action.error.message || 'API Error';
        state.eventLoadingMessage = '';
      },
    );

    // updateProfile
    builder.addCase(updateProfile.fulfilled, (state) => {
      state.eventResultMessage = '会員情報が更新されました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // updateEmail
    builder.addCase(updateEmail.fulfilled, (state, action) => {
      state.eventResultMessage = `${action.payload} 宛に認証コードを送信しました`;
      state.eventLoadingMessage = '';
    });
    builder.addCase(updateEmail.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // updateEmailComplete
    builder.addCase(updateEmailComplete.fulfilled, (state) => {
      state.eventResultMessage = 'メールアドレスが更新されました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(updateEmailComplete.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // updateValidPeriod
    builder.addCase(updateValidPeriod.fulfilled, (state, action) => {
      localStorage.setItem(
        LocalStorageKey.INVOICE_FEE,
        String(action.payload.renewFee || CustomerConsts.RENEW_FEE),
      );
      window.location.href = action.payload.paymentUrl;
      state.eventLoadingMessage = '';
    });
    builder.addCase(updateValidPeriod.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // completeValidPeriod
    builder.addCase(completeValidPeriod.fulfilled, (state, action) => {
      state.invoiceFee =
        localStorage.getItem(LocalStorageKey.INVOICE_FEE) || '';
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.eventServerTime =
        action.payload.validPeriodCompleteResultData.serverTime || '';
      state.invoiceData =
        action.payload.validPeriodCompleteResultData.invoice || {};
      state.eventResultMessage = '有効期限が更新されました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(completeValidPeriod.rejected, (state, action) => {
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // completeCinemasunshineValidPeriod
    builder.addCase(
      completeCinemasunshineValidPeriod.fulfilled,
      (state, action) => {
        state.invoiceFee =
          localStorage.getItem(LocalStorageKey.INVOICE_FEE) || '';
        localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
        state.eventServerTime =
          action.payload.validPeriodGmoCompleteResultData.serverTime || '';
        state.invoiceData =
          action.payload.validPeriodGmoCompleteResultData.invoice || {};
        state.eventResultMessage = '有効期限が更新されました';
        state.eventLoadingMessage = '';
      },
    );
    builder.addCase(
      completeCinemasunshineValidPeriod.rejected,
      (state, action) => {
        state.eventErrorMessage = action.error.message || 'API Error';
        state.eventLoadingMessage = '';
      },
    );

    // updateValidPeriodFree
    builder.addCase(updateValidPeriodFree.fulfilled, (state) => {
      state.eventResultMessage = '有効期限が更新されました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(updateValidPeriodFree.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // deleteMember
    builder.addCase(deleteMember.fulfilled, (state, action) => {
      if (action.payload) {
        state.eventResultMessage = '退会しました';
      }
      state.eventLoadingMessage = '';
    });
    builder.addCase(deleteMember.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // migrationCinemasunshineMemberData
    builder.addCase(migrationCinemasunshineMemberData.fulfilled, (state) => {
      state.eventResultMessage = '会員情報の移行が完了しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(
      migrationCinemasunshineMemberData.rejected,
      (state, action) => {
        state.eventErrorMessage = action.error.message || 'API Error';
        state.eventLoadingMessage = '';
      },
    );

    // postFaceImageRegister
    builder.addCase(postFaceImageRegister.fulfilled, (state) => {
      state.eventResultMessage = '顔写真を登録しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(postFaceImageRegister.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // postFaceImageErasure
    builder.addCase(postFaceImageErasure.fulfilled, (state) => {
      state.eventResultMessage = '顔写真を削除しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(postFaceImageErasure.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // createInviteCoupon
    builder.addCase(createInviteCoupon.fulfilled, (state) => {
      state.eventResultMessage = '紹介クーポンを発行しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(createInviteCoupon.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // applyMemberCoupons
    builder.addCase(applyMemberCoupons.fulfilled, (state) => {
      state.eventResultMessage = 'クーポンを使用しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(applyMemberCoupons.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // sendShareCouponMail
    builder.addCase(sendShareCouponMail.fulfilled, (state, action) => {
      state.couponShareData.result = action.payload.couponShareResult;
      state.eventResultMessage = 'シェア用画像を作成しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(sendShareCouponMail.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // updatePointCouponToCoupon
    builder.addCase(updatePointCouponToCoupon.fulfilled, (state) => {
      state.eventResultMessage = 'クーポンを取得しました';
      state.eventLoadingMessage = '';
    });
    builder.addCase(updatePointCouponToCoupon.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // paymentPaidCouponsIssue
    builder.addCase(paymentPaidCouponsIssue.fulfilled, (state, action) => {
      localStorage.setItem(
        LocalStorageKey.INVOICE_FEE,
        String(action.payload.couponFee),
      );
      window.location.href = action.payload.paymentUrl;
      state.eventLoadingMessage = '';
    });
    builder.addCase(paymentPaidCouponsIssue.rejected, (state, action) => {
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });

    // completePaidCouponsIssue
    builder.addCase(completePaidCouponsIssue.fulfilled, (state, action) => {
      state.invoiceFee =
        localStorage.getItem(LocalStorageKey.INVOICE_FEE) || '';
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.paymentSuccess = true;
      state.eventServerTime =
        action.payload.PaidCouponsIssueCompleteResultData.serverTime || '';
      state.invoiceData =
        action.payload.PaidCouponsIssueCompleteResultData.invoice || {};
      state.resultMemberCouponList =
        action.payload.PaidCouponsIssueCompleteResultData.coupons || [];
      state.eventResultMessage = 'お買い上げありがとうございます';
      state.eventLoadingMessage = '';
    });
    builder.addCase(completePaidCouponsIssue.rejected, (state, action) => {
      localStorage.removeItem(LocalStorageKey.INVOICE_FEE);
      state.eventErrorMessage = action.error.message || 'API Error';
      state.eventLoadingMessage = '';
    });
  },
});
