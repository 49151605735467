/**
 * テキスト入力フォーム
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { RequiredIcon, Text } from 'components/atoms';
import { InputText } from 'components/molecules';
import React, { ReactNode, useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`};
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

interface MoleculeProps {
  children?: ReactNode;
  initialValue: string | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  inputType?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  errorFontSize?: number;
  setRef?: React.Dispatch<React.RefObject<HTMLInputElement>>;
  onChange?: (value: string, isError: boolean) => void;
  onBlur?: (value: string, isError: boolean) => void;
  activeFlag?: boolean;
}

/**
 * 【分子】 テキスト入力フォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string} initialValue - 【必須】 初期値(nullで非表示)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {string} inputType - 入力タイプ(上書き)
 * @param {string} placeholder - サンプル入力値
 * @param {boolean} required - 必須項目指定
 * @param {boolean} disabled - 非活性指定
 * @param {number} maxLength - 最大文字数
 * @param {number} errorFontSize - エラーフォントサイズ
 * @param {React.Dispatch<React.RefObject<HTMLInputElement>>} setRef - ref取得(非推奨)
 * @param {(value: string, isError: boolean) => void} onChange - 変更処理
 * @param {(value: string, isError: boolean) => void} onBlur - フォーカスアウト処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @returns コンポーネント
 */
export const InputTextForm: React.FC<MoleculeProps> = ({
  children,
  initialValue,
  validatorRules,
  validatorMessage,
  inputType,
  placeholder,
  required = false,
  disabled,
  maxLength,
  errorFontSize,
  setRef,
  onChange,
  onBlur,
  activeFlag,
}: MoleculeProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, padding: '8px 24px', margin: '24px 0 0' }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '8px 12px',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  return (
    <>
      {initialValue !== null ? (
        <Wrap colorPalette={colorPalette} margin={css.margin}>
          <TitleWrap>
            {required && (
              <RequiredIcon
                colorPalette={colorPalette}
                fontSize={css.requiredFontSize}
                padding={css.requiredPadding}
                margin={css.requiredMargin}
              />
            )}
            <Text fontSize={css.fontSize} bold>
              {children}
            </Text>
          </TitleWrap>
          <InputText
            colorPalette={colorPalette}
            initialValue={initialValue}
            validatorRules={validatorRules}
            validatorMessage={validatorMessage}
            inputType={inputType}
            fontSize={css.fontSize}
            errorFontSize={errorFontSize || css.fontSizeSmall}
            width="100%"
            padding={css.padding}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            setRef={setRef}
            onChange={onChange}
            onBlur={onBlur}
            activeFlag={activeFlag}
          />
        </Wrap>
      ) : null}
    </>
  );
};
