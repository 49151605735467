/**
 * 旧会員登録エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ContentsBlock } from 'components/organisms';
import { RegisterPanel } from 'components/organisms/koronaworld';
import React from 'react';

/**
 * 【テンプレート】 旧会員登録エリア
 * @returns コンポーネント
 */
export const MigrationRegisterArea: React.FC = () => {
  return (
    <ContentsBlock>
      <RegisterPanel isMigration />
    </ContentsBlock>
  );
};
