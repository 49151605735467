/**
 * クーポンページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents, CouponArea } from 'components/templates';
import { Header, PointHeader } from 'components/templates/koronaworld';
import React from 'react';
import { didMountCouponPage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 クーポンページ
 * @returns コンポーネント
 */
export const Coupon: React.FC = () => {
  const dispatch = useTypeDispatch();

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.COUPON;
    dispatch(didMountCouponPage());
  });

  return (
    <>
      <Header />
      <PointHeader />
      <Contents>
        <CouponArea />
      </Contents>
    </>
  );
};
