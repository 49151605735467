/**
 * 利用規約エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Button, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 160px;
`;

/**
 * 【テンプレート】 利用規約エリア
 * @returns コンポーネント
 */
export const UserPolicyArea: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          buttonMargin: '0 0 24px',
          textMargin: '24px 0 0',
          paragraphMargin: '0 0 0 24px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          buttonMargin: '0 0 16px',
          textMargin: '16px 0 0',
          paragraphMargin: '0 0 0 16px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Button
        colorPalette={colorPalette}
        width={css.buttonWidth}
        margin={css.buttonMargin}
        onClick={() => {
          if (history.length <= 1) {
            window.close();
          } else {
            history.back();
          }
        }}
      >
        同意して戻る
      </Button>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center" bold>
          ワタシアター会員規約
        </Text>
      </Panel>
    </Wrap>
  );
};
