/**
 * 会員証表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { MessageModal, RenewAnnounceModal } from 'components/organisms';
import { CardPanel, PanelMenu } from 'components/organisms/sskts';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px 120px;
`;

/**
 * 【テンプレート】 会員証表示エリア
 * @returns コンポーネント
 */
export const CardArea: React.FC = () => {
  return (
    <Wrap>
      <CardPanel />
      <PanelMenu />
      <RenewAnnounceModal />
      <MessageModal
        onClose={() => {
          location.reload();
        }}
      />
    </Wrap>
  );
};
