/**
 * 文章
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface WrapProps {
  fontSize?: number;
  color?: string;
  bold?: boolean;
  sharp?: boolean;
  underline?: boolean;
  letterSpacing?: number;
  lineHeight?: number;
  textAlign?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  ellipsis?: boolean;
  wordBreakAll?: boolean;
}
const Wrap = styled.div<WrapProps>`
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ sharp }) => sharp && 'font-weight: 100;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ letterSpacing }) =>
    letterSpacing !== undefined && `letter-spacing: ${letterSpacing}px;`}
  ${({ lineHeight }) =>
    lineHeight !== undefined && `line-height: ${lineHeight};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ ellipsis }) => ellipsis && 'overflow: hidden;'}
  ${({ ellipsis }) => ellipsis && 'text-overflow: ellipsis;'}
  ${({ ellipsis }) => ellipsis && 'white-space: nowrap;'}
  ${({ wordBreakAll }) => wordBreakAll && 'word-break: break-all;'}
`;

interface TextProps {
  id?: string;
  children: ReactNode;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  sharp?: boolean;
  underline?: boolean;
  letterSpacing?: number;
  lineHeight?: number;
  textAlign?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  ellipsis?: boolean;
  wordBreakAll?: boolean;
}
/**
 * 【原子】 文章
 * @param {string} id - HHTMLのID要素
 * @param {ReactNode} children - 【必須】 子要素
 * @param {number} fontSize - 文字サイズ [px]
 * @param {string} color - 文字色
 * @param {boolean} bold - 太字
 * @param {boolean} sharp - 細字
 * @param {boolean} underline - 下線
 * @param {number} letterSpacing - 文字間隔 [px]
 * @param {number} lineHeight - 行間 [倍率]
 * @param {string} textAlign - テキスト位置
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} margin - マージン
 * @param {string} padding - パディング
 * @param {boolean} ellipsis - 文字あふれ時省略
 * @param {boolean} wordBreakAll - 強制改行
 * @returns コンポーネント
 */
export const Text: React.FC<TextProps> = ({
  id,
  children,
  fontSize,
  color,
  bold,
  sharp,
  underline,
  letterSpacing,
  lineHeight,
  textAlign,
  width,
  height,
  margin = '0',
  padding,
  ellipsis,
  wordBreakAll,
}: TextProps) => {
  return (
    <Wrap
      id={id}
      fontSize={fontSize}
      color={color}
      bold={bold}
      sharp={sharp}
      underline={underline}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      textAlign={textAlign}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      ellipsis={ellipsis}
      wordBreakAll={wordBreakAll}
    >
      {children}
    </Wrap>
  );
};
