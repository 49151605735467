/**
 * ページヘッダー
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, DisplaySize, UrlPath, Z_INDEX } from 'commons';
import { HeaderMenuButton } from 'components/organisms';
import { ListMenu } from 'components/organisms/koronaworld';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  displaySize: DisplaySize;
}

const Wrap = styled.div<WrapProps>`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.header};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  line-height: 1;
`;

const PageTitle = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 40px;
`;

interface HeaderProps {
  disabledMenu?: boolean;
}
/**
 * 【テンプレート】 ページヘッダー
 * @param {boolean} disabledMenu - メニューボタン表示フラグ
 * @returns コンポーネント
 */
export const Header: React.FC<HeaderProps> = ({
  disabledMenu = false,
}: HeaderProps) => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);

  return (
    <>
      <Wrap colorPalette={colorPalette} displaySize={displaySize}>
        <PageTitle
          onClick={() => {
            if (!disabledMenu) {
              navigate(UrlPath.CARD);
            }
          }}
        >
          <LogoImg src="/koronaworld/logo_white.svg" />
        </PageTitle>
        {disabledMenu === false && <HeaderMenuButton isDark />}
      </Wrap>
      <ListMenu />
    </>
  );
};
