/**
 * 会員更新期間告知モーダル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  LocalStorageKey,
  RenewPopupType,
  UrlName,
  UrlPath,
  Z_INDEX,
  checkPopupDisabled,
  getWeekString,
  removePopupDisabled,
  setPopupDisabled,
} from 'commons';
import { Button, Enhancement, Link, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CheckBoxForm } from 'components/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 32px;
`;

/**
 * 【有機体】 会員更新期間告知モーダル
 * @returns コンポーネント
 */
export const RenewAnnounceModal: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [opened1, setOpened1] = useState<boolean>(false);
  const [opened2, setOpened2] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, modalWidth: '600px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  useEffect(() => {
    if (
      membershipData.memberNo &&
      membershipData.updatablePeriodFrom &&
      membershipData.updatablePeriodTo &&
      membershipData.validPeriodTo
    ) {
      //有効期限が更新されていたらポップアップ非表示削除
      const lastValidPeriodTo = localStorage.getItem(
        membershipData.memberNo + LocalStorageKey.LAST_VALID_PERIOD_TO,
      );
      if (
        !lastValidPeriodTo ||
        new Date(lastValidPeriodTo).getTime() !==
          new Date(membershipData.validPeriodTo).getTime()
      ) {
        removePopupDisabled(RenewPopupType.start);
        removePopupDisabled(RenewPopupType.month);
      }
      localStorage.setItem(
        membershipData.memberNo + LocalStorageKey.LAST_VALID_PERIOD_TO,
        membershipData.validPeriodTo,
      );
      //ポップアップ表示制御処理
      let date = new Date();
      const updatablePeriodFrom = new Date(
        membershipData.updatablePeriodFrom,
      ).getTime();
      const updatablePeriodTo = new Date(
        membershipData.updatablePeriodTo,
      ).getTime();
      date.setDate(date.getDate() + 30);
      if (
        updatablePeriodTo < date.getTime() &&
        !checkPopupDisabled(RenewPopupType.month)
      ) {
        setPopupDisabled(RenewPopupType.start);
        setOpened2(true);
        return;
      }
      date = new Date();
      if (
        updatablePeriodFrom < date.getTime() &&
        !checkPopupDisabled(RenewPopupType.start)
      ) {
        setOpened1(true);
        return;
      }
    }
  }, [membershipData]);

  if (loadingMessage) {
    return null;
  } else {
    return (
      <>
        <Modal
          colorPalette={colorPalette}
          zIndex={Z_INDEX.renewPopup}
          headerTitle="有効期限更新のお願い"
          headerTitleSize={css.fontSize}
          width={css.modalWidth}
          padding={css.modalPadding}
          opened={opened1}
          onClose={() => {
            setOpened1(false);
          }}
        >
          <Wrap>
            <Text
              fontSize={css.fontSize}
              textAlign="center"
              margin="16px 0 16px"
            >
              有効期限が更新可能になりました。
              <br />
              <Enhancement color={colorPalette.negative}>
                {membershipData.validPeriodTo
                  ? new Date(membershipData.validPeriodTo).toLocaleDateString(
                      'ja-JP',
                    )
                  : '―'}
                (
                {membershipData.validPeriodTo
                  ? getWeekString(membershipData.validPeriodTo)
                  : '―'}
                )
              </Enhancement>
              <br />
              上記有効期限までに会員の更新を行って下さい。
            </Text>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin="0 0 16px"
              onClick={() => {
                setOpened1(false);
                navigate(UrlPath.RENEW);
              }}
            >
              {UrlName.RENEW}画面へ進む
            </Button>
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSizeMiddle}
              bold
              onClick={() => {
                setOpened1(false);
              }}
            >
              閉じる
            </Link>
            {opened1 && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(RenewPopupType.start);
                    } else {
                      removePopupDisabled(RenewPopupType.start);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>

        <Modal
          colorPalette={colorPalette}
          zIndex={Z_INDEX.modal}
          headerTitle="有効期限更新のお願い"
          headerTitleSize={css.fontSize}
          width={css.modalWidth}
          padding={css.modalPadding}
          opened={opened2}
          onClose={() => {
            setOpened2(false);
          }}
        >
          <Wrap>
            <Text
              fontSize={css.fontSize}
              textAlign="center"
              margin="16px 0 16px"
            >
              有効期限まで残り30日を切りました。
              <br />
              <Enhancement color={colorPalette.negative}>
                {membershipData.validPeriodTo
                  ? new Date(membershipData.validPeriodTo).toLocaleDateString(
                      'ja-JP',
                    )
                  : '―'}
                (
                {membershipData.validPeriodTo
                  ? getWeekString(membershipData.validPeriodTo)
                  : '―'}
                )
              </Enhancement>
              <br />
              上記有効期間を過ぎても更新を行わない場合は、特典その他サービスの提供は失効いたします。
            </Text>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin="0 0 16px"
              onClick={() => {
                setOpened2(false);
                navigate(UrlPath.RENEW);
              }}
            >
              {UrlName.RENEW}画面へ進む
            </Button>
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSizeMiddle}
              bold
              onClick={() => {
                setOpened2(false);
              }}
            >
              閉じる
            </Link>
            {opened2 && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(RenewPopupType.month);
                    } else {
                      removePopupDisabled(RenewPopupType.month);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>
      </>
    );
  }
};
