/**
 * メールアドレス修正パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  Placeholder,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import { InputTextForm, MessageModal } from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  updateEmail,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface EmailWrapProps {
  colorPalette: ColorPalette;
}
const EmailWrap = styled.div<EmailWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.5)};
  border-radius: 8px;
  padding: 8px;
  margin: 16px 0;
`;

/**
 * 【有機体】 メールアドレス修正パネル
 * @returns コンポーネント
 */
export const EmailEditPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [active, setActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const errorList = useRef<{
    email: boolean;
  }>({
    email: true,
  });
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center">
          変更するメールアドレスを入力して
          <br />
          送信ボタンを押してください。
        </Text>
        <EmailWrap colorPalette={colorPalette}>
          <Text fontSize={css.fontSize} textAlign="center">
            現在のメールアドレス
          </Text>
          <Text
            color={colorPalette.positive}
            fontSize={css.fontSize}
            textAlign="center"
            bold
            wordBreakAll
          >
            {membershipData.email}
          </Text>
        </EmailWrap>
        <InputTextForm
          initialValue={email}
          validatorRules={['required', 'email', 'email_string']}
          placeholder={Placeholder.EMAIL}
          maxLength={200}
          onChange={(value, isError) => {
            errorList.current.email = isError;
            setEmail(value);
          }}
          activeFlag={active}
        >
          新しいメールアドレス
        </InputTextForm>
        <Text fontSize={css.fontSizeSmall}>
          入力したメールアドレスは全て小文字に自動変換されます。今後のログイン時には小文字でご入力ください。
        </Text>
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSizeSmall}
          margin="16px 0 0"
        >
          通信事業者が提供するキャリアメール（docomo、au、softbank)等を登録した場合、認証メールが届かない事例が報告されています。
          <br />
          ご変更の際には、Gmailやyahooメール等のフリーメールをご登録ください。
        </Text>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={() => {
            if (errorList.current.email) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力した情報に誤りがあります',
                ),
              );
            } else {
              dispatch(updateEmail({ email: email.toLowerCase() }));
            }
          }}
        >
          送信
        </Button>
        <Text margin="24px 0 16px" textAlign="center">
          <Link
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            onClick={() => navigate(UrlPath.EMAIL_EDIT_CONFIRM)}
          >
            {UrlName.EMAIL_EDIT_CONFIRM}画面に進む
          </Link>
        </Text>
      </Panel>

      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.PROFILE)}
        >
          {UrlName.PROFILE}画面に戻る
        </Link>
      </Text>
      <MessageModal onClose={() => navigate(UrlPath.EMAIL_EDIT_CONFIRM)} />
    </Wrap>
  );
};
