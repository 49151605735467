/**
 * 顔認証登録エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  FACE_IMAGE_MAX_SIZE,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Image, Link, Text } from 'components/atoms';
import { InputFile, Modal, Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  MessageModal,
} from 'components/organisms';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  faceImageUrlSelector,
  postFaceImageErasure,
  postFaceImageRegister,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
`;

/**
 * 【テンプレート】 顔認証登録エリア
 * @returns コンポーネント
 */
export const FaceRegisterArea: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const faceImageUrl = useTypeSelector(faceImageUrlSelector);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          panelPadding: '32px 32px 16px',
          textMargin: '0 0 16px',
          imgSrc: '/kobeportmuseum/guidance_pc.png',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          panelPadding: '16px 16px 8px',
          textMargin: '0 0 16px',
          imgSrc: '/kobeportmuseum/guidance_sp.png',
        };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.FACE_REGISTER}</ContentsTitle>
      <Wrap>
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
          margin="0 0 32px"
        >
          {faceImageUrl ? (
            <>
              <Text textAlign="center" margin={css.textMargin}>
                以下の顔写真が登録済みです。
                <br />
                別の写真を登録したい場合は、削除を実行してください。
              </Text>
              <ImageWrap>
                <Image src={faceImageUrl} width="240px" shadow />
              </ImageWrap>
              <Button
                colorPalette={colorPalette}
                color={colorPalette.negative}
                width={css.buttonWidth}
                margin="32px auto 16px"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                削除
              </Button>
              <Modal
                colorPalette={colorPalette}
                width={css.modalWidth}
                padding={css.modalPadding}
                zIndex={Z_INDEX.modal}
                opened={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
              >
                <Text
                  fontSize={css.fontSize}
                  lineHeight={1.3}
                  textAlign="center"
                  margin="12px 0 0"
                >
                  登録されている顔写真を削除します。
                </Text>
                <Button
                  colorPalette={colorPalette}
                  color={colorPalette.negative}
                  fontSize={css.fontSize}
                  width={css.buttonWidth}
                  margin="24px auto 0"
                  onClick={() => {
                    setIsOpen(false);
                    dispatch(postFaceImageErasure());
                  }}
                >
                  削除実行
                </Button>
                <Button
                  colorPalette={colorPalette}
                  fontSize={css.fontSize}
                  width={css.buttonWidth}
                  margin="24px auto 16px"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  戻る
                </Button>
              </Modal>
              <MessageModal
                onClose={() => {
                  location.reload();
                }}
              />
            </>
          ) : (
            <>
              <Image src={css.imgSrc} width="100%" />
              <Text textAlign="center" margin={css.textMargin}>
                カメラアプリで顔写真を撮影し選択してください。
              </Text>
              <InputFile
                accept=".jpg,.jpeg,.heic,.heif"
                maxSize={FACE_IMAGE_MAX_SIZE}
                previewed
                colorPalette={colorPalette}
                width={css.inputFileWidth}
                margin="0 auto"
                onChange={(file) => {
                  setFile(file);
                }}
                dispatchLoadingMessage={(message) => {
                  dispatch(BasicSlice.actions.changeLoadingMessage(message));
                }}
              />
              <Button
                colorPalette={colorPalette}
                width={css.buttonWidth}
                margin="32px auto 16px"
                disabled={file === null}
                onClick={() => {
                  if (file) {
                    dispatch(postFaceImageRegister({ file }));
                  }
                }}
              >
                登録実行
              </Button>
              <MessageModal
                onClose={() => {
                  navigate(UrlPath.CARD);
                }}
              />
            </>
          )}
        </Panel>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面に戻る
        </Link>
      </Wrap>
    </ContentsBlock>
  );
};
