/**
 * コンテンツ表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { DisplaySize } from 'commons';
import React, { ReactNode, useMemo } from 'react';
import { displaySizeSelector, useTypeSelector } from 'stores';
import styled from 'styled-components';

interface WrapProps {
  imageSrc?: string;
  minHeight?: string;
  height?: string;
}

const Wrap = styled.div<WrapProps>`
  width: 100%;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  ${({ height }) => height && `overflow: hidden;`}
  ${({ height }) => height && `height: ${height};`}
  ${({ imageSrc }) => imageSrc && `background-image: url(${imageSrc});`}
  ${({ imageSrc }) => imageSrc && 'background-repeat: no-repeat;'}
  ${({ imageSrc }) => imageSrc && 'background-size: cover;'}
`;

interface ContentsProps {
  children?: ReactNode;
  minHeight?: string;
  height?: string;
  imageSrcPc?: string;
  imageSrcSp?: string;
}
/**
 * 【テンプレート】 コンテンツ表示エリア
 * @param {ReactNode} children - 子要素
 * @param {string} minHeight - 最小高さ
 * @param {string} height - 高さ
 * @param {String} imageSrcPc - 背景画像(PC)
 * @param {String} imageSrcSp - 背景画像(SP)
 * @returns コンポーネント
 */
export const Contents: React.FC<ContentsProps> = ({
  children,
  minHeight,
  height,
  imageSrcPc,
  imageSrcSp,
}: ContentsProps) => {
  const displaySize = useTypeSelector(displaySizeSelector);
  const imageSrc = useMemo(() => {
    return displaySize === DisplaySize.LARGE ? imageSrcPc : imageSrcSp;
  }, [displaySize]);

  return (
    <Wrap imageSrc={imageSrc} minHeight={minHeight} height={height}>
      {children}
    </Wrap>
  );
};
