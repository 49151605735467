/**
 * パネルメニュー
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
} from 'commons';
import { Text } from 'components/atoms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  maxWidth: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0 8px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  padding: 20px;
`;

interface PanelButtonProps {
  imgSrc?: string;
  colorPalette: ColorPalette;
  width: string;
  height: string;
  margin?: string;
}
const PanelButton = styled.button<PanelButtonProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  ${({ imgSrc }) => `background-image: url('${imgSrc}');`}
  background-repeat: no-repeat;
  background-position: center;
  ${({ margin }) => margin && `margin: ${margin};`}
  border-radius: 8px;
  border: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    ${({ onClick, colorPalette }) =>
      onClick && `background-color: ${colorPalette.conceptLight}`}
  }
`;

/**
 * 【有機体】 パネルメニュー
 * @returns コンポーネント
 */
export const PanelMenu: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          width: '160px',
          height: '104px',
          margin: '16px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          width: '48%',
          height: '104px',
          margin: '12px 0 0',
        };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} maxWidth={css.panelWidth}>
      <PanelButton
        colorPalette={colorPalette}
        width="100%"
        height="80px"
        onClick={() => {
          if (membershipData.link) {
            window.open(membershipData.link);
          } else if (process.env.REACT_APP_PORTAL_URI) {
            window.open(process.env.REACT_APP_PORTAL_URI);
          } else {
            navigate(UrlPath.ENV_ERROR);
          }
        }}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1.4}
          textAlign="center"
          bold
        >
          {membershipData.link
            ? membershipData.attributes?.theater + '公式ページ'
            : process.env.REACT_APP_PORTAL_NAME}
          <br />
          (チケット購入)
        </Text>
      </PanelButton>
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/coupon_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.COUPON)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.COUPON}
        </Text>
      </PanelButton>
      {membershipData.couponMarketOpen && (
        <PanelButton
          colorPalette={colorPalette}
          imgSrc="/svg/coupon_gray.svg"
          width={css.width}
          height={css.height}
          margin={css.margin}
          onClick={() => navigate(UrlPath.COUPON_MARKET)}
        >
          <Text
            color={colorPalette.darkTone}
            fontSize={css.fontSize}
            lineHeight={1}
            textAlign="center"
            bold
          >
            {UrlName.COUPON_MARKET}
          </Text>
        </PanelButton>
      )}
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/exchange_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.POINT)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.POINT}
        </Text>
      </PanelButton>
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/point_history_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.POINT_HISTORY)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.POINT_HISTORY}
        </Text>
      </PanelButton>
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/movie_history_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.HISTORY)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.HISTORY}
        </Text>
      </PanelButton>
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/setting_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.PROFILE)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.PROFILE}
        </Text>
      </PanelButton>
      <PanelButton
        colorPalette={colorPalette}
        imgSrc="/svg/update_gray.svg"
        width={css.width}
        height={css.height}
        margin={css.margin}
        onClick={() => navigate(UrlPath.RENEW)}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSize}
          lineHeight={1}
          textAlign="center"
          bold
        >
          {UrlName.RENEW}
        </Text>
      </PanelButton>
    </Wrap>
  );
};
