/**
 * メンテナンス表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { GoogleFontsIcon, Text } from 'components/atoms';
import React, { useMemo } from 'react';
import { displaySizeSelector, useTypeSelector } from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0 0;
`;

/**
 * 【テンプレート】 メンテナンス表示エリア
 * @returns コンポーネント
 */
export const MaintenanceArea: React.FC = () => {
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Message>
        <GoogleFontsIcon
          name="engineering"
          size={css.fontSizeLarge}
          margin="0 16px 0 0"
        />
        <Text>メンテナンス中</Text>
      </Message>
      <Message>
        <Text fontSize={css.fontSize} textAlign="center">
          ただいまサイトのメンテナンス中です。
          <br />
          時間をおいて再度ご訪問ください。
        </Text>
      </Message>
    </Wrap>
  );
};
