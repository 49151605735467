/**
 * クーポンシェア完了エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import {
  ContentsBlock,
  ContentsTitle,
  CouponShareCompletePanel,
} from 'components/organisms';
import React from 'react';

interface CouponShareCompleteAreaProps {
  couponName?: string;
  couponNo?: string;
  pinCode?: string;
  startingTime?: string;
  endingTime?: string;
  description?: string;
}
/**
 * 【テンプレート】 クーポンシェア完了エリア
 * @param {string} couponName - クーポン名
 * @param {string} couponNo - クーポン番号
 * @param {string} pinCode - 暗証番号
 * @param {string} startingTime - 有効期限開始
 * @param {string} endingTime - 有効期限終了
 * @param {string} description - 詳細説明
 * @returns コンポーネント
 */
export const CouponShareCompleteArea: React.FC<
  CouponShareCompleteAreaProps
> = ({
  couponName,
  couponNo,
  pinCode,
  startingTime,
  endingTime,
  description,
}: CouponShareCompleteAreaProps) => {
  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.COUPON_SHARE_COMPLETE}</ContentsTitle>
      <CouponShareCompletePanel
        couponName={couponName}
        couponNo={couponNo}
        pinCode={pinCode}
        startingTime={startingTime}
        endingTime={endingTime}
        description={description}
      />
    </ContentsBlock>
  );
};
