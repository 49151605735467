/**
 * クーポン購入完了エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  MemberCouponListDataTypes,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Enhancement, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  MessageModal,
} from 'components/organisms';
import { GoogleAnalyticsPageViewEvent } from 'components/utilities';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  invoiceFeeSelector,
  paymentSuccessSelector,
  resultMemberCouponListSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface CouponWrapProps {
  colorPalette: ColorPalette;
}
const CouponWrap = styled.div<CouponWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.3)};
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0 0;
`;

/**
 * 【テンプレート】 クーポン購入完了エリア
 * @returns コンポーネント
 */
export const CouponMarketPaymentCompleteArea: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const paymentSuccess = useTypeSelector(paymentSuccessSelector);
  const invoiceFee = useTypeSelector(invoiceFeeSelector);
  const resultMemberCouponList = useTypeSelector(
    resultMemberCouponListSelector,
  );
  const stockCouponList = useMemo(() => {
    const result: { coupon: MemberCouponListDataTypes; stock: number }[] = [];
    resultMemberCouponList.forEach((data) => {
      for (let i = 0; i < result.length; i += 1) {
        if (
          result[i].coupon.couponId === data.couponId &&
          result[i].coupon.validPeriod?.startingTime ===
            data.validPeriod?.startingTime &&
          result[i].coupon.validPeriod?.endingTime ===
            data.validPeriod?.endingTime
        ) {
          result[i].stock += 1;
          return;
        }
      }
      result.push({ coupon: data, stock: 1 });
    });
    return result;
  }, [resultMemberCouponList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.COUPON_MARKET_PAYMENT_COMPLETE}</ContentsTitle>
      <Wrap>
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
        >
          {paymentSuccess ? (
            <>
              <Text fontSize={css.fontSize}>
                クーポンの購入が完了しました。
                <br />
                購入したクーポンは{UrlName.COUPON}
                画面に追加されましたのでご確認下さい。
              </Text>
              <CouponWrap colorPalette={colorPalette}>
                <Text fontSize={css.fontSize} bold>
                  追加クーポン
                </Text>
                {stockCouponList.map((data) => (
                  <Text key={data.coupon.couponId} fontSize={css.fontSize}>
                    ・ {data.coupon.couponName}
                    <Enhancement color={colorPalette.concept} bold>
                      ×{data.stock}枚
                    </Enhancement>
                  </Text>
                ))}
              </CouponWrap>
              {invoiceFee === '' || invoiceFee === '0' ? (
                <Button
                  colorPalette={colorPalette}
                  fontSize={css.fontSize}
                  width={css.buttonWidth}
                  margin="32px auto 8px"
                  onClick={() => {
                    navigate(UrlPath.CARD);
                  }}
                >
                  {UrlName.CARD}画面へ進む
                </Button>
              ) : (
                <Button
                  colorPalette={colorPalette}
                  fontSize={css.fontSize}
                  width={css.buttonWidth}
                  margin="32px auto 8px"
                  onClick={() => {
                    navigate(UrlPath.INVOICE);
                  }}
                >
                  {UrlName.INVOICE}画面へ進む
                </Button>
              )}
              <GoogleAnalyticsPageViewEvent sendToString="IfIaCJrXi4UYEOL23pEp" />
            </>
          ) : (
            <>
              <Text fontSize={css.fontSize}>
                クーポン購入の手続きをしています。
                <br />
                エラーが表示された場合は購入が完了しておりません。
                <br />
                再度{UrlName.COUPON_MARKET}画面から購入をお試しください。
              </Text>
              <Button
                colorPalette={colorPalette}
                fontSize={css.fontSize}
                width={css.buttonWidth}
                margin="32px auto 8px"
                onClick={() => {
                  navigate(UrlPath.COUPON_MARKET);
                }}
              >
                {UrlName.COUPON_MARKET}画面へ戻る
              </Button>
            </>
          )}
        </Panel>
      </Wrap>
      <MessageModal />
    </ContentsBlock>
  );
};
