/**
 * ログイン後リダイレクトページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import {
  DOCUMENT_TITLE,
  LocalStorageKey,
  LoginStatus,
  UrlName,
  UrlPath,
  postLogError,
  useDidMount,
} from 'commons';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BasicSlice, getNewCustomerApiToken, useTypeDispatch } from 'stores';

/**
 * 【ページ】 ログイン後リダイレクトページ
 * @returns コンポーネント
 */
export const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const queryParameter = new URLSearchParams(useLocation().search);

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.HOME;
    dispatch(BasicSlice.actions.changeLoadingMessage('認証処理中...'));
    const state = queryParameter.get('state');
    const code = queryParameter.get('code');
    const error = queryParameter.get('error');
    const status =
      localStorage.getItem(LocalStorageKey.LOGIN_STATUS) || LoginStatus.FIRST;

    //シネマサンシャイン移行対応
    const sub = queryParameter.get('sub');
    const previousNo = localStorage.getItem(LocalStorageKey.PREVIOUS_NO);
    if (sub && sub !== 'undefined' && !previousNo) {
      localStorage.setItem(LocalStorageKey.PREVIOUS_NO, sub);
    }
    if (state === 'signup') {
      window.location.href = process.env.REACT_APP_CUSTOMER_API_LOGIN_URI
        ? process.env.REACT_APP_CUSTOMER_API_LOGIN_URI + location.origin
        : location.origin + UrlPath.ENV_ERROR;
    } else if (code && status === LoginStatus.FIRST) {
      dispatch(getNewCustomerApiToken({ code }));
    } else if (error) {
      postLogError(`ログイン認証エラー[${status}]`);
      dispatch(BasicSlice.actions.changeLoadingMessage(''));
      dispatch(BasicSlice.actions.changeAuthErrorMessage(error));
    } else {
      dispatch(BasicSlice.actions.changeLoadingMessage(''));
      navigate(UrlPath.CARD);
    }
  });

  return null;
};
