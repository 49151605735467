/**
 * 会員証ページポップアップ表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
  Z_INDEX,
  checkPopupDisabled,
  removePopupDisabled,
  setPopupDisabled,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { CheckBox, Modal } from 'components/molecules';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CARD_001 = {
  key: 'S7f68L9Y',
  fromDate: new Date('2024-08-01T00:00:00+09:00').getTime(),
  toDate: new Date('2024-08-13T00:00:00+09:00').getTime(),
};

/**
 * 【テンプレート】 会員証ページポップアップ表示エリア
 * @returns コンポーネント
 */
export const CardPopup: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [opened001, setOpened001] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          textMargin: '16px 0 0',
          buttonMargin: '8px auto 24px',
          checkBoxSize: '20px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          textMargin: '8px 0 0',
          buttonMargin: '8px auto 16px',
          checkBoxSize: '20px',
        };
  }, [displaySize]);

  useEffect(() => {
    if (membershipData.serverTime) {
      const date = new Date(membershipData.serverTime).getTime();
      if (!checkPopupDisabled(CARD_001.key)) {
        if (date >= CARD_001.fromDate && date < CARD_001.toDate) {
          setOpened001(true);
        }
      }
    }
  }, [membershipData]);

  if (loadingMessage) {
    return null;
  } else {
    return (
      <>
        <Modal
          colorPalette={colorPalette}
          opened={opened001}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.popup}
          headerTitle="お知らせ"
          headerTitleSize={css.fontSize}
          onClose={() => {
            setOpened001(false);
          }}
        >
          <Wrap>
            <Text fontSize={css.fontSizeMiddle} bold>
              8月1日にお得なクーポンが配信されました！
            </Text>
            <Text fontSize={css.fontSize} margin={css.textMargin}>
              詳細はクーポン確認画面からチェック
            </Text>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin={css.buttonMargin}
              onClick={() => {
                navigate(UrlPath.COUPON);
                setOpened001(false);
              }}
            >
              {UrlName.COUPON}画面へ
            </Button>
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              onClick={() => {
                setOpened001(false);
              }}
            >
              閉じる
            </Link>
            {opened001 && (
              <CheckBox
                colorPalette={colorPalette}
                valueList={[{ label: '次回から表示しない', value: 'false' }]}
                initialValue={[]}
                fontSize={css.fontSize}
                errorFontSize={css.fontSizeSmall}
                width={css.checkBoxSize}
                height={css.checkBoxSize}
                margin="16px auto 0"
                itemMargin="0"
                onChange={(value) => {
                  if (value.includes('false')) {
                    setPopupDisabled(CARD_001.key);
                  } else {
                    removePopupDisabled(CARD_001.key);
                  }
                }}
              />
            )}
          </Wrap>
        </Modal>
      </>
    );
  }
};
