/**
 * ブラウザ情報表示パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  browserSelector,
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px 32px;
`;

/**
 * 【有機体】 ブラウザ情報表示パネル
 * @returns コンポーネント
 */
export const BrowserVersionPanel: React.FC = () => {
  const browser = useTypeSelector(browserSelector);
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} bold>
          お使いのスマートフォン情報
        </Text>
        {browser.os.name === 'iOS' ? (
          <Text fontSize={css.fontSize} margin="0 0 0 24px">
            {browser.os.name} {browser.os.version}
          </Text>
        ) : (
          <Text fontSize={css.fontSize} margin="0 0 0 24px">
            {browser.os.name}
          </Text>
        )}
        <Text fontSize={css.fontSize} bold>
          お使いのブラウザ情報
        </Text>
        <Text fontSize={css.fontSize} margin="0 0 0 24px">
          {browser.name}
        </Text>
      </Panel>
    </Wrap>
  );
};
