/**
 * 会員証ページポップアップ表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  Z_INDEX,
  checkPopupDisabled,
  removePopupDisabled,
  setPopupDisabled,
} from 'commons';
import { Button } from 'components/atoms';
import { Modal } from 'components/molecules';
import { CheckBoxForm } from 'components/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 32px;
`;

const CARD_001 = {
  key: 'CMB7tKzh',
  fromDate: new Date('2023-05-20T00:00:00+09:00').getTime(),
  toDate: new Date('2023-06-01T00:00:00+09:00').getTime(),
};

/**
 * 【テンプレート】 会員証ページポップアップ表示エリア
 * @returns コンポーネント
 */
export const CardPopup: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  useEffect(() => {
    if (membershipData.serverTime) {
      const date = new Date(membershipData.serverTime).getTime();
      if (!checkPopupDisabled(CARD_001.key)) {
        if (date >= CARD_001.fromDate && date < CARD_001.toDate) {
          setOpened(true);
        }
      }
    }
  }, [membershipData]);

  if (loadingMessage) {
    return null;
  } else {
    return (
      <>
        <Modal
          colorPalette={colorPalette}
          opened={opened}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.popup}
          headerTitle="お知らせ"
          headerTitleSize={css.fontSize}
          onClose={() => {
            setOpened(false);
          }}
        >
          <Wrap>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              onClick={() => {
                setOpened(false);
              }}
            >
              閉じる
            </Button>
            {opened && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(CARD_001.key);
                    } else {
                      removePopupDisabled(CARD_001.key);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>
      </>
    );
  }
};
