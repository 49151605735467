import { UrlPath } from 'commons';
import {
  EnvError,
  Home,
  Login,
  Logout,
  Maintenance,
  NotFound,
  RegisterPortal,
  SignInStatus,
} from 'components/pages';
import {
  Card,
  Coupon,
  CouponMarket,
  CouponMarketPaymentComplete,
  CouponShare,
  CouponShareComplete,
  DigitalRegister,
  DigitalRegisterComplete,
  DigitalRegisterConfirm,
  DigitalRegisterCoupon,
  DigitalRegisterCouponConfirm,
  EmailEdit,
  EmailEditConfirm,
  History,
  Invoice,
  InvoicePrint,
  MigrationChecker,
  MigrationRegister,
  MigrationRegisterConfirm,
  NameSeparationEdit,
  PhysicsCardChecker,
  PhysicsCardRegister,
  PhysicsCardRegisterConfirm,
  Point,
  PointHistory,
  Profile,
  ProfileEdit,
  ProfileEditConfirm,
  Renew,
  RenewComplete,
  UserPolicy,
} from 'components/pages/koronaworld';
import {
  AuthElement,
  CheckBrowser,
  ErrorModals,
  GoogleAnalytics,
  Loading,
  ResizeDisplaySize,
  ScrollToTop,
  Wallpaper,
} from 'components/utilities';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export const AppKoronaWorld: React.FC = () => {
  return (
    <BrowserRouter>
      <GoogleAnalytics />
      <CheckBrowser />
      <ScrollToTop />
      <ResizeDisplaySize />
      <ErrorModals />
      <Loading />
      <Wallpaper>
        <Routes>
          <Route path={UrlPath.HOME} element={<Home />} />
          <Route path={UrlPath.USER_POLICY} element={<UserPolicy />} />
          <Route path={UrlPath.REGISTER_PORTAL} element={<RegisterPortal />} />
          <Route path={UrlPath.LOGIN} element={<Login />} />
          <Route path={UrlPath.LOGOUT} element={<Logout />} />
          <Route path={UrlPath.SIGN_IN_STATUS} element={<SignInStatus />} />
          <Route
            path={UrlPath.MIGRATION_CHECKER}
            element={
              <AuthElement
                element={<MigrationChecker />}
                path={UrlPath.MIGRATION_CHECKER}
              />
            }
          />
          <Route
            path={UrlPath.MIGRATION_REGISTER}
            element={
              <AuthElement
                element={<MigrationRegister />}
                path={UrlPath.MIGRATION_REGISTER}
              />
            }
          />
          <Route
            path={UrlPath.MIGRATION_REGISTER_CONFIRM}
            element={
              <AuthElement
                element={<MigrationRegisterConfirm />}
                path={UrlPath.MIGRATION_REGISTER_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.PHYSICS_CARD_CHECKER}
            element={
              <AuthElement
                element={<PhysicsCardChecker />}
                path={UrlPath.PHYSICS_CARD_CHECKER}
              />
            }
          />
          <Route
            path={UrlPath.DIGITAL_REGISTER}
            element={
              <AuthElement
                element={<DigitalRegister />}
                path={UrlPath.DIGITAL_REGISTER}
              />
            }
          />
          <Route
            path={UrlPath.DIGITAL_REGISTER_CONFIRM}
            element={
              <AuthElement
                element={<DigitalRegisterConfirm />}
                path={UrlPath.DIGITAL_REGISTER_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.DIGITAL_REGISTER_COMPLETE}
            element={
              <AuthElement
                element={<DigitalRegisterComplete />}
                path={UrlPath.DIGITAL_REGISTER_COMPLETE}
              />
            }
          />
          <Route
            path={UrlPath.DIGITAL_REGISTER_COUPON}
            element={
              <AuthElement
                element={<DigitalRegisterCoupon />}
                path={UrlPath.DIGITAL_REGISTER_COUPON}
              />
            }
          />
          <Route
            path={UrlPath.DIGITAL_REGISTER_COUPON_CONFIRM}
            element={
              <AuthElement
                element={<DigitalRegisterCouponConfirm />}
                path={UrlPath.DIGITAL_REGISTER_COUPON_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.PHYSICS_CARD_REGISTER_CONFIRM}
            element={
              <AuthElement
                element={<PhysicsCardRegisterConfirm />}
                path={UrlPath.PHYSICS_CARD_REGISTER_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.PHYSICS_CARD_REGISTER}
            element={
              <AuthElement
                element={<PhysicsCardRegister />}
                path={UrlPath.PHYSICS_CARD_REGISTER}
              />
            }
          />
          <Route
            path={UrlPath.NAME_SEPARATION_EDIT}
            element={
              <AuthElement
                element={<NameSeparationEdit />}
                path={UrlPath.NAME_SEPARATION_EDIT}
              />
            }
          />
          <Route
            path={UrlPath.CARD}
            element={<AuthElement element={<Card />} path={UrlPath.CARD} />}
          />
          <Route
            path={UrlPath.COUPON}
            element={<AuthElement element={<Coupon />} path={UrlPath.COUPON} />}
          />
          <Route
            path={UrlPath.COUPON_SHARE}
            element={
              <AuthElement
                element={<CouponShare />}
                path={UrlPath.COUPON_SHARE}
              />
            }
          />
          <Route
            path={UrlPath.COUPON_SHARE_COMPLETE}
            element={
              <AuthElement
                element={<CouponShareComplete />}
                path={UrlPath.COUPON_SHARE_COMPLETE}
              />
            }
          />
          <Route
            path={UrlPath.COUPON_MARKET}
            element={
              <AuthElement
                element={<CouponMarket />}
                path={UrlPath.COUPON_MARKET}
              />
            }
          />
          <Route
            path={UrlPath.COUPON_MARKET_PAYMENT_COMPLETE}
            element={
              <AuthElement
                element={<CouponMarketPaymentComplete />}
                path={UrlPath.COUPON_MARKET_PAYMENT_COMPLETE}
              />
            }
          />
          <Route
            path={UrlPath.POINT}
            element={<AuthElement element={<Point />} path={UrlPath.POINT} />}
          />
          <Route
            path={UrlPath.POINT_HISTORY}
            element={
              <AuthElement
                element={<PointHistory />}
                path={UrlPath.POINT_HISTORY}
              />
            }
          />
          <Route
            path={UrlPath.HISTORY}
            element={
              <AuthElement element={<History />} path={UrlPath.HISTORY} />
            }
          />
          <Route
            path={UrlPath.PROFILE}
            element={
              <AuthElement element={<Profile />} path={UrlPath.PROFILE} />
            }
          />
          <Route
            path={UrlPath.PROFILE_EDIT}
            element={
              <AuthElement
                element={<ProfileEdit />}
                path={UrlPath.PROFILE_EDIT}
              />
            }
          />
          <Route
            path={UrlPath.PROFILE_EDIT_CONFIRM}
            element={
              <AuthElement
                element={<ProfileEditConfirm />}
                path={UrlPath.PROFILE_EDIT_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.EMAIL_EDIT}
            element={
              <AuthElement element={<EmailEdit />} path={UrlPath.EMAIL_EDIT} />
            }
          />
          <Route
            path={UrlPath.EMAIL_EDIT_CONFIRM}
            element={
              <AuthElement
                element={<EmailEditConfirm />}
                path={UrlPath.EMAIL_EDIT_CONFIRM}
              />
            }
          />
          <Route
            path={UrlPath.RENEW}
            element={<AuthElement element={<Renew />} path={UrlPath.RENEW} />}
          />
          <Route
            path={UrlPath.RENEW_COMPLETE}
            element={
              <AuthElement
                element={<RenewComplete />}
                path={UrlPath.RENEW_COMPLETE}
              />
            }
          />
          <Route
            path={UrlPath.INVOICE}
            element={
              <AuthElement element={<Invoice />} path={UrlPath.INVOICE} />
            }
          />
          <Route
            path={UrlPath.INVOICE_PRINT}
            element={
              <AuthElement
                element={<InvoicePrint />}
                path={UrlPath.INVOICE_PRINT}
              />
            }
          />
          <Route path={UrlPath.MAINTENANCE} element={<Maintenance />} />
          <Route path={UrlPath.ENV_ERROR} element={<EnvError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Wallpaper>
    </BrowserRouter>
  );
};
