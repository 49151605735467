/**
 * 会員更新画面QR表示パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, ColorPalette, DisplaySize } from 'commons';
import { Enhancement, Separator, Text } from 'components/atoms';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  border-radius: 12px;
  padding: ${({ padding }) => padding};
  margin: 16px 0 0;
`;

const QrCode = styled.img`
  width: 120px;
  height: 120px;
`;

/**
 * 【有機体】 会員更新画面QR表示パネル
 * @returns コンポーネント
 */
export const MemberQrPanel: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  useEffect(() => {
    QRCode.toDataURL(membershipData.memberNo || '0', (err, url) => {
      setQrCodeImg(url);
    });
  }, [membershipData]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '16px',
          separator: '32px',
          topTextMargin: '0 16px 16px',
          bottomTextMargin: '16px 16px 0',
          memberNoSeparator: '16px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '16px 8px',
          separator: '16px',
          topTextMargin: '0 8px 8px',
          bottomTextMargin: '8px 8px 0',
          memberNoSeparator: '8px',
        };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} padding={css.padding}>
      <QrCode src={membershipData.memberNo && qrCodeImg} />
      <Text fontSize={css.fontSize} margin={css.bottomTextMargin}>
        <Enhancement bold>会員番号</Enhancement>
        <Separator width={css.separator} />
        {membershipData?.memberNo?.substring(0, 4) || '―'}
        <Separator width={css.memberNoSeparator} />
        {membershipData?.memberNo?.substring(4, 7) || '―'}
        <Separator width={css.memberNoSeparator} />
        {membershipData?.memberNo?.substring(7, 10) || '―'}
        <Separator width={css.memberNoSeparator} />
        {membershipData?.memberNo?.substring(10, 14) || '―'}
      </Text>
    </Wrap>
  );
};
