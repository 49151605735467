/**
 * パネルメニュー
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  SEAL_FUNCTION,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Image, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import { MessageModal } from 'components/organisms';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  createInviteCoupon,
  displaySizeSelector,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  maxWidth: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0 8px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
`;

const IconWrap = styled.div`
  text-align: center;
  margin: 0;
`;

/**
 * 【有機体】 パネルメニュー
 * @returns コンポーネント
 */
export const PanelMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          width: '128px',
          padding: '8px 8px 0',
          margin: '16px 0 0',
          textHeight: '32px',
          buttonMargin: '32px auto 0',
          iconSize: '96px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          width: '31%',
          padding: '4px 0 0',
          margin: '16px 0 0',
          textHeight: '24px',
          buttonMargin: '16px auto 8px',
          iconSize: '96px',
        };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} maxWidth={css.panelWidth}>
      <Panel
        colorPalette={colorPalette}
        width="100%"
        padding="8px 0"
        margin={css.margin}
        onClick={() => {
          if (process.env.REACT_APP_PORTAL_URI) {
            window.open(process.env.REACT_APP_PORTAL_URI);
          } else {
            navigate(UrlPath.ENV_ERROR);
          }
        }}
      >
        <Text
          color={colorPalette.darkTone}
          fontSize={css.fontSizeSmall}
          lineHeight={1.4}
          textAlign="center"
          bold
        >
          {process.env.REACT_APP_PORTAL_NAME}
          <br />
          (チケット購入)
        </Text>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.COUPON)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_coupon.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      {SEAL_FUNCTION && membershipData.couponMarketOpen && (
        <Panel
          colorPalette={colorPalette}
          width={css.width}
          padding={css.padding}
          margin={css.margin}
          onClick={() => navigate(UrlPath.COUPON_MARKET)}
        >
          <IconWrap>
            <Image
              src="/svg/menu_buy_coupon.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
          </IconWrap>
        </Panel>
      )}
      {SEAL_FUNCTION && membershipData.couponMarketOpen && (
        <Panel
          colorPalette={colorPalette}
          width={css.width}
          padding={css.padding}
          margin={css.margin}
          onClick={() => navigate(UrlPath.COUPON_MARKET)}
        >
          <IconWrap>
            <Image
              src="/svg/menu_fukubukuro.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
          </IconWrap>
        </Panel>
      )}
      {membershipData.couponMarketOpen && (
        <Panel
          colorPalette={colorPalette}
          width={css.width}
          padding={css.padding}
          margin={css.margin}
          onClick={() => navigate(UrlPath.COUPON_MARKET)}
        >
          <IconWrap>
            <Image
              src="/svg/menu_summer_enjoy_ticket.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
          </IconWrap>
        </Panel>
      )}
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.POINT)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_exchange.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.POINT_HISTORY)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_point_history.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.HISTORY)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_movie_history.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      {process.env.REACT_APP_MOBILE_ORDER_URI && (
        <Panel
          colorPalette={colorPalette}
          width={css.width}
          padding={css.padding}
          margin={css.margin}
          onClick={() => {
            if (process.env.REACT_APP_MOBILE_ORDER_URI) {
              window.open(process.env.REACT_APP_MOBILE_ORDER_URI);
            } else {
              navigate(UrlPath.ENV_ERROR);
            }
          }}
        >
          <IconWrap>
            <Image
              src="/svg/menu_mobileorder.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
          </IconWrap>
        </Panel>
      )}
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.PROFILE)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_setting.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      <Panel
        colorPalette={colorPalette}
        width={css.width}
        padding={css.padding}
        margin={css.margin}
        onClick={() => navigate(UrlPath.RENEW)}
      >
        <IconWrap>
          <Image
            src="/svg/menu_update.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
        </IconWrap>
      </Panel>
      {SEAL_FUNCTION && (
        <>
          <Panel
            colorPalette={colorPalette}
            width={css.width}
            padding={css.padding}
            margin={css.margin}
            onClick={() => setOpened(true)}
          >
            <IconWrap>
              <Image
                src="/svg/menu_invite_coupon.svg"
                width={css.iconSize}
                height={css.iconSize}
              />
            </IconWrap>
          </Panel>
          <Modal
            colorPalette={colorPalette}
            zIndex={Z_INDEX.modal}
            opened={opened}
            width={css.modalWidth}
            padding={css.modalPadding}
            headerTitle="紹介クーポン発行"
            onClose={() => {
              setOpened(false);
            }}
          >
            <Text fontSize={css.fontSize}>
              発行した紹介クーポンは{UrlName.COUPON}
              画面から『シェアする』を押して紹介相手のメールアドレスにお送りください。
            </Text>
            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin={css.buttonMargin}
              onClick={() => {
                setOpened(false);
                dispatch(createInviteCoupon());
              }}
            >
              発行する
            </Button>
            <Button
              colorPalette={colorPalette}
              color={colorPalette.negative}
              width={css.buttonWidth}
              margin={css.buttonMargin}
              onClick={() => {
                setOpened(false);
              }}
            >
              発行しない
            </Button>
          </Modal>
          <MessageModal
            onClose={() => {
              navigate(UrlPath.COUPON);
            }}
          />
        </>
      )}
    </Wrap>
  );
};
