/**
 * 会員証パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Image, Separator, Text } from 'components/atoms';
import { FaceIcon, Panel } from 'components/molecules';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  faceImageUrlSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const QrCodeArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0 20px;
`;

interface QrCodeProps {
  size: string;
}
const QrCode = styled.img<QrCodeProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
`;

interface TitleWrapPrpos {
  width: string;
}
const TitleWrap = styled.div<TitleWrapPrpos>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ width }) => width};
`;

interface MemberNoWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const MemberNoWrap = styled.div<MemberNoWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  padding: ${({ padding }) => padding};
`;

interface PeriodWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const PeriodWrap = styled.div<PeriodWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  padding: ${({ padding }) => padding};
`;

/**
 * 【有機体】 会員証パネル
 * @returns コンポーネント
 */
export const CardPanel: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const faceImageUrl = useTypeSelector(faceImageUrlSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          qrCodeSize: '160px',
          titleWidth: '200px',
          valueWidth: '240px',
          memberNoSeparator: '16px',
          memberNoPadding: '24px 0 16px',
          periodPadding: '8px 0 24px',
          faceIconSize: '160px',
          faceIconMargin: '0 0 0 48px',
          panelWidth: '640px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          qrCodeSize: '120px',
          titleWidth: '128px',
          valueWidth: 'calc(100% - 128px)',
          memberNoSeparator: '8px',
          memberNoPadding: '16px 0 8px 8px',
          periodPadding: '8px 0 24px 8px',
          faceIconSize: '120px',
          faceIconMargin: '0 0 0 32px',
          panelWidth: '100%',
        };
  }, [displaySize]);

  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  useEffect(() => {
    QRCode.toDataURL(membershipData.memberNo || '0', (err, url) => {
      setQrCodeImg(url);
    });
  }, [membershipData]);

  return (
    <Panel colorPalette={colorPalette} width={css.panelWidth} padding="0">
      <QrCodeArea>
        <QrCode
          src={membershipData.memberNo && qrCodeImg}
          size={css.qrCodeSize}
        />
        <FaceIcon
          faceImageSrc={faceImageUrl}
          size={css.faceIconSize}
          margin={css.faceIconMargin}
        />
      </QrCodeArea>

      <MemberNoWrap colorPalette={colorPalette} padding={css.memberNoPadding}>
        <TitleWrap width={css.titleWidth}>
          <Image
            src="/svg/member_icon.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            padding="0 0 0 8px"
          >
            会員番号
          </Text>
        </TitleWrap>
        <Text
          fontSize={css.fontSizeMiddle}
          lineHeight={1}
          letterSpacing={0}
          width={css.valueWidth}
          bold
        >
          {membershipData?.memberNo?.substring(0, 4) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(4, 7) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(7, 10) || '―'}
          <Separator width={css.memberNoSeparator} />
          {membershipData?.memberNo?.substring(10, 14) || '―'}
        </Text>
      </MemberNoWrap>

      <PeriodWrap colorPalette={colorPalette} padding={css.periodPadding}>
        <TitleWrap width={css.titleWidth}>
          <Image
            src="/svg/calendar_icon.svg"
            width={css.iconSize}
            height={css.iconSize}
          />
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            padding="0 0 0 8px"
            textAlign="center"
          >
            有効期限
          </Text>
        </TitleWrap>
        <Text
          fontSize={css.fontSizeMiddle}
          lineHeight={1}
          letterSpacing={0}
          width={css.valueWidth}
          bold
        >
          {membershipData.validPeriodTo
            ? new Date(membershipData.validPeriodTo).toLocaleDateString('ja-JP')
            : '―'}
        </Text>
      </PeriodWrap>
    </Panel>
  );
};
