/**
 * 会員証ページポップアップ表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  ColorPalette,
  DisplaySize,
  Z_INDEX,
  checkPopupDisabled,
  hexToRgba,
  removePopupDisabled,
  setPopupDisabled,
} from 'commons';
import {
  Button,
  Enhancement,
  Image,
  Link,
  NewIcon,
  Separator,
  Text,
} from 'components/atoms';
import { Modal } from 'components/molecules';
import { CheckBoxForm } from 'components/organisms';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 32px;
`;

interface ImageWrapProps {
  colorPalette: ColorPalette;
  padding: string;
  margin: string;
}
const ImageWrap = styled.div<ImageWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.5)};
  width: 100%;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-radius: 12px;
`;

const NewWrap = styled.div`
  display: flex;
`;

const CARD_001_A = {
  key: 'BCCin93c',
  fromDate: new Date('2023-11-15T14:00:00+09:00').getTime(),
  toDate: new Date('2024-05-31T00:00:00+09:00').getTime(),
};
const CARD_001_B = {
  key: 'BCCin93c',
  fromDate: new Date('2024-06-14T00:00:00+09:00').getTime(),
  toDate: new Date('2100-01-01T00:00:00+09:00').getTime(),
};
const CARD_002 = {
  key: 'FmVJg7ra',
  fromDate: new Date('2024-05-31T00:00:00+09:00').getTime(),
  toDate: new Date('2024-06-14T00:00:00+09:00').getTime(),
};

/**
 * 【テンプレート】 会員証ページポップアップ表示エリア
 * @returns コンポーネント
 */
export const CardPopup: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [opened001, setOpened001] = useState<boolean>(false);
  const [opened002, setOpened002] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imageSrc01: '/sskts/get_coupon_infomation_pc.png',
          imageSrc02: '/sskts/select_seat_infomation_pc.png',
          imagePadding: '24px 16px',
          imageMargin: '0 0 48px',
          newIconWidth: '48px',
          newIconHeight: '32px',
          titleMargin: '0 0 32px',
          buttonmMargin: '48px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imageSrc01: '/sskts/get_coupon_infomation_sp.png',
          imageSrc02: '/sskts/select_seat_infomation_sp.png',
          imagePadding: '24px 16px',
          imageMargin: '0 0 24px',
          newIconWidth: '40px',
          newIconHeight: '24px',
          titleMargin: '0 0 16px',
          buttonmMargin: '24px 0 0',
        };
  }, [displaySize]);

  useEffect(() => {
    if (membershipData.serverTime) {
      const date = new Date(membershipData.serverTime).getTime();
      if (!checkPopupDisabled(CARD_001_A.key)) {
        if (date >= CARD_001_A.fromDate && date < CARD_001_A.toDate) {
          setOpened001(true);
        }
      }
      if (!checkPopupDisabled(CARD_001_B.key)) {
        if (date >= CARD_001_B.fromDate && date < CARD_001_B.toDate) {
          setOpened001(true);
        }
      }
      if (!checkPopupDisabled(CARD_002.key)) {
        if (date >= CARD_002.fromDate && date < CARD_002.toDate) {
          setOpened002(true);
        }
      }
    }
  }, [membershipData]);

  if (loadingMessage) {
    return null;
  } else {
    return (
      <>
        <Modal
          colorPalette={colorPalette}
          opened={opened001}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.popup}
          headerTitle="ご案内"
          headerTitleSize={css.fontSize}
          onClose={() => {
            setOpened001(false);
          }}
        >
          <Wrap>
            <Text
              fontSize={css.fontSize}
              width="100%"
              margin="0 0 16px"
              textAlign="right"
              underline
            >
              2023/11/15 更新
            </Text>
            <Text fontSize={css.fontSize} margin={css.imageMargin}>
              シネマサンシャインより下記のご案内がございます。リンクをクリックするとご案内の内容まで移動します。
              <br />
              <Separator width={`calc(${css.newIconWidth} + 4px)`} />
              <Link
                colorPalette={colorPalette}
                href="#CardPopup01"
                fontSize={css.fontSize}
                bold
              >
                ポイント利用に関する注意事項
              </Link>
              <br />
              <NewWrap>
                <NewIcon
                  colorPalette={colorPalette}
                  fontSize={css.fontSizeSmall}
                  width={css.newIconWidth}
                  height={css.newIconHeight}
                  margin="0 4px 0 0"
                />
                <Text width={`calc(100% - ${css.newIconWidth})`}>
                  <Link
                    colorPalette={colorPalette}
                    fontSize={css.fontSize}
                    href="#CardPopup02"
                    bold
                  >
                    お連れ様の会員サービス利用に関して
                  </Link>
                </Text>
              </NewWrap>
            </Text>

            <Text id="CardPopup01" fontSize={css.fontSizeMiddle} bold>
              ポイント利用に関する注意事項
            </Text>
            <Text fontSize={css.fontSize} margin="0 0 16px">
              ポイントを使用するには、{process.env.REACT_APP_PORTAL_NAME}
              （チケット購入）を押す前に
              <Enhancement color={colorPalette.negative} bold>
                先にポイント交換が必要
              </Enhancement>
              です。
              <br />
              ポイント交換してから、チケット購入画面へとお進み下さい。
            </Text>
            <ImageWrap
              colorPalette={colorPalette}
              padding={css.imagePadding}
              margin={css.imageMargin}
            >
              <Image src={css.imageSrc01} width="100%" />
            </ImageWrap>

            <NewWrap>
              <NewIcon
                colorPalette={colorPalette}
                fontSize={css.fontSizeSmall}
                width={css.newIconWidth}
                height={css.newIconHeight}
                margin="6px 4px 0 0"
              />
              <Text
                id="CardPopup02"
                fontSize={css.fontSizeMiddle}
                width={`calc(100% - ${css.newIconWidth})`}
                bold
              >
                お連れ様の会員サービス利用に関して
              </Text>
            </NewWrap>
            <Text fontSize={css.fontSize} margin="0 0 16px">
              ポイント付与・会員サービスデイ/クーポン利用等）が利用できるようになりました。
            </Text>
            <ImageWrap
              colorPalette={colorPalette}
              padding={css.imagePadding}
              margin={css.imageMargin}
            >
              <Image src={css.imageSrc02} width="100%" />
            </ImageWrap>

            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              onClick={() => {
                setOpened001(false);
              }}
            >
              閉じる
            </Button>
            {opened001 && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(CARD_001_A.key);
                    } else {
                      removePopupDisabled(CARD_001_A.key);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>

        <Modal
          colorPalette={colorPalette}
          opened={opened002}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.popup}
          headerTitle="ご案内"
          headerTitleSize={css.fontSize}
          onClose={() => {
            setOpened002(false);
          }}
        >
          <Wrap>
            <Text
              fontSize={css.fontSizeMiddle}
              margin={css.titleMargin}
              letterSpacing={0}
              bold
            >
              シネマサンシャインリワードWEEK開催
            </Text>
            <Text fontSize={css.fontSize} margin="0 0 16px">
              シネマサンシャインの会員サービス＜シネマサンシャインリワード＞のおトクすぎる8日間が始まります！
              <br />
              リワード会員の方は期間中いつでも、何度でも通常鑑賞料金が1,200円（※）！
            </Text>
            <Text fontSize={css.fontSizeMiddle} bold>
              【開催期間】
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin="0 0 16px" bold>
              2024年6月6日（木）〜6月13日（木）
            </Text>
            <Text fontSize={css.fontSizeSmall} margin="0 0 16px">
              ※プレミアムシアターやスペシャルシートのチケットをご購入の場合は別途追加料金が発生します。
              <br />
              ※一部、特別興行等でご利用いただけない場合がございます。
              <br />
              ※インターネットでのご購入に限ります。
            </Text>
            <Image src="/sskts/campaign_banner.jpg" width="100%" />

            <Button
              colorPalette={colorPalette}
              width={css.buttonWidth}
              margin={css.buttonmMargin}
              onClick={() => {
                setOpened002(false);
              }}
            >
              閉じる
            </Button>
            {opened002 && (
              <CheckboxWrap>
                <CheckBoxForm
                  valueList={[{ label: '次回から表示しない', value: 'false' }]}
                  initialValue={[]}
                  onChange={(value) => {
                    if (value.includes('false')) {
                      setPopupDisabled(CARD_002.key);
                    } else {
                      removePopupDisabled(CARD_002.key);
                    }
                  }}
                />
              </CheckboxWrap>
            )}
          </Wrap>
        </Modal>
      </>
    );
  }
};
