/**
 * 各ページの情報を管理・操作
 */

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CustomerConsts } from 'App';
import {
  CouponListDataTypes,
  CouponType,
  FeeDataTypes,
  HistoryListDataTypes,
  LocalStorageKey,
  MemberCouponListDataTypes,
  MembershipDataTypes,
  PointHistoryListDataTypes,
  UrlPath,
  getCouponsList,
  getHistory,
  getMemberCoupons,
  getMembers,
  getServerVersion,
  postDigitalCreateMigrationTemporary,
  postFaceDownload,
  postFees,
  postMembersGetPreviousNo,
  postPoints,
} from 'commons';
import { DispatchState, EventSlice, SelectorState } from 'stores';

export interface PageState {
  pageLoadingMessage: string;
  pageErrorMessage: string;
  membershipData: MembershipDataTypes;
  faceImageUrl: string;
  registerUrl: UrlPath;
  memberCouponList: MemberCouponListDataTypes[];
  couponList: CouponListDataTypes[];
  pointHistoryList: PointHistoryListDataTypes[];
  historyList: HistoryListDataTypes[];
  feeList: FeeDataTypes;
}

const initialState: PageState = {
  pageLoadingMessage: '',
  pageErrorMessage: '',
  membershipData: {},
  faceImageUrl: '',
  registerUrl: UrlPath.HOME,
  memberCouponList: [],
  couponList: [],
  pointHistoryList: [],
  historyList: [],
  feeList: {},
};

/**
 * 汎用ページ表示処理
 */
export const didMountPage = createAsyncThunk<
  boolean,
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  return true;
});

/**
 * 会員登録ページ表示処理
 */
export const didMountRegisterPage = createAsyncThunk<
  { feeList: FeeDataTypes },
  {
    isStudent?: boolean;
    previousNo?: string;
  },
  { dispatch: DispatchState; state: SelectorState }
>('didMountRegisterPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  let feeList: FeeDataTypes = {};
  if (!CustomerConsts.DISABLED_FEE_LIST) {
    thunkApi.dispatch(
      PageSlice.actions.changePageLoadingMessage('登録準備中...'),
    );
    feeList = await postFees({ action: 'create' });
  }
  if (thunkApi.getState().event.submitMembershipData.status === 200) {
    // 確認画面から戻ってきた場合
    thunkApi.dispatch(
      PageSlice.actions.changeMembershipData(
        thunkApi.getState().event.submitMembershipData,
      ),
    );
    return { feeList };
  }
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData({
      student: props.isStudent || false,
      previousNo: props.previousNo || '',
      status: 200,
    }),
  );
  thunkApi.dispatch(
    PageSlice.actions.changeMembershipData(
      thunkApi.getState().event.submitMembershipData,
    ),
  );
  return { feeList };
});

/**
 * 姓名分離ページ表示処理
 */
export const didMountNameSeparationEditPage = createAsyncThunk<
  { membershipData: MembershipDataTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountNameSeparationEditPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  const membershipData: MembershipDataTypes = {
    lastName: '',
    firstName: '',
    lastKanaName: '',
    firstKanaName: '',
    ...(await getMembers()),
  };
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData(membershipData),
  );
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipDataAttributes({
      nameSeparated: true,
    }),
  );
  return { membershipData };
});

/**
 * 会員証(TOP)ページ表示処理
 */
export const didMountCardPage = createAsyncThunk<
  { membershipData: MembershipDataTypes; faceImageUrl: string },
  { registerUrl: UrlPath },
  { dispatch: DispatchState; state: SelectorState }
>('didMountCard/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  // バージョン更新時にページを自動更新
  const serverVersion = localStorage.getItem(LocalStorageKey.SERVER_VERSION);
  const { version } = await getServerVersion();
  if (serverVersion !== version) {
    localStorage.setItem(LocalStorageKey.SERVER_VERSION, version);
    window.location.href = `${location.origin}?update=${new Date().getTime()}`;
  }
  thunkApi.dispatch(PageSlice.actions.changeRegisterUrl(props.registerUrl));
  const membershipData = await getMembers();
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData(membershipData),
  );
  const faceImageUrl = membershipData.facePhotoCreatedAt
    ? await postFaceDownload()
    : '';
  return { membershipData, faceImageUrl };
});

/**
 * 会員証(TOP)ページ表示処理【sskts専用】
 */
export const didMountCardPageSskts = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountCardPageSskts/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  // バージョン更新時にページを自動更新
  const serverVersion = localStorage.getItem(LocalStorageKey.SERVER_VERSION);
  const { version } = await getServerVersion();
  if (serverVersion !== version) {
    localStorage.setItem(LocalStorageKey.SERVER_VERSION, version);
    window.location.href = `${location.origin}?update=${new Date().getTime()}`;
  }
  const localStoragePreviousNo = localStorage.getItem(
    LocalStorageKey.PREVIOUS_NO,
  );
  const response = await postMembersGetPreviousNo();
  if (response.previousNo && response.previousNo !== 'undefined') {
    // 登録済みの移行番号がある場合セットする(登録途中での離脱ユーザ救済)
    localStorage.setItem(LocalStorageKey.PREVIOUS_NO, response.previousNo);
  } else if (localStoragePreviousNo) {
    // 移行番号がありサーバーに未登録なら登録(離脱ユーザーを救済できるようにする)
    await postDigitalCreateMigrationTemporary({
      previousNo: localStoragePreviousNo,
    });
  }
  const previousNo = localStorage.getItem(LocalStorageKey.PREVIOUS_NO);
  thunkApi.dispatch(
    PageSlice.actions.changeRegisterUrl(
      previousNo && previousNo !== 'undefined'
        ? UrlPath.MIGRATION_REGISTER
        : UrlPath.DIGITAL_REGISTER,
    ),
  );
  const membershipData = await getMembers();
  return { membershipData };
});

/**
 * クーポンページ表示処理
 */
export const didMountCouponPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    memberCouponList: MemberCouponListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountCouponPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('クーポン情報取得中...'),
  );
  const membershipData = await getMembers();
  const memberCouponList = await getMemberCoupons();
  return { membershipData, memberCouponList };
});

/**
 * クーポンシェア完了ページ表示処理
 */
export const didMountCouponShareCompletePage = createAsyncThunk<
  { membershipData: MembershipDataTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountCouponShareCompletePage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  const membershipData = await getMembers();
  return { membershipData };
});

/**
 * クーポン購入ページ表示処理
 */
export const didMountCouponMarketPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    couponList: CouponListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountCouponMarketPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('クーポン販売情報取得中...'),
  );
  const membershipData = await getMembers();
  const couponList = await getCouponsList({ type: CouponType.PAID_COUPON });
  couponList.sort((a, b) => {
    if (a.sort && b.sort) {
      return a.sort < b.sort ? -1 : 1;
    }
    return 0;
  });
  return { membershipData, couponList };
});

/**
 * ポイント使用ページ表示処理
 */
export const didMountPointPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    couponList: CouponListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountPointPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('ポイント交換情報取得中...'),
  );
  const membershipData = await getMembers();
  const couponList = await getCouponsList({ type: CouponType.POINT_COUPON });
  return { membershipData, couponList };
});

/**
 * ポイント履歴ページ表示処理
 */
export const didMountPointHistoryPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    pointHistoryList: PointHistoryListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountPointHistoryPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('ポイント交換履歴取得中...'),
  );
  const membershipData = await getMembers();
  const date = new Date();
  const reflectDateTo = date.toISOString();
  date.setFullYear(date.getFullYear() - 5);
  const reflectDateFrom = date.toISOString();
  const pointHistoryList = await postPoints({
    reflectDateFrom,
    reflectDateTo,
  });
  return { membershipData, pointHistoryList };
});

/**
 * 鑑賞履歴ページ表示処理
 */
export const didMountHistoryPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    historyList: HistoryListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountHistoryPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('チケット情報取得中...'),
  );
  const membershipData = await getMembers();
  const historyList = await getHistory();
  return { membershipData, historyList };
});

/**
 * 会員情報ページ表示処理
 */
export const didMountProfilePage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
    memberCouponList: MemberCouponListDataTypes[];
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountProfilePage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  const membershipData = await getMembers();
  const memberCouponList = await getMemberCoupons();
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData(membershipData),
  );
  return { membershipData, memberCouponList };
});

/**
 * 会員情報修正ページ表示処理
 */
export const didMountProfileEditPage = createAsyncThunk<
  { membershipData: MembershipDataTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountProfileEditPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  let membershipData: MembershipDataTypes = {};
  if (thunkApi.getState().event.submitMembershipData.status === 200) {
    // 確認画面から戻ってきた場合
    membershipData = thunkApi.getState().event.submitMembershipData;
  } else {
    thunkApi.dispatch(
      PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
    );
    membershipData = await getMembers();
  }
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData(membershipData),
  );
  return { membershipData };
});

/**
 * メールアドレス修正ページ表示処理
 */
export const didMountEmailEditPage = createAsyncThunk<
  {
    membershipData: MembershipDataTypes;
  },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountEmailEditPage/fetch', async (props, thunkApi) => {
  let membershipData: MembershipDataTypes = {};
  if (thunkApi.getState().page.membershipData.status === 200) {
    membershipData = thunkApi.getState().page.membershipData;
  } else {
    thunkApi.dispatch(
      PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
    );
    membershipData = await getMembers();
  }
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  return { membershipData };
});

/**
 * 有効期限更新ページ表示処理
 */
export const didMountRenewPage = createAsyncThunk<
  { membershipData: MembershipDataTypes; feeList: FeeDataTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountRenewPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  const membershipData = await getMembers();
  thunkApi.dispatch(
    EventSlice.actions.changeSubmitMembershipData(membershipData),
  );
  const feeList = CustomerConsts.DISABLED_FEE_LIST
    ? {}
    : await postFees({ action: 'update' });
  return { membershipData, feeList };
});

/**
 * 顔認証登録ページ表示処理
 */
export const didMountFaceRegisterPage = createAsyncThunk<
  { membershipData: MembershipDataTypes; faceImageUrl: string },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountFaceRegisterPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('顔情報確認中...'),
  );
  const membershipData = await getMembers();
  let faceImageUrl = '';
  if (membershipData.facePhotoCreatedAt) {
    faceImageUrl = await postFaceDownload();
  }
  return { membershipData, faceImageUrl };
});

/**
 * アプリインストールページ表示処理
 * @returns 戻り値
 */
export const didMountAppInstallPage = createAsyncThunk<
  { membershipData: MembershipDataTypes },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountAppInstallPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(PageSlice.actions.initializeState());
  thunkApi.dispatch(EventSlice.actions.initializeState());
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('会員情報取得中...'),
  );
  const membershipData = await getMembers();
  return { membershipData };
});

/**
 * 領収書ページ表示処理
 */
export const didMountInvoicePage = createAsyncThunk<
  { invoiceFee: string },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountInvoicePage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('領収書情報取得中...'),
  );
  const invoiceFee = thunkApi.getState().event.invoiceFee;
  return { invoiceFee };
});

/**
 * 領収書印刷ページ表示処理
 */
export const didMountInvoicePrintPage = createAsyncThunk<
  { invoiceFee: string },
  undefined,
  { dispatch: DispatchState; state: SelectorState }
>('didMountInvoicePrintPage/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(
    PageSlice.actions.changePageLoadingMessage('領収書情報取得中...'),
  );
  const invoiceFee = thunkApi.getState().event.invoiceFee;
  return { invoiceFee };
});

export const PageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    initializeState: (state) => {
      state.pageLoadingMessage = initialState.pageLoadingMessage;
      state.pageErrorMessage = initialState.pageErrorMessage;
      state.membershipData = initialState.membershipData;
      state.faceImageUrl = initialState.faceImageUrl;
      state.registerUrl = initialState.registerUrl;
      state.memberCouponList = initialState.memberCouponList;
      state.couponList = initialState.couponList;
      state.pointHistoryList = initialState.pointHistoryList;
      state.historyList = initialState.historyList;
      state.feeList = initialState.feeList;
    },
    changePageLoadingMessage: (state, action: PayloadAction<string>) => {
      state.pageLoadingMessage = action.payload;
    },
    changePageErrorMessage: (state, action: PayloadAction<string>) => {
      state.pageErrorMessage = action.payload;
    },
    changeRegisterUrl: (state, action: PayloadAction<UrlPath>) => {
      state.registerUrl = action.payload;
    },
    changeMembershipData: (
      state,
      action: PayloadAction<MembershipDataTypes>,
    ) => {
      state.membershipData = {
        ...state.membershipData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // didMountPage
    builder.addCase(didMountPage.fulfilled, (state) => {
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountRegisterPage
    builder.addCase(didMountRegisterPage.fulfilled, (state, action) => {
      state.feeList = action.payload.feeList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountRegisterPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountNameSeparationEditPage
    builder.addCase(
      didMountNameSeparationEditPage.fulfilled,
      (state, action) => {
        state.membershipData = action.payload.membershipData;
        state.pageLoadingMessage = '';
      },
    );
    builder.addCase(
      didMountNameSeparationEditPage.rejected,
      (state, action) => {
        state.pageErrorMessage = action.error.message || 'API Error';
        state.pageLoadingMessage = '';
      },
    );

    // didMountCardPage
    builder.addCase(didMountCardPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.faceImageUrl = action.payload.faceImageUrl;
      const validPeriodTo = action.payload.membershipData.validPeriodTo;
      const nameSeparated =
        action.payload.membershipData.attributes?.nameSeparated;
      if (
        !CustomerConsts.DISABLED_VALID_PERIOD &&
        validPeriodTo &&
        new Date(validPeriodTo).getTime() < new Date().getTime()
      ) {
        // ログイン直後遷移した際には有効期限確認モジュールをすり抜けるためここでも判定
        window.location.href = location.origin + UrlPath.RENEW;
      }
      if (CustomerConsts.NAME_SEPARATED && !nameSeparated) {
        // ログイン直後遷移した際には姓名変更確認をすり抜けるためここでも判定
        window.location.href = location.origin + UrlPath.NAME_SEPARATION_EDIT;
      }
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountCardPage.rejected, (state, action) => {
      const code = parseInt(action.error?.code || '1000', 10);
      if (code === 404) {
        window.location.href = location.origin + state.registerUrl;
      } else {
        state.pageErrorMessage = action.error.message || 'API Error';
      }
      state.pageLoadingMessage = '';
    });

    // didMountCardPageSskts
    builder.addCase(didMountCardPageSskts.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      const validPeriodTo = action.payload.membershipData.validPeriodTo;
      const nameSeparated =
        action.payload.membershipData.attributes?.nameSeparated;
      if (
        !CustomerConsts.DISABLED_VALID_PERIOD &&
        validPeriodTo &&
        new Date(validPeriodTo).getTime() < new Date().getTime()
      ) {
        // ログイン直後遷移した際には有効期限確認モジュールをすり抜けるためここでも判定
        window.location.href = location.origin + UrlPath.RENEW;
      }
      if (CustomerConsts.NAME_SEPARATED && !nameSeparated) {
        // ログイン直後遷移した際には姓名変更確認をすり抜けるためここでも判定
        window.location.href = location.origin + UrlPath.NAME_SEPARATION_EDIT;
      }
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountCardPageSskts.rejected, (state, action) => {
      const code = parseInt(action.error?.code || '1000', 10);
      if (code === 404) {
        window.location.href = location.origin + state.registerUrl;
      } else {
        state.pageErrorMessage = action.error.message || 'API Error';
      }
      state.pageLoadingMessage = '';
    });

    // didMountCouponPage
    builder.addCase(didMountCouponPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.memberCouponList = action.payload.memberCouponList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountCouponPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountCouponShareCompletePage
    builder.addCase(
      didMountCouponShareCompletePage.fulfilled,
      (state, action) => {
        state.membershipData = action.payload.membershipData;
        state.pageLoadingMessage = '';
      },
    );
    builder.addCase(
      didMountCouponShareCompletePage.rejected,
      (state, action) => {
        state.pageErrorMessage = action.error.message || 'API Error';
        state.pageLoadingMessage = '';
      },
    );

    // didMountCouponMarketPage
    builder.addCase(didMountCouponMarketPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.couponList = action.payload.couponList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountCouponMarketPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountPointPage
    builder.addCase(didMountPointPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.couponList = action.payload.couponList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountPointPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountPointHistoryPage
    builder.addCase(didMountPointHistoryPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.pointHistoryList = action.payload.pointHistoryList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountPointHistoryPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountHistoryPage
    builder.addCase(didMountHistoryPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.historyList = action.payload.historyList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountHistoryPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountProfilePage
    builder.addCase(didMountProfilePage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.memberCouponList = action.payload.memberCouponList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountProfilePage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountProfileEditPage
    builder.addCase(didMountProfileEditPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountProfileEditPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountEmailEditPage
    builder.addCase(didMountEmailEditPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountEmailEditPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountRenewPage
    builder.addCase(didMountRenewPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.feeList = action.payload.feeList;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountRenewPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountFaceRegisterPage
    builder.addCase(didMountFaceRegisterPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.faceImageUrl = action.payload.faceImageUrl;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountFaceRegisterPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountAppInstallPage
    builder.addCase(didMountAppInstallPage.fulfilled, (state, action) => {
      state.membershipData = action.payload.membershipData;
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountAppInstallPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountInvoicePage
    builder.addCase(didMountInvoicePage.fulfilled, (state, action) => {
      if (
        action.payload.invoiceFee === '' ||
        action.payload.invoiceFee === '0'
      ) {
        window.location.href = location.origin + UrlPath.CARD;
      }
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountInvoicePage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });

    // didMountInvoicePrintPage
    builder.addCase(didMountInvoicePrintPage.fulfilled, (state, action) => {
      if (
        action.payload.invoiceFee === '' ||
        action.payload.invoiceFee === '0'
      ) {
        window.location.href = location.origin + UrlPath.CARD;
      }
      window.print();
      state.pageLoadingMessage = '';
    });
    builder.addCase(didMountInvoicePrintPage.rejected, (state, action) => {
      state.pageErrorMessage = action.error.message || 'API Error';
      state.pageLoadingMessage = '';
    });
  },
});
