/**
 * クーポンシェアパネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import { MessageModal } from 'components/organisms';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EventSlice,
  colorPaletteSelector,
  couponShareDataSelector,
  displaySizeSelector,
  sendShareCouponMail,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px 32px;
`;

/**
 * 【有機体】 クーポンシェアパネル
 * @returns コンポーネント
 */
export const CouponSharePanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const couponShareData = useTypeSelector(couponShareDataSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize}>
          上記クーポンのシェア用画像を作成します。
        </Text>
        <Text fontSize={css.fontSize}>
          シェアを行なうとマイページからなくなり、元に戻すことはできませんのでご注意下さい。
        </Text>
        <Text fontSize={css.fontSize}>
          シェア用画像が表示されましたら、ダウンロード又はLINE等のアプリ経由でシェアを行なってください。
        </Text>
        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          margin="32px auto 16px"
          onClick={() => {
            const email = process.env.REACT_APP_COUPON_SHARE_EMAIL;
            if (email) {
              dispatch(
                EventSlice.actions.changeCouponShareData({
                  email,
                }),
              );
              setIsOpen(true);
            }
          }}
        >
          シェアを行なう
        </Button>
      </Panel>

      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.COUPON)}
        >
          {UrlName.COUPON}画面に戻る
        </Link>
      </Text>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Text fontSize={css.fontSize} textAlign="center" bold>
          「{couponShareData.coupon?.couponName || ''}」
        </Text>
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSize}
          lineHeight={1.3}
          textAlign="center"
          margin="12px 0 0"
          bold
        >
          シェア用画像の作成を行ないます。
          <br />
          よろしいですか？
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setIsOpen(false);
            dispatch(sendShareCouponMail());
          }}
        >
          はい
        </Button>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          color={colorPalette.negative}
          width={css.buttonWidth}
          margin="24px auto 16px"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          戻る
        </Button>
      </Modal>

      <MessageModal
        onClose={() =>
          navigate({
            pathname: UrlPath.COUPON_SHARE_COMPLETE,
            search:
              '?' +
              new URLSearchParams({
                email: couponShareData.email || '',
                color: couponShareData.ticketColor || '',
                coupon_name: couponShareData.result?.couponName || '',
                coupon_no: couponShareData.result?.couponNo || '',
                pin_code: couponShareData.result?.pinCode || '',
                startingTime:
                  couponShareData.result?.validPeriod?.startingTime || '',
                endingTime:
                  couponShareData.result?.validPeriod?.endingTime || '',
                description: couponShareData.result?.description || '',
              }).toString(),
          })
        }
      />
    </Wrap>
  );
};
