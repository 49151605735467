/**
 * 必須アイコン
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import React from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  padding?: string;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ colorPalette }) => colorPalette.lightTone};
  font-weight: bold;
  background-color: ${({ colorPalette }) => colorPalette.negative};
  border-radius: 8px;
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
`;

interface RequiredIconProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  padding?: string;
  margin?: string;
}
/**
 * 【原子】 必須アイコン
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {number} fontSize - フォントサイズ[px]
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @returns コンポーネント
 */
export const RequiredIcon: React.FC<RequiredIconProps> = ({
  colorPalette,
  fontSize,
  padding,
  margin,
}: RequiredIconProps) => {
  return (
    <Wrap
      colorPalette={colorPalette}
      fontSize={fontSize}
      padding={padding}
      margin={margin}
    >
      必須
    </Wrap>
  );
};
