/**
 * 会員情報修正確認パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlName, UrlPath } from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import { MessageModal } from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  submitMembershipDataSelector,
  updateProfile,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface RecordProps {
  minHiehgt: string;
}
const Record = styled.div<RecordProps>`
  margin: 16px 0;
  min-height: ${({ minHiehgt }) => minHiehgt};
`;

/**
 * 【有機体】 会員情報修正確認パネル
 * @param {ReactNode} children - 子要素
 * @returns コンポーネント
 */
export const ProfileEditConfirmPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const submitMembershipData = useTypeSelector(submitMembershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          valueMargin: '0 0 0 36px',
          minHeight: '72px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          valueMargin: '0 0 0 16px',
          minHeight: '56px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center">
          入力内容を確認して下さい
        </Text>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            お名前(カタカナ)
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.lastName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstName || ''}(
            {submitMembershipData.lastKanaName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstKanaName || ''})
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            電話番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.tel || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            誕生日
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.birthDate || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            郵便番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.postalCode}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            住所
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.all}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            性別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.sex}
          </Text>
        </Record>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 16px"
          onClick={() => {
            dispatch(updateProfile());
          }}
        >
          送信
        </Button>
      </Panel>

      <Text fontSize={css.fontSize} margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.PROFILE_EDIT)}
        >
          {UrlName.PROFILE_EDIT}画面に戻る
        </Link>
      </Text>

      <MessageModal onClose={() => navigate(UrlPath.PROFILE)} />
    </Wrap>
  );
};
