/**
 * クーポンシェアエリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import {
  ContentsBlock,
  ContentsTitle,
  CouponPanel,
  CouponSharePanel,
} from 'components/organisms';
import React from 'react';
import { couponShareDataSelector, useTypeSelector } from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 0 32px;
`;

/**
 * 【テンプレート】 クーポンシェアエリア
 * @returns コンポーネント
 */
export const CouponShareArea: React.FC = () => {
  const couponShareData = useTypeSelector(couponShareDataSelector);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.COUPON_SHARE}</ContentsTitle>
      <Wrap>
        <CouponPanel
          memberCoupon={couponShareData.coupon}
          ticketColor={couponShareData.ticketColor || ''}
          shareDisalbed
          appliableDisalbed
        />
      </Wrap>
      <CouponSharePanel />
    </ContentsBlock>
  );
};
