/**
 * 有償クーポン購入完了ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, UrlPath, useDidMount } from 'commons';
import {
  Contents,
  CouponMarketPaymentCompleteArea,
} from 'components/templates';
import { Header } from 'components/templates/koronaworld';
import { BrowserBackRedirect } from 'components/utilities';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BasicSlice, completePaidCouponsIssue, useTypeDispatch } from 'stores';

/**
 * 【ページ】 有償クーポン購入完了ページ
 * @returns コンポーネント
 */
export const CouponMarketPaymentComplete: React.FC = () => {
  const dispatch = useTypeDispatch();
  const queryParameter = new URLSearchParams(useLocation().search);

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.COUPON_MARKET_PAYMENT_COMPLETE;
    const encryptValue = queryParameter.get('EncryptValue');
    if (encryptValue) {
      dispatch(completePaidCouponsIssue({ encryptValue }));
    } else {
      dispatch(
        BasicSlice.actions.changeErrorMessage(
          '決済完了情報取得失敗[EncryptValue is null]',
        ),
      );
    }
  });

  return (
    <>
      <Header disabledMenu />
      <Contents>
        <CouponMarketPaymentCompleteArea />
      </Contents>
      <BrowserBackRedirect urlPath={UrlPath.COUPON} />
    </>
  );
};
