/**
 * チェックボックス
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, validateCheckbox } from 'commons';
import { ValidatorMessage } from 'components/atoms';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

interface WrapProps {
  $colorPalette: ColorPalette;
  $fontSize?: number;
  $padding?: string;
  $margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ $fontSize }) => $fontSize !== undefined && `font-size: ${$fontSize}px;`}
  ${({ $padding }) => $padding && `padding: ${$padding};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
`;

interface ItemWrapProps {
  $margin: string;
}
const ItemWrap = styled.div<ItemWrapProps>`
  display: inline-flex;
  align-items: center;
  margin: ${({ $margin }) => $margin};
`;

interface InputProps {
  $width?: string;
  $height?: string;
}
const Input = styled.input<InputProps>`
  padding: 0;
  margin: 0;
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  cursor: pointer;
`;

const Label = styled.label`
  margin: 0 0 2px 8px;
  cursor: pointer;
`;

const getLabels = (
  valueList: CheckBoxItemDataType[],
  values: string[],
): string[] => {
  const labels: string[] = [];
  values.forEach((value) => {
    for (let i = 0; i < valueList.length; i += 1) {
      if (valueList[i].value === value) {
        labels.push(valueList[i].label);
        break;
      }
    }
  });
  return labels;
};

export interface CheckBoxItemDataType {
  label: string;
  value: string;
}

interface CheckBoxProps {
  colorPalette: ColorPalette;
  valueList: CheckBoxItemDataType[];
  initialValue: string[];
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  fontSize?: number;
  errorFontSize?: number;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  itemMargin?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string[], isError: boolean, label: string[]) => void;
  activeFlag?: boolean;
}
/**
 * 【分子】 チェックボックス
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {ComboBoxItemDataType[]} valueList - 【必須】 選択リスト
 * @param {string[]} initialValue -  【必須】 初期値
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {number} fontSize - フォントサイズ
 * @param {number} errorFontSize - エラーフォントサイズ
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @param {string} itemMargin - 項目マージン
 * @param {boolean} required - 必須項目指定
 * @param {boolean} disabled - 非活性
 * @param {(value: string[], isError: boolean, label:string[]) => void} onChange - 変更処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @returns コンポーネント
 */
export const CheckBox: React.FC<CheckBoxProps> = ({
  colorPalette,
  valueList,
  initialValue,
  validatorRules,
  validatorMessage,
  fontSize,
  errorFontSize,
  width,
  height,
  padding,
  margin,
  itemMargin = '0 32px 8px 0',
  required = false,
  disabled = false,
  onChange,
  activeFlag,
}: CheckBoxProps) => {
  const [values, setValues] = useState<string[]>(initialValue);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [valueListCash, setValueListCash] = useState(valueList);
  const [validatorRulesCash, setValidatorRulesCash] = useState(validatorRules);
  const [validatorMessageCash, setValidatorMessageCash] =
    useState(validatorMessage);
  const idList = useMemo(() => {
    return valueListCash.map(() => uuidV4());
  }, [valueListCash]);

  // オブジェクトの変更判定を厳密に行う(予期せぬ再レンダリングを阻止)
  useEffect(() => {
    if (!isEqual(valueListCash, valueList)) {
      setValueListCash(valueList);
    }
    if (!isEqual(validatorRulesCash, validatorRules)) {
      setValidatorRulesCash(validatorRules);
    }
    if (!isEqual(validatorMessageCash, validatorMessage)) {
      setValidatorMessageCash(validatorMessage);
    }
  }, [valueList, validatorRules, validatorMessage]);

  // リストが更新された場合に初期化する
  useEffect(() => {
    setValues(initialValue);
    const result = validateCheckbox({
      values: initialValue,
      validatorRules: validatorRulesCash,
      validatorMessage: validatorMessageCash,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange &&
      onChange(
        initialValue,
        result.isError,
        getLabels(valueListCash, initialValue),
      );
  }, [valueListCash]);

  // バリデーションルールが更新された際に再判定する
  useEffect(() => {
    const result = validateCheckbox({
      values,
      validatorRules: validatorRulesCash,
      validatorMessage: validatorMessageCash,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange && onChange(values, result.isError, getLabels(valueList, values));
  }, [validatorRulesCash, validatorMessageCash]);

  // エラー表示フラグを強制的にONにする
  useEffect(() => {
    if (!isError) {
      const { isError, errorMessage } = validateCheckbox({
        values,
        validatorRules: validatorRulesCash,
        validatorMessage: validatorMessageCash,
      });
      setIsError(isError);
      setErrorMessage(errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <Wrap
      $colorPalette={colorPalette}
      $fontSize={fontSize}
      $padding={padding}
      $margin={margin}
    >
      <ValidatorMessage
        colorPalette={colorPalette}
        fontSize={errorFontSize}
        isDisplay={isActive && isError}
        message={errorMessage}
      >
        {valueListCash.map((data, index) => {
          return (
            <ItemWrap key={data.value} $margin={itemMargin}>
              <Input
                key={data.value}
                type="checkbox"
                id={idList[index]}
                value={data.value}
                checked={values.includes(data.value)}
                $width={width}
                $height={height}
                required={required}
                disabled={disabled}
                onChange={() => {
                  const tempValues = [...values];
                  tempValues.includes(data.value)
                    ? tempValues.splice(values.indexOf(data.value), 1)
                    : tempValues.push(data.value);
                  setValues(tempValues);
                  const tempLabels = getLabels(valueList, tempValues);
                  const result = validateCheckbox({
                    values: tempValues,
                    validatorRules: validatorRulesCash,
                    validatorMessage: validatorMessageCash,
                  });
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  setIsActive(true);
                  onChange && onChange(tempValues, result.isError, tempLabels);
                }}
              />
              <Label htmlFor={idList[index]}>{data.label}</Label>
            </ItemWrap>
          );
        })}
      </ValidatorMessage>
    </Wrap>
  );
};
