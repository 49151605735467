/**
 * ポイント利用表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlName, UrlPath } from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  MessageModal,
  PointCouponPanel,
  PointPanel,
} from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  couponListSelector,
  displaySizeSelector,
  loadingMessageSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

/**
 * 【テンプレート】 ポイント利用表示エリア
 * @returns コンポーネント
 */
export const PointArea: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const couponList = useTypeSelector(couponListSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
        };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.POINT}</ContentsTitle>
      <Wrap>
        <PointPanel margin="24px 16px" />
        {loadingMessage === '' && couponList.length === 0 && (
          <Panel
            colorPalette={colorPalette}
            width={css.panelWidth}
            padding={css.panelPadding}
            margin="24px 0 0"
          >
            <Text fontSize={css.fontSize} textAlign="center">
              交換可能なクーポンはありません
            </Text>
          </Panel>
        )}
        {couponList.map((data) => (
          <PointCouponPanel
            key={data.couponId}
            pointCoupon={data}
            point={membershipData.totalPoints}
            margin="24px 0 0"
          />
        ))}
        <Button
          colorPalette={colorPalette}
          margin="40px 0"
          width={css.buttonWidth}
          onClick={() => {
            navigate(UrlPath.COUPON);
          }}
        >
          保有クーポンを確認
        </Button>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面に戻る
        </Link>

        <MessageModal
          onClose={() => {
            navigate(UrlPath.COUPON);
          }}
        />
      </Wrap>
    </ContentsBlock>
  );
};
