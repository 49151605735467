/**
 * 姓名分離ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents } from 'components/templates';
import {
  Header,
  NameSeparationEditArea,
} from 'components/templates/koronaworld';
import React from 'react';
import { didMountNameSeparationEditPage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 姓名分離ページ
 * @returns コンポーネント
 */
export const NameSeparationEdit: React.FC = () => {
  const dispatch = useTypeDispatch();

  useDidMount(() => {
    //マウント処理
    document.title = DOCUMENT_TITLE + UrlName.NAME_SEPARATION_EDIT;
    dispatch(didMountNameSeparationEditPage());
  });

  return (
    <>
      <Header disabledMenu />
      <Contents>
        <NameSeparationEditArea />
      </Contents>
    </>
  );
};
