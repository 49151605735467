/**
 * 会員証パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Image, Text } from 'components/atoms';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useState } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  imgSrc: string;
}
const Wrap = styled.div<WrapProps>`
  overflow: hidden;
  background-image: url(${({ imgSrc }) => imgSrc});
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  padding: 20px 0;
`;

const MembershipArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MemberNoWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const MemberNoWrap = styled.div<MemberNoWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
`;

interface PeriodWrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const PeriodWrap = styled.div<PeriodWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
`;

interface PointAreaProps {
  colorPalette: ColorPalette;
}
const PointArea = styled.div<PointAreaProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface BaseLineWrapProps {
  width: string;
}
const BaseLineWrap = styled.div<BaseLineWrapProps>`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: ${({ width }) => width};
`;

interface TitleWrapPrpos {
  width: string;
}
const TitleWrap = styled.div<TitleWrapPrpos>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ width }) => width};
`;

const QrCodeArea = styled.div`
  text-align: center;
  padding: 20px 0 12px 0;
`;

const QrCode = styled.img`
  width: 120px;
  height: 120px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
`;

/**
 * 【有機体】 会員証パネル
 * @returns コンポーネント
 */
export const CardPanel: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imgSrc: '/alexcinemas/wallpaper_pc.jpg',
          iconSize: '24px',
          titleWidth: '160px',
          valueWidth: '240px',
          memberNoPadding: '0 0 16px',
          periodPadding: '0 0 16px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imgSrc: 'alexcinemas/wallpaper_sp.jpg',
          iconSize: '16px',
          titleWidth: '88px',
          valueWidth: '144px',
          memberNoPadding: '0 0 16px',
          periodPadding: '0 0 16px',
        };
  }, [displaySize]);

  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  useEffect(() => {
    QRCode.toDataURL(membershipData.memberNo || '0', (err, url) => {
      setQrCodeImg(url);
    });
  }, [membershipData]);

  return (
    <Wrap imgSrc={css.imgSrc}>
      <MembershipArea>
        <MemberNoWrap colorPalette={colorPalette} padding={css.memberNoPadding}>
          <TitleWrap width={css.titleWidth}>
            <Image
              src="/svg/member_icon_white.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
            <Text
              color={colorPalette.lightTone}
              fontSize={css.fontSizeSmall}
              lineHeight={1}
              letterSpacing={0}
              padding="0 0 2px 8px"
            >
              会員番号
            </Text>
          </TitleWrap>
          <Text
            color={colorPalette.lightTone}
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            width={css.valueWidth}
            bold
          >
            {membershipData.memberNo || '―'}
          </Text>
        </MemberNoWrap>
        <PeriodWrap colorPalette={colorPalette} padding={css.periodPadding}>
          <TitleWrap width={css.titleWidth}>
            <Image
              src="/svg/calendar_icon_white.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
            <Text
              color={colorPalette.lightTone}
              fontSize={css.fontSizeSmall}
              lineHeight={1}
              letterSpacing={0}
              padding="0 0 2px 8px"
              textAlign="center"
            >
              有効期限
            </Text>
          </TitleWrap>
          <Text
            color={colorPalette.lightTone}
            fontSize={css.fontSize}
            lineHeight={1}
            letterSpacing={0}
            width={css.valueWidth}
            bold
          >
            {membershipData.validPeriodTo
              ? new Date(membershipData.validPeriodTo).toLocaleDateString(
                  'ja-JP',
                )
              : '―'}
          </Text>
        </PeriodWrap>
        <PointArea colorPalette={colorPalette}>
          <TitleWrap width={css.titleWidth}>
            <Image
              src="/svg/point_white.svg"
              width={css.iconSize}
              height={css.iconSize}
            />
            <Text
              fontSize={css.fontSizeSmall}
              color={colorPalette.lightTone}
              lineHeight={1}
              letterSpacing={0}
              padding="0 0 2px 8px"
              textAlign="center"
            >
              ポイント
            </Text>
          </TitleWrap>
          <BaseLineWrap width={css.valueWidth}>
            <Text
              color={colorPalette.negative}
              fontSize={css.fontSizeLarge}
              lineHeight={1}
              letterSpacing={0}
              bold
            >
              {membershipData.totalPoints?.toLocaleString('ja-JP') || '―'}
            </Text>
            <Text
              color={colorPalette.lightTone}
              fontSize={css.fontSizeMiddle}
              lineHeight={1}
              letterSpacing={0}
              margin="0 0 0 4px"
            >
              pt
            </Text>
          </BaseLineWrap>
        </PointArea>
      </MembershipArea>
      <QrCodeArea>
        <QrCode src={membershipData.memberNo && qrCodeImg} />
      </QrCodeArea>
    </Wrap>
  );
};
