/**
 * 無料クーポン入力フォーム
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  Placeholder,
  postCouponsIsCreat,
  validateValue,
} from 'commons';
import { RequiredIcon, Text, ValidatorMessage } from 'components/atoms';
import { InputText } from 'components/molecules';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const InputWrap = styled.div`
  display: flex;
  margin: 0 0 8px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

interface CouponFormProps {
  children?: ReactNode;
  initialValue: string | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  setRef?: React.Dispatch<React.RefObject<HTMLInputElement>>;
  onChange?: (value: string, isError: boolean, membershipCode: string) => void;
  onBlur?: (value: string, isError: boolean, membershipCode: string) => void;
  activeFlag?: boolean;
  required?: boolean;
  disabled?: boolean;
}
/**
 * 【分子】 無料クーポン入力フォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string} initialValue - 【必須】 初期値(nullで非表示)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {React.Dispatch<React.RefObject<HTMLDivElement>>} setRef - ref取得(非推奨)
 * @param {(value: string, isError: boolean) => void} onChange - 変更処理
 * @param {(value: string, isError: numbbooleaner) => void} onBlur - フォーカスアウト処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @param {boolean} required - 必須項目指定
 * @param {boolean} disabled - 非活性
 * @returns コンポーネント
 */
export const CouponForm: React.FC<CouponFormProps> = ({
  children,
  initialValue,
  validatorRules,
  validatorMessage,
  setRef,
  onChange,
  onBlur,
  activeFlag,
  required = false,
  disabled = false,
}: CouponFormProps) => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const values = useRef<string>(initialValue ? initialValue : '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(
    initialValue ? true : false,
  );
  const [membershipName, setMembershipName] = useState<string>('');
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '8px 24px',
          margin: '24px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '8px 12px',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: values.current,
        validatorRules,
        validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <>
      {initialValue !== null ? (
        <Wrap colorPalette={colorPalette} margin={css.margin}>
          <TitleWrap>
            {required && (
              <RequiredIcon
                colorPalette={colorPalette}
                fontSize={css.requiredFontSize}
                padding={css.requiredPadding}
                margin={css.requiredMargin}
              />
            )}
            <Text fontSize={css.fontSize} bold>
              {children}
            </Text>
          </TitleWrap>

          <ValidatorMessage
            colorPalette={colorPalette}
            fontSize={css.fontSizeSmall}
            isDisplay={isActive && isError}
            message={errorMessage}
          >
            <InputWrap>
              <InputText
                inputType={
                  displaySize === DisplaySize.SMALL ? 'number' : 'text'
                }
                colorPalette={colorPalette}
                initialValue={initialValue || ''}
                fontSize={css.fontSize}
                width="100%"
                padding={css.padding}
                placeholder={Placeholder.REGISTER_COUPON}
                maxLength={14}
                setRef={setRef}
                disabled={disabled}
                onChange={(value) => {
                  setMembershipName('');
                  values.current = value;
                  const { isError, errorMessage } = validateValue({
                    value: values.current,
                    validatorRules,
                    validatorMessage,
                  });
                  setIsError(isError);
                  setErrorMessage(errorMessage);
                  onChange && onChange(value, isError, '');
                }}
                onBlur={async (value) => {
                  setMembershipName('');
                  values.current = value;
                  let { isError, errorMessage } = validateValue({
                    value: values.current,
                    validatorRules,
                    validatorMessage,
                  });
                  let membershipCode = '';
                  if (!isError) {
                    dispatch(
                      BasicSlice.actions.changeLoadingMessage(
                        'クーポン番号確認中...',
                      ),
                    );
                    try {
                      const response = await postCouponsIsCreat({
                        couponNoAndPinCode: values.current,
                      });
                      if (!response.valid) {
                        isError = true;
                        errorMessage = '利用できないクーポン番号です';
                      } else {
                        membershipCode = response.membership?.code || '';
                        setMembershipName(response.membership?.name || '');
                      }
                    } catch (error) {
                      isError = true;
                      if (error.code) {
                        errorMessage = `${error.message} [${error.code}]`;
                      } else {
                        errorMessage =
                          'クーポン番号を確認できませんでした [通信エラー]';
                      }
                    }
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  }
                  setIsError(isError);
                  setErrorMessage(errorMessage);
                  setIsActive(true);
                  onBlur && onBlur(value, isError, membershipCode);
                }}
              />
            </InputWrap>
          </ValidatorMessage>
          <Text fontSize={css.fontSize} color={colorPalette.positive}>
            {membershipName ? `利用クーポン 『${membershipName}』` : ``}
          </Text>
        </Wrap>
      ) : null}
    </>
  );
};
