/**
 * 有効期限更新ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents } from 'components/templates';
import {
  Header,
  PointHeader,
  RenewArea,
} from 'components/templates/koronaworld';
import React from 'react';
import { didMountRenewPage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 有効期限更新ページ
 * @returns コンポーネント
 */
export const Renew: React.FC = () => {
  const dispatch = useTypeDispatch();

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.RENEW;
    dispatch(didMountRenewPage());
  });

  return (
    <>
      <Header />
      <PointHeader />
      <Contents>
        <RenewArea />
      </Contents>
    </>
  );
};
