/**
 * 会員登録完了エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlPath } from 'commons';
import { MessageModal } from 'components/organisms';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * 【テンプレート】 会員登録完了エリア
 * @returns コンポーネント
 */
export const RegisterCompleteArea: React.FC = () => {
  const navigate = useNavigate();

  return <MessageModal onClose={() => navigate(UrlPath.INVOICE)} />;
};
