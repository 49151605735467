/**
 * リストメニュー
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  LocalStorageKey,
  LoginStatus,
  SEAL_FUNCTION,
  setModalOpenClassToBody,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { ClientVersion } from 'components/molecules';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  browserSelector,
  colorPaletteSelector,
  displaySizeSelector,
  mainMenuOpenSelector,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

interface WrapProps {
  colorPalette: ColorPalette;
  mainMenuOpen: boolean;
}
const Wrap = styled.div<WrapProps>`
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 48px;
  z-index: ${Z_INDEX.mainMenu};
  width: 100%;
  height: ${({ mainMenuOpen }) => (mainMenuOpen ? 'calc(100% - 48px)' : '0px')};
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.darkTone, 0.9)};
  transition: 0.3s ease-in-out;
`;

interface MenuItemProps {
  colorPalette: ColorPalette;
  color?: string;
  fontSize: number;
}
const MenuItem = styled.div<MenuItemProps>`
  width: 100%;
  padding: 12px 32px;
  color: ${({ colorPalette }) => colorPalette.lightTone};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 100;
  border-bottom: 1px solid ${({ colorPalette }) => colorPalette.grayTone};
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${({ colorPalette }) => colorPalette.lightTone};
    color: ${({ color, colorPalette }) => color || colorPalette.darkTone};
    font-weight: bold;
    cursor: pointer;
  }

  &:active {
    background-color: ${({ colorPalette }) => colorPalette.lightTone};
    color: ${({ colorPalette, color }) => color || colorPalette.darkTone};
    transition: none;
  }
`;

/**
 * 【有機体】 リストメニュー
 * @returns コンポーネント
 */
export const ListMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const browser = useTypeSelector(browserSelector);
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const mainMenuOpen = useTypeSelector(mainMenuOpenSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [uniqueId] = useState<string>(uuidV4());
  useEffect(() => {
    // Bodyのスクロールを非表示にしてマウスホイールで動かないようにする
    setModalOpenClassToBody(mainMenuOpen, uniqueId);
    return () => {
      setModalOpenClassToBody(false, uniqueId);
    };
  }, [mainMenuOpen]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} mainMenuOpen={mainMenuOpen}>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.CARD);
        }}
      >
        {UrlName.CARD}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          if (membershipData.link) {
            window.open(membershipData.link);
          } else if (process.env.REACT_APP_PORTAL_URI) {
            window.open(process.env.REACT_APP_PORTAL_URI);
          } else {
            dispatch(BasicSlice.actions.changeMainMenuOpen(false));
            navigate(UrlPath.ENV_ERROR);
          }
        }}
      >
        {membershipData.link
          ? membershipData.attributes?.theater + '公式ページ'
          : process.env.REACT_APP_PORTAL_NAME}
        (チケット購入)
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.COUPON);
        }}
      >
        {UrlName.COUPON}
      </MenuItem>
      {membershipData.couponMarketOpen && (
        <MenuItem
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            dispatch(BasicSlice.actions.changeMainMenuOpen(false));
            navigate(UrlPath.COUPON_MARKET);
          }}
        >
          {UrlName.COUPON_MARKET}
        </MenuItem>
      )}
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.POINT);
        }}
      >
        {UrlName.POINT}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.POINT_HISTORY);
        }}
      >
        {UrlName.POINT_HISTORY}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.TICKETHOLDER);
        }}
      >
        {UrlName.TICKETHOLDER}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.PROFILE);
        }}
      >
        {UrlName.PROFILE}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.RENEW);
        }}
      >
        {UrlName.RENEW}
      </MenuItem>
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          navigate(UrlPath.USER_POLICY);
        }}
      >
        {UrlName.USER_POLICY}
      </MenuItem>
      {SEAL_FUNCTION && !browser.pwa && (
        <MenuItem
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            dispatch(BasicSlice.actions.changeMainMenuOpen(false));
            navigate(UrlPath.APP_INSTALL);
          }}
        >
          {UrlName.APP_INSTALL}
        </MenuItem>
      )}
      <MenuItem
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          dispatch(BasicSlice.actions.changeMainMenuOpen(false));
          if (browser.pwa) {
            localStorage.setItem(
              LocalStorageKey.LOGIN_STATUS,
              LoginStatus.RELOGIN,
            );
          }
          navigate(UrlPath.LOGOUT);
        }}
      >
        {UrlName.LOGOUT}
      </MenuItem>
      {SEAL_FUNCTION && (
        <ClientVersion
          color={colorPalette.lightTone}
          fontSize={css.fontSizeSmall}
          textAlign="right"
          margin="4px 8px 120px"
        />
      )}
    </Wrap>
  );
};
