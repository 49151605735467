/**
 * エラーモーダル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlPath, Z_INDEX } from 'commons';
import { Button, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  EventSlice,
  colorPaletteSelector,
  displaySizeSelector,
  errorMessageSelector,
  eventErrorMessageSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * 【有機体】 エラーモーダル
 * @returns コンポーネント
 */
export const ErrorModal: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const errorMessage = useTypeSelector(errorMessageSelector);
  const eventErrorMessage = useTypeSelector(eventErrorMessageSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, modalWidth: '600px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Modal
      colorPalette={colorPalette}
      zIndex={Z_INDEX.error}
      headerTitle="エラー"
      headerTitleSize={css.fontSize}
      width={css.modalWidth}
      padding={css.modalPadding}
      opened={errorMessage === '' && eventErrorMessage === '' ? false : true}
      onClose={() => {
        dispatch(BasicSlice.actions.changeErrorMessage(''));
        dispatch(EventSlice.actions.changeEventErrorMessage(''));
        if (location.pathname === UrlPath.DIGITAL_REGISTER_COMPLETE) {
          navigate(UrlPath.LOGOUT);
        } else if (location.pathname === UrlPath.RENEW_COMPLETE) {
          navigate(UrlPath.RENEW);
        }
      }}
    >
      <Wrap>
        {errorMessage && (
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSize}
            textAlign="center"
            margin="0 0 16px"
          >
            {errorMessage}
          </Text>
        )}
        {eventErrorMessage && (
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSize}
            textAlign="center"
            margin="0 0 16px"
          >
            {eventErrorMessage}
          </Text>
        )}
        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          fontSize={css.fontSize}
          onClick={() => {
            dispatch(BasicSlice.actions.changeErrorMessage(''));
            dispatch(EventSlice.actions.changeEventErrorMessage(''));
            if (location.pathname === UrlPath.DIGITAL_REGISTER_COMPLETE) {
              navigate(UrlPath.LOGOUT);
            } else if (location.pathname === UrlPath.RENEW_COMPLETE) {
              navigate(UrlPath.RENEW);
            }
          }}
        >
          閉じる
        </Button>
      </Wrap>
    </Modal>
  );
};
