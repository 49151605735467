/**
 * 会員ID入力フォーム
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  Placeholder,
  postCardIsCreat,
  validateValue,
} from 'commons';
import { RequiredIcon, Text, ValidatorMessage } from 'components/atoms';
import { InputText } from 'components/molecules';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const InputWrap = styled.div`
  display: flex;
  margin: 0 0 8px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

interface MemberIdFormProps {
  children?: ReactNode;
  initialValue: string[] | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  onChange?: (value: string, index: number, isError: boolean) => void;
  onBlur?: (value: string, index: number, isError: boolean) => void;
  activeFlag?: boolean;
  required?: boolean;
}
/**
 * 【分子】 会員ID入力フォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string[]} initialValue - 【必須】 初期値(nullで非表示)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @param {(value: string, index: number, isError: boolean) => void} onChange - 変更処理
 * @param {(value: string, index: number, isError: boolean) => void} onBlur - フォーカスアウト処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @param {boolean} required - 必須項目指定
 * @returns コンポーネント
 */
export const MemberIdForm: React.FC<MemberIdFormProps> = ({
  children,
  initialValue,
  validatorRules = [],
  validatorMessage,
  onChange,
  onBlur,
  activeFlag,
  required = false,
}: MemberIdFormProps) => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const values = useRef<string[]>(
    initialValue
      ? [initialValue[0], initialValue[1], initialValue[2], initialValue[3]]
      : ['', '', '', ''],
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean[]>(
    initialValue ? [true, true, true, true] : [false, false, false, false],
  );
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '8px 12px',
          margin: '24px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '8px 12px',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: values.current.join(''),
        validatorRules,
        validatorMessage,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive([true, true, true, true]);
    }
  }, [activeFlag]);

  return (
    <>
      {initialValue !== null ? (
        <Wrap colorPalette={colorPalette} margin={css.margin}>
          <TitleWrap>
            {required && (
              <RequiredIcon
                colorPalette={colorPalette}
                fontSize={css.requiredFontSize}
                padding={css.requiredPadding}
                margin={css.requiredMargin}
              />
            )}
            <Text fontSize={css.fontSize} bold>
              {children}
            </Text>
          </TitleWrap>

          <ValidatorMessage
            colorPalette={colorPalette}
            fontSize={css.fontSizeSmall}
            isDisplay={
              isActive[0] &&
              isActive[1] &&
              isActive[2] &&
              isActive[3] &&
              isError
            }
            message={errorMessage}
          >
            <InputWrap>
              <InputText
                inputType={
                  displaySize === DisplaySize.SMALL ? 'number' : 'text'
                }
                colorPalette={colorPalette}
                initialValue={initialValue[0] || ''}
                fontSize={css.fontSize}
                width="calc(25% - 8px)"
                margin="0 8px 0 0"
                padding={css.padding}
                placeholder={Placeholder.MEMBER_ID_1}
                maxLength={4}
                onChange={(value) => {
                  values.current[0] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  onChange && onChange(value, 0, result.isError);
                }}
                onBlur={async (value) => {
                  values.current[0] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  if (!result.isError) {
                    dispatch(
                      BasicSlice.actions.changeLoadingMessage(
                        '会員番号確認中...',
                      ),
                    );
                    try {
                      const response = await postCardIsCreat({
                        memberNo: values.current.join(''),
                      });
                      if (!response) {
                        result.isError = true;
                        result.errorMessage = '利用できない会員番号です';
                      }
                    } catch (error) {
                      result.isError = true;
                      if (error.code) {
                        result.errorMessage = `${error.message} [${error.code}]`;
                      } else {
                        result.errorMessage =
                          '会員番号を確認できませんでした [通信エラー]';
                      }
                    }
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  }
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  setIsActive([true, isActive[1], isActive[2], isActive[3]]);
                  onBlur && onBlur(value, 0, result.isError);
                }}
              />
              <InputText
                inputType={
                  displaySize === DisplaySize.SMALL ? 'number' : 'text'
                }
                colorPalette={colorPalette}
                initialValue={initialValue[1] || ''}
                fontSize={css.fontSize}
                width="calc(25% - 8px)"
                margin="0 8px 0 0"
                padding={css.padding}
                placeholder={Placeholder.MEMBER_ID_2_4}
                maxLength={3}
                onChange={(value) => {
                  values.current[1] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  onChange && onChange(value, 1, result.isError);
                }}
                onBlur={async (value) => {
                  values.current[1] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  if (!result.isError) {
                    dispatch(
                      BasicSlice.actions.changeLoadingMessage(
                        '会員番号確認中...',
                      ),
                    );
                    try {
                      const response = await postCardIsCreat({
                        memberNo: values.current.join(''),
                      });
                      if (!response) {
                        result.isError = true;
                        result.errorMessage = '利用できない会員番号です';
                      }
                    } catch (error) {
                      result.isError = true;
                      if (error.code) {
                        result.errorMessage = `${error.message} [${error.code}]`;
                      } else {
                        result.errorMessage =
                          '会員番号を確認できませんでした [通信エラー]';
                      }
                    }
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  }
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  setIsActive([isActive[0], true, isActive[2], isActive[3]]);
                  onBlur && onBlur(value, 1, result.isError);
                }}
              />
              <InputText
                inputType={
                  displaySize === DisplaySize.SMALL ? 'number' : 'text'
                }
                colorPalette={colorPalette}
                initialValue={initialValue[2] || ''}
                fontSize={css.fontSize}
                width="calc(25% - 8px)"
                margin="0 8px 0 0"
                padding={css.padding}
                placeholder={Placeholder.MEMBER_ID_2_4}
                maxLength={3}
                onChange={(value) => {
                  values.current[2] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  onChange && onChange(value, 2, result.isError);
                }}
                onBlur={async (value) => {
                  values.current[2] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  if (!result.isError) {
                    dispatch(
                      BasicSlice.actions.changeLoadingMessage(
                        '会員番号確認中...',
                      ),
                    );
                    try {
                      const response = await postCardIsCreat({
                        memberNo: values.current.join(''),
                      });
                      if (!response) {
                        result.isError = true;
                        result.errorMessage = '利用できない会員番号です';
                      }
                    } catch (error) {
                      result.isError = true;
                      if (error.code) {
                        result.errorMessage = `${error.message} [${error.code}]`;
                      } else {
                        result.errorMessage =
                          '会員番号を確認できませんでした [通信エラー]';
                      }
                    }
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  }
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  setIsActive([isActive[0], isActive[1], true, isActive[3]]);
                  onBlur && onBlur(value, 2, result.isError);
                }}
              />
              <InputText
                inputType={
                  displaySize === DisplaySize.SMALL ? 'number' : 'text'
                }
                colorPalette={colorPalette}
                initialValue={initialValue[3] || ''}
                fontSize={css.fontSize}
                width="calc(25%)"
                padding={css.padding}
                placeholder={Placeholder.MEMBER_ID_2_4}
                maxLength={3}
                onChange={(value) => {
                  values.current[3] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  onChange && onChange(value, 3, result.isError);
                }}
                onBlur={async (value) => {
                  values.current[3] = value;
                  const result = validateValue({
                    value: values.current.join(''),
                    validatorRules,
                    validatorMessage,
                  });
                  if (!result.isError) {
                    dispatch(
                      BasicSlice.actions.changeLoadingMessage(
                        '会員番号確認中...',
                      ),
                    );
                    try {
                      const response = await postCardIsCreat({
                        memberNo: values.current.join(''),
                      });
                      if (!response) {
                        result.isError = true;
                        result.errorMessage = '利用できない会員番号です';
                      }
                    } catch (error) {
                      result.isError = true;
                      if (error.code) {
                        result.errorMessage = `${error.message} [${error.code}]`;
                      } else {
                        result.errorMessage =
                          '会員番号を確認できませんでした [通信エラー]';
                      }
                    }
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  }
                  setIsError(result.isError);
                  setErrorMessage(result.errorMessage);
                  setIsActive([isActive[0], isActive[1], isActive[2], true]);
                  onBlur && onBlur(value, 3, result.isError);
                }}
              />
            </InputWrap>
          </ValidatorMessage>
        </Wrap>
      ) : null}
    </>
  );
};
