/**
 * 会員情報修正確認表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName } from 'commons';
import { ContentsBlock, ContentsTitle } from 'components/organisms';
import { ProfileEditConfirmPanel } from 'components/organisms/koronaworld';
import React from 'react';

/**
 * 【テンプレート】 会員情報修正確認表示エリア
 * @returns コンポーネント
 */
export const ProfileEditConfirmArea: React.FC = () => {
  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.PROFILE_EDIT_CONFIRM}</ContentsTitle>
      <ProfileEditConfirmPanel />
    </ContentsBlock>
  );
};
