/**
 * ファイルアップロード
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, convertBlobToFile } from 'commons';
import { Enhancement, Image, Text, ValidatorMessage } from 'components/atoms';
import heic2any from 'heic2any';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

const File = styled.input`
  display: none;
`;

interface LabelProps {
  colorPalette: ColorPalette;
  padding?: string;
  fontSize?: number;
}
const Label = styled.label<LabelProps>`
  display: inline-block;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.positive};
  border: 1px solid ${({ colorPalette }) => colorPalette.positive};
  border-radius: 12px;
  width: 100%;
  padding: 4px 0;
  color: ${({ colorPalette }) => colorPalette.lightTone};
  text-align: center;
  text-decoration: none;
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${({ colorPalette }) => colorPalette.lightTone};
    color: ${({ colorPalette }) => colorPalette.positive};
  }

  &:active {
    background-color: ${({ colorPalette }) => colorPalette.lightTone};
    color: ${({ colorPalette }) => colorPalette.positive};
    transition: none;
  }
`;

const PreviewWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface InputFileProps {
  colorPalette: ColorPalette;
  accept: string;
  previewed?: boolean;
  previewWidth?: string;
  previewHeight?: string;
  maxSize: number;
  fontSize?: number;
  errorFontSize?: number;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  onChange?: (file: File | null) => void;
  dispatchLoadingMessage?: (message: string) => void;
}
/**
 * 【分子】 ファイルアップロード
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @returns コンポーネント
 */
export const InputFile: React.FC<InputFileProps> = ({
  colorPalette,
  accept,
  previewed = false,
  previewWidth = '240px',
  previewHeight,
  maxSize,
  fontSize = 20,
  errorFontSize,
  width,
  height,
  padding,
  margin,
  onChange,
  dispatchLoadingMessage,
}: InputFileProps) => {
  const [fileId] = useState<string>(uuidV4());
  const [file, setFile] = useState<File | null>(null);
  const [previewUri, setPreviewUri] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  return (
    <ValidatorMessage
      colorPalette={colorPalette}
      fontSize={errorFontSize}
      width={width}
      height={height}
      margin={margin}
      isDisplay={false}
    >
      <Label
        htmlFor={fileId}
        colorPalette={colorPalette}
        padding={padding}
        fontSize={fontSize}
      >
        ファイルを選択
      </Label>
      <Text textAlign="center" ellipsis>
        {file === null ? (
          <Enhancement color={colorPalette.negative}>{message}</Enhancement>
        ) : (
          <Enhancement
            fontSize={fontSize - 8}
            color={colorPalette.positive}
            bold
          >
            [{Math.floor(file.size / 1024).toLocaleString()}KB] {file.name}
          </Enhancement>
        )}
      </Text>
      {previewUri && (
        <PreviewWrap>
          <Image
            src={previewUri}
            width={previewWidth}
            height={previewHeight}
            shadow
          />
        </PreviewWrap>
      )}

      <File
        id={fileId}
        type="file"
        accept={accept}
        onChange={async (e) => {
          if (e.target.files) {
            setFile(null);
            setMessage('');
            setPreviewUri('');
            onChange && onChange(null);
            const fileMime =
              `.${e.target.files?.[0]?.name?.split('.').pop()}` || '';
            const mimeList = accept.split(',').filter((mime) => {
              return mime.toLocaleLowerCase() === fileMime.toLocaleLowerCase();
            });
            if (mimeList.length === 0) {
              setMessage('登録できないファイルです');
              return;
            }
            if (e.target.files[0].size > maxSize) {
              setMessage('ファイルサイズが大きすぎます');
              return;
            }
            let file: File = e.target.files[0];
            if (
              fileMime.toLocaleLowerCase() === '.heic' ||
              fileMime.toLocaleLowerCase() === '.heif'
            ) {
              // iOS系の場合の画像変換処理
              dispatchLoadingMessage &&
                dispatchLoadingMessage('JPEGファイルへ変換中');
              const jpegBlob = await heic2any({
                blob: e.target.files?.[0],
                toType: 'image/jpeg',
              });
              if (Array.isArray(jpegBlob)) {
                file = convertBlobToFile(
                  jpegBlob,
                  `${e.target.files?.[0].name}.jpg`,
                );
              } else {
                file = convertBlobToFile(
                  [jpegBlob],
                  `${e.target.files?.[0].name}.jpg`,
                );
              }
              dispatchLoadingMessage && dispatchLoadingMessage('');
            }
            setFile(file);
            onChange && onChange(file);
            //画像プレビュー表示処理
            if (previewed) {
              const render = new FileReader();
              render.onload = (_e) => {
                if (_e.target?.result && typeof _e.target.result === 'string') {
                  setPreviewUri(_e.target.result);
                }
              };
              render.readAsDataURL(file);
            }
          } else {
            setMessage('ファイルサイズが大きすぎます');
          }
        }}
      />
    </ValidatorMessage>
  );
};
