/**
 * ログアウトページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  LocalStorageKey,
  LoginStatus,
  LogoutStatus,
  UrlPath,
  loginStatusClear,
  useDidMount,
} from 'commons';
import React from 'react';

/**
 * 【ページ】 ログアウトページ
 * @returns コンポーネント
 */
export const Logout: React.FC = () => {
  useDidMount(() => {
    const logoutStatus = localStorage.getItem(LocalStorageKey.LOGOUT_STATUS);
    if (logoutStatus === LogoutStatus.FIRST) {
      // 認証踏み台サーバーの情報削除
      localStorage.setItem(LocalStorageKey.LOGOUT_STATUS, LogoutStatus.FINISH);
      window.location.href = process.env.REACT_APP_AUTH_SERVER_LOGOUT_URI
        ? process.env.REACT_APP_AUTH_SERVER_LOGOUT_URI +
          location.origin +
          UrlPath.LOGOUT
        : location.origin + UrlPath.ENV_ERROR;
    } else if (logoutStatus === LogoutStatus.FINISH) {
      // ログアウト完了の場合
      const loginStatus = localStorage.getItem(LocalStorageKey.LOGIN_STATUS);
      loginStatusClear(() => {
        if (loginStatus === LoginStatus.RELOGIN_WAIT) {
          // ログアウト完了後、リログイン処理の場合
          window.location.href = process.env.REACT_APP_CUSTOMER_API_LOGIN_URI
            ? process.env.REACT_APP_CUSTOMER_API_LOGIN_URI + location.origin
            : location.origin + UrlPath.ENV_ERROR;
        } else {
          window.location.href = process.env.REACT_APP_PORTAL_URI
            ? process.env.REACT_APP_PORTAL_URI
            : location.origin + UrlPath.ENV_ERROR;
        }
      });
    } else {
      const loginStatus = localStorage.getItem(LocalStorageKey.LOGIN_STATUS);
      loginStatusClear(() => {
        if (loginStatus === LoginStatus.RELOGIN) {
          // リログイン処理(ログイン画面に戻す)の場合
          localStorage.setItem(
            LocalStorageKey.LOGIN_STATUS,
            LoginStatus.RELOGIN_WAIT,
          );
        }
        if (!CustomerConsts.DISABLED_SMART_THEATER) {
          localStorage.setItem(
            LocalStorageKey.LOGOUT_STATUS,
            LogoutStatus.FIRST,
          );
        } else {
          // スマシ無しの場合
          localStorage.setItem(
            LocalStorageKey.LOGOUT_STATUS,
            LogoutStatus.FINISH,
          );
        }
        window.location.href = process.env.REACT_APP_CUSTOMER_API_LOGOUT_URI
          ? process.env.REACT_APP_CUSTOMER_API_LOGOUT_URI +
            location.origin +
            UrlPath.LOGOUT
          : location.origin + UrlPath.ENV_ERROR;
      });
    }
  });

  return null;
};
