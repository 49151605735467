/**
 * リンク
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  letterSpacing?: number;
  lineHeight?: number;
}
const Wrap = styled.a<WrapProps>`
  display: inline-block;
  margin: 0;
  overflow-wrap: anywhere;
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  color: ${({ colorPalette, color }) => (color ? color : colorPalette.concept)};
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ letterSpacing }) =>
    letterSpacing !== undefined && `letter-spacing: ${letterSpacing}px;`}
  ${({ lineHeight }) =>
    lineHeight !== undefined && `line-height: ${lineHeight};`}
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${({ colorPalette, color }) => color || colorPalette.conceptLight};
  }

  &:active {
    color: ${({ colorPalette, color }) => color || colorPalette.conceptLight};
  }
`;

interface LinkProps {
  children: ReactNode;
  colorPalette: ColorPalette;
  href?: string;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  letterSpacing?: number;
  lineHeight?: number;
  forNewTab?: boolean;
  onClick?: () => void;
}
/**
 * 【原子】 リンク
 * @param {ReactNode} children - 【必須】 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {string} href - ページ遷移先URL
 * @param {number} fontSize - 文字サイズ [px]
 * @param {string} color - 文字色
 * @param {boolean} bold - 太字
 * @param {number} letterSpacing - 文字色
 * @param {number} lineHeight - 文字色
 * @param {boolean} forNewTab - hrefを新しいタブで開く
 * @param {() => void} onClick - クリックイベント
 * @returns コンポーネント
 */
export const Link: React.FC<LinkProps> = ({
  children,
  colorPalette,
  href,
  fontSize,
  color,
  bold,
  letterSpacing,
  lineHeight,
  forNewTab,
  onClick,
}: LinkProps) => {
  return href && forNewTab ? (
    <Wrap
      colorPalette={colorPalette}
      href={href}
      fontSize={fontSize}
      color={color}
      bold={bold}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </Wrap>
  ) : (
    <Wrap
      colorPalette={colorPalette}
      href={href}
      fontSize={fontSize}
      color={color}
      bold={bold}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      onClick={onClick}
    >
      {children}
    </Wrap>
  );
};
