/**
 * 新規会員登録確認パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlPath,
  getServerTime,
  isUnder19,
} from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  registerDigitalCouponMember,
  registerDigitalFreeMember,
  registerDigitalMember,
  registerMigrationMember,
  registerPhysicsCardMember,
  submitMembershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px 32px;
`;

interface RecordProps {
  minHiehgt: string;
}
const Record = styled.div<RecordProps>`
  margin: 16px 0;
  min-height: ${({ minHiehgt }) => minHiehgt};
`;

interface RegisterConfirmPanelProps {
  isMigration?: boolean;
  isPhysicsCardMember?: boolean;
  hasCoupon?: boolean;
}
/**
 * 【有機体】 新規会員登録確認パネル
 * @param {boolean} isMigration - 旧会員フラグ
 * @param {boolean} isPhysicsCardMember - カード会員フラグ
 * @param {boolean} hasCoupon - 無料クーポンフラグ
 * @returns コンポーネント
 */
export const RegisterConfirmPanel: React.FC<RegisterConfirmPanelProps> = ({
  isMigration,
  isPhysicsCardMember,
  hasCoupon,
}) => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const submitMembershipData = useTypeSelector(submitMembershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          valueMargin: '0 0 0 36px',
          minHeight: '72px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          valueMargin: '0 0 0 16px',
          minHeight: '56px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
          会員情報登録
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="8px 0 0">
          入力内容を確認して下さい
        </Text>
        {isMigration && (
          <Record minHiehgt={css.minHeight}>
            <Text fontSize={css.fontSize} lineHeight={1} bold>
              コロナッチョカード番号
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
              {submitMembershipData?.previousNoArray
                ?.join('')
                .substring(0, 1) || '―'}
              <Separator width="8px" />
              {submitMembershipData?.previousNoArray
                ?.join('')
                .substring(1, 7) || '―'}
              <Separator width="8px" />
              {submitMembershipData?.previousNoArray
                ?.join('')
                .substring(7, 13) || '―'}
            </Text>
          </Record>
        )}
        {isPhysicsCardMember && (
          <Record minHiehgt={css.minHeight}>
            <Text fontSize={css.fontSize} lineHeight={1} bold>
              会員番号
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
              {submitMembershipData?.memberNoArray?.join('').substring(0, 4) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray?.join('').substring(4, 7) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray?.join('').substring(7, 10) ||
                '―'}
              <Separator width="8px" />
              {submitMembershipData?.memberNoArray
                ?.join('')
                .substring(10, 14) || '―'}
            </Text>
          </Record>
        )}
        {hasCoupon && (
          <Record minHiehgt={css.minHeight}>
            <Text fontSize={css.fontSize} lineHeight={1} bold>
              クーポン番号入力
            </Text>
            <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
              {submitMembershipData.registerCoupon || ''}
            </Text>
          </Record>
        )}
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            お名前(カタカナ)
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.lastName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstName || ''}(
            {submitMembershipData.lastKanaName || ''}
            <Separator width={`${css.fontSize}px`} />
            {submitMembershipData.firstKanaName || ''})
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            電話番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.tel || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            誕生日
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.birthDate || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            郵便番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.postalCode}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            住所
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.address?.all}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            性別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.sex}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            メルマガ
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.newsletter ? '受信する' : '受信しない'}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            マイシアター
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.attributes?.theater}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            好きなジャンル①
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.attributes?.favorite1}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            好きなジャンル②
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {submitMembershipData.attributes?.favorite2}
          </Text>
        </Record>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 16px"
          onClick={async () => {
            const { time } = await getServerTime();
            if (isMigration) {
              dispatch(registerMigrationMember());
            } else if (isPhysicsCardMember) {
              dispatch(registerPhysicsCardMember());
            } else if (hasCoupon) {
              dispatch(registerDigitalCouponMember());
            } else if (isUnder19(time, submitMembershipData.birthDate)) {
              dispatch(registerDigitalFreeMember());
            } else {
              dispatch(registerDigitalMember({}));
            }
          }}
        >
          送信
        </Button>
      </Panel>
      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            if (isMigration) {
              navigate(UrlPath.MIGRATION_REGISTER);
            } else if (isPhysicsCardMember) {
              navigate(UrlPath.PHYSICS_CARD_REGISTER);
            } else if (hasCoupon) {
              navigate(UrlPath.DIGITAL_REGISTER_COUPON);
            } else {
              navigate(UrlPath.DIGITAL_REGISTER);
            }
          }}
        >
          入力画面に戻る
        </Link>
      </Text>
    </Wrap>
  );
};
