/**
 * 会員証ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, UrlPath, useDidMount } from 'commons';
import { Contents } from 'components/templates';
import {
  CardArea,
  CardPopup,
  Header,
  PointHeader,
} from 'components/templates/koronaworld';
import React from 'react';
import { didMountCardPage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 会員証ページ
 * @returns コンポーネント
 */
export const Card: React.FC = () => {
  const dispatch = useTypeDispatch();

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.CARD;
    dispatch(didMountCardPage({ registerUrl: UrlPath.PHYSICS_CARD_CHECKER }));
  });

  return (
    <>
      <Header />
      <PointHeader />
      <Contents
        minHeight="calc(100vh - 80px)"
        imageSrcPc="/koronaworld/wallpaper_pc.jpg"
        imageSrcSp="/koronaworld/wallpaper_sp.jpg"
      >
        <CardArea />
        <CardPopup />
      </Contents>
    </>
  );
};
