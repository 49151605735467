/**
 * カスタマーエンドポイントと通信するAPI
 */
import { CustomerConsts } from 'App';
import axios from 'axios';
import {
  AddressDataYpes,
  CouponListDataTypes,
  CouponShareResultTypes,
  CouponsIsCreatTypes,
  CouponType,
  DigitalCompleteResultTypes,
  DigitalCreateGmoCompleteResultTypes,
  FeeDataTypes,
  HistoryListDataTypes,
  LocalStorageKey,
  MemberCouponListDataTypes,
  MembersGetPreviousNoTypes,
  MembershipDataTypes,
  PaidCouponsIssueCompleteResultTypes,
  PaidCouponsIssueDataTypes,
  PointHistoryListDataTypes,
  ServerTimeResponse,
  ServerVersionResponse,
  setLocalStorageItems,
  UrlPath,
  ValidPeriodCompleteResultTypes,
  ValidPeriodGmoCompleteResultTypes,
} from 'commons';
import { v4 as uuidV4 } from 'uuid';

interface PostAddressFromPostalCodeProps {
  postalCode: string;
}
/**
 * 【サーバー通信関数】 郵便番号検索処理
 * @param {array} postalCode - 検索する郵便番号
 * @returns 通信結果
 */
export const postAddressFromPostalCode = async ({
  postalCode,
}: PostAddressFromPostalCodeProps): Promise<AddressDataYpes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/address_from_postal_code`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        data: { postalCode },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

/**
 * 【サーバー通信関数】 サーバーバージョン番号取得処理
 */
export const getServerVersion = async (): Promise<ServerVersionResponse> => {
  try {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/versioncheck`;
    return (await axios.get(uri)).data;
  } catch (error) {
    const status = error.response?.status || 400;
    const message =
      error.response?.data?.error?.message || error.message || 'API Error';
    throw { code: String(status), message: message };
  }
};

/**
 * 【サーバー通信関数】 サーバー時間取得処理
 */
export const getServerTime = async (): Promise<ServerTimeResponse> => {
  try {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/servertimecheck`;
    return (await axios.get(uri)).data;
  } catch (error) {
    const status = error.response?.status || 400;
    const message =
      error.response?.data?.error?.message || error.message || 'API Error';
    throw { code: String(status), message: message };
  }
};

/**
 * 【サーバー通信関数】 エラー書き込み処理処理(リダイレクトコールバック時の使用を想定)
 */
export const postLogError = (message: string): string => {
  const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/log/error`;
  const form = {
    transponder,
    message,
    searchParams: window.location.search,
    referrer: document.referrer,
    userAgent: window.navigator.userAgent,
  };
  axios.post(uri, JSON.stringify(form)); //戻りは気にせず投げっぱなしにする
  return transponder;
};

interface PostCustomerTokenProps {
  code: string;
}
/**
 * 【サーバー通信関数】 カスタマーAPIトークン取得処理
 * @param {string} code - トークンコード
 */
export const postCustomerToken = async ({ code }: PostCustomerTokenProps) => {
  const transponder = ` [CT: ${uuidV4().substring(0, 8)}]`;
  try {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/token`;
    const form = {
      transponder,
      code,
      state: 'undefined',
      redirect_uri: location.origin,
    };
    const { access_token, refresh_token, token_type } = (
      await axios.post(uri, JSON.stringify(form), { withCredentials: true })
    ).data;
    setLocalStorageItems([
      { key: LocalStorageKey.CUSTOMER_API_TOKEN, value: access_token },
      { key: LocalStorageKey.CUSTOMER_API_TOKEN_TYPE, value: token_type },
      { key: LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN, value: refresh_token },
      {
        key: LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN_DATE,
        value: new Date().toISOString(),
      },
    ]);
  } catch (error) {
    const status = 403;
    const message =
      error.response?.data?.error?.message || error.message || 'API Error';
    throw { code: String(status), message: message + transponder };
  }
};

/**
 * 【サーバー通信関数】 カスタマーAPIトークン更新処理
 */
export const postCustomerRefreshToken = async () => {
  const transponder = ` [CRT: ${uuidV4().substring(0, 8)}]`;
  try {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/token/refresh`;
    const form = {
      transponder,
      refresh_token: localStorage.getItem(
        LocalStorageKey.CUSTOMER_API_REFRESH_TOKEN,
      ),
      memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
    };
    const { access_token, token_type } = (
      await axios.post(uri, JSON.stringify(form), { withCredentials: true })
    ).data;
    setLocalStorageItems([
      { key: LocalStorageKey.CUSTOMER_API_TOKEN, value: access_token },
      { key: LocalStorageKey.CUSTOMER_API_TOKEN_TYPE, value: token_type },
    ]);
  } catch (error) {
    const status = 403;
    const message =
      error.response?.data?.error?.message || error.message || 'API Error';
    throw {
      response: {
        status: String(status),
        data: { error: { message: message + transponder } },
      },
    };
  }
};

/**
 * 【サーバー通信関数】 カスタマーAPIトークンクッキークリア
 */
export const postCustomerTokenClear = async () => {
  const transponder = ` [CTC: ${uuidV4().substring(0, 8)}]`;
  try {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/token/clear`;
    const form = {
      transponder,
      memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
    };
    await axios.post(uri, JSON.stringify(form), { withCredentials: true });
  } catch (error) {
    const status = 403;
    const message =
      error.response?.data?.error?.message || error.message || 'API Error';
    throw { code: String(status), message: message + transponder };
  }
};

interface PostOldMemberIsCreatProps {
  previousNo: string;
}
/**
 * 【サーバー通信関数】 旧会員存在チェック処理
 * @param {string} previousNo - 会員番号
 * @returns 通信結果
 */
export const postOldMemberIsCreat = async ({
  previousNo,
}: PostOldMemberIsCreatProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/migration/is_create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { previousNo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostAlexCinemasOldMemberIsCreatProps {
  previousNo: string;
  validPeriodTo: string;
}
/**
 * 【サーバー通信関数】 アレックスシネマ旧会員存在チェック処理
 * @param {string} previousNo - 会員番号
 * @param {string} validPeriodTo - 有効期限
 * @returns 通信結果
 */
export const postAlexCinemasOldMemberIsCreat = async ({
  previousNo,
  validPeriodTo,
}: PostAlexCinemasOldMemberIsCreatProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/previous/is_create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { previousNo, validPeriodTo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostCardIsCreatProps {
  memberNo: string;
}
/**
 * 【サーバー通信関数】 カード会員存在チェック処理
 * @param {string} memberNo - 会員番号
 * @returns 通信結果
 */
export const postCardIsCreat = async ({
  memberNo,
}: PostCardIsCreatProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/card/is_create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { memberNo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface postCouponsIsCreatProps {
  couponNoAndPinCode: string;
}
/**
 * 【サーバー通信関数】 無料クーポン存在チェック処理
 * @param {string} memberNo - 会員番号
 * @returns 通信結果
 */
export const postCouponsIsCreat = async ({
  couponNoAndPinCode,
}: postCouponsIsCreatProps): Promise<CouponsIsCreatTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/coupons/is_create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { couponNoAndPinCode },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

/**
 * 【サーバー通信関数】 シネマサンシャイン会員情報移行処理
 * @returns 通信結果
 */
export const postMigrateBookingsAndPoints =
  async (): Promise<MembershipDataTypes> => {
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/migrate/bookings_and_points`;
    for (let i = 0; i < 2; i += 1) {
      const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
      try {
        if (i === 1) {
          await postCustomerRefreshToken();
        }
        const form = {
          transponder,
          token_type: localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
          ),
          access_token: localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN,
          ),
          memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        };
        return (await axios.post(uri, JSON.stringify(form))).data;
      } catch (error) {
        const status = error.response?.status || 400;
        const message =
          error.response?.data?.error?.message || error.message || 'API Error';
        if (i === 0 && (status === 401 || status === 403)) {
          continue; // 認証エラー(トークンを再取得して再実行)
        }
        throw { code: String(status), message: message + transponder };
      }
    }
    return {};
  };

/**
 * 【サーバー通信関数】 会員情報取得処理
 * @returns 通信結果
 */
export const getMembers = async (): Promise<MembershipDataTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/members`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      const response = (await axios.post(uri, JSON.stringify(form))).data;
      setLocalStorageItems([
        { key: LocalStorageKey.MEMBER_ID, value: response.memberNo },
        {
          key: LocalStorageKey.NAME,
          value: CustomerConsts.NAME_SEPARATED
            ? response.firstName
            : response.name,
        },
        { key: LocalStorageKey.TOTAL_POINTS, value: response.totalPoints },
        { key: LocalStorageKey.VALID_PERIOD_TO, value: response.validPeriodTo },
        {
          key: LocalStorageKey.NAME_SEPARATED,
          value: response.attributes.nameSeparated || false,
        },
      ]);
      return {
        ...response,
        school: response.school || '', //途中から増えたため、DBによっては返ってこないので強制的に作成
        address: {
          ...response.address,
          postalCode: response.address?.postalCode?.split('-').join('') || '',
        },
        status: 200,
      };
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostDigitalCreateMigrationTemporaryProps {
  previousNo: string;
}
/**
 * 【サーバー通信関数】 旧シネマサンシャインアプリ会員移行ID保存
 * @param {string} previousNo - 登録する会員移行ID
 * @returns 通信結果
 */
export const postDigitalCreateMigrationTemporary = async ({
  previousNo,
}: PostDigitalCreateMigrationTemporaryProps): Promise<string> => {
  const uri =
    process.env.REACT_APP_API_ENDPOINT +
    '/customer/digital/create_migration_temporary';
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { previousNo: previousNo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return '';
};

/**
 * 【サーバー通信関数】 旧シネマサンシャインアプリ会員移行ID取得
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postMembersGetPreviousNo =
  async (): Promise<MembersGetPreviousNoTypes> => {
    const uri =
      process.env.REACT_APP_API_ENDPOINT + '/customer/member/get_previous_no';
    for (let i = 0; i < 2; i += 1) {
      const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
      try {
        if (i === 1) {
          await postCustomerRefreshToken();
        }
        const form = {
          transponder,
          token_type: localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
          ),
          access_token: localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN,
          ),
        };
        return (await axios.post(uri, JSON.stringify(form))).data;
      } catch (error) {
        const status = error.response?.status || 400;
        const message =
          error.response?.data?.error?.message || error.message || 'API Error';
        if (i === 0 && (status === 401 || status === 403)) {
          continue; // 認証エラー(トークンを再取得して再実行)
        }
        throw { code: String(status), message: message + transponder };
      }
    }
    return {};
  };

interface PostCinemasunshineMigrationMemberCreateProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 旧シネマサンシャインアプリ会員登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postCinemasunshineMigrationMemberCreate = async ({
  submitMembershipData,
}: PostCinemasunshineMigrationMemberCreateProps): Promise<boolean> => {
  const uri =
    process.env.REACT_APP_API_ENDPOINT +
    '/customer/digital/create_migration_member';
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          previousNo: submitMembershipData.previousNo || '',
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      await axios.post(uri, JSON.stringify(form));
      return true;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostMigrationMemberCreateProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 旧会員登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postMigrationMemberCreate = async ({
  submitMembershipData,
}: PostMigrationMemberCreateProps): Promise<boolean> => {
  const uri =
    process.env.REACT_APP_API_ENDPOINT +
    '/customer/digital/create_with_previous_no';
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          previousNo: submitMembershipData.previousNoArray?.join('') || '',
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      await axios.post(uri, JSON.stringify(form));
      return true;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostMigrationMemberAlexCinemasCreate {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 アレックスシネマ旧会員登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postMigrationMemberAlexCinemasCreate = async ({
  submitMembershipData,
}: PostMigrationMemberAlexCinemasCreate): Promise<{ paymentUrl: string }> => {
  const uri =
    process.env.REACT_APP_API_ENDPOINT +
    '/customer/digital/create_with_previous_member';
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          callbackUrl: location.origin + UrlPath.MIGRATION_REGISTER_COMPLETE,
          previousNo: submitMembershipData.previousNo || '',
          validPeriodTo: submitMembershipData.previousValidPeriodTo || '',
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      const { paymentUrl } = (await axios.post(uri, JSON.stringify(form))).data;
      return { paymentUrl };
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return { paymentUrl: '' };
};

interface PostPhysicsCardMemberProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 カード会員登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postPhysicsCardMemberCreate = async ({
  submitMembershipData,
}: PostPhysicsCardMemberProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/card/create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          memberNo: submitMembershipData.memberNoArray?.join('') || '',
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      await axios.post(uri, JSON.stringify(form));
      return true;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostDigitalCreateProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 デジタル会員登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postDigitalCreate = async ({
  submitMembershipData,
}: PostDigitalCreateProps): Promise<{ paymentUrl: string }> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/digital/create`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          callbackUrl: location.origin + UrlPath.DIGITAL_REGISTER_COMPLETE,
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      const { paymentUrl } = (await axios.post(uri, JSON.stringify(form))).data;
      return { paymentUrl };
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return { paymentUrl: '' };
};

interface PostDigitalCreateFreeProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 デジタル会員無料登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postDigitalCreateFree = async ({
  submitMembershipData,
}: PostDigitalCreateFreeProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/digital/create/free`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      await axios.post(uri, JSON.stringify(form));
      return true;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostDigitalCreateWithCouponProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 デジタル会員無料クーポン登録処理
 * @param {MembershipDataTypes} submitMembershipData - 登録する会員情報
 * @returns 通信結果
 */
export const postDigitalCreateWithCoupon = async ({
  submitMembershipData,
}: PostDigitalCreateWithCouponProps): Promise<boolean> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/digital/create_with_coupon`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: {
          couponNoAndPinCode: submitMembershipData.registerCoupon || '',
          name: submitMembershipData.name || '',
          kanaName: submitMembershipData.kanaName || '',
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          birthDate: submitMembershipData.birthDate || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          sex: submitMembershipData.sex || '',
          student: submitMembershipData.student || false,
          school: submitMembershipData.school || '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes || {},
          membershipCode: submitMembershipData.membershipCode || '',
        },
      };
      await axios.post(uri, JSON.stringify(form));
      return true;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return false;
};

interface PostDigitalCompleteProps {
  encryptValue: string;
}
/**
 * 【サーバー通信関数】 デジタル会員登録完了処理
 * @param {string} encryptValue - 完了コード
 * @returns 通信結果
 */
export const postDigitalComplete = async ({
  encryptValue,
}: PostDigitalCompleteProps): Promise<DigitalCompleteResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/digital/create/complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { result: encryptValue },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface postDigitalCreateGmoCompleteProps {
  encryptValue: string;
}
/**
 * 【サーバー通信関数】 デジタル会員登録完了処理(シネマサンシャイン用)
 * @param {string} encryptValue - 完了コード
 * @returns 通信結果
 */
export const postDigitalCreateGmoComplete = async ({
  encryptValue,
}: postDigitalCreateGmoCompleteProps): Promise<DigitalCreateGmoCompleteResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/digital/create/gmo_complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        data: { result: encryptValue },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

/**
 * 【サーバー通信関数】 会員クーポン情報取得処理
 * @returns 通信結果
 */
export const getMemberCoupons = async (): Promise<
  MemberCouponListDataTypes[]
> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/member/coupons`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostCouponsApplyProps {
  memberNo: string;
  transactionId: string;
}
/**
 * 【サーバー通信関数】 クーポン着券処理
 * @param {string} memberNo - 対象ユーザー
 * @param {string} transactionId - 共有するクーポントランザクションID
 * @returns 通信結果
 */
export const postCouponsApply = async ({
  memberNo,
  transactionId,
}: PostCouponsApplyProps): Promise<CouponShareResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/member/coupons/apply`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { memberNo, transactionId },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostCouponsShareProps {
  email: string;
  transactionId: string;
}
/**
 * 【サーバー通信関数】 クーポン共有処理
 * @param {string} email - 共有するメールアドレス
 * @param {string} transactionId - 共有するクーポントランザクションID
 * @returns 通信結果
 */
export const postCouponsShare = async ({
  email,
  transactionId,
}: PostCouponsShareProps): Promise<CouponShareResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/member/coupons/share`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { email, transactionId },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface GetCouponsListProps {
  type: CouponType;
}
/**
 * 【サーバー通信関数】 クーポン情報取得処理
 * @param {CouponType} type - クーポン種別
 * @returns 通信結果
 */
export const getCouponsList = async ({
  type,
}: GetCouponsListProps): Promise<CouponListDataTypes[]> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/coupons/list`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { type },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostPaidCouponsIssueProps {
  couponId: string;
}
/**
 * 【サーバー通信関数】 有償クーポン発行処理
 * @param {string} couponId - 購入するクーポン情報
 * @returns 通信結果
 */
export const postPaidCouponsIssue = async ({
  couponId,
}: PostPaidCouponsIssueProps): Promise<PaidCouponsIssueDataTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/paid_coupons/issue`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: {
          couponId,
          callbackUrl: location.origin + UrlPath.COUPON_MARKET_PAYMENT_COMPLETE,
        },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostPaidCouponsIssueCompleteProps {
  encryptValue: string;
}
/**
 * 【サーバー通信関数】 有償クーポン決済完了処理
 * @param {string} encryptValue - 完了コード
 * @returns 通信結果
 */
export const postPaidCouponsIssueComplete = async ({
  encryptValue,
}: PostPaidCouponsIssueCompleteProps): Promise<PaidCouponsIssueCompleteResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/paid_coupons/issue/complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { result: encryptValue },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostPointCouponsIssueProps {
  couponInfo: {
    couponId: string;
    count: number;
  }[];
}
/**
 * 【サーバー通信関数】 ポイントクーポン発行処理
 * @param {array} couponInfo - 発行するクーポン情報
 * @returns 通信結果
 */
export const postPointCouponsIssue = async ({
  couponInfo,
}: PostPointCouponsIssueProps): Promise<CouponListDataTypes[]> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/point/coupons/issue`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { couponInfo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostPointsProps {
  reflectDateFrom: string;
  reflectDateTo: string;
}
/**
 * 【サーバー通信関数】 ポイント履歴取得処理
 * @param {string} reflectDateFrom - 開始日
 * @param {string} reflectDateTo - 終了日
 * @returns 通信結果
 */
export const postPoints = async ({
  reflectDateFrom,
  reflectDateTo,
}: PostPointsProps): Promise<PointHistoryListDataTypes[]> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/points`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { reflectDateFrom, reflectDateTo },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

/**
 * 【サーバー通信関数】 鑑賞履歴取得処理
 * @returns 通信結果
 */
export const getHistory = async (): Promise<HistoryListDataTypes[]> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/movie_histories`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostMembersEditProps {
  submitMembershipData: MembershipDataTypes;
}
/**
 * 【サーバー通信関数】 会員情報更新処理
 * @param {MembershipDataTypes} submitMembershipData - 変更した会員情報
 * @returns 通信結果
 */
export const postMembersEdit = async ({
  submitMembershipData,
}: PostMembersEditProps) => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/member/edit`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: {
          firstName: submitMembershipData.firstName || '',
          lastName: submitMembershipData.lastName || '',
          firstKanaName: submitMembershipData.firstKanaName || '',
          lastKanaName: submitMembershipData.lastKanaName || '',
          tel: submitMembershipData.tel || '',
          address: {
            postalCode: submitMembershipData.address?.postalCode || '',
            all: submitMembershipData.address?.all || '',
          },
          school: submitMembershipData.student
            ? submitMembershipData.school || ''
            : '',
          newsletter: submitMembershipData.newsletter || false,
          attributes: submitMembershipData.attributes,
        },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostUpdateEmailProps {
  email: string;
}
/**
 * 【サーバー通信関数】 メールアドレス更新処理
 * @param {MembershipDataTypes} email - 変更したメールアドレス
 * @returns 通信結果
 */
export const postUpdateEmail = async ({ email }: PostUpdateEmailProps) => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/update_email`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { email },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

interface PostUpdateEmailCompleteProps {
  code: string;
}
/**
 * 【サーバー通信関数】 メールアドレス更新完了処理
 * @param {MembershipDataTypes} code - 認証コード
 * @returns 通信結果
 */
export const postUpdateEmailComplete = async ({
  code,
}: PostUpdateEmailCompleteProps) => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/update_email/complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { code },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return [];
};

/**
 * 【サーバー通信関数】 退会処理
 * @returns 通信結果
 */
export const postMemberDelete = async (): Promise<{ memberNo?: string }> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/member/delete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

/**
 * 【サーバー通信関数】 会員有効期限更新処理
 * @returns 通信結果
 */
export const postValidPeriod = async (): Promise<{ paymentUrl: string }> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/valid_period`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: {
          callbackUrl: location.origin + UrlPath.RENEW_COMPLETE,
        },
      };
      const { paymentUrl } = (await axios.post(uri, JSON.stringify(form))).data;
      return { paymentUrl };
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return { paymentUrl: '' };
};

/**
 * 【サーバー通信関数】 会員有効期限無料更新処理
 * @returns 通信結果
 */
export const postValidPeriodFree = async (): Promise<{
  paymentUrl: string;
}> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/valid_period/free`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      const { paymentUrl } = (await axios.post(uri, JSON.stringify(form))).data;
      return { paymentUrl };
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return { paymentUrl: '' };
};

interface PostValidPeriodCompleteProps {
  encryptValue: string;
}
/**
 * 【サーバー通信関数】 会員有効期限更新完了処理
 * @param {string} encryptValue - 完了コード
 * @returns 通信結果
 */
export const postValidPeriodComplete = async ({
  encryptValue,
}: PostValidPeriodCompleteProps): Promise<ValidPeriodCompleteResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/valid_period/complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { result: encryptValue },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostValidPeriodGmoCompleteProps {
  encryptValue: string;
}
/**
 * 【サーバー通信関数】 会員有効期限更新完了処理(シネマサンシャイン用)
 * @param {string} encryptValue - 完了コード
 * @returns 通信結果
 */
export const postValidPeriodGmoComplete = async ({
  encryptValue,
}: PostValidPeriodGmoCompleteProps): Promise<ValidPeriodGmoCompleteResultTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/valid_period/gmo_complete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { result: encryptValue },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};

interface PostFaceUploadProps {
  file: File;
}
/**
 * 【サーバー通信関数】 顔認証画像アップロードURL取得
 * @param {File} file - 顔画像ファイル
 * @returns 通信結果
 */
export const postFaceUpload = async ({
  file,
}: PostFaceUploadProps): Promise<{ result: boolean }> => {
  let uploadUri = '';
  for (let i = 0; i < 2; i += 1) {
    //アップロード先のURI取得
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/face/upload`;
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      uploadUri = (await axios.post(uri, JSON.stringify(form))).data;
      break;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  try {
    //S3のテンポラリーに画像アップロード
    await axios.put(uploadUri, file, {
      headers: { 'Content-Type': file.type },
    });
  } catch (error) {
    throw {
      code: String(500),
      message: '顔写真の送信に失敗しました',
    };
  }
  for (let i = 0; i < 2; i += 1) {
    // 画像のチェックと登録処理
    const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/face/upload/complete`;
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return { result: false };
};

/**
 * 【サーバー通信関数】 顔認証画像ダウンロード
 * @returns 通信結果
 */
export const postFaceDownload = async (): Promise<string> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/face/download`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return '';
};

/**
 * 【サーバー通信関数】 顔認証画像削除
 * @returns 通信結果
 */
export const postFaceDelete = async (): Promise<string> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/face/delete`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return '';
};

/**
 * 【サーバー通信関数】 紹介クーポンの発行
 * @returns 通信結果
 */
export const postCouponsIssueInviteCoupon = async (): Promise<string> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/coupons/issue/invite_coupon`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return '';
};

interface PostFeesProps {
  action: 'create' | 'update';
}
/**
 * 【サーバー通信関数】 会員料金一覧取得
 * @param {'create' | 'update'} action - 新規登録時 | 更新時
 * @returns 通信結果
 */
export const postFees = async ({
  action,
}: PostFeesProps): Promise<FeeDataTypes> => {
  const uri = `${process.env.REACT_APP_API_ENDPOINT}/customer/fees`;
  for (let i = 0; i < 2; i += 1) {
    const transponder = ` [E-ID: ${uuidV4().substring(0, 8)}]`;
    try {
      if (i === 1) {
        await postCustomerRefreshToken();
      }
      const form = {
        transponder,
        token_type: localStorage.getItem(
          LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
        ),
        access_token: localStorage.getItem(LocalStorageKey.CUSTOMER_API_TOKEN),
        memberNo: localStorage.getItem(LocalStorageKey.MEMBER_ID),
        data: { action },
      };
      return (await axios.post(uri, JSON.stringify(form))).data;
    } catch (error) {
      const status = error.response?.status || 400;
      const message =
        error.response?.data?.error?.message || error.message || 'API Error';
      if (i === 0 && (status === 401 || status === 403)) {
        continue; // 認証エラー(トークンを再取得して再実行)
      }
      throw { code: String(status), message: message + transponder };
    }
  }
  return {};
};
