/**
 * コンテンツブロック
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import React, { ReactNode, useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
`;

interface ContentsBlockProps {
  children?: ReactNode;
}
/**
 * 【有機体】 コンテンツブロック
 * @param {ReactNode} children - 子要素
 * @returns コンポーネント
 */
export const ContentsBlock: React.FC<ContentsBlockProps> = ({
  children,
}: ContentsBlockProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, wrapPadding: '24px 0 120px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, wrapPadding: '16px 0 120px' };
  }, [displaySize]);

  return (
    <Wrap colorPalette={colorPalette} padding={css.wrapPadding}>
      {children}
    </Wrap>
  );
};
