/**
 * 有効期限更新表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { UrlName, UrlPath } from 'commons';
import {
  ContentsBlock,
  ContentsTitle,
  MessageModal,
} from 'components/organisms';
import { RenewPanel } from 'components/organisms/koronaworld';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * 【テンプレート】 有効期限更新表示エリア
 * @returns コンポーネント
 */
export const RenewArea: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.RENEW}</ContentsTitle>
      <RenewPanel />
      <MessageModal
        onClose={() => {
          navigate(UrlPath.CARD);
        }}
      />
    </ContentsBlock>
  );
};
