/**
 * ポイントクーポンパネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CouponListDataTypes,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  Z_INDEX,
} from 'commons';
import { Button, Enhancement, Image, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  colorPaletteSelector,
  displaySizeSelector,
  updatePointCouponToCoupon,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const CouponName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CouponPointProps {
  colorPalette: ColorPalette;
  width: string;
}
const CouponPoint = styled.div<CouponPointProps>`
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.3)};
  border-radius: 8px;
  width: ${({ width }) => width};
  padding: 8px 0 12px;
  margin: 8px auto 0;
  max-width: 100%;
`;

interface DescriptionWrapProps {
  colorPalette: ColorPalette;
  margin: string;
}
const DescriptionWrap = styled.div<DescriptionWrapProps>`
  padding: 0 16px;
  margin: ${({ margin }) => margin};
  border: 1px solid ${({ colorPalette }) => colorPalette.grayTone};
  border-radius: 8px;
`;

interface PointCouponPanelProps {
  pointCoupon: CouponListDataTypes;
  point?: number;
  margin?: string;
}
/**
 * 【有機体】 ポイントクーポンパネル
 * @param {CouponListDataTypes} pointCoupon - ポイント交換情報
 * @param {number} point - ポイント交換情報
 * @param {string} margin - マージン
 * @returns コンポーネント
 */
export const PointCouponPanel: React.FC<PointCouponPanelProps> = ({
  pointCoupon,
  point,
  margin,
}: PointCouponPanelProps) => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const disabled = useMemo(() => {
    if (pointCoupon.rate && point) {
      return pointCoupon.rate > point;
    }
    return true;
  }, [pointCoupon, point]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, margin: '16px 40px 16px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, margin: '16px 0 16px' };
  }, [displaySize]);

  return (
    <Panel colorPalette={colorPalette} width={css.panelWidth} margin={margin}>
      <CouponName>
        <Image src="/svg/exchange_blue.svg" width="40px" height="40px" />
        <Text fontSize={css.fontSize} lineHeight={1} margin="0 0 0 12px" bold>
          {pointCoupon.couponName}
        </Text>
      </CouponName>
      {pointCoupon?.description && (
        <DescriptionWrap colorPalette={colorPalette} margin={css.margin}>
          <Enhancement fontSize={css.fontSizeSmall}>
            <ReactMarkdown>{pointCoupon.description}</ReactMarkdown>
          </Enhancement>
        </DescriptionWrap>
      )}
      <CouponPoint colorPalette={colorPalette} width={css.buttonWidth}>
        <Text fontSize={css.fontSize} lineHeight={1}>
          必要ポイント
        </Text>
        <Text
          fontSize={css.fontSizeLarge}
          color={colorPalette.negative}
          lineHeight={1}
          margin="0 0 0 16px"
          bold
        >
          {pointCoupon.rate?.toLocaleString('ja-JP') || '―'}
        </Text>
        <Text fontSize={css.fontSize} lineHeight={1}>
          pt
        </Text>
      </CouponPoint>
      <Button
        colorPalette={colorPalette}
        width={css.buttonWidth}
        margin="16px auto 8px"
        disabled={disabled}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        ポイントを交換
      </Button>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <CouponName>
          <Image src="/svg/exchange_blue.svg" width="40px" height="40px" />
          <Text fontSize={css.fontSize} lineHeight={1} margin="0 0 0 12px" bold>
            {pointCoupon.couponName}
          </Text>
        </CouponName>
        {pointCoupon?.description && (
          <DescriptionWrap colorPalette={colorPalette} margin={css.margin}>
            <Enhancement fontSize={css.fontSizeSmall}>
              <ReactMarkdown>{pointCoupon.description}</ReactMarkdown>
            </Enhancement>
          </DescriptionWrap>
        )}
        <CouponPoint colorPalette={colorPalette} width={css.buttonWidth}>
          <Text fontSize={css.fontSize} lineHeight={1}>
            必要ポイント
          </Text>
          <Text
            fontSize={css.fontSizeLarge}
            color={colorPalette.negative}
            lineHeight={1}
            margin="0 0 0 16px"
            bold
          >
            {pointCoupon.rate?.toLocaleString('ja-JP') || '―'}
          </Text>
          <Text fontSize={css.fontSize} lineHeight={1}>
            pt
          </Text>
        </CouponPoint>
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSize}
          lineHeight={1.3}
          textAlign="center"
          margin="24px 0"
          bold
        >
          交換したクーポンをポイントに戻すことはできません。
          <br />
          よくご確認の後、交換を行って下さい。
        </Text>
        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          margin="8px auto 0"
          onClick={() => {
            setIsOpen(false);
            if (pointCoupon.couponId) {
              dispatch(
                updatePointCouponToCoupon({
                  couponId: pointCoupon.couponId,
                  count: 1,
                }),
              );
            }
          }}
        >
          交換する
        </Button>
        <Button
          colorPalette={colorPalette}
          color={colorPalette.negative}
          width={css.buttonWidth}
          margin="24px auto 8px"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          交換しない
        </Button>
      </Modal>
    </Panel>
  );
};
