/**
 * 鑑賞履歴パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  CSS_DEFAULT_PARAMETER,
  ColorPalette,
  DisplaySize,
  HistoryListDataTypes,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import {
  Button,
  Enhancement,
  Image,
  Link,
  Separator,
  Text,
} from 'components/atoms';
import { Modal, PageSelector, Panel } from 'components/molecules';
import QRCode from 'qrcode';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  historyListSelector,
  loadingMessageSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const HistoryArea = styled.div`
  display: flex;
`;

interface PosterImageProps {
  imageSrc: string;
  width: number;
}
const PosterImage = styled.div<PosterImageProps>`
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: ${({ width }) => width}px;
`;

interface DetailProps {
  detailPadding: number;
  width: string;
}
const Detail = styled.div<DetailProps>`
  width: ${({ width }) => width};
  padding: 0 0 0 ${({ detailPadding }) => detailPadding}px;
`;

const QrCodeArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QrImgWrap = styled.div`
  margin: 16px 0 0;
`;

interface HeaderPageSelectorWrapProps {
  width: string;
}
const HeaderPageSelectorWrap = styled.div<HeaderPageSelectorWrapProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ width }) => width};
  margin: 0 0 16px;
`;

const FooterPageSelectorWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 32px;
`;

interface QrTextWrapProps {
  colorPalette: ColorPalette;
}
const QrTextWrap = styled.div<QrTextWrapProps>`
  background-color: ${({ colorPalette }) => colorPalette.wallpaper};
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0 0;
  width: 100%;
`;

interface QrTexLineProps {
  margin?: string;
}
const QrTexLine = styled.div<QrTexLineProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ margin }) => `margin: ${margin};`}
  width: 100%;
`;

interface HistoryPanelProps {
  enabledFilter?: boolean;
}
/**
 * 【有機体】 鑑賞履歴パネル
 * @returns コンポーネント
 */
export const HistoryPanel: React.FC<HistoryPanelProps> = ({
  enabledFilter = false,
}: HistoryPanelProps) => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const historyList = useTypeSelector(historyListSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  const [qrHistory, setQrHistory] = useState<HistoryListDataTypes | null>(null);
  const [selectPage, setSelectPage] = useState<number>(1);
  const maxPage = useMemo(() => {
    return Math.ceil(historyList.length / CustomerConsts.HISTORY_LENGTH);
  }, [historyList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imgWidth: 240,
          detailPadding: 60,
          titleMargin: '16px 0 0',
          valueMargin: '4px 0 0 16px',
          separator: '16px',
          qrCodeWidth: '280px',
          qrCodeTitleMargin: '0 0 24px',
          qrCodeTextLineMargin: '20px 0 0',
          qrCodeHeaderWidth: '160px',
          qrCodeValueWidth: 'calc(100% - 160px)',
          descriptionMargin: '16px 0 0',
          buttonMargin: '24px 0 16px',
          pageSelectorWidth: '320px',
          pointButtonMargin: '32px auto 16px',
          memberNoSeparator: '16px',
          linkMargin: '0 0 16px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imgWidth: 96,
          detailPadding: 16,
          titleMargin: '4px 0 0',
          valueMargin: '0 0 0 16px',
          separator: '8px',
          qrCodeWidth: '160px',
          qrCodeTitleMargin: '0 0 16px',
          qrCodeTextLineMargin: '12px 0 0',
          qrCodeHeaderWidth: '96px',
          qrCodeValueWidth: 'calc(100% - 96px)',
          qrCodeValueMargin: '16px 0 0',
          descriptionMargin: '8px 0 0',
          buttonMargin: '16px 0 8px',
          pageSelectorWidth: '256px',
          pointButtonMargin: '24px auto 8px',
          memberNoSeparator: '16px',
          linkMargin: '0 0 16px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      {historyList.length !== 0 && (
        <HeaderPageSelectorWrap width={css.panelWidth}>
          <Text fontSize={css.fontSizeSmall} lineHeight={1} letterSpacing={0}>
            {(
              CustomerConsts.HISTORY_LENGTH * (selectPage - 1) +
              1
            ).toLocaleString('ja-JP')}
            〜
            {selectPage === maxPage
              ? historyList.length.toLocaleString('ja-JP')
              : (CustomerConsts.HISTORY_LENGTH * selectPage).toLocaleString(
                  'ja-JP',
                )}
            件を表示
          </Text>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </HeaderPageSelectorWrap>
      )}
      {loadingMessage === '' && historyList.length === 0 && (
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
          margin="0 0 24px"
        >
          <Text fontSize={css.fontSize} textAlign="center">
            {enabledFilter
              ? '対象のチケットはありません'
              : '購入したチケットはありません'}
          </Text>
        </Panel>
      )}
      {historyList.map((data: HistoryListDataTypes, index) => {
        const hiddenDate = data.eventDate
          ? new Date(data.eventDate)
          : new Date('2000/01/01');
        hiddenDate.setDate(hiddenDate.getDate() + 1);
        const nowDate = new Date();
        const ticketToken = data.ticketToken;
        const imageUrl = (() => {
          if (data.location === 'サツゲキ') {
            return '/sskts/noimage_satsugeki.jpg';
          }
          if (
            data.location === 'ディノスシネマズ苫小牧' ||
            data.location === 'ディノスシネマズ室蘭'
          ) {
            return '/sskts/noimage_dinos.jpg';
          }
          return '/sskts/noimage.jpg';
        })();
        if (
          index >= CustomerConsts.HISTORY_LENGTH * (selectPage - 1) &&
          index < CustomerConsts.HISTORY_LENGTH * selectPage
        ) {
          return (
            <Panel
              key={`${index}${data.eventDate}`}
              colorPalette={colorPalette}
              width={css.panelWidth}
              margin="0 0 24px"
            >
              <HistoryArea>
                <PosterImage
                  imageSrc={data.title?.thumbnailUrl || imageUrl}
                  width={css.imgWidth}
                />

                <Detail
                  width={`calc(100% - ${css.imgWidth}px)`}
                  detailPadding={css.detailPadding}
                >
                  <Text fontSize={css.fontSizeSmall}>
                    <Enhancement>上映日時</Enhancement>
                    <Separator width={css.separator} />
                    {data.eventDate
                      ? new Date(data.eventDate)
                          .toLocaleString('ja-JP')
                          .slice(0, -3)
                      : '―'}
                  </Text>
                  <Text fontSize={css.fontSize} lineHeight={1.25} bold>
                    {data.title?.name || ''}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    劇場情報
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.location || ''}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    スクリーン
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.area || ''}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    チケット情報
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.ticketInfo?.map((data, i) => (
                      <Fragment key={`ticket${i}-${data.code}`}>
                        {data.name}
                        <Separator width={css.separator} />
                      </Fragment>
                    ))}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    座席情報
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.seatInfo?.map((data, i) => (
                      <Fragment key={`seat${i}-${data.code}`}>
                        {data.code}
                        <Separator width={css.separator} />
                      </Fragment>
                    ))}
                  </Text>
                  {(data.mealCoupon || 0) > 0 && (
                    <>
                      <Text
                        fontSize={css.fontSizeSmall}
                        color={colorPalette.negative}
                        margin={css.descriptionMargin}
                      >
                        本チケットには、ミールクーポン
                        {data.mealCoupon}
                        円分が含まれています。
                      </Text>
                      <Text fontSize={css.fontSizeSmall}>
                        4F・コンセッション、5F・クラッパーズダイナー、12F・バール
                        パノーラマのいずれかで入場QRコードをご提示の上、ご利用願います。
                        <br />
                        ※お釣りのお返しは致しかねます。
                        <br />
                        ※ご鑑賞当日のみ有効
                      </Text>
                    </>
                  )}
                  {ticketToken && hiddenDate.getTime() > nowDate.getTime() && (
                    <Button
                      colorPalette={colorPalette}
                      fontSize={css.fontSizeSmall}
                      width={css.buttonWidth}
                      margin={css.buttonMargin}
                      onClick={() => {
                        QRCode.toDataURL(ticketToken, (err, url) => {
                          setQrCodeImg(url);
                          setQrHistory(data);
                        });
                      }}
                    >
                      入場QRコード表示
                    </Button>
                  )}
                </Detail>
              </HistoryArea>
            </Panel>
          );
        }
      })}
      {historyList.length !== 0 && (
        <FooterPageSelectorWrap>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </FooterPageSelectorWrap>
      )}
      <Modal
        colorPalette={colorPalette}
        opened={qrCodeImg ? true : false}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        onClose={() => {
          setQrCodeImg('');
          setQrHistory(null);
        }}
      >
        <QrCodeArea>
          <Text
            fontSize={css.fontSizeMiddle}
            margin={css.qrCodeTitleMargin}
            textAlign="center"
            bold
          >
            入場用QRコード
          </Text>
          <Text
            fontSize={css.fontSizeSmall}
            lineHeight={1.25}
            textAlign="center"
            bold
          >
            入場の際はこちらのQRコードをご提示下さい
          </Text>
          <QrImgWrap>
            <Image src={qrCodeImg} width={css.qrCodeWidth} shadow />
          </QrImgWrap>
          <QrTextWrap colorPalette={colorPalette}>
            <QrTexLine>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                座席
              </Text>
              <Text
                fontSize={css.fontSizeMiddle}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
                bold
              >
                {qrHistory?.seatInfo?.map((data, i) => (
                  <Fragment key={`qrSeat${i}-${data.code}`}>
                    {data.code}
                    <Separator width={css.separator} />
                  </Fragment>
                ))}
              </Text>
            </QrTexLine>
            <QrTexLine margin={css.qrCodeTextLineMargin}>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                チケット情報
              </Text>
              <Text
                fontSize={css.fontSize}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
                bold
              >
                {qrHistory?.ticketInfo?.map((data, i) => (
                  <Fragment key={`qrTicket${i}-${data.code}`}>
                    {data.name}
                    <Separator width={css.separator} />
                  </Fragment>
                ))}
              </Text>
            </QrTexLine>
            <QrTexLine margin={css.qrCodeTextLineMargin}>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                上映日時
              </Text>
              <Text
                fontSize={css.fontSize}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
              >
                {qrHistory?.eventDate
                  ? new Date(qrHistory.eventDate)
                      .toLocaleString('ja-JP')
                      .slice(0, -3)
                  : '―'}
              </Text>
            </QrTexLine>
            <QrTexLine margin={css.qrCodeTextLineMargin}>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                作品名
              </Text>
              <Text
                fontSize={css.fontSize}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
              >
                {qrHistory?.title?.name || '―'}
              </Text>
            </QrTexLine>
            <QrTexLine margin={css.qrCodeTextLineMargin}>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                劇場情報
              </Text>
              <Text
                fontSize={css.fontSize}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
              >
                {qrHistory?.location || ''}
              </Text>
            </QrTexLine>
            <QrTexLine margin={css.qrCodeTextLineMargin}>
              <Text
                fontSize={css.fontSizeSmall}
                lineHeight={1.25}
                width={css.qrCodeHeaderWidth}
                bold
              >
                スクリーン
              </Text>
              <Text
                fontSize={css.fontSize}
                lineHeight={1.25}
                width={css.qrCodeValueWidth}
              >
                {qrHistory?.area || ''}
              </Text>
            </QrTexLine>
          </QrTextWrap>
          {(qrHistory?.mealCoupon || 0) > 0 && (
            <>
              <Text
                fontSize={css.fontSizeSmall}
                color={colorPalette.negative}
                margin={css.descriptionMargin}
              >
                本チケットには、ミールクーポン
                {qrHistory?.mealCoupon}
                円分が含まれています。
              </Text>
              <Text fontSize={css.fontSizeSmall}>
                4F・コンセッション、5F・クラッパーズダイナー、12F・バールパノーラマ
                のいずれかで入場QRコードをご提示の上、ご利用願います。
                <br />
                ※お釣りのお返しは致しかねます。
                <br />
                ※ご鑑賞当日のみ有効
              </Text>
            </>
          )}
          <Button
            colorPalette={colorPalette}
            width={css.buttonWidth}
            margin={css.buttonMargin}
            onClick={() => {
              setQrCodeImg('');
              setQrHistory(null);
            }}
          >
            閉じる
          </Button>
        </QrCodeArea>
      </Modal>
      <Link
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          navigate(UrlPath.CARD);
        }}
      >
        {UrlName.CARD}画面に戻る
      </Link>
    </Wrap>
  );
};
