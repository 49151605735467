/**
 * エラー表示モーダル
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ApiErrorModal,
  AuthErrorModal,
  ErrorModal,
} from 'components/organisms';
import React from 'react';

/**
 * 【特殊コンポーネント】 エラー表示モーダル
 * @returns コンポーネント
 */
export const ErrorModals: React.FC = () => {
  return (
    <>
      <ErrorModal />
      <AuthErrorModal />
      <ApiErrorModal />
    </>
  );
};
