/**
 * メルマガ未登録ヘッダー
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  ColorPalette,
  DisplaySize,
  Z_INDEX,
} from 'commons';
import { Button, GoogleFontsIcon, Link, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import { MessageModal } from 'components/organisms';
import React, { useMemo, useState } from 'react';
import {
  EventSlice,
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  updateProfile,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  displaySize: DisplaySize;
}

const Wrap = styled.div<WrapProps>`
  overflow: hidden;
  position: sticky;
  top: 80px;
  left: 0;
  z-index: ${Z_INDEX.header};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  width: 100%;
  height: 72px;
  border: 2px solid ${({ colorPalette }) => colorPalette.warning};
  padding: 0 8px;
  line-height: 1.2;
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

/**
 * 【テンプレート】 メルマガ未登録ヘッダー
 * @returns コンポーネント
 */
export const NewsletterBar: React.FC = () => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const newsletter = useMemo(() => {
    return membershipData.newsletter === false ? true : false;
  }, [membershipData]);
  const [opened, setOpened] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, registerWidth: '80px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, registerWidth: '56px' };
  }, [displaySize]);

  return newsletter ? (
    <>
      <Wrap colorPalette={colorPalette} displaySize={displaySize}>
        <TextWrap>
          <GoogleFontsIcon
            name="warning"
            color={colorPalette.warning}
            size={css.fontSizeLarge}
          />
          <Text fontSize={css.fontSize} margin="0 0 0 8px">
            お得なクーポンをGETするなら
            {displaySize !== DisplaySize.LARGE && <br />}
            メルマガ登録が必要です
            <br />
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              href="https://startheaters.jp/news/652/"
              forNewTab
            >
              特典の詳細はこちら
            </Link>
          </Text>
        </TextWrap>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          padding="4px 8px"
          width={css.registerWidth}
          onClick={() => {
            setOpened(true);
          }}
        >
          登録
        </Button>
      </Wrap>
      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        opened={opened}
        headerTitle="メルマガ登録"
        headerTitleSize={css.fontSize}
        onClose={() => {
          setOpened(false);
        }}
      >
        <Text fontSize={css.fontSize} margin="0 0 16px" textAlign="center">
          メルマガを受信する設定に変更します
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setOpened(false);
            dispatch(
              EventSlice.actions.changeSubmitMembershipData({
                newsletter: true,
              }),
            );
            dispatch(updateProfile());
          }}
        >
          メルマガを受信する
        </Button>
        <Button
          colorPalette={colorPalette}
          color={colorPalette.negative}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 16px"
          onClick={() => {
            setOpened(false);
          }}
        >
          閉じる
        </Button>
      </Modal>
      <MessageModal
        onClose={() => {
          window.location.reload();
        }}
      />
    </>
  ) : null;
};
