/**
 * メッセージモーダル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, Z_INDEX } from 'commons';
import { Button, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  EventSlice,
  colorPaletteSelector,
  displaySizeSelector,
  eventResultMessageSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';

interface MessageModalProps {
  onClose?: () => void;
}
/**
 * 【有機体】 メッセージモーダル
 * @param {() => void} onClose - 閉じるボタンクリック時動作
 * @returns コンポーネント
 */
export const MessageModal: React.FC<MessageModalProps> = ({
  onClose,
}: MessageModalProps) => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const message = useTypeSelector(eventResultMessageSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, modalWidth: '600px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Modal
      colorPalette={colorPalette}
      zIndex={Z_INDEX.modal}
      headerTitle="メッセージ"
      headerTitleSize={css.fontSize}
      width={css.modalWidth}
      padding={css.modalPadding}
      opened={message === '' ? false : true}
      onClose={() => {
        dispatch(EventSlice.actions.changeEventResultMessage(''));
        onClose && onClose();
      }}
    >
      <Text
        color={colorPalette.darkTone}
        fontSize={css.fontSize}
        textAlign="center"
        margin="0 0 16px"
      >
        {message}
      </Text>
      <Button
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        width={css.buttonWidth}
        margin="0 auto"
        onClick={() => {
          dispatch(EventSlice.actions.changeEventResultMessage(''));
          onClose && onClose();
        }}
      >
        閉じる
      </Button>
    </Modal>
  );
};
