/**
 * 環境変数警告エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, getServerVersion } from 'commons';
import { Text } from 'components/atoms';
import React, { useEffect, useMemo, useState } from 'react';
import { displaySizeSelector, useTypeSelector } from 'stores';
import pkg from '../../../package.json';

/**
 * 【テンプレート】 環境変数警告エリア
 * @returns コンポーネント
 */
export const EnvErrorArea: React.FC = () => {
  const displaySize = useTypeSelector(displaySizeSelector);
  const [serverVersion, setServerVersion] = useState<string>('');
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      const response = await getServerVersion();
      setServerVersion(response.version);
    }, 1);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      <Text
        fontSize={css.fontSize}
        textAlign="center"
        width="100%"
        margin="16px 0 8px"
      >
        環境変数の設定を確認してください
      </Text>
      <Text
        fontSize={css.fontSize}
        width="100%"
        textAlign="center"
        margin="0 0 8px"
      >
        ClientVersion: {pkg.version}
      </Text>
      <Text
        fontSize={css.fontSize}
        width="100%"
        textAlign="center"
        margin="0 0 8px"
      >
        ServerVersion: {serverVersion}
      </Text>
    </>
  );
};
