/**
 * ページ遷移前に認証状態確認を行う
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import { LocalStorageKey, UrlPath, isCustomerApiAuth } from 'commons';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthElementProps {
  element: ReactNode;
  path: string;
}
/**
 * 【特殊コンポーネント】 ページ遷移前に認証状態確認を行う
 * @param {ReactNode} element - 遷移先のページコンポーネント
 * @param {string} path - 遷移先のページURL
 */
export const AuthElement: React.FC<AuthElementProps> = ({
  element,
  path,
}: AuthElementProps) => {
  if (!isCustomerApiAuth()) {
    return <Navigate to={UrlPath.LOGIN} />;
  }
  if (!CustomerConsts.DISABLED_VALID_PERIOD) {
    const validPeriodTo = localStorage.getItem(LocalStorageKey.VALID_PERIOD_TO);
    if (
      path !== UrlPath.RENEW_COMPLETE &&
      path !== UrlPath.RENEW &&
      validPeriodTo &&
      new Date(validPeriodTo).getTime() < new Date().getTime()
    ) {
      return <Navigate to={UrlPath.RENEW} />;
    }

    //姓名分離の判断、分離有効で未分離なら専用画面に遷移
    if (CustomerConsts.NAME_SEPARATED) {
      const nameSeparated = localStorage.getItem(
        LocalStorageKey.NAME_SEPARATED,
      );
      if (
        path !== UrlPath.NAME_SEPARATION_EDIT &&
        nameSeparated &&
        nameSeparated !== 'true' &&
        validPeriodTo &&
        new Date(validPeriodTo).getTime() > new Date().getTime()
      ) {
        return <Navigate to={UrlPath.NAME_SEPARATION_EDIT} />;
      }
    }
  }
  return <>{element}</>;
};
