/**
 * 領収書表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  GenerateImageId,
  UrlName,
  UrlPath,
  Z_INDEX,
  downloadHtmlToPng,
} from 'commons';
import { Button, GoogleFontsIcon, Link, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  InvoicePanel,
} from 'components/organisms';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  invoiceFeeSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
`;

const Back = styled.div`
  margin: 16px 0 0;
  text-align: center;
`;

/**
 * 【テンプレート】 領収書表示エリア
 * @returns コンポーネント
 */
export const InvoiceArea: React.FC = () => {
  const dispatch = useTypeDispatch();
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const invoiceFee = useTypeSelector(invoiceFeeSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          panelPadding: '16px 0 48px',
          textMargin: '16px 32px',
          imagePadding: '16px 32px',
          invoiceMargin: '0 0 24px',
          borderMargin: '16px 0',
          buttonMargin: '0 16px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          panelPadding: '8px 0 24px',
          textMargin: '8px 16px',
          imagePadding: '8px 12px',
          invoiceMargin: '0 0 16px',
          borderMargin: '8px 0',
          buttonMargin: '0 16px',
        };
  }, [displaySize]);

  return invoiceFee === '' || invoiceFee === '0' ? null : (
    <ContentsBlock>
      <ContentsTitle>{UrlName.INVOICE}</ContentsTitle>
      <Wrap>
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
        >
          <Text fontSize={css.fontSize} margin={css.textMargin} bold>
            再発行できませんので、必要なお客様はこの場でダウンロードや印刷を行なってください。
          </Text>
          <InvoicePanel />
          <ButtonWrap>
            <Button
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              width={css.buttonWidth}
              margin={css.buttonMargin}
              onClick={async () => {
                const now = new Date();
                const result = await downloadHtmlToPng(
                  GenerateImageId.INVOICE,
                  `領収証_${now
                    .toLocaleString('ja-JP')
                    .replaceAll('/', '-')
                    .replaceAll(':', '')
                    .replaceAll(' ', '_')}`,
                );
                if (!result) {
                  dispatch(
                    BasicSlice.actions.changeErrorMessage(
                      '領収証画像の生成に失敗しました',
                    ),
                  );
                }
              }}
            >
              <GoogleFontsIcon
                name="download"
                size={css.fontSizeMiddle}
                margin="0 8px 0 0"
              />
              ダウンロード
            </Button>
            <Button
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              width={css.buttonWidth}
              margin={css.buttonMargin}
              onClick={async () => {
                navigate(UrlPath.INVOICE_PRINT);
              }}
            >
              <GoogleFontsIcon
                name="print"
                size={css.fontSizeMiddle}
                margin="0 8px 0 0"
              />
              印刷
            </Button>
          </ButtonWrap>
        </Panel>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {UrlName.CARD}画面へ進む
        </Button>

        <Modal
          colorPalette={colorPalette}
          width={css.modalWidth}
          padding={css.modalPadding}
          zIndex={Z_INDEX.modal}
          opened={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSize}
            textAlign="center"
            bold
          >
            ダウンロードまたは印刷をしましたか？
          </Text>
          <Text fontSize={css.fontSize} textAlign="center" margin="16px 0 0">
            この画面は再度開くことができません。必要なお客様はダウンロードか印刷を行なってください。
          </Text>

          <Button
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            width={css.buttonWidth}
            margin="24px auto 0"
            onClick={() => {
              setIsOpen(false);
              navigate(UrlPath.CARD);
            }}
          >
            {UrlName.CARD}画面へ進む
          </Button>
          <Back>
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              onClick={() => setIsOpen(false)}
            >
              前画面に戻る
            </Link>
          </Back>
        </Modal>
      </Wrap>
    </ContentsBlock>
  );
};
