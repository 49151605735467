/**
 * Reduxを利用するための基本パッケージ
 */
import { configureStore } from '@reduxjs/toolkit';
import {
  BrowserTypes,
  ColorPalette,
  CouponListDataTypes,
  CouponShareDataTypes,
  DisplaySize,
  FeeDataTypes,
  HistoryListDataTypes,
  InvoiceDataTypes,
  Languages,
  MemberCouponListDataTypes,
  MembershipDataTypes,
  PointHistoryListDataTypes,
} from 'commons';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { BasicSlice, EventSlice, PageSlice } from 'stores';

// Providerに渡すstore
export const store = configureStore({
  reducer: {
    basic: BasicSlice.reducer,
    page: PageSlice.reducer,
    event: EventSlice.reducer,
  },
});

// 型付けがされたuseDispatch
export type DispatchState = typeof store.dispatch;
export const useTypeDispatch = (): DispatchState =>
  useDispatch<DispatchState>();

// 型付けがされたuseSelector
export type SelectorState = ReturnType<typeof store.getState>;
export const useTypeSelector: TypedUseSelectorHook<SelectorState> = useSelector;

// useSelectorで利用するセレクタ(BasicSliceのstate)
export const browserSelector = (state: SelectorState): BrowserTypes =>
  state.basic.browser;
export const startPwaInstallSelector = (state: SelectorState): boolean =>
  state.basic.startPwaInstall;
export const languageSelector = (state: SelectorState): Languages =>
  state.basic.language;
export const displaySizeSelector = (state: SelectorState): DisplaySize =>
  state.basic.displaySize;
export const colorPaletteSelector = (state: SelectorState): ColorPalette =>
  state.basic.colorPalette;
export const loadingMessageSelector = (state: SelectorState): string =>
  state.basic.loadingMessage;
export const mainMenuOpenSelector = (state: SelectorState): boolean =>
  state.basic.mainMenuOpen;
export const errorMessageSelector = (state: SelectorState): string =>
  state.basic.errorMessage;
export const authErrorMessageSelector = (state: SelectorState): string =>
  state.basic.authErrorMessage;

// useSelectorで利用するセレクタ(PageSliceのState)
export const pageLoadingMessageSelector = (state: SelectorState): string =>
  state.page.pageLoadingMessage;
export const pageErrorMessageSelector = (state: SelectorState): string =>
  state.page.pageErrorMessage;
export const membershipDataSelector = (
  state: SelectorState,
): MembershipDataTypes => state.page.membershipData;
export const faceImageUrlSelector = (state: SelectorState): string =>
  state.page.faceImageUrl;
export const memberCouponListSelector = (
  state: SelectorState,
): MemberCouponListDataTypes[] => state.page.memberCouponList;
export const couponListSelector = (
  state: SelectorState,
): CouponListDataTypes[] => state.page.couponList;
export const pointHistoryListSelector = (
  state: SelectorState,
): PointHistoryListDataTypes[] => state.page.pointHistoryList;
export const historyListSelector = (
  state: SelectorState,
): HistoryListDataTypes[] => state.page.historyList;
export const FeeListSelector = (state: SelectorState): FeeDataTypes =>
  state.page.feeList;

// useSelectorで利用するセレクタ(EventSliceのState)
export const eventServerTimeSelector = (state: SelectorState): string =>
  state.event.eventServerTime;
export const eventLoadingMessageSelector = (state: SelectorState): string =>
  state.event.eventLoadingMessage;
export const eventErrorMessageSelector = (state: SelectorState): string =>
  state.event.eventErrorMessage;
export const eventResultMessageSelector = (state: SelectorState): string =>
  state.event.eventResultMessage;
export const submitMembershipDataSelector = (
  state: SelectorState,
): MembershipDataTypes => state.event.submitMembershipData;
export const approvalDeleteSelector = (state: SelectorState): boolean =>
  state.event.approvalDelete;
export const couponShareDataSelector = (
  state: SelectorState,
): CouponShareDataTypes => state.event.couponShareData;
export const paymentSuccessSelector = (state: SelectorState): boolean =>
  state.event.paymentSuccess;
export const resultMemberCouponListSelector = (
  state: SelectorState,
): MemberCouponListDataTypes[] => state.event.resultMemberCouponList;
export const invoiceDataSelector = (state: SelectorState): InvoiceDataTypes =>
  state.event.invoiceData;
export const invoiceFeeSelector = (state: SelectorState): string =>
  state.event.invoiceFee;
