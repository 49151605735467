/**
 * クーポン購入パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CouponListDataTypes,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  Z_INDEX,
} from 'commons';
import { Button, Image, Link, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import { CheckBoxForm } from 'components/organisms';
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  colorPaletteSelector,
  displaySizeSelector,
  paymentPaidCouponsIssue,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  margin: 16px;
`;

const CouponName = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px;
`;

interface DescriptionWrapProps {
  colorPalette: ColorPalette;
  fontSize: number;
}
const DescriptionWrap = styled.div<DescriptionWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.3)};
  border-radius: 8px;
  padding: 4px 16px;
  margin: 8px 0;
  font-size: ${({ fontSize }) => fontSize}px;
`;

interface ImageWrapProps {
  ticketColor: string;
}
const ImageWrap = styled.div<ImageWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ ticketColor }) => ticketColor};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 0 0 0 8px;
`;

const PriceWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 16px 0 0;
`;

const AcceptArea = styled.div`
  text-align: center;
  margin: 16px 0 0;
`;

const LinkWrap = styled.div`
  text-align: center;
  margin: 16px 0 0;
`;

interface PaidCouponPanelProps {
  paidCoupon: CouponListDataTypes;
  ticketColor: string;
}
/**
 * 【有機体】 クーポン購入パネル
 * @param {CouponListDataTypes} paidCoupon - ポイント交換情報
 * @param {string} ticketColor - チケットの色
 * @returns コンポーネント
 */
export const PaidCouponPanel: React.FC<PaidCouponPanelProps> = ({
  paidCoupon,
  ticketColor,
}: PaidCouponPanelProps) => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel colorPalette={colorPalette} padding={css.panelPadding}>
        <CouponName>
          <ImageWrap ticketColor={ticketColor}>
            <Image src="/svg/movie.svg" width="20px" height="20px" />
          </ImageWrap>
          <Text
            fontSize={css.fontSize}
            lineHeight={1}
            width="calc(100% - 52px)"
            margin="0 0 0 12px"
            bold
          >
            {paidCoupon.couponName}
          </Text>
        </CouponName>
        {paidCoupon.description && (
          <DescriptionWrap
            colorPalette={colorPalette}
            fontSize={css.fontSizeSmall}
          >
            <ReactMarkdown>{paidCoupon.description}</ReactMarkdown>
          </DescriptionWrap>
        )}
        <PriceWrap>
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSizeLarge}
            textAlign="center"
            lineHeight={1}
            bold
          >
            {paidCoupon.rate?.toLocaleString('ja-JP') || '―'}
          </Text>
          <Text fontSize={css.fontSize} textAlign="center" lineHeight={1} bold>
            円
          </Text>
        </PriceWrap>

        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setDisabledSubmit(true);
            setIsOpen(true);
          }}
        >
          購入する
        </Button>
      </Panel>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSize}
          textAlign="center"
          bold
        >
          注意事項を確認し同意の上、
          <br />
          決済にお進み下さい。
        </Text>
        <CouponName>
          <ImageWrap ticketColor={ticketColor}>
            <Image src="/svg/movie.svg" width="20px" height="20px" />
          </ImageWrap>
          <Text
            fontSize={css.fontSize}
            width="calc(100% - 52px)"
            margin="0 0 0 12px"
            bold
          >
            {paidCoupon.couponName}
          </Text>
        </CouponName>
        {paidCoupon.description && (
          <DescriptionWrap
            colorPalette={colorPalette}
            fontSize={css.fontSizeSmall}
          >
            <ReactMarkdown>{paidCoupon.description}</ReactMarkdown>
          </DescriptionWrap>
        )}
        <PriceWrap>
          <Text fontSize={css.fontSize} textAlign="center" lineHeight={1} bold>
            お支払い金額：
          </Text>
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSizeLarge}
            textAlign="center"
            lineHeight={1}
            bold
          >
            {paidCoupon.rate?.toLocaleString('ja-JP') || '―'}
          </Text>
          <Text fontSize={css.fontSize} textAlign="center" lineHeight={1} bold>
            円
          </Text>
        </PriceWrap>

        <AcceptArea>
          {isOpen && (
            <CheckBoxForm
              valueList={[{ label: '上記に同意します', value: 'accept' }]}
              initialValue={['']}
              onChange={(value) => {
                setDisabledSubmit(value.join('') !== 'accept');
              }}
            />
          )}
        </AcceptArea>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="16px auto 0"
          disabled={disabledSubmit}
          onClick={() => {
            setIsOpen(false);
            if (paidCoupon.couponId && paidCoupon.rate) {
              dispatch(
                paymentPaidCouponsIssue({
                  couponId: paidCoupon.couponId,
                  couponFee: paidCoupon.rate,
                }),
              );
            }
          }}
        >
          決済に進む
        </Button>
        <LinkWrap>
          <Link
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            前画面に戻る
          </Link>
        </LinkWrap>
      </Modal>
    </Wrap>
  );
};
