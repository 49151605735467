/**
 * QRコードリーダー
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import jsQR from 'jsqr';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const MessageArea = styled.div`
  font-size: 16px;
`;

const Canvas = styled.canvas`
  width: 100%;
`;

const Video = styled.video`
  display: none;
`;

interface QrCodeReaderProps {
  colorPalette: ColorPalette;
  enabled: boolean;
  onRead: (value: string) => void;
}
/**
 * 【原子】 QRコードリーダー
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {boolean} enabled - 【必須】 読み取り状態切り替え
 * @param {(value: string) => void} onRead - 【必須】 QRを読み取った際の動作
 * @returns コンポーネント
 */
export const QrCodeReader: React.FC<QrCodeReaderProps> = ({
  colorPalette,
  enabled = false,
  onRead,
}: QrCodeReaderProps) => {
  const [message, setMessage] = useState<string>('カメラを起動中...');
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoStream = useRef<MediaStream | null>(null);

  // Canvas上に線を描画
  const drawLine = useCallback(
    (begin: { x: number; y: number }, end: { x: number; y: number }) => {
      const context2D = canvasRef.current?.getContext('2d');
      if (context2D) {
        context2D.beginPath();
        context2D.moveTo(begin.x, begin.y);
        context2D.lineTo(end.x, end.y);
        context2D.lineWidth = 4;
        context2D.strokeStyle = colorPalette.negative;
        context2D.stroke();
      }
    },
    [],
  );

  // QRコード読み取り処理
  const tick = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        const context2D = canvasRef.current?.getContext('2d');
        if (canvasRef.current && context2D) {
          if (message !== '') {
            setMessage('');
          }
          canvasRef.current.width = videoRef.current.videoWidth;
          canvasRef.current.height = videoRef.current.videoHeight;
          context2D.drawImage(
            videoRef.current,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height,
          );
          const imageData = context2D.getImageData(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height,
          );
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            drawLine(code.location.topLeftCorner, code.location.topRightCorner);
            drawLine(
              code.location.topRightCorner,
              code.location.bottomRightCorner,
            );
            drawLine(
              code.location.bottomRightCorner,
              code.location.bottomLeftCorner,
            );
            drawLine(
              code.location.bottomLeftCorner,
              code.location.topLeftCorner,
            );
            setTimeout(() => {
              onRead(code.data);
              if (canvasRef.current) {
                context2D.clearRect(
                  0,
                  0,
                  canvasRef.current.width,
                  canvasRef.current.height,
                );
              }
              videoStream.current?.getVideoTracks().forEach((track) => {
                track.stop();
              });
            }, 1000);
            return;
          }
        }
      }
    }
    requestAnimationFrame(tick);
  }, [onRead, drawLine]);

  useEffect(() => {
    if (enabled) {
      // カメラの起動とQRコード読み取り開始
      if (videoStream.current === null) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then(function (stream) {
            if (videoRef.current) {
              videoStream.current = stream; //停止用にストリームを保存
              videoRef.current.srcObject = stream;
              videoRef.current.playsInline = true;
              videoRef.current.play();
              tick();
            }
          });
      }
    } else {
      // カメラの停止
      if (videoStream.current !== null) {
        videoStream.current?.getVideoTracks().forEach((track) => {
          track.stop();
        });
        videoStream.current = null;
      }
    }
    return () => {
      // カメラを起動したままコンポーネントが消えた際にカメラを止める
      if (enabled && videoStream.current !== null) {
        videoStream.current?.getVideoTracks().forEach((track) => {
          track.stop();
        });
        videoStream.current = null;
      }
    };
  }, [enabled]);

  return (
    <>
      <MessageArea>{message}</MessageArea>
      <Video ref={videoRef} />
      <Canvas ref={canvasRef} />
    </>
  );
};
