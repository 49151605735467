/**
 * ボタン
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  color?: string;
  fontSize?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  isTextLeft?: boolean;
}
const Wrap = styled.button<WrapProps>`
  display: flex;
  justify-content: ${({ isTextLeft }) =>
    isTextLeft ? 'flex-start' : 'center'};
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  max-width: 100%;
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  border: 2px solid ${({ colorPalette, color }) =>
    color || colorPalette.concept};
  border-radius: 12px;
  color: ${({ colorPalette, color }) => color || colorPalette.concept};
  line-height: 1.85;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${({ colorPalette, color }) =>
      color || colorPalette.concept};
    color: ${({ colorPalette }) => colorPalette.lightTone};
  }

  &:active {
    background-color: ${({ colorPalette, color }) =>
      color || colorPalette.concept};
    color: ${({ colorPalette }) => colorPalette.lightTone};
    transition: none;
  }

  &:disabled {
    background-color: ${({ colorPalette }) => colorPalette.grayTone};
    border: 1px solid ${({ colorPalette }) => colorPalette.grayTone};
    color: ${({ colorPalette }) => colorPalette.lightTone};
    pointer-events: none;
  }
`;

interface ReversalButtonProps {
  children?: ReactNode;
  colorPalette: ColorPalette;
  color?: string;
  fontSize?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  isTextLeft?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}
/**
 * 【原子】 ボタン(カラー反転版)
 * @param {ReactNode} children - 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {string} color - 指定色
 * @param {number} fontSize - 文字サイズ [px]
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} margin - マージン
 * @param {string} padding - パディング (初期値:"4px 16px")
 * @param {boolean} isTextLeft - 子要素左寄せ
 * @param {boolean} disabled - 無効フラグ (初期値:false)
 * @param {() => void} onClick - クリックイベント
 * @returns コンポーネント
 */
export const ReversalButton: React.FC<ReversalButtonProps> = ({
  children,
  colorPalette,
  color,
  fontSize = 20,
  width,
  height,
  margin,
  padding = '12px 0',
  isTextLeft,
  disabled = false,
  onClick,
}: ReversalButtonProps) => {
  return (
    <Wrap
      colorPalette={colorPalette}
      color={color}
      fontSize={fontSize}
      width={width}
      height={height}
      margin={margin}
      padding={padding}
      isTextLeft={isTextLeft}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Wrap>
  );
};
