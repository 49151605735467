/**
 * クーポンシェア完了パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  BrandColors,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  downloadHtmlToPng,
  GenerateImageId,
  shareHtmlToPng,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, GoogleFontsIcon, Image, Link, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import QRCode from 'qrcode';
import React, { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const CouponWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 4px solid #c1272d;
  width: 100%;
`;

interface GiftBackgroundImageProps {
  imageSrc: string;
  repeat: string;
  width: string;
  height?: string;
}
const GiftBackgroundImage = styled.div<GiftBackgroundImageProps>`
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: ${({ repeat }) => repeat};
  background-size: contain;
  width: ${({ width }) => width};
  ${({ height }) => `height: ${height}`}
`;

interface CouponDataWrapProps {
  width: string;
}
const CouponDataWrap = styled.div<CouponDataWrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  padding: 0 24px 40px 0;
`;

const BorderLine = styled.div`
  background-color: #cca467;
  width: 100%;
  height: 2px;
  margin: 8px 0;
`;

const CodeWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const NumberWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NumberCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cca467;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  padding: 0 12px;
`;

interface QrCodeProps {
  size: number;
}
const QrCode = styled.img<QrCodeProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
`;

const Back = styled.div`
  margin: 16px 0 0;
  text-align: center;
`;

interface CouponShareCompletePanelProps {
  couponName?: string;
  couponNo?: string;
  pinCode?: string;
  startingTime?: string;
  endingTime?: string;
  description?: string;
}
/**
 * 【有機体】 クーポンシェア完了パネル
 * @param {string} couponName - クーポン名
 * @param {string} couponNo - クーポン番号
 * @param {string} pinCode - 暗証番号
 * @param {string} startingTime - 有効期限開始
 * @param {string} endingTime - 有効期限終了
 * @param {string} description - 詳細説明
 * @returns コンポーネント
 */
export const CouponShareCompletePanel: React.FC<
  CouponShareCompletePanelProps
> = ({
  couponName,
  couponNo,
  pinCode,
  startingTime,
  endingTime,
  description,
}: CouponShareCompletePanelProps) => {
  const dispatch = useTypeDispatch();
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [qrCodeImg, setQrCodeImg] = useState<string>('');
  const disabledShare = useMemo(() => {
    return navigator.share === undefined;
  }, []);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          panelPadding: '16px 0 48px',
          textMargin: '16px 32px',
          qrSize: 150,
          buttonMargin: '0 16px',
          giftBackgroundImageSize: '150px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          panelPadding: '8px 0 24px',
          textMargin: '8px 16px',
          qrSize: 100,
          buttonMargin: '0 16px',
          giftBackgroundImageSize: '50px',
        };
  }, [displaySize]);

  useEffect(() => {
    QRCode.toDataURL(`${couponNo || ''}${pinCode || ''}`, (err, url) => {
      setQrCodeImg(url);
    });
  }, [couponNo, pinCode]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} margin={css.textMargin} bold>
          再発行できませんので、はじめに必ず「スクリーンショット」をお取りください。
          <br />
          その後、ダウンロードやシェアを行なってください。
          <br />
          ※ご登録されているメールアドレス宛にご案内メールを送信致しました。
        </Text>
        <ImageWrap id={GenerateImageId.SHARE_COUPON}>
          <CouponWrap>
            <GiftBackgroundImage
              imageSrc="/img/share_coupon02.png"
              repeat="no-repeat"
              width={css.giftBackgroundImageSize}
              height={css.giftBackgroundImageSize}
            />
            <GiftBackgroundImage
              imageSrc="/img/share_coupon03.png"
              repeat="repeat-x"
              width={`calc(100% - ${css.giftBackgroundImageSize})`}
              height={css.giftBackgroundImageSize}
            />
            <GiftBackgroundImage
              imageSrc="/img/share_coupon04.png"
              repeat="repeat-y"
              width={css.giftBackgroundImageSize}
            />
            <CouponDataWrap
              width={`calc(100% - ${css.giftBackgroundImageSize})`}
            >
              <Image src="/img/share_coupon01.png" width="40%" />
              <BorderLine />
              <Text
                fontSize={css.fontSize}
                textAlign="center"
                width="100%"
                bold
              >
                {couponName}
              </Text>
              <Text
                fontSize={css.fontSizeSmall}
                color={colorPalette.negative}
                textAlign="center"
                width="100%"
                bold
              >
                有効期限
                <br />
                {startingTime
                  ? new Date(startingTime).toLocaleString('ja-JP').slice(0, -3)
                  : ''}
                〜
                {endingTime
                  ? new Date(endingTime).toLocaleString('ja-JP').slice(0, -3)
                  : ''}
              </Text>
              <BorderLine />
              <CodeWrap>
                <NumberWrap>
                  <Text fontSize={css.fontSizeSmall}>クーポン番号</Text>
                  <NumberCode>
                    <Text
                      fontSize={css.fontSize}
                      color={colorPalette.lightTone}
                      bold
                    >
                      {couponNo}
                    </Text>
                  </NumberCode>
                  <Text fontSize={css.fontSizeSmall}>暗証番号</Text>
                  <NumberCode>
                    <Text
                      fontSize={css.fontSize}
                      color={colorPalette.lightTone}
                      bold
                    >
                      {pinCode}
                    </Text>
                  </NumberCode>
                </NumberWrap>
                <QrCode
                  src={qrCodeImg !== '' ? qrCodeImg : undefined}
                  size={css.qrSize}
                />
              </CodeWrap>
            </CouponDataWrap>
          </CouponWrap>
          {description && (
            <Text fontSize={css.fontSizeTiny} width="100%" padding="0 16px">
              <ReactMarkdown>{description}</ReactMarkdown>
            </Text>
          )}
        </ImageWrap>
        <ButtonWrap>
          <Button
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            width={css.buttonWidth}
            margin={css.buttonMargin}
            onClick={async () => {
              const now = new Date();
              const result = await downloadHtmlToPng(
                GenerateImageId.SHARE_COUPON,
                `${couponName}_${now
                  .toLocaleString('ja-JP')
                  .replaceAll('/', '-')
                  .replaceAll(':', '')
                  .replaceAll(' ', '_')}`,
              );
              if (!result) {
                dispatch(
                  BasicSlice.actions.changeErrorMessage(
                    'クーポン画像の生成に失敗しました',
                  ),
                );
              }
            }}
          >
            <GoogleFontsIcon
              name="download"
              size={css.fontSizeMiddle}
              margin="0 8px 0 0"
            />
            ダウンロード
          </Button>
          <Button
            colorPalette={colorPalette}
            color={BrandColors.line}
            fontSize={css.fontSize}
            width={css.buttonWidth}
            margin={css.buttonMargin}
            onClick={async () => {
              const now = new Date();
              const result = await shareHtmlToPng(
                GenerateImageId.SHARE_COUPON,
                `${couponName}_${now
                  .toLocaleString('ja-JP')
                  .replaceAll('/', '-')
                  .replaceAll(':', '')
                  .replaceAll(' ', '_')}`,
              );
              if (!result) {
                dispatch(
                  BasicSlice.actions.changeErrorMessage(
                    'クーポン画像の生成・シェアに失敗しました',
                  ),
                );
              }
            }}
            disabled={disabledShare}
          >
            <GoogleFontsIcon
              name="share"
              size={css.fontSizeMiddle}
              margin="0 8px 0 0"
            />
            シェア
          </Button>
        </ButtonWrap>
      </Panel>

      <Button
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        width={css.buttonWidth}
        margin="24px auto 0"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {UrlName.CARD}画面へ戻る
      </Button>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSize}
          textAlign="center"
          bold
        >
          ダウンロードまたはシェアをしましたか？
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="16px 0 0">
          この画面は再度開くことができません。必ず今ダウンロードかシェアを行なってください。
        </Text>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setIsOpen(false);
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面へ戻る
        </Button>
        <Back>
          <Link
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            onClick={() => setIsOpen(false)}
          >
            前画面に戻る
          </Link>
        </Back>
      </Modal>
    </Wrap>
  );
};
