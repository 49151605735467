/**
 * 各種定数を管理
 */
import {
  CheckBoxItemDataType,
  RadioButtonItemDataType,
} from 'components/molecules';

/** 【enum】 表示言語 */
export enum Languages {
  JA,
  EN,
}

/** 【enum】 表示画面サイズ */
export enum DisplaySize {
  LARGE = 9999, //PC・タブレット
  SMALL = 720, //スマホ
}

/** 【enum】 ページURL */
export enum UrlPath {
  HOME = '/',
  USER_POLICY = '/userPolicy',
  USER_POLICY_ANNUAL = '/userPolicy/annual',
  LOGIN = '/login',
  SIGN_IN = '/signIn',
  SIGN_OUT = '/signOut',
  REGISTER_PORTAL = '/register/portal',
  MIGRATION_CHECKER = '/migration/checker',
  MIGRATION_REGISTER = '/migration/register',
  MIGRATION_REGISTER_CONFIRM = '/migration/register/confirm',
  MIGRATION_REGISTER_COMPLETE = '/migration/register/complete',
  PHYSICS_CARD_CHECKER = '/physicsCard/checker',
  PHYSICS_CARD_REGISTER = '/physicsCard/register',
  PHYSICS_CARD_REGISTER_CONFIRM = '/physicsCard/register/confirm',
  DIGITAL_CHECKER = '/digital/checker',
  DIGITAL_REGISTER = '/digital/register',
  DIGITAL_REGISTER_CONFIRM = '/digital/register/confirm',
  DIGITAL_REGISTER_COMPLETE = '/digital/register/complete',
  DIGITAL_REGISTER_COUPON = '/digital/register/coupon',
  DIGITAL_REGISTER_COUPON_CONFIRM = '/digital/register/coupon/confirm',
  CARD = '/card',
  COUPON = '/coupon',
  COUPON_SHARE = '/coupon/share',
  COUPON_SHARE_COMPLETE = '/coupon/share/complete',
  COUPON_MARKET = '/coupon/market',
  COUPON_MARKET_PAYMENT_COMPLETE = '/coupon/market/payment/complete',
  COUPON_REQUEST = '/coupon/request',
  POINT = '/point',
  POINT_HISTORY = '/point/history',
  HISTORY = '/history',
  AQUARIUM_HISTORY = '/aquarium/history',
  TICKETHOLDER = '/ticketholder',
  PROFILE = '/profile',
  PROFILE_EDIT = '/profile/edit',
  PROFILE_EDIT_CONFIRM = '/profile/edit/confirm',
  EMAIL_EDIT = '/email/edit',
  EMAIL_EDIT_CONFIRM = '/email/edit/confirm',
  NAME_SEPARATION_EDIT = '/name/separation/edit',
  RENEW = '/renew',
  RENEW_COMPLETE = '/renew/complete',
  FACE_REGISTER = '/face/register',
  PASSPORT_REGISTER = '/passport/register',
  MY_NUMBER = '/myNumber',
  LOGOUT = '/logout',
  ENV_ERROR = '/enverror',
  MAINTENANCE = '/maintenance',
  SIGN_IN_STATUS = '/signIn/status',
  COMING_SOON = '/comingsoon',
  APP_INSTALL = '/app/install',
  CAMPAING_INVITATION = '/campaign/invitation',
  INVOICE = '/invoice',
  INVOICE_PRINT = '/invoice/print',
}

/** 【enum】 ページ名 */
export enum UrlName {
  HOME = 'ログイン処理',
  USER_POLICY = '利用規約',
  USER_POLICY_ANNUAL = '年間パスポート利用規約',
  LOGIN = 'ログイン',
  SIGN_IN = 'サインイン処理',
  SIGN_OUT = 'サインアウト処理',
  REGISTER_PORTAL = '会員登録',
  MIGRATION_CHECKER = '会員移行',
  MIGRATION_REGISTER = '旧カード移行会員登録',
  MIGRATION_REGISTER_CONFIRM = '旧カード移行会員登録確認',
  MIGRATION_REGISTER_COMPLETE = '旧カード移行会員登録完了',
  PHYSICS_CARD_CHECKER = 'カード会員確認',
  PHYSICS_CARD_REGISTER = 'カード会員登録',
  PHYSICS_CARD_REGISTER_CONFIRM = 'カード会員登録確認',
  DIGITAL_CHECKER = '会員登録クーポン確認',
  DIGITAL_REGISTER = 'デジタル会員登録',
  DIGITAL_REGISTER_CONFIRM = 'デジタル会員登録確認',
  DIGITAL_REGISTER_COMPLETE = 'デジタル会員登録完了',
  DIGITAL_REGISTER_COUPON = 'デジタル会員無料登録',
  DIGITAL_REGISTER_COUPON_CONFIRM = 'デジタル会員無料登録確認',
  CARD = 'マイページTOP',
  COUPON = 'クーポン確認',
  COUPON_MARKET = 'クーポン購入',
  COUPON_MARKET_FUKUBUKURO = '福袋購入',
  COUPON_MARKET_SUMMER = 'サマー・エンジョイ・チケット購入',
  COUPON_MARKET_PAYMENT_COMPLETE = 'クーポン購入処理結果',
  COUPON_SHARE = 'クーポンシェア',
  COUPON_SHARE_COMPLETE = 'クーポンシェア画像',
  COUPON_REQUEST = 'クーポン申請',
  POINT = 'ポイント交換',
  POINT_HISTORY = 'ポイント履歴',
  HISTORY = '鑑賞履歴',
  AQUARIUM_HISTORY = '入場履歴',
  TICKETHOLDER = 'チケットホルダー',
  PROFILE = '会員情報',
  PROFILE_EDIT = '会員情報修正',
  PROFILE_EDIT_CONFIRM = '会員情報修正確認',
  EMAIL_EDIT = 'メールアドレス変更',
  EMAIL_EDIT_CONFIRM = '認証コード入力',
  NAME_SEPARATION_EDIT = 'お名前登録変更のお願い',
  RENEW = '有効期限更新',
  RENEW_COMPLETE = '有効期限更新完了',
  FACE_REGISTER = '顔写真登録',
  PASSPORT_REGISTER = '顔PASS購入',
  MY_NUMBER = 'マイナンバー',
  LOGOUT = 'ログアウト',
  ENV_ERROR = 'サーバーエラー',
  MAINTENANCE = 'メンテナンス中',
  SIGN_IN_STATUS = 'サインインステータス',
  COMING_SOON = '近日公開',
  APP_INSTALL = 'アプリ入手',
  APP_IOS_PWA_TEST = '最新iOSテストページ',
  CAMPAING_INVITATION = '新規入会キャンペーン',
  INVOICE = '領収書発行',
  INVOICE_PRINT = '領収書印刷',
}

/** 【enum】 ローカルストレージキー */
export enum LocalStorageKey {
  MEMBER_ID = 'memberId',
  NAME = 'name',
  TOTAL_POINTS = 'totalPoints',
  VALID_PERIOD_TO = 'validPeriodTo',
  LAST_VALID_PERIOD_TO = 'lastValidPeriodTo',
  LOGIN_STATUS = 'loginStatus',
  LOGOUT_STATUS = 'logoutStatus',
  CUSTOMER_API_TOKEN = 'customerApiToken',
  CUSTOMER_API_TOKEN_TYPE = 'customerApiTokenType',
  CUSTOMER_API_REFRESH_TOKEN = 'customerApiRefreshToken',
  CUSTOMER_API_REFRESH_TOKEN_DATE = 'customerApiTokenDate',
  PREVIOUS_NO = 'previousNo',
  POPUP_STOP_LIST = 'popupStopList',
  REGISTER_COUPON_NO = 'registerCouponNo',
  NAME_SEPARATED = 'nameSeparated',
  SERVER_VERSION = 'serverVersion',
  INVOICE_FEE = 'invoieFee',
}

/** 【enum】 ログインステータス */
export enum LoginStatus {
  FIRST = 'first',
  FINISH = 'finish',
  RELOGIN = 'relogin',
  RELOGIN_WAIT = 'reloginWait',
}

/** 【enum】 ログアウトステータス */
export enum LogoutStatus {
  FIRST = 'first',
  FINISH = 'finish',
}

/** 【enum】 プレイスホルダー文 */
export enum Placeholder {
  MEMBER_ID = '1234123123123',
  MEMBER_ID_1 = '1234',
  MEMBER_ID_2_4 = '123',
  PREVIOUS_NO = '1234567',
  PREVIOUS_NO_1 = '1',
  PREVIOUS_NO_2_3 = '123456',
  REGISTER_COUPON = '12345678901234',
  NAME = '姓名',
  KANA_NAME = 'セイメイ',
  FIRST_NAME = '名',
  LAST_NAME = '姓',
  FIRST_KANA_NAME = 'メイ',
  LAST_KANA_NAME = 'セイ',
  POSTAL_CODE = '0001234',
  ADRDRESS_ALL = '都道府県、区市町村、番地、建物名',
  TEL = '00012345678',
  TEL_NO_HYPHEN = '00012345678',
  EMAIL = 'xxx@example.com',
  SCHOOL = '○○○高校',
}

/** 【enum】 クーポン種別 */
export enum CouponType {
  PAID_COUPON = 'PaidCoupon', //有償クーポン
  POINT_COUPON = 'PointCoupon', //ポイントクーポン
}

/** 【enum】 会員ランク種別 */
export enum MembersRankType {
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  DIAMOND = 'diamond',
}

/** 【enum】 会員更新ポップアップ通知 */
export enum RenewPopupType {
  start = 'renewStart',
  month = 'renew1month',
}

/** 【enum】 画像生成用HTMLのID */
export enum GenerateImageId {
  INVOICE = 'generateImageIdInvoice', //領収書
  SHARE_COUPON = 'generateImageIdShareCoupon', //クーポンシェア
}

/** 【enum】 ブランドカラー */
export enum BrandColors {
  line = '#00B900',
}

/** 【定数】 ページタイトル */
export const DOCUMENT_TITLE = `${process.env.REACT_APP_WEBSITE_NAME} - `;

/** 【定数】 日付の区切り文字 */
export const DATE_SEPARATOR = '/';

/** 【定数】 404ページから自動でトップページに戻す時間[ms] */
export const NOT_FOUND_TIMEOUT = 10000;

/** 【定数】 モーダル管理名 */
export const MODAL_IDENTIFIER_CLASS_NAME = 'smart-membership-modal-opened-';

/** 【定数】 オブジェクトの重なり調整 */
export const Z_INDEX = {
  context: 50,
  header: 100,
  mainMenu: 200,
  modal: 300,
  popup: 310,
  renewPopup: 390,
  error: 400,
  loading: 900,
};

/** 【定数】 退会準備待機時間[ms] */
export const DELETE_MEMBER_WAIT = 20000;

/** 【定数】 顔写真登録の最大サイズ[byte] */
export const FACE_IMAGE_MAX_SIZE = 10485760; //10MB

/** 【定数】 性別リスト */
export const SEX_VALUE_LIST: RadioButtonItemDataType[] = [
  { label: '男性', value: '男性' },
  { label: '女性', value: '女性' },
  { label: '回答しない', value: '回答しない' },
];

/** 【定数】 学生リスト */
export const STUDENT_VALUE_LIST: CheckBoxItemDataType[] = [
  { label: '高校生である', value: 'true' },
];

/** 【定数】 メルマガリスト */
export const MAIL_MAGAZINE_VALUE_LIST: CheckBoxItemDataType[] = [
  { label: 'メルマガを受信する', value: 'true' },
];

/** 【定数】 会員年齢区分リスト */
export const FEE_BILLING_VALUE_LIST: RadioButtonItemDataType[] = [
  { label: '大人 (中学生以上)', value: 'Adult' },
  { label: '子ども (小学生)', value: 'Child' },
  { label: '幼児 (3歳以上)', value: 'Infant' },
];

/** 【定数】 日本の消費税率 */
export const TAX_RATE = 0.1;

/** 【定数】 CSS基準値 */
export const CSS_DEFAULT_PARAMETER = {
  PC: {
    fontSizeLarge: 32,
    fontSizeMiddle: 24,
    fontSize: 20,
    fontSizeSmall: 16,
    fontSizeTiny: 12,
    iconSize: '28px',
    buttonWidth: '400px',
    panelWidth: '1200px',
    panelPadding: '16px 32px',
    modalWidth: '1120px',
    modalPadding: '24px',
    requiredFontSize: 16,
    requiredPadding: '0 4px 2px',
    requiredMargin: '0 8px 0 0',
    inputFileWidth: '640px',
    pageSelectorSize: 32,
  },
  SP: {
    fontSizeLarge: 28,
    fontSizeMiddle: 20,
    fontSize: 16,
    fontSizeSmall: 12,
    fontSizeTiny: 8,
    iconSize: '24px',
    buttonWidth: '100%',
    panelWidth: '100%',
    panelPadding: '16px 16px 24px',
    modalWidth: '100%',
    modalPadding: '16px',
    requiredFontSize: 12,
    requiredPadding: '0 4px 2px',
    requiredMargin: '0 4px 0 0',
    inputFileWidth: '100%',
    pageSelectorSize: 28,
  },
};

/**【定数】 PWAサポートOS一覧 */
export const PWA_SUPPORTED_OS = [
  'iOS 17',
  'iOS 16',
  'iOS 15',
  'iOS 14',
  'Android 13',
  'Android 12',
  'Android 11',
  'Android 10',
  'Android 9',
];

/**【定数】 PWAサポートブラウザ一覧 */
export const PWA_SUPPORTED_BROWSER = ['Safari', 'Chrome Mobile'];

/**【定数】 機能封印用変数 */
export const SEAL_FUNCTION = false;
