/**
 * クーポンパネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  MemberCouponListDataTypes,
  SEAL_FUNCTION,
  UrlPath,
} from 'commons';
import { Button, Image, Separator, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import {
  applyMemberCoupons,
  colorPaletteSelector,
  displaySizeSelector,
  EventSlice,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  ticketColor: string;
  width: string;
}
const Wrap = styled.div<WrapProps>`
  background-color: ${({ ticketColor }) => ticketColor};
  width: ${({ width }) => width};
  max-width: 100%;
`;

interface TicketWrapProps {
  colorPalette: ColorPalette;
}
const TicketWrap = styled.div<TicketWrapProps>`
  display: flex;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  border-radius: 12px;
  padding: 16px 0;
  margin: 16px 16px;
`;

const DataArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 16px - 16px);
`;

interface RightCircleProps {
  ticketColor: string;
}
const RightCircle = styled.div<RightCircleProps>`
  background-color: ${({ ticketColor }) => ticketColor};
  border-radius: 0 16px 16px 0;
  width: 16px;
  height: 32px;
`;

interface LeftCircleProps {
  ticketColor: string;
}
const LeftCircle = styled.div<LeftCircleProps>`
  background: ${({ ticketColor }) => ticketColor};
  border-radius: 16px 0 0 16px;
  width: 16px;
  height: 32px;
`;

const HeadIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 8px;
`;

interface ImageWrapProps {
  ticketColor: string;
  iconSize: string;
}
const ImageWrap = styled.div<ImageWrapProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ ticketColor }) => ticketColor};
  width: calc(${({ iconSize }) => iconSize} + 16px);
  height: calc(${({ iconSize }) => iconSize} + 16px);
  border-radius: calc(${({ iconSize }) => iconSize} + 16px);
`;

const ShareWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface CouponNameWrapProps {
  iconSize: string;
}
const CouponNameWrap = styled.div<CouponNameWrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - ${({ iconSize }) => iconSize} - 16px - 8px);
`;

const CouponButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 0 8px;
`;

interface TextWrapProps {
  colorPalette: ColorPalette;
}
const TextWrap = styled.div<TextWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.5)};
  border-radius: 8px;
  width: 100%;
  padding: 8px 0;
  margin: 8px 8px 0;
`;

const FontEffect = styled.span`
  line-height: 1;
  letter-spacing: 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface StockWrapProps {
  ticketColor: string;
}
const StockWrap = styled.div<StockWrapProps>`
  border: 3px solid ${({ ticketColor }) => ticketColor};
  border-radius: 8px;
  padding: 0 8px;
  margin: 0 8px 0;
  color: ${({ ticketColor }) => ticketColor};
`;

interface CouponPanelProps {
  memberCoupon?: MemberCouponListDataTypes;
  ticketColor: string;
  shareDisalbed?: boolean;
  appliableDisalbed?: boolean;
  stock?: number;
}
/**
 * 【有機体】 クーポンパネル
 * @param {MemberCouponListDataTypes} memberCoupon - クーポン情報
 * @param {string} ticketColor - 【必須】 クーポンカラー
 * @param {boolean} shareDisalbed - 共有無効
 * @param {boolean} appliableDisalbed - 利用する無効
 * @param {number} stock - 所有枚数
 * @returns コンポーネント
 */
export const CouponPanel: React.FC<CouponPanelProps> = ({
  memberCoupon,
  ticketColor,
  shareDisalbed = false,
  appliableDisalbed = false,
  stock,
}: CouponPanelProps) => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const newBorderDate = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate() - CustomerConsts.COUPON_NEW_DAYS);
    return today;
  }, []);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '16px 36px',
          separator: '16px',
          buttonPadding: '12px 16px',
          iconSize: '36px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '8px 24px',
          separator: '4px',
          buttonPadding: '8px 8px',
          iconSize: '20px',
        };
  }, [displaySize]);

  return (
    <Wrap ticketColor={ticketColor} width={css.panelWidth}>
      <TicketWrap colorPalette={colorPalette}>
        <RightCircle ticketColor={ticketColor} />
        <DataArea>
          <ShareWrap>
            <HeadIconWrap>
              {SEAL_FUNCTION &&
                new Date(memberCoupon?.exchangeDate || '').getTime() >
                  newBorderDate.getTime() && (
                  <Text
                    color={colorPalette.negative}
                    fontSize={css.fontSizeSmall}
                    textAlign="center"
                    margin="0 0 4px"
                    bold
                  >
                    NEW!
                  </Text>
                )}
              <ImageWrap ticketColor={ticketColor} iconSize={css.iconSize}>
                <Image
                  src="/svg/movie.svg"
                  width={css.iconSize}
                  height={css.iconSize}
                />
              </ImageWrap>
            </HeadIconWrap>
            <CouponNameWrap iconSize={css.iconSize}>
              <CouponButtonWrap>
                {stock && (
                  <StockWrap ticketColor={ticketColor}>
                    <Text fontSize={css.fontSizeSmall} letterSpacing={0} bold>
                      {stock}枚保有
                    </Text>
                  </StockWrap>
                )}
                <ButtonWrap>
                  {!appliableDisalbed && memberCoupon?.isAppliable && (
                    <Button
                      colorPalette={colorPalette}
                      color={colorPalette.positive}
                      fontSize={css.fontSizeSmall}
                      padding={css.buttonPadding}
                      margin="0 8px 0 0"
                      onClick={() => {
                        setOpened(true);
                      }}
                    >
                      <FontEffect>利用する</FontEffect>
                    </Button>
                  )}
                  {!shareDisalbed && memberCoupon?.isShareable && (
                    <Button
                      colorPalette={colorPalette}
                      fontSize={css.fontSizeSmall}
                      padding={css.buttonPadding}
                      margin="0 8px 0 0"
                      onClick={() => {
                        dispatch(
                          EventSlice.actions.changeCouponShareData({
                            email: '',
                            coupon: memberCoupon,
                            ticketColor: ticketColor,
                          }),
                        );
                        navigate(UrlPath.COUPON_SHARE);
                      }}
                    >
                      <FontEffect>シェアする</FontEffect>
                    </Button>
                  )}
                </ButtonWrap>
              </CouponButtonWrap>
              <Text
                fontSize={css.fontSize}
                textAlign="left"
                width="calc(100% - 16px)"
                margin="8px 0 0 8px"
                bold
              >
                {memberCoupon?.couponName}
              </Text>
            </CouponNameWrap>
          </ShareWrap>
          <TextWrap colorPalette={colorPalette}>
            <Text
              fontSize={css.fontSizeSmall}
              width="100%"
              padding="0 8px"
              letterSpacing={0}
            >
              獲得日時：
              <Separator width={css.separator} />
              {new Date(memberCoupon?.exchangeDate || '')
                .toLocaleString('ja-JP')
                .slice(0, -3)}
            </Text>
            <Text
              fontSize={css.fontSizeSmall}
              width="100%"
              padding="0 8px"
              letterSpacing={0}
            >
              有効期限：
              <Separator width={css.separator} />
              {memberCoupon?.validPeriod?.startingTime
                ? new Date(memberCoupon?.validPeriod?.startingTime)
                    .toLocaleString('ja-JP')
                    .slice(0, -3)
                : '―'}
              〜
              {memberCoupon?.validPeriod?.endingTime
                ? new Date(memberCoupon?.validPeriod?.endingTime)
                    .toLocaleString('ja-JP')
                    .slice(0, -3)
                : '―'}
            </Text>
            {memberCoupon?.description && (
              <Text
                fontSize={css.fontSizeSmall}
                padding="0 16px"
                letterSpacing={0}
              >
                <ReactMarkdown>{memberCoupon.description}</ReactMarkdown>
              </Text>
            )}
          </TextWrap>
        </DataArea>
        <LeftCircle ticketColor={ticketColor} />
      </TicketWrap>

      <Modal
        colorPalette={colorPalette}
        width={css.modalWidth}
        padding={css.modalPadding}
        opened={opened}
        headerTitle="クーポン利用確認"
        headerTitleSize={css.fontSize}
        onClose={() => {
          setOpened(false);
        }}
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold underline>
          {memberCoupon?.couponName}
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="16px 0 0">
          上記クーポンを利用します、よろしいですか？
        </Text>
        <Text
          color={colorPalette.negative}
          fontSize={css.fontSizeSmall}
          textAlign="center"
        >
          利用後に未利用に戻すことはできません。
          <br />
          劇場スタッフにお見せになった上で利用してください。
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 0"
          onClick={() => {
            setOpened(false);
            dispatch(
              applyMemberCoupons({
                transactionId: memberCoupon?.transactionId,
              }),
            );
          }}
        >
          利用する
        </Button>
        <Button
          colorPalette={colorPalette}
          color={colorPalette.negative}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="24px auto 16px"
          onClick={() => {
            setOpened(false);
          }}
        >
          利用しない
        </Button>
      </Modal>
    </Wrap>
  );
};
