/**
 * 文字の修飾
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface WrapProps {
  backgroundColor?: string;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  sharp?: boolean;
  underline?: boolean;
}
const Wrap = styled.span<WrapProps>`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ bold }) => bold && 'font-weight: bold;'}
  ${({ sharp }) => sharp && 'font-weight: 100;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
`;

interface EnhancementProps {
  children: ReactNode;
  backgroundColor?: string;
  fontSize?: number;
  color?: string;
  bold?: boolean;
  sharp?: boolean;
  underline?: boolean;
}
/**
 * 【原子】 文字の修飾
 * @param {ReactNode} children - 【必須】 子要素
 * @param {string} backgroundColor - 背景色
 * @param {number} fontSize - 文字サイズ [px]
 * @param {string} color - 文字色
 * @param {boolean} bold - 太字
 * @param {boolean} sharp - 細字
 * @param {boolean} underline - 下線
 * @returns コンポーネント
 */
export const Enhancement: React.FC<EnhancementProps> = ({
  children,
  backgroundColor,
  fontSize,
  color,
  bold,
  sharp,
  underline,
}: EnhancementProps) => {
  return (
    <Wrap
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      color={color}
      bold={bold}
      sharp={sharp}
      underline={underline}
    >
      {children}
    </Wrap>
  );
};
