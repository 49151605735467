/**
 * 鑑賞履歴パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  HistoryListDataTypes,
  UrlName,
  UrlPath,
} from 'commons';
import {
  Enhancement,
  GoogleFontsIcon,
  Link,
  Separator,
  Text,
} from 'components/atoms';
import { PageSelector, Panel } from 'components/molecules';
import React, { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  historyListSelector,
  loadingMessageSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

const HistoryArea = styled.div`
  display: flex;
`;

interface PosterImageProps {
  imageSrc: string;
  width: number;
}
const PosterImage = styled.div<PosterImageProps>`
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: ${({ width }) => width}px;
`;

interface DetailProps {
  detailPadding: number;
  width: string;
}
const Detail = styled.div<DetailProps>`
  width: ${({ width }) => width};
  padding: 0 0 0 ${({ detailPadding }) => detailPadding}px;
`;

const TicketLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px 0 0;
`;

interface HeaderPageSelectorWrapProps {
  width: string;
}
const HeaderPageSelectorWrap = styled.div<HeaderPageSelectorWrapProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${({ width }) => width};
  margin: 0 0 16px;
`;

const FooterPageSelectorWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 32px;
`;

/**
 * 【有機体】 鑑賞履歴パネル
 * @returns コンポーネント
 */
export const HistoryPanel: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const historyList = useTypeSelector(historyListSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const [selectPage, setSelectPage] = useState<number>(1);
  const maxPage = useMemo(() => {
    return Math.ceil(historyList.length / CustomerConsts.HISTORY_LENGTH);
  }, [historyList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imgWidth: 240,
          detailPadding: 60,
          titleMargin: '16px 0 0',
          valueMargin: '4px 0 0 16px',
          separator: '16px',
          pageSelectorWidth: '320px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imgWidth: 96,
          detailPadding: 16,
          titleMargin: '4px 0 0',
          valueMargin: '0 0 0 16px',
          separator: '8px',
          pageSelectorWidth: '256px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      {historyList.length !== 0 && (
        <HeaderPageSelectorWrap width={css.panelWidth}>
          <Text fontSize={css.fontSizeSmall} lineHeight={1} letterSpacing={0}>
            {(
              CustomerConsts.HISTORY_LENGTH * (selectPage - 1) +
              1
            ).toLocaleString()}
            〜
            {selectPage === maxPage
              ? historyList.length.toLocaleString('ja-JP')
              : (CustomerConsts.HISTORY_LENGTH * selectPage).toLocaleString(
                  'ja-JP',
                )}
            件を表示
          </Text>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </HeaderPageSelectorWrap>
      )}
      {loadingMessage === '' && historyList.length === 0 && (
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
          margin="0 0 24px"
        >
          <Text fontSize={css.fontSize} textAlign="center">
            鑑賞履歴はありません
          </Text>
        </Panel>
      )}
      {historyList.map((data: HistoryListDataTypes, index) => {
        const hiddenDate = data.eventDate
          ? new Date(data.eventDate)
          : new Date('2000/01/01');
        hiddenDate.setDate(hiddenDate.getDate() + 1);
        const nowDate = new Date();
        const confirmUrl = data.confirmUrl || '';
        if (
          index >= CustomerConsts.HISTORY_LENGTH * (selectPage - 1) &&
          index < CustomerConsts.HISTORY_LENGTH * selectPage
        ) {
          return (
            <Panel
              key={`${index}${data.eventDate}`}
              colorPalette={colorPalette}
              width={css.panelWidth}
              margin="0 0 24px"
            >
              <HistoryArea>
                <PosterImage
                  imageSrc={
                    data.title?.thumbnailUrl || '/startheaters/noimage.jpg'
                  }
                  width={css.imgWidth}
                />
                <Detail
                  width={`calc(100% - ${css.imgWidth}px)`}
                  detailPadding={css.detailPadding}
                >
                  <Text fontSize={css.fontSizeSmall}>
                    <Enhancement>鑑賞日時</Enhancement>
                    <Separator width={css.separator} />
                    {data.eventDate
                      ? new Date(data.eventDate)
                          .toLocaleString('ja-JP')
                          .slice(0, -3)
                      : '―'}
                  </Text>
                  <Text fontSize={css.fontSizeMiddle} lineHeight={1.25} bold>
                    {data.title?.name || ''}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    有効劇場
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.location || ''}
                    <Separator width={css.separator} />
                    {data.area || ''}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    チケット情報
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.ticketInfo?.map((data) => (
                      <Fragment key={`${index}-${data.code}`}>
                        {data.name}
                        <Separator width={css.separator} />
                      </Fragment>
                    ))}
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    margin={css.titleMargin}
                    bold
                  >
                    座席情報
                  </Text>
                  <Text
                    fontSize={css.fontSizeSmall}
                    lineHeight={1.25}
                    margin={css.valueMargin}
                  >
                    {data.seatInfo?.map((data) => (
                      <Fragment key={`${index}-${data.code}`}>
                        {data.code}
                        <Separator width={css.separator} />
                      </Fragment>
                    ))}
                  </Text>
                </Detail>
              </HistoryArea>
              {confirmUrl && hiddenDate.getTime() > nowDate.getTime() && (
                <TicketLink>
                  <GoogleFontsIcon
                    name="confirmation_number"
                    color={colorPalette.concept}
                    size={css.fontSizeLarge}
                  />
                  <Separator width="8px" />
                  <Link
                    colorPalette={colorPalette}
                    forNewTab
                    href={confirmUrl}
                    fontSize={css.fontSize}
                  >
                    入場チケットを表示
                  </Link>
                </TicketLink>
              )}
            </Panel>
          );
        }
      })}
      {historyList.length !== 0 && (
        <FooterPageSelectorWrap>
          <PageSelector
            colorPalette={colorPalette}
            width={css.pageSelectorWidth}
            maxPage={maxPage}
            selectPage={selectPage}
            size={css.pageSelectorSize}
            onClick={(number) => {
              setSelectPage(number);
            }}
          />
        </FooterPageSelectorWrap>
      )}
      <Link
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          navigate(UrlPath.CARD);
        }}
      >
        {UrlName.CARD}画面に戻る
      </Link>
    </Wrap>
  );
};
