/**
 * デジタル会員登録確認エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ContentsBlock } from 'components/organisms';
import { RegisterConfirmPanel } from 'components/organisms/koronaworld';
import React from 'react';

/**
 * 【テンプレート】 デジタル会員登録確認エリア
 * @returns コンポーネント
 */
export const DigitalRegisterConfirmArea: React.FC = () => {
  return (
    <ContentsBlock>
      <RegisterConfirmPanel />
    </ContentsBlock>
  );
};
