/**
 * APIエラーモーダル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  LocalStorageKey,
  LoginStatus,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Link, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PageSlice,
  colorPaletteSelector,
  displaySizeSelector,
  pageErrorMessageSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * 【有機体】 APIエラーモーダル
 * @returns コンポーネント
 */
export const ApiErrorModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const pageErrorMessage = useTypeSelector(pageErrorMessageSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, modalWidth: '600px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Modal
      colorPalette={colorPalette}
      zIndex={Z_INDEX.error}
      headerTitle="通信エラー"
      headerTitleSize={css.fontSize}
      width={css.modalWidth}
      padding={css.modalPadding}
      opened={pageErrorMessage === '' ? false : true}
      disabledCloseButton={true}
    >
      <Wrap>
        {pageErrorMessage && (
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSize}
            textAlign="center"
          >
            {pageErrorMessage}
          </Text>
        )}
        <Text fontSize={css.fontSize} textAlign="center" margin="16px 0 16px">
          下記リンクより
          <br />
          ページの再読込を実行してください。
        </Text>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          bold
          onClick={() => {
            dispatch(PageSlice.actions.changePageErrorMessage(''));
            const currentUrl = location.origin + location.pathname;
            window.location.href = currentUrl.includes('?')
              ? `${currentUrl}&reload=${new Date().getTime()}`
              : `${currentUrl}?reload=${new Date().getTime()}`;
          }}
        >
          再読込を実行
        </Link>
        <Text fontSize={css.fontSize} textAlign="center" margin="24px 0 16px">
          再読込後もうまくいかない場合
          <br />
          下記リンクより再度ログイン後にお試しください。
        </Text>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          bold
          onClick={() => {
            dispatch(PageSlice.actions.changePageErrorMessage(''));
            localStorage.setItem(
              LocalStorageKey.LOGIN_STATUS,
              LoginStatus.RELOGIN,
            );
            navigate(UrlPath.LOGOUT);
          }}
        >
          ログインページへ
        </Link>
        <Text fontSize={css.fontSize} textAlign="center" margin="24px 0 16px">
          再度ログインしてもうまくいかない場合
          <br />
          しばらく時間をあけてからお試しください。
        </Text>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          bold
          href={process.env.REACT_APP_PORTAL_URI}
        >
          {process.env.REACT_APP_PORTAL_NAME}
        </Link>
      </Wrap>
    </Modal>
  );
};
