/**
 * 物理カード会員確認エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Modal } from 'components/molecules';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  text-align: center;
`;

/**
 * 【テンプレート】 物理カード会員確認エリア
 * @returns コンポーネント
 */
export const PhysicsCardConfirmArea: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, modalWidth: '600px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Modal
        colorPalette={colorPalette}
        opened={true}
        width={css.modalWidth}
        padding={css.modalPadding}
        zIndex={Z_INDEX.modal}
        disabledCloseButton
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
          カード所持確認
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="16px 0">
          劇場購入の会員カードをお持ちですか？
        </Text>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="16px auto 24px"
          onClick={() => {
            navigate(UrlPath.PHYSICS_CARD_REGISTER);
          }}
        >
          持っている
        </Button>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="16px auto"
          onClick={() => {
            navigate(UrlPath.DIGITAL_REGISTER);
          }}
        >
          持っていない
        </Button>
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => {
            navigate(UrlPath.MIGRATION_CHECKER);
          }}
        >
          {UrlName.MIGRATION_CHECKER}画面に戻る
        </Link>
      </Modal>
    </Wrap>
  );
};
