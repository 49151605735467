/**
 * ポイント表示パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlPath } from 'commons';
import { Button, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const PointWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

interface PointPanelProps {
  margin?: string;
}
/**
 * 【有機体】 ポイント表示パネル
 * @param {string} margin - マージン
 * @returns コンポーネント
 */
export const PointPanel: React.FC<PointPanelProps> = ({ margin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Panel colorPalette={colorPalette} width={css.panelWidth} margin={margin}>
      <Text fontSize={css.fontSize} textAlign="center" bold>
        現在保有ポイント
      </Text>
      <PointWrap>
        <Text color={colorPalette.concept} fontSize={css.fontSizeLarge} bold>
          {membershipData.totalPoints?.toLocaleString('ja-JP') || '―'}
        </Text>
        <Text fontSize={css.fontSize} bold>
          pt
        </Text>
      </PointWrap>
      <Text fontSize={css.fontSizeSmall} textAlign="center">
        保有ポイントをお得なクーポンと交換できます
      </Text>
      {location.pathname !== UrlPath.POINT && (
        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          margin="8px auto"
          onClick={() => navigate(UrlPath.POINT)}
        >
          ポイントを交換
        </Button>
      )}
    </Panel>
  );
};
