/**
 * 獲得済みクーポン表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  MemberCouponListDataTypes,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  CouponPanel,
  DirectionMessagePanel,
  MessageModal,
  PointPanel,
} from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  loadingMessageSelector,
  memberCouponListSelector,
  membershipDataSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 16px;
  width: 100%;
  max-width: calc(100% - 32px);
`;

/**
 * 【テンプレート】 獲得済みクーポン表示エリア
 * @returns コンポーネント
 */
export const CouponArea: React.FC = () => {
  const navigate = useNavigate();
  const membershipData = useTypeSelector(membershipDataSelector);
  const memberCouponList = useTypeSelector(memberCouponListSelector);
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const stockCouponList: {
    coupon: MemberCouponListDataTypes;
    stock: number;
  }[] = useMemo(() => {
    const okCouponList: MemberCouponListDataTypes[] = [];
    memberCouponList.forEach((data) => {
      if (data.couponStatus === 'OK' || data.couponStatus === 'BeforeExpiry') {
        okCouponList.push(data);
      }
    });
    const result: { coupon: MemberCouponListDataTypes; stock: number }[] = [];
    okCouponList.forEach((data) => {
      for (let i = 0; i < result.length; i += 1) {
        if (
          result[i].coupon.couponId === data.couponId &&
          result[i].coupon.validPeriod?.startingTime ===
            data.validPeriod?.startingTime &&
          result[i].coupon.validPeriod?.endingTime ===
            data.validPeriod?.endingTime
        ) {
          result[i].stock += 1;
          return;
        }
      }
      result.push({ coupon: data, stock: 1 });
    });
    return result;
  }, [memberCouponList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, buttonWidth: '800px' }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.COUPON}</ContentsTitle>
      <ContentsWrap>
        <DirectionMessagePanel>
          こちらでは利用可能クーポンをご確認いただけます。 <br />
          ご利用の際は、
          {membershipData.link
            ? membershipData.attributes?.theater + '公式ページ'
            : process.env.REACT_APP_PORTAL_NAME}
          (チケット購入)から映画を選んでいただき 、
          <br />
          チケット選択時に所有しているクーポンが表示されますのでご利用ください。
        </DirectionMessagePanel>
        <Button
          colorPalette={colorPalette}
          margin="24px auto 24px"
          width={css.buttonWidth}
          fontSize={css.fontSize}
          onClick={() => {
            if (membershipData.link) {
              window.open(membershipData.link);
            } else if (process.env.REACT_APP_PORTAL_URI) {
              window.open(process.env.REACT_APP_PORTAL_URI);
            } else {
              navigate(UrlPath.ENV_ERROR);
            }
          }}
        >
          {membershipData.link
            ? membershipData.attributes?.theater + '公式ページ'
            : process.env.REACT_APP_PORTAL_NAME}
          <br />
          (チケット購入)
        </Button>
      </ContentsWrap>

      {loadingMessage === '' && memberCouponList.length === 0 && (
        <ContentsWrap>
          <Panel
            colorPalette={colorPalette}
            width={css.panelWidth}
            padding={css.panelPadding}
            margin="24px 0 0"
          >
            <Text fontSize={css.fontSize} textAlign="center">
              クーポンはありません
            </Text>
          </Panel>
        </ContentsWrap>
      )}
      {stockCouponList.map((data, index) => (
        <CouponPanel
          key={`${data.coupon.couponId}${data.coupon.exchangeDate}`}
          memberCoupon={data.coupon}
          ticketColor={colorPalette.repeat[index % 4]}
          stock={data.stock}
        />
      ))}
      <ContentsWrap>
        {membershipData.couponMarketOpen && (
          <Button
            colorPalette={colorPalette}
            width={css.buttonWidth}
            margin="16px auto 24px"
            onClick={() => {
              navigate(UrlPath.COUPON_MARKET);
            }}
          >
            クーポンを購入
          </Button>
        )}
        <PointPanel margin="16px 0 0" />
      </ContentsWrap>
      <Link
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          navigate(UrlPath.CARD);
        }}
      >
        {UrlName.CARD}画面に戻る
      </Link>
      <MessageModal
        onClose={() => {
          location.reload();
        }}
      />
    </ContentsBlock>
  );
};
