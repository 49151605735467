/**
 * ブラウザバックがされた時に特定ページに飛ばす
 */
import { UrlPath } from 'commons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface BrowserBackRedirectProps {
  urlPath: UrlPath;
}
/**
 * 【特殊コンポーネント】 ブラウザバックがされた時に特定ページに飛ばす
 */
export const BrowserBackRedirect: React.FC<BrowserBackRedirectProps> = ({
  urlPath,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = () => {
      navigate(urlPath);
    };
    history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', redirect);
    return () => {
      window.removeEventListener('popstate', redirect);
    };
  }, []);

  return null;
};
