/**
 * クーポンシェア完了ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, useDidMount } from 'commons';
import { Contents, CouponShareCompleteArea } from 'components/templates';
import { Header, PointHeader } from 'components/templates/koronaworld';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { didMountCouponShareCompletePage, useTypeDispatch } from 'stores';

/**
 * 【ページ】 クーポンシェア完了ページ
 * @returns コンポーネント
 */
export const CouponShareComplete: React.FC = () => {
  const dispatch = useTypeDispatch();
  const queryParameter = new URLSearchParams(useLocation().search);

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.COUPON_SHARE_COMPLETE;
    dispatch(didMountCouponShareCompletePage());
  });

  return (
    <>
      <Header disabledMenu />
      <PointHeader />
      <Contents>
        <CouponShareCompleteArea
          couponName={queryParameter.get('coupon_name') || ''}
          couponNo={queryParameter.get('coupon_no') || ''}
          pinCode={queryParameter.get('pin_code') || ''}
          startingTime={queryParameter.get('startingTime') || ''}
          endingTime={queryParameter.get('endingTime') || ''}
          description={queryParameter.get('description') || ''}
        />
      </Contents>
    </>
  );
};
