/**
 * 領収書パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  calculateTaxExcludedPrice,
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  GenerateImageId,
  TAX_RATE,
} from 'commons';
import { Enhancement, Separator, Text } from 'components/atoms';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  eventServerTimeSelector,
  invoiceDataSelector,
  invoiceFeeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  padding: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  width: 100%;
  padding: ${({ padding }) => padding};
  border: 2px solid ${({ colorPalette }) => colorPalette.grayTone};
`;

interface InvoiceHeaderProps {
  margin: string;
}
const InvoiceHeader = styled.div<InvoiceHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: ${({ margin }) => margin};
`;

interface InvoiceAddresseeProps {
  colorPalette: ColorPalette;
  margin: string;
}
const InvoiceAddressee = styled.div<InvoiceAddresseeProps>`
  width: 50%;
  margin: ${({ margin }) => margin};
  border-bottom: 1px solid ${({ colorPalette }) => colorPalette.darkTone};
`;

interface InvoicePriceProps {
  colorPalette: ColorPalette;
  margin: string;
  padding: string;
}
const InvoicePrice = styled.div<InvoicePriceProps>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border: 1px solid ${({ colorPalette }) => colorPalette.darkTone};
`;

interface InvoiceProvisoProps {
  colorPalette: ColorPalette;
  margin: string;
}
const InvoiceProviso = styled.div<InvoiceProvisoProps>`
  width: 100%;
  margin: ${({ margin }) => margin};
  border-bottom: 1px solid ${({ colorPalette }) => colorPalette.darkTone};
`;

const InvoiceText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

interface InvoiceBorderProps {
  colorPalette: ColorPalette;
  margin: string;
}
const InvoiceBorder = styled.div<InvoiceBorderProps>`
  width: 100%;
  margin: ${({ margin }) => margin};
  border-top: 1px solid ${({ colorPalette }) => colorPalette.darkTone};
`;

/**
 * 【有機体】 領収書パネル
 * @returns コンポーネント
 */
export const InvoicePanel: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const eventServerTime = useTypeSelector(eventServerTimeSelector);
  const invoiceData = useTypeSelector(invoiceDataSelector);
  const invoiceFee = useTypeSelector(invoiceFeeSelector);
  const { taxExcludedPrice, tax } = useMemo(() => {
    return calculateTaxExcludedPrice(parseInt(invoiceFee, 10), TAX_RATE);
  }, [invoiceFee]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          imagePadding: '16px 32px',
          invoiceMargin: '0 0 24px',
          borderMargin: '16px 0',
          buttonMargin: '0 16px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          imagePadding: '8px 12px',
          invoiceMargin: '0 0 16px',
          borderMargin: '8px 0',
        };
  }, [displaySize]);

  return (
    <Wrap
      colorPalette={colorPalette}
      padding={css.imagePadding}
      id={GenerateImageId.INVOICE}
    >
      <InvoiceHeader margin={css.invoiceMargin}>
        <Text fontSize={css.fontSizeLarge}>領収書</Text>
        <Text fontSize={css.fontSize}>
          {eventServerTime
            ? new Date(eventServerTime).toLocaleDateString('ja-JP')
            : new Date().toLocaleDateString('ja-JP')}
        </Text>
      </InvoiceHeader>
      <InvoiceAddressee colorPalette={colorPalette} margin={css.invoiceMargin}>
        <Text fontSize={css.fontSizeMiddle} textAlign="right">
          様
        </Text>
      </InvoiceAddressee>
      <InvoicePrice
        colorPalette={colorPalette}
        padding={css.imagePadding}
        margin={css.invoiceMargin}
      >
        <Text fontSize={css.fontSizeMiddle}>金額</Text>
        <Text fontSize={css.fontSizeLarge}>
          <Enhancement bold>
            {invoiceFee
              ? `￥ ${parseInt(invoiceFee, 10).toLocaleString('ja-JP')} - `
              : '￥ ― '}
          </Enhancement>
          <Enhancement fontSize={css.fontSize}>(税込)</Enhancement>
        </Text>
        <div />
      </InvoicePrice>
      <InvoiceProviso colorPalette={colorPalette} margin={css.invoiceMargin}>
        <Text fontSize={css.fontSize}>
          但し
          <Separator width="24px" />
          <Enhancement fontSize={css.fontSizeMiddle}>
            {invoiceData.description}
          </Enhancement>
        </Text>
      </InvoiceProviso>
      <InvoiceText>
        <Text fontSize={css.fontSize} bold>
          内訳
        </Text>
      </InvoiceText>
      <InvoiceText>
        <Text fontSize={css.fontSize}>税率 {TAX_RATE * 100}%</Text>
      </InvoiceText>
      <InvoiceText>
        <Text fontSize={css.fontSize}>税抜金額</Text>
        <Text fontSize={css.fontSize}>
          {taxExcludedPrice
            ? `￥ ${taxExcludedPrice.toLocaleString('ja-JP')}`
            : '￥ ―'}
        </Text>
      </InvoiceText>
      <InvoiceText>
        <Text fontSize={css.fontSize}>消費税額</Text>
        <Text fontSize={css.fontSize}>
          {taxExcludedPrice ? `￥ ${tax.toLocaleString('ja-JP')}` : '￥ ―'}
        </Text>
      </InvoiceText>
      <InvoiceBorder colorPalette={colorPalette} margin={css.borderMargin} />
      <InvoiceText>
        <Text fontSize={css.fontSize}>
          上記⾦額正に領収致しました。
          <br />
          {invoiceData.companyName} / 登録番号
          {invoiceData.no}
          <br />
          決済⽅法: クレジットカード
        </Text>
      </InvoiceText>
      {invoiceData.receiptText && (
        <>
          <InvoiceBorder
            colorPalette={colorPalette}
            margin={css.borderMargin}
          />
          <InvoiceText>
            <Text fontSize={css.fontSize}>{invoiceData.receiptText}</Text>
          </InvoiceText>
        </>
      )}
      <InvoiceBorder colorPalette={colorPalette} margin={css.borderMargin} />
      <InvoiceText>
        <Text fontSize={css.fontSize}>
          {invoiceData.theater}
          <br />
          {invoiceData.receiptAddress}
        </Text>
      </InvoiceText>
    </Wrap>
  );
};
