/**
 * ログイン状態確認ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { Buffer } from 'buffer';
import { DOCUMENT_TITLE, LocalStorageKey, UrlName, useDidMount } from 'commons';
import React from 'react';
import { postCustomerRefreshToken } from 'commons';

/**
 * 【ページ】 ログイン状態確認ページ
 * @returns コンポーネント
 */
export const SignInStatus: React.FC = () => {
  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.SIGN_IN_STATUS;
    const origin = '*';
    const memberId = localStorage.getItem(LocalStorageKey.MEMBER_ID);
    const customerApiToken = localStorage.getItem(
      LocalStorageKey.CUSTOMER_API_TOKEN,
    );
    const customerApiTokenType = localStorage.getItem(
      LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
    );
    const name = localStorage.getItem(LocalStorageKey.NAME);
    const totalPoints = localStorage.getItem(LocalStorageKey.TOTAL_POINTS);
    const tokenSections = customerApiToken
      ? customerApiToken.split('.')
      : undefined;
    const payload =
      tokenSections &&
      JSON.parse(Buffer.from(tokenSections[1], 'base64').toString('utf8'));
    const receiveMessage = function (event: { data: { type: string } }) {
      if (event.data.type === 'IS_LOGINED') {
        // ログイン済みかどうかをtrue,falseで返す
        window.parent.postMessage(
          {
            type: 'IS_LOGINED',
            value: memberId ? true : false,
          },
          origin,
        );
      } else if (event.data.type === 'GET_USER_INFO') {
        // ユーザーIDを返す
        // ログインしていない場合はnull
        window.parent.postMessage(
          {
            type: 'GET_USER_INFO',
            value: {
              userId: memberId || null,
              typeOf: process.env.REACT_APP_TICKET_TYPE,
              identifier: memberId ? memberId.substring(0, 14) : null,
              accessCode: null,
              sub: payload ? payload.sub : null,
              name: name ? name : null,
              totalPoints: totalPoints ? totalPoints : null,
              accessToken: customerApiToken,
              accessTokenType: customerApiTokenType,
            },
          },
          origin,
        );
      } else if (event.data.type === 'REFRESH') {
        postCustomerRefreshToken().then(() => {
          const customerApiToken = localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN,
          );
          const customerApiTokenType = localStorage.getItem(
            LocalStorageKey.CUSTOMER_API_TOKEN_TYPE,
          );
          window.parent.postMessage(
            {
              type: 'REFRESH',
              value: {
                accessToken: customerApiToken,
                accessTokenType: customerApiTokenType,
              },
            },
            origin,
          );
        });
      }
    };
    window.addEventListener('message', receiveMessage, false);
    window.parent.postMessage({ type: 'INITIALIZED' }, origin);
  });

  return null;
};
