/**
 * 404警告エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, NOT_FOUND_TIMEOUT } from 'commons';
import { GoogleFontsIcon, Text } from 'components/atoms';
import React, { useMemo } from 'react';
import { displaySizeSelector, useTypeSelector } from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;
`;

/**
 * 【テンプレート】 404警告エリア
 * @returns コンポーネント
 */
export const NotFoundArea: React.FC = () => {
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Message>
        <GoogleFontsIcon
          name="highlight_off"
          size={css.fontSize}
          margin="0 16px 0 0"
        />
        <Text fontSize={css.fontSize}>該当URLのページは存在しません</Text>
      </Message>
      <Message>
        <Text fontSize={css.fontSizeSmall}>
          {NOT_FOUND_TIMEOUT / 1000}秒後にトップページに戻ります
        </Text>
      </Message>
    </Wrap>
  );
};
