/**
 * 会員紹介入会エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  LocalStorageKey,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Enhancement, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import {
  ContentsBlock,
  ContentsTitle,
  DirectionMessagePanel,
} from 'components/organisms';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 16px;
  width: 100%;
  max-width: calc(100% - 32px);
`;

interface CampaignInvitationAreaProps {
  couponCode: string;
}
/**
 * 【テンプレート】 会員紹介入会エリア
 * @param {string} couponCode - 使用するクーポン番号
 * @returns コンポーネント
 */
export const CampaignInvitationArea: React.FC<CampaignInvitationAreaProps> = ({
  couponCode,
}: CampaignInvitationAreaProps) => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          panelMargin: '32px 0 0',
          textMargin: '16px 0 0',
          buttonMargin: '16px auto 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          panelMargin: '24px 0 0',
          textMargin: '16px 0 0',
          buttonMargin: '16px auto 0',
        };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.CAMPAING_INVITATION}</ContentsTitle>
      <ContentsWrap>
        <DirectionMessagePanel>
          当ページは招待されたお客様ごとに専用となっております。
          <br />
          このため
          <Enhancement color={colorPalette.negative} underline bold>
            URLを他の方に共有(SNSに投稿する等)すると特典を受けられなくなります
          </Enhancement>
          のでご注意ください。
        </DirectionMessagePanel>
        <Panel
          colorPalette={colorPalette}
          width={css.panelWidth}
          padding={css.panelPadding}
          margin={css.panelMargin}
        >
          <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
            会員入会のご希望ありがとうございます
          </Text>
          <Text fontSize={css.fontSize} margin={css.textMargin}>
            このページの『新規登録』ボタンより新規会員登録にお進みいただくと
            <Enhancement color={colorPalette.negative} bold>
              無料
            </Enhancement>
            にてご登録いただけます。
          </Text>
          <Button
            colorPalette={colorPalette}
            width={css.buttonWidth}
            margin={css.buttonMargin}
            onClick={() => {
              localStorage.setItem(
                LocalStorageKey.REGISTER_COUPON_NO,
                couponCode,
              );
              if (process.env.REACT_APP_REGISTER_URI) {
                window.location.href =
                  process.env.REACT_APP_REGISTER_URI + location.origin;
              } else {
                navigate(UrlPath.ENV_ERROR);
              }
            }}
          >
            新規登録
          </Button>
          <Text fontSize={css.fontSize} margin={css.textMargin}>
            メールアドレス登録後、途中で中断された場合は、メールに記載のURLからこの画面にお戻りいただき、こちらの
            <Link
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              onClick={() => {
                localStorage.setItem(
                  LocalStorageKey.REGISTER_COUPON_NO,
                  couponCode,
                );
                if (process.env.REACT_APP_CUSTOMER_API_LOGIN_URI) {
                  window.location.href =
                    process.env.REACT_APP_CUSTOMER_API_LOGIN_URI +
                    location.origin;
                } else {
                  navigate(UrlPath.ENV_ERROR);
                }
              }}
              bold
            >
              [ログイン]
            </Link>
            から再開ください。
          </Text>
        </Panel>
      </ContentsWrap>
      <Link
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        href={process.env.REACT_APP_PORTAL_URI}
      >
        {process.env.REACT_APP_PORTAL_NAME}
      </Link>
    </ContentsBlock>
  );
};
