/**
 * ページ選択ボタンリスト
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import { GoogleFontsIcon } from 'components/atoms';
import React, { useCallback, useMemo } from 'react';
import { BasicSlice, useTypeDispatch } from 'stores';
import styled from 'styled-components';

interface WrapProps {
  width: string;
}
const Wrap = styled.div<WrapProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width};
`;

interface PageButtonProps {
  colorPalette: ColorPalette;
  size: number;
  displayed?: boolean;
  selected?: boolean;
}
const PageButton = styled.button<PageButtonProps>`
  display: ${({ displayed }) => (displayed ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  padding: 0 0 ${({ size }) => Math.floor(size * 0.1)}px;
  margin: 0;
  border: 0;
  border-radius: 100%;
  font-size: ${({ size }) => size - 16}px;
  line-height: 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: ${({ colorPalette }) => colorPalette.concept};
    color: ${({ colorPalette }) => colorPalette.lightTone};
  }

  &:active {
    background-color: ${({ colorPalette }) => colorPalette.concept};
    color: ${({ colorPalette }) => colorPalette.lightTone};
    transition: none;
  }

  &:disabled {
    background-color: ${({ colorPalette }) => colorPalette.grayTone};
    color: ${({ colorPalette }) => colorPalette.lightTone};
    pointer-events: none;
  }

  ${({ selected, colorPalette }) =>
    selected &&
    `
      background-color: ${colorPalette.conceptLight};
      color: ${colorPalette.darkTone};
      pointer-events: none;
    `}
`;

interface PageSelectorProps {
  colorPalette: ColorPalette;
  width: string;
  maxPage: number;
  selectPage: number;
  size: number;
  onClick?: (page: number) => void;
}
/**
 * 【分子】 ページ選択ボタンリスト
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {string} width - 【必須】 表示幅
 * @param {number} maxPage - 【必須】 最大ページ数
 * @param {number} selectPage - 【必須】 選択中のページ番号
 * @param {number} size - 【必須】 ボタンサイズ
 * @param {(page: number) => void} onClick - ボタンクリックイベント
 * @returns コンポーネント
 */
export const PageSelector: React.FC<PageSelectorProps> = ({
  colorPalette,
  width,
  maxPage,
  selectPage,
  size,
  onClick,
}: PageSelectorProps) => {
  const dispatch = useTypeDispatch();
  const pageNumber = useMemo(() => {
    const result: number[] = [];
    for (let i = 1; i <= maxPage; i += 1) {
      result.push(i);
    }
    return result;
  }, [maxPage]);
  const loadingEvent = useCallback(() => {
    dispatch(BasicSlice.actions.changeLoadingMessage('ページ切り替え中'));
    window.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(BasicSlice.actions.changeLoadingMessage(''));
    }, 500);
  }, []);

  return (
    <Wrap width={width}>
      <PageButton
        colorPalette={colorPalette}
        size={size}
        disabled={selectPage === 1}
        onClick={() => {
          loadingEvent();
          onClick && onClick(selectPage - 1);
        }}
        displayed
      >
        <GoogleFontsIcon name="chevron_left" size={size} />
      </PageButton>
      {pageNumber.map((number) => (
        <PageButton
          key={number}
          colorPalette={colorPalette}
          size={size}
          selected={number === selectPage}
          displayed={
            (number >= selectPage - 2 || number >= maxPage - 4) &&
            (number <= selectPage + 2 || number <= 5)
          }
          onClick={() => {
            loadingEvent();
            onClick && onClick(number);
          }}
        >
          {number}
        </PageButton>
      ))}
      <PageButton
        colorPalette={colorPalette}
        size={size}
        disabled={selectPage === maxPage}
        onClick={() => {
          loadingEvent();
          onClick && onClick(selectPage + 1);
        }}
        displayed
      >
        <GoogleFontsIcon name="chevron_right" size={size} />
      </PageButton>
    </Wrap>
  );
};
