/**
 * パネル
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では基本的に TypeScript は組み込まず、必要な値は props で受け取る
 */
import { ColorPalette } from 'commons';
import { GoogleFontsIcon, Separator, Text } from 'components/atoms';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  position?: string;
  backgroundColor?: string;
  width?: string;
  margin?: string;
  onClick?: () => void;
}
const Wrap = styled.div<WrapProps>`
  ${({ position }) => position && `position: ${position};`}
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor, colorPalette }) =>
    backgroundColor || colorPalette.lightTone};
  border-radius: 16px;
  max-width: 100%;
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ onClick }) => onClick && 'cursor: pointer;'}

  &:hover {
    ${({ onClick, colorPalette }) =>
      onClick && `background-color: ${colorPalette.conceptLight}`}
  }
`;

interface HeaderProps {
  colorPalette: ColorPalette;
}
const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px;
  background-color: ${({ colorPalette }) => colorPalette.concept};
  border-radius: 16px 16px 0 0;
  color: ${({ colorPalette }) => colorPalette.lightTone};
  transition: 0.3s ease-in-out;
`;

interface ContentsProps {
  padding?: string;
}
const Contents = styled.div<ContentsProps>`
  width: 100%;
  padding: ${({ padding }) => padding || '16px'};
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface PanelProps {
  children?: ReactNode;
  colorPalette: ColorPalette;
  position?: string;
  backgroundColor?: string;
  headerTitle?: string;
  headerTitleSize?: number;
  headerIconName?: string;
  headerIconSize?: number;
  width?: string;
  margin?: string;
  padding?: string;
  onClick?: () => void;
}
/**
 * 【分子】 パネル
 * @param {ReactNode} children - 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {string} position - CSSのposition設定
 * @param {string} backgroundColor - パネル背景色
 * @param {string} headerTitle - ヘッダー文
 * @param {number} headerTitleSize - ヘッダー文サイズ [px] (初期値:16)
 * @param {string} headerIconName - GoogleFontsアイコン名
 * @ https://fonts.google.com/icons?selected=Material+Icons
 * @param {number} headerIconSize - アイコンサイズ [px] (初期値:24)
 * @param {string} width - 幅
 * @param {string} margin - マージン
 * @param {string} padding - パディング
 * @param {() => void} onClick - パネルをクリックしたイベント
 * @returns コンポーネント
 */
export const Panel: React.FC<PanelProps> = ({
  children,
  colorPalette,
  position,
  backgroundColor,
  headerTitle,
  headerTitleSize = 16,
  headerIconName,
  headerIconSize = 24,
  width,
  margin,
  padding,
  onClick,
}: PanelProps) => {
  return (
    <Wrap
      colorPalette={colorPalette}
      position={position}
      backgroundColor={backgroundColor}
      width={width}
      margin={margin}
      onClick={onClick}
    >
      {headerTitle && (
        <Header colorPalette={colorPalette}>
          {headerIconName && (
            <IconWrap>
              <GoogleFontsIcon name={headerIconName} size={headerIconSize} />
              <Separator width="16px" />
            </IconWrap>
          )}
          <Text fontSize={headerTitleSize} bold>
            {headerTitle}
          </Text>
        </Header>
      )}
      <Contents padding={padding}>{children}</Contents>
    </Wrap>
  );
};
