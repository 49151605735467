/**
 * クーポン購入表示エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize, UrlName, UrlPath } from 'commons';
import { Button, Link, Text } from 'components/atoms';
import {
  ContentsBlock,
  ContentsTitle,
  DirectionMessagePanel,
} from 'components/organisms';
import { PaidCouponPanel } from 'components/organisms/startheaters';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  couponListSelector,
  displaySizeSelector,
  loadingMessageSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface CouponWrapProps {
  ticketColor: string;
  width: string;
}
const CouponWrap = styled.div<CouponWrapProps>`
  background-color: ${({ ticketColor }) => ticketColor};
  width: ${({ width }) => width};
  max-width: 100%;
`;

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 16px;
  width: 100%;
  max-width: calc(100% - 32px);
`;

/**
 * 【テンプレート】 クーポン購入表示エリア
 * @returns コンポーネント
 */
export const CouponMarketArea: React.FC = () => {
  const navigate = useNavigate();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const couponList = useTypeSelector(couponListSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <ContentsBlock>
      <ContentsTitle>{UrlName.COUPON_MARKET}</ContentsTitle>
      <ContentsWrap>
        <DirectionMessagePanel>
          こちらではクレジットカードでクーポンを購入いただけます。
          <br />
          購入したクーポンは、{UrlName.COUPON}画面で確認できます。
          <br />
          ご利用の際は{process.env.REACT_APP_PORTAL_NAME}
          (チケット購入)から映画を選んで頂き、
          <br />
          チケット選択時に所有しているクーポンが表示されますのでご利用ください。
        </DirectionMessagePanel>
      </ContentsWrap>

      {loadingMessage === '' && couponList.length === 0 && (
        <CouponWrap width={css.panelWidth} ticketColor={colorPalette.repeat[0]}>
          <Text
            fontSize={css.fontSize}
            color={colorPalette.lightTone}
            padding="24px 0"
            textAlign="center"
            bold
          >
            購入可能なクーポンはありません
          </Text>
        </CouponWrap>
      )}
      {couponList.map((data, index) => (
        <CouponWrap
          key={data.couponId}
          width={css.panelWidth}
          ticketColor={colorPalette.repeat[index % 4]}
        >
          <PaidCouponPanel
            paidCoupon={data}
            ticketColor={colorPalette.repeat[index % 4]}
          />
        </CouponWrap>
      ))}
      <ContentsWrap>
        <Button
          colorPalette={colorPalette}
          margin="0 auto 8px"
          width={css.buttonWidth}
          onClick={() => {
            navigate(UrlPath.COUPON);
          }}
        >
          保有クーポンを確認
        </Button>
      </ContentsWrap>
      <Link
        colorPalette={colorPalette}
        fontSize={css.fontSize}
        onClick={() => {
          navigate(UrlPath.CARD);
        }}
      >
        {UrlName.CARD}画面に戻る
      </Link>
    </ContentsBlock>
  );
};
