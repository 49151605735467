/**
 * コンボボックス
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, hexToRgba, validateValue } from 'commons';
import { ValidatorMessage } from 'components/atoms';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

interface SelectProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  padding?: string;
  isActive: boolean;
  isError: boolean;
}
const Select = styled.select<SelectProps>`
  background-color: ${({ colorPalette }) => colorPalette.lightTone};
  border: 1px solid
    ${({ isActive, isError, colorPalette }) =>
      isActive && isError ? colorPalette.negative : colorPalette.grayTone};
  border-radius: 12px;
  ${({ fontSize }) => fontSize !== undefined && `font-size: ${fontSize}px;`}
  width: 100%;
  height: 100%;
  ${({ padding }) => padding && `padding: ${padding};`}
  &:disabled {
    background-color: ${({ colorPalette }) =>
      hexToRgba(colorPalette.grayTone, 0.5)};
  }
`;

const Option = styled.option`
  font-size: 16px;
`;

export interface ComboBoxItemDataType {
  label: string;
  value: string;
}

const getLabel = (valueList: ComboBoxItemDataType[], value: string): string => {
  const labels: string[] = [];
  for (let i = 0; i < valueList.length; i += 1) {
    if (valueList[i].value === value) {
      labels.push(valueList[i].label);
      return valueList[i].label;
    }
  }
  return '';
};

interface ComboBoxProps {
  colorPalette: ColorPalette;
  valueList: ComboBoxItemDataType[];
  initialValue: string;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  fontSize?: number;
  errorFontSize?: number;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string, isError: boolean, label: string) => void;
  activeFlag?: boolean;
}
/**
 * 【分子】 コンボボックス
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {ComboBoxItemDataType[]} valueList - 【必須】 選択リスト
 * @param {string} initialValue -  【必須】 初期値
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {number} fontSize - フォントサイズ
 * @param {number} errorFontSize - エラーフォントサイズ
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @param {boolean} required - 必須項目指定
 * @param {boolean} disabled - 非活性
 * @param {(value: string, isError: boolean, label: string) => void} onChange - 変更処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @returns コンポーネント
 */
export const ComboBox: React.FC<ComboBoxProps> = ({
  colorPalette,
  valueList,
  initialValue,
  validatorRules,
  validatorMessage,
  fontSize,
  errorFontSize,
  width,
  height,
  padding,
  margin,
  required = false,
  disabled = false,
  onChange,
  activeFlag,
}: ComboBoxProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [valueListCash, setValueListCash] = useState(valueList);
  const [validatorRulesCash, setValidatorRulesCash] = useState(validatorRules);
  const [validatorMessageCash, setValidatorMessageCash] =
    useState(validatorMessage);
  const options = useMemo(
    () =>
      valueListCash.map((data) => (
        <Option key={data.value} value={data.value}>
          {data.label}
        </Option>
      )),
    [valueListCash],
  );

  // オブジェクトの変更判定を厳密に行う(予期せぬ再レンダリングを阻止)
  useEffect(() => {
    if (!isEqual(valueListCash, valueList)) {
      setValueListCash(valueList);
    }
    if (!isEqual(validatorRulesCash, validatorRules)) {
      setValidatorRulesCash(validatorRules);
    }
    if (!isEqual(validatorMessageCash, validatorMessage)) {
      setValidatorMessageCash(validatorMessage);
    }
  }, [valueList, validatorRules, validatorMessage]);

  // リストが更新された場合に初期化する
  useEffect(() => {
    setValue(initialValue);
    const result = validateValue({
      value: initialValue,
      validatorRules: validatorRulesCash,
      validatorMessage: validatorMessageCash,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange &&
      onChange(
        initialValue,
        result.isError,
        getLabel(valueListCash, initialValue),
      );
  }, [valueListCash]);

  // バリデーションルールが更新された際に再判定する
  useEffect(() => {
    const result = validateValue({
      value,
      validatorRules: validatorRulesCash,
      validatorMessage: validatorMessageCash,
    });
    setIsError(result.isError);
    setErrorMessage(result.errorMessage);
    onChange && onChange(value, result.isError, getLabel(valueListCash, value));
  }, [validatorRulesCash, validatorMessageCash]);

  // エラー表示フラグを強制的にONにする
  useEffect(() => {
    if (!isError) {
      const result = validateValue({
        value: value,
        validatorRules: validatorRulesCash,
        validatorMessage: validatorMessageCash,
      });
      setIsError(result.isError);
      setErrorMessage(result.errorMessage);
    }
    if (activeFlag) {
      setIsActive(true);
    }
  }, [activeFlag]);

  return (
    <ValidatorMessage
      colorPalette={colorPalette}
      fontSize={errorFontSize}
      width={width}
      height={height}
      margin={margin}
      isDisplay={isActive && isError}
      message={errorMessage}
      isInputBox
    >
      <Select
        value={value}
        colorPalette={colorPalette}
        fontSize={fontSize}
        padding={padding}
        required={required}
        disabled={disabled}
        isActive={isActive}
        isError={isError}
        onChange={(e) => {
          setValue(e.target.value);
          const result = validateValue({
            value: e.target.value,
            validatorRules: validatorRulesCash,
            validatorMessage: validatorMessageCash,
          });
          setIsError(result.isError);
          setErrorMessage(result.errorMessage);
          setIsActive(true);
          onChange &&
            onChange(
              e.target.value,
              result.isError,
              valueListCash[e.target.selectedIndex].label,
            );
        }}
      >
        {options}
      </Select>
    </ValidatorMessage>
  );
};
