/**
 * デジタルカード会員登録確認ページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import { DOCUMENT_TITLE, UrlName, UrlPath, useDidMount } from 'commons';
import { Contents, RegisterCompleteArea } from 'components/templates';
import {
  DigitalRegisterConfirmArea,
  Header,
} from 'components/templates/koronaworld';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PageSlice,
  submitMembershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';

/**
 * 【ページ】 デジタルカード会員登録確認ページ
 * @returns コンポーネント
 */
export const DigitalRegisterConfirm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const submitMembershipData = useTypeSelector(submitMembershipDataSelector);

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.DIGITAL_REGISTER_CONFIRM;
    if (!submitMembershipData.status) {
      navigate(UrlPath.DIGITAL_REGISTER);
    } else {
      dispatch(PageSlice.actions.changeMembershipData(submitMembershipData));
    }
  });

  return (
    <>
      <Header disabledMenu />
      <Contents>
        <DigitalRegisterConfirmArea />
        <RegisterCompleteArea />
      </Contents>
    </>
  );
};
