/**
 * GoogleFonts アイコン
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import React from 'react';
import styled from 'styled-components';

interface WrapProps {
  size?: number;
  color?: string;
  margin?: string;
  padding?: string;
}
const Wrap = styled.span<WrapProps>`
  display: inline-block;
  ${({ size }) => size !== undefined && `font-size: ${size}px;`}
  ${({ size }) => size !== undefined && `width: ${size}px;`}
  ${({ size }) => size !== undefined && `height: ${size}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ color }) => color && `color: ${color};`}
  line-height: normal;
`;

interface GoogleFontsIconProps {
  name: string;
  size?: number;
  color?: string;
  margin?: string;
  padding?: string;
}
/**
 * 【原子】 GoogleFonts アイコン
 * @param {string} name - 【必須】 GoogleFontsアイコン名
 * @ https://fonts.google.com/icons?selected=Material+Icons
 * @param {number} size - アイコンサイズ [px]
 * @param {string} color - アイコン色
 * @param {string} margin - マージン
 * @param {string} padding - パディング
 * @returns コンポーネント
 */
export const GoogleFontsIcon: React.FC<GoogleFontsIconProps> = ({
  name,
  size,
  color,
  margin,
  padding,
}: GoogleFontsIconProps) => {
  return (
    <Wrap
      className="material-icons"
      size={size}
      color={color}
      margin={margin}
      padding={padding}
    >
      {name}
    </Wrap>
  );
};
