/**
 * 注意書きパネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { ReactNode, useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';

interface DirectionMessagePanelProps {
  children?: ReactNode;
  margin?: string;
}
/**
 * 【有機体】 注意書きパネル
 * @param {ReactNode} children - 子要素
 * @param {string} margin - 子要素
 * @returns コンポーネント
 */
export const DirectionMessagePanel: React.FC<DirectionMessagePanelProps> = ({
  children,
  margin,
}: DirectionMessagePanelProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, textAligen: 'center' }
      : { ...CSS_DEFAULT_PARAMETER.SP, textAligen: 'left' };
  }, [displaySize]);

  return (
    <Panel
      colorPalette={colorPalette}
      width={css.panelWidth}
      padding={css.panelPadding}
      margin={margin}
    >
      <Text fontSize={css.fontSizeSmall} textAlign={css.textAligen}>
        {children}
      </Text>
    </Panel>
  );
};
