/**
 * 入力エラー表示
 * - atom(原子) では基本タグのみ使用できる
 * - atom(原子) では汎用的に使用できるパーツを作成
 * - atom(原子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, hexToRgba } from 'commons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Wrap {
  width?: string;
  height?: string;
  margin?: string;
}
const Wrap = styled.div<Wrap>`
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

interface CheckBoxMessageWrapProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  isDisplay: boolean;
}
const CheckBoxMessageWrap = styled.div<CheckBoxMessageWrapProps>`
  display: ${({ isDisplay }) => (isDisplay ? 'block' : 'none')};
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.negative, 0.1)};
  border-radius: 8px;
  padding: 0 8px;
  color: ${({ colorPalette }) => colorPalette.negative};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  text-align: center;
  white-space: nowrap;
`;

interface InputMessageWrapProps {
  colorPalette: ColorPalette;
  fontSize?: number;
  isDisplay: boolean;
}
const InputMessageWrap = styled.div<InputMessageWrapProps>`
  display: ${({ isDisplay }) => (isDisplay ? 'block' : 'none')};
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.negative, 0.1)};
  border-radius: 12px 0 12px 0;
  padding: 0 8px;
  color: ${({ colorPalette }) => colorPalette.negative};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  white-space: nowrap;
`;

interface ValidatorMessageProps {
  children: ReactNode;
  colorPalette: ColorPalette;
  fontSize?: number;
  width?: string;
  height?: string;
  margin?: string;
  isDisplay: boolean;
  message?: string;
  isInputBox?: boolean;
}
/**
 * 【原子】 入力エラー表示
 * @param {ReactNode} children - 【必須】 子要素
 * @param {ColorPalette} colorPalette - 【必須】 カラーパレット
 * @param {number} fontSize - フォントサイズ[px]
 * @param {string} width - 幅
 * @param {string} height - 高さ
 * @param {string} margin - マージン
 * @param {boolean} isDisplay - 【必須】 エラーメッセージ表示フラグ
 * @param {string} message - エラーメッセージ
 * @param {boolean} isInputBox - インプットボックス内表示を行うか
 * @returns コンポーネント
 */
export const ValidatorMessage: React.FC<ValidatorMessageProps> = ({
  children,
  fontSize,
  width,
  height,
  margin,
  colorPalette,
  isDisplay,
  message = '',
  isInputBox,
}: ValidatorMessageProps) => {
  if (isInputBox) {
    return (
      <Wrap width={width} height={height} margin={margin}>
        {children}
        <InputMessageWrap
          colorPalette={colorPalette}
          fontSize={fontSize}
          isDisplay={isDisplay}
        >
          {message}
        </InputMessageWrap>
      </Wrap>
    );
  }
  return (
    <Wrap width={width} height={height} margin={margin}>
      {children}
      <CheckBoxMessageWrap
        colorPalette={colorPalette}
        fontSize={fontSize}
        isDisplay={isDisplay}
      >
        {message}
      </CheckBoxMessageWrap>
    </Wrap>
  );
};
