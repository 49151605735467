/**
 * 新規会員登録パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CustomerConsts } from 'App';
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  MAIL_MAGAZINE_VALUE_LIST,
  Placeholder,
  postAlexCinemasOldMemberIsCreat,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, QrCodeReader, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import {
  CheckBoxForm,
  ComboBoxForm,
  CouponForm,
  DateForm,
  InputTextForm,
  MemberIdForm,
} from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  EventSlice,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px 32px;
`;

interface CommercialWrapProps {
  colorPalette: ColorPalette;
}
const CommercialWrap = styled.div<CommercialWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.concept, 0.2)};
  border-radius: 8px;
  padding: 4px 16px;
  margin: 0 0 0;
`;

const validatorRules = {
  previousNo: ['required', 'numeric'],
  validPeriodTo: ['valid_date'],
  memberNoArray: ['required', 'member_no'],
  registerCoupon: ['required', 'register_coupon'],
  name: ['required'],
  kanaName: ['required', 'katakana_with_space'],
  tel: ['required', 'phone_no_hyphen'],
  birthDate: ['valid_date'],
  theater: ['required'],
};

interface RegisterPanelProps {
  isMigration?: boolean;
  isPhysicsCardMember?: boolean;
  hasCoupon?: boolean;
}
/**
 * 【有機体】 新規会員登録パネル
 * @param {boolean} isMigration - 旧会員フラグ
 * @param {boolean} isPhysicsCardMember - カード会員フラグ
 * @param {boolean} hasCoupon - 無料クーポンフラグ
 * @returns コンポーネント
 */
export const RegisterPanel: React.FC<RegisterPanelProps> = ({
  isMigration = false,
  isPhysicsCardMember = false,
  hasCoupon = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const previous = useRef<{
    previousNo: string;
    validPeriodTo: string;
  }>({ previousNo: '', validPeriodTo: '' });
  const [isQrReaderOpened, setIsQrReaderOpened] = useState<boolean>(false);
  const [registerCouponRef, setRegisterCouponRef] =
    useState<React.RefObject<HTMLInputElement>>();
  const errorList = useRef<{
    previousNo: boolean;
    validPeriodTo: boolean;
    memberNo: boolean;
    registerCoupon: boolean;
    name: boolean;
    kanaName: boolean;
    tel: boolean;
    birthDate: boolean;
    theater: boolean;
  }>({
    previousNo: membershipData.status === 200 ? false : true,
    validPeriodTo: membershipData.status === 200 ? false : true,
    memberNo: membershipData.status === 200 ? false : true,
    registerCoupon: membershipData.status === 200 ? false : true,
    name: membershipData.status === 200 ? false : true,
    kanaName: membershipData.status === 200 ? false : true,
    tel: membershipData.status === 200 ? false : true,
    birthDate: membershipData.status === 200 ? false : true,
    theater: membershipData.status === 200 ? false : true,
  });
  const [active, setActive] = useState<boolean>(false);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC, qrCodeModalWidth: '640px' }
      : { ...CSS_DEFAULT_PARAMETER.SP, qrCodeModalWidth: '100%' };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSizeMiddle} textAlign="center" bold>
          会員情報登録
        </Text>
        <Text fontSize={css.fontSize} textAlign="center" margin="8px 0 0">
          会員情報を設定してください
        </Text>
        {isMigration && (
          <>
            <InputTextForm
              inputType={displaySize === DisplaySize.SMALL ? 'number' : 'text'}
              initialValue={
                membershipData.previousNo !== undefined
                  ? membershipData.previousNo
                  : null
              }
              validatorRules={validatorRules.previousNo}
              placeholder={Placeholder.PREVIOUS_NO}
              maxLength={7}
              onChange={(value, isError) => {
                const replaceData = { previousNo: value };
                errorList.current.previousNo = isError;
                previous.current.previousNo = value;
                dispatch(
                  EventSlice.actions.changeSubmitMembershipData(replaceData),
                );
              }}
              activeFlag={active}
              required
            >
              旧カード番号
            </InputTextForm>
            <Text
              color={colorPalette.negative}
              fontSize={css.fontSizeSmall}
              letterSpacing={0}
            >
              ※アレックスシネマポイントカードの会員番号を入力して下さい
            </Text>
            <DateForm
              initialValue={
                membershipData.validPeriodTo !== undefined
                  ? membershipData.validPeriodTo
                  : null
              }
              validatorRules={validatorRules.validPeriodTo}
              yearFrom={2023}
              yearTo={2025}
              defaultYear={2023}
              onChange={(value, isError) => {
                const replaceData = {
                  validPeriodTo: value,
                  previousValidPeriodTo: value.replaceAll('/', ''),
                };
                errorList.current.validPeriodTo = isError;
                previous.current.validPeriodTo = value.replaceAll('/', '');
                dispatch(
                  EventSlice.actions.changeSubmitMembershipData(replaceData),
                );
              }}
              activeFlag={active}
              required
            >
              旧カード有効期限
            </DateForm>
            <Text
              color={colorPalette.negative}
              fontSize={css.fontSizeSmall}
              letterSpacing={0}
            >
              ※アレックスシネマポイントカードの有効期限を入力して下さい
            </Text>
          </>
        )}
        {isPhysicsCardMember && (
          <>
            <MemberIdForm
              initialValue={
                membershipData.memberNoArray !== undefined
                  ? membershipData.memberNoArray
                  : null
              }
              validatorRules={validatorRules.memberNoArray}
              onChange={(value, index, isError) => {
                errorList.current.memberNo = isError;
                dispatch(
                  EventSlice.actions.changeSubmitMembershipDataMemberIdArray({
                    value,
                    index,
                  }),
                );
              }}
              onBlur={(value, index, isError) => {
                errorList.current.memberNo = isError;
                dispatch(
                  EventSlice.actions.changeSubmitMembershipDataMemberIdArray({
                    value,
                    index,
                  }),
                );
              }}
              activeFlag={active}
              required
            >
              会員番号
            </MemberIdForm>
            <Text
              color={colorPalette.negative}
              fontSize={css.fontSizeSmall}
              letterSpacing={0}
            >
              ※紙の会員証をお持ちの方は入力して下さい
            </Text>
          </>
        )}
        {hasCoupon && (
          <>
            <CouponForm
              initialValue={
                membershipData.registerCoupon !== undefined
                  ? membershipData.registerCoupon
                  : null
              }
              validatorRules={validatorRules.registerCoupon}
              setRef={setRegisterCouponRef}
              onChange={(value, isError) => {
                const replaceData = { registerCoupon: value };
                errorList.current.registerCoupon = isError;
                dispatch(
                  EventSlice.actions.changeSubmitMembershipData(replaceData),
                );
              }}
              onBlur={(value, isError) => {
                const replaceData = { registerCoupon: value };
                errorList.current.registerCoupon = isError;
                dispatch(
                  EventSlice.actions.changeSubmitMembershipData(replaceData),
                );
              }}
              activeFlag={active}
              required
            >
              クーポン番号入力
            </CouponForm>
            <Button
              colorPalette={colorPalette}
              fontSize={css.fontSize}
              padding="4px 8px"
              onClick={() => setIsQrReaderOpened(true)}
            >
              QRコード読み取り
            </Button>
          </>
        )}
        <InputTextForm
          initialValue={
            membershipData.name !== undefined ? membershipData.name : null
          }
          validatorRules={validatorRules.name}
          placeholder={Placeholder.NAME}
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { name: value };
            errorList.current.name = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
          required
        >
          お名前
        </InputTextForm>
        <InputTextForm
          initialValue={
            membershipData.kanaName !== undefined
              ? membershipData.kanaName
              : null
          }
          validatorRules={validatorRules.kanaName}
          placeholder={Placeholder.KANA_NAME}
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { kanaName: value };
            errorList.current.kanaName = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
          required
        >
          お名前（カタカナ）
        </InputTextForm>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'tel' : 'text'}
          initialValue={
            membershipData.tel !== undefined ? membershipData.tel : null
          }
          validatorRules={validatorRules.tel}
          placeholder={Placeholder.TEL_NO_HYPHEN}
          maxLength={200}
          onChange={(value, isError) => {
            const replaceData = { tel: value };
            errorList.current.tel = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
          required
        >
          電話番号
        </InputTextForm>
        <Text fontSize={css.fontSizeSmall}>
          ※「-」ハイフンなしで入力してください
        </Text>
        <DateForm
          initialValue={
            membershipData.birthDate !== undefined
              ? membershipData.birthDate
              : null
          }
          validatorRules={validatorRules.birthDate}
          yearFrom={1900}
          yearTo={new Date().getFullYear()}
          onChange={(value, isError) => {
            const replaceData = { birthDate: value };
            errorList.current.birthDate = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
          activeFlag={active}
          required
        >
          誕生日
        </DateForm>
        <CheckBoxForm
          initialValue={
            membershipData.newsletter !== undefined
              ? membershipData.newsletter
                ? ['true']
                : []
              : null
          }
          valueList={MAIL_MAGAZINE_VALUE_LIST}
          onChange={(value) => {
            const replaceData = {
              newsletter: value[0] === 'true' ? true : false,
            };
            dispatch(
              EventSlice.actions.changeSubmitMembershipData(replaceData),
            );
          }}
        >
          メルマガ
        </CheckBoxForm>
        <CommercialWrap colorPalette={colorPalette}>
          <Text fontSize={css.fontSizeSmall}>
            メルマガ会員には、最新の映画情報やお得な限定クーポンなどをお届けいたします。
          </Text>
        </CommercialWrap>
        <ComboBoxForm
          initialValue={
            membershipData.attributes?.theater !== undefined
              ? membershipData.attributes.theater
              : null
          }
          validatorRules={validatorRules.theater}
          valueList={CustomerConsts.COMBO_BOX_VALUE_LIST_1}
          onChange={(value, isError) => {
            const replaceData = { theater: value };
            errorList.current.theater = isError;
            dispatch(
              EventSlice.actions.changeSubmitMembershipDataAttributes(
                replaceData,
              ),
            );
          }}
          activeFlag={active}
          required
        >
          よく行く劇場
        </ComboBoxForm>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={async () => {
            if (
              (isMigration && errorList.current.previousNo) ||
              (isMigration && errorList.current.validPeriodTo) ||
              (isPhysicsCardMember && errorList.current.memberNo) ||
              (hasCoupon && errorList.current.registerCoupon) ||
              errorList.current.name ||
              errorList.current.kanaName ||
              errorList.current.tel ||
              errorList.current.birthDate ||
              errorList.current.theater
            ) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力または選択した情報に誤りがあります',
                ),
              );
            } else {
              if (isMigration) {
                dispatch(
                  BasicSlice.actions.changeLoadingMessage(
                    '旧カード情報確認中...',
                  ),
                );
                try {
                  const response = await postAlexCinemasOldMemberIsCreat({
                    previousNo: previous.current.previousNo,
                    validPeriodTo: previous.current.validPeriodTo,
                  });
                  if (!response) {
                    dispatch(
                      BasicSlice.actions.changeErrorMessage(
                        '利用できない旧カード情報です',
                      ),
                    );
                    dispatch(BasicSlice.actions.changeLoadingMessage(''));
                    return;
                  }
                } catch (error) {
                  if (error.code) {
                    dispatch(
                      BasicSlice.actions.changeErrorMessage(
                        `${error.message} [${error.code}]`,
                      ),
                    );
                  } else {
                    dispatch(
                      BasicSlice.actions.changeErrorMessage(
                        '会員番号を確認できませんでした [通信エラー]',
                      ),
                    );
                  }
                  dispatch(BasicSlice.actions.changeLoadingMessage(''));
                  return;
                }
                dispatch(BasicSlice.actions.changeLoadingMessage(''));
                navigate(UrlPath.MIGRATION_REGISTER_CONFIRM);
              } else if (isPhysicsCardMember) {
                navigate(UrlPath.PHYSICS_CARD_REGISTER_CONFIRM);
              } else if (hasCoupon) {
                navigate(UrlPath.DIGITAL_REGISTER_COUPON_CONFIRM);
              } else {
                navigate(UrlPath.DIGITAL_REGISTER_CONFIRM);
              }
            }
          }}
        >
          確認
        </Button>
      </Panel>

      <Text margin="24px 0 0">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.MIGRATION_CHECKER)}
        >
          {UrlName.MIGRATION_CHECKER}選択画面に戻る
        </Link>
      </Text>

      <Modal
        colorPalette={colorPalette}
        opened={isQrReaderOpened}
        headerIconName="qr_code_scanner"
        headerTitle="QRコード読み取り"
        headerTitleSize={css.fontSize}
        width={css.qrCodeModalWidth}
        padding="16px"
        onClose={() => {
          setIsQrReaderOpened(false);
        }}
      >
        <QrCodeReader
          colorPalette={colorPalette}
          enabled={isQrReaderOpened}
          onRead={(value) => {
            if (registerCouponRef?.current) {
              registerCouponRef.current.value = value;
              registerCouponRef.current.focus();
              registerCouponRef.current.blur();
            }
            setIsQrReaderOpened(false);
          }}
        />
      </Modal>
    </Wrap>
  );
};
