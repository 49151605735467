/**
 * メールアドレス修正認証パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  LocalStorageKey,
  LoginStatus,
  UrlName,
  UrlPath,
} from 'commons';
import { Button, Link, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import { InputTextForm, MessageModal } from 'components/organisms';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BasicSlice,
  colorPaletteSelector,
  displaySizeSelector,
  updateEmailComplete,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface EmailWrapProps {
  colorPalette: ColorPalette;
}
const EmailWrap = styled.div<EmailWrapProps>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.5)};
  border-radius: 8px;
  padding: 8px;
  margin: 16px 0;
`;

/**
 * 【有機体】 メールアドレス修正認証パネル
 * @returns コンポーネント
 */
export const EmailEditConfirmPanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const [active, setActive] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const errorList = useRef<{ code: boolean }>({ code: true });
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? { ...CSS_DEFAULT_PARAMETER.PC }
      : { ...CSS_DEFAULT_PARAMETER.SP };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center">
          新しいメールアドレス宛に届いた認証コードを
          <br />
          下記にご入力ください
        </Text>
        <EmailWrap colorPalette={colorPalette}>
          <Text
            color={colorPalette.negative}
            fontSize={css.fontSize}
            textAlign="center"
            bold
          >
            認証コードが届かない場合は変更画面に戻り
            <br />
            再度送信をお試しください
          </Text>
        </EmailWrap>
        <InputTextForm
          inputType={displaySize === DisplaySize.SMALL ? 'number' : 'text'}
          initialValue={code}
          validatorRules={['required']}
          maxLength={200}
          onChange={(value, isError) => {
            errorList.current.code = isError;
            setCode(value);
          }}
          activeFlag={active}
        >
          認証コードを入力
        </InputTextForm>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 8px"
          onClick={() => {
            if (errorList.current.code) {
              setActive(true);
              dispatch(
                BasicSlice.actions.changeErrorMessage(
                  '入力した情報に誤りがあります',
                ),
              );
            } else {
              dispatch(updateEmailComplete({ code }));
            }
          }}
        >
          送信
        </Button>
        <Text margin="24px 0 16px" textAlign="center">
          <Link
            colorPalette={colorPalette}
            fontSize={css.fontSize}
            onClick={() => navigate(UrlPath.EMAIL_EDIT)}
          >
            {UrlName.EMAIL_EDIT}画面に戻る
          </Link>
        </Text>
      </Panel>
      <Text margin="24px 0 0" textAlign="center">
        <Link
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          onClick={() => navigate(UrlPath.PROFILE)}
        >
          {UrlName.PROFILE}画面に戻る
        </Link>
      </Text>
      <MessageModal
        onClose={() => {
          localStorage.setItem(
            LocalStorageKey.LOGIN_STATUS,
            LoginStatus.RELOGIN,
          );
          navigate(UrlPath.LOGOUT);
        }}
      />
    </Wrap>
  );
};
