/**
 * ユーザー顔写真アイコン
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { Image, Text } from 'components/atoms';
import React, { useState } from 'react';
import styled from 'styled-components';

interface WrapProps {
  padding?: string;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const TapArea = styled.div`
  cursor: pointer;
`;

interface FaceIconProps {
  faceImageSrc: string;
  size?: string;
  padding?: string;
  margin?: string;
}
/**
 * 【分子】 ユーザー顔写真アイコン
 * @param {string} faceImageSrc - 顔写真URL
 * @param {string} size - サイズ
 * @param {string} padding - パディング
 * @param {string} margin - マージン
 * @returns コンポーネント
 */
export const FaceIcon: React.FC<FaceIconProps> = ({
  faceImageSrc,
  padding,
  margin,
  size,
}: FaceIconProps) => {
  const [tap, isTap] = useState<boolean>(false);

  return (
    <Wrap padding={padding} margin={margin}>
      {faceImageSrc ? (
        <>
          {tap ? (
            <Image src={faceImageSrc} width={size} height={size} square />
          ) : (
            <TapArea
              onClick={() => {
                isTap(true);
              }}
            >
              <Image
                src="/svg/person.svg"
                width={`calc(${size} - 40px)`}
                height={`calc(${size} - 40px)`}
              />
              <Text lineHeight={1} letterSpacing={0}>
                タップで表示
              </Text>
            </TapArea>
          )}
        </>
      ) : (
        <>
          <Image
            src="/svg/person.svg"
            width={`calc(${size} - 40px)`}
            height={`calc(${size} - 40px)`}
          />
          <Text lineHeight={1} letterSpacing={0}>
            顔写真未登録
          </Text>
        </>
      )}
    </Wrap>
  );
};
