/**
 * ログインページ
 * - page(ページ) では template のみ使用できる(基本的に styled コンポーネント の生成は行わない)
 * - page(ページ) では template(テンプレート) のみを使用しページを作成
 * - page(ページ) では ページ全体に影響する最小限の TypeScript による動作を組み込む
 */
import {
  DOCUMENT_TITLE,
  UrlName,
  UrlPath,
  loginStatusClear,
  useDidMount,
} from 'commons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * 【ページ】 ログインページ
 * @returns コンポーネント
 */
export const Login: React.FC = () => {
  const navigate = useNavigate();

  useDidMount(() => {
    document.title = DOCUMENT_TITLE + UrlName.LOGIN;
    loginStatusClear(() => {
      if (process.env.REACT_APP_CUSTOMER_API_LOGIN_URI) {
        window.location.href =
          process.env.REACT_APP_CUSTOMER_API_LOGIN_URI + location.origin;
      } else {
        navigate(UrlPath.ENV_ERROR);
      }
    });
  });

  return null;
};
