/**
 * 利用規約エリア
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { Button, Enhancement, Text } from 'components/atoms';
import { Panel } from 'components/molecules';
import React, { useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 160px;
`;

/**
 * 【テンプレート】 利用規約エリア
 * @returns コンポーネント
 */
export const UserPolicyArea: React.FC = () => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          buttonMargin: '24px 0 0',
          textMargin: '24px 0 0',
          paragraphMargin: '0 0 0 24px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          buttonMargin: '16px 0 0',
          textMargin: '16px 0 0',
          paragraphMargin: '0 0 0 16px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Text fontSize={css.fontSize} textAlign="center" bold>
          FORUM friends（フォーラムフレンズ）
          <br />
          会員規約
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          このFORUM
          friends（フォーラムフレンズ）会員規約（以下、「本規約」といいます。）は、
          株式会社フォーラム運営委員会および株式会社フォーラムマルチプレックスシアターズ（以下、「当社」といいます。）が運営する、
          インターネットを利用した通信機能を有する情報機器（パソコン、スマートフォン、タブレット等を含む）
          の利用者向けの情報配信サービス及びその他会員サービス（以下、「本サービス」）の利用に関して定めることを目的とします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜定義＞</Enhancement>
          <br />
          本規約において、次の用語は、以下に定義する意味で使用します。
          <br />
          1.
          会員とは、本サービスの利用を希望されるお客さまで、本規約を承諾の上、当社が定める方法により所定の登録を完了した方をいいます。
          会員は、利用可能なサービス範囲により、以下のとおり分類されます。
          <Text margin={css.paragraphMargin}>
            ・ 「マイページ登録会員」：
            本規約の定めに従い、マイページに会員情報を登録したお客様（会員証カードの有無にかかわらず）
            <br />・ 「マイページ未登録会員」：
            本規約の定めに従い、会員証カードを取得したお客さまでマイページに会員情報を未登録のお客様
          </Text>
          2.
          「マイページ」とは、当社が本サービスを提供するために運営する会員専用サイトをいいます。
          <br />
          3.
          「会員証」とは、当社が会員に発行する会員番号を表示するために当社が発行するものをいいます。会員証は、以下のとおり分類されます。
          <Text margin={css.paragraphMargin}>
            ・ 「デジタル会員証」：
            マイページ内に作成され、会員がマイページにログインすることにより表示されるデジタル形式の会員証
            <br />・ 「会員証カード」： 劇場窓口で発行されるカード形式の会員証
          </Text>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜入会＞</Enhancement>
          <br />
          ・
          お客様は、次の条件のもとに、本サービスにご入会いただくことができます。（以下、入会が成立したお客様を「会員」といいます。）
          <br />
          ・ 入会金として400 円（税込）をお支払いいただくこと。
          （当社指定のクレジットカード、その他電子マネーなど当社指定の決済方法をご利用の場合、決済の認証をもってお支払いの完了といたします。）
          なお、入会金は、理由の如何を問わず、返金いたしません。
          <br />
          ・ 会員証カードの場合は入会金が500円（税込）となります。
          <br />
          ・ 本規約にご同意いただくこと。
          <br />
          ・ 当社が要求するお客様情報をご提供いただくこと。
          <br />
          ・
          暴力団等反社会勢力（＜会員の表明・確約＞において定義）に該当しないこと。
          <br />
          ・
          当社ホームページから入会したお客様は、デジタル会員証が発行されます。
          その後に会員証カードを作成することはできません。
          デジタル会員証は、ご本人のみが使用になれます。
          <br />
          ・
          会員証カードを発行されたお客様は、お一人につき１枚のみとし、ご本人のみがご使用になれます。
          また、本カードを譲渡、貸与することはできません。
          <br />
          ・ 会員の有効期間は、入会日から１年間です（1年ごとの更新が可能です）。
          <br />
          ・
          劇場窓口で入会される場合には、会員証カードをお受け取り後、お客様ご自身で当社ホームページからマイページに会員情報をご登録ください。
          登録することで、マイページ登録会員となり、同じ会員番号のデジタル会員証も発行されます。
          会員証カード、デジタル会員証のどちらも利用できます。
          <br />・
          会員証カードを折り曲げたり、高温、高湿度な所に置いたりしますと使えなくなることがありますのでご注意ください。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員特典＞</Enhancement>
          <br />
          会員特典は、次の通りで、当社の運営する劇場（以下「劇場」といいます。）でご利用いただけます。
          マイページ登録会員とマイページ未登録会員では、受けられる特典が一部異なります。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          1. マイページ登録会員
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典①
            <Text margin={css.paragraphMargin}>
              ・
              入場料がいつでも会員割引となります。（劇場によって割引額が異なります）
              <br />・
              毎週月曜日は会員デイで割引となります。（劇場によって割引額が異なります）
              <br />・
              会員割引は会員ご本人様に加え、同伴の方５名様までご利用になれます。
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典②
            <Text margin={css.paragraphMargin}>
              ・
              映画１回有料鑑賞で１ポイント付与され、６ポイントで映画の無料鑑賞クーポンへの引き換えができます。
              （一部作品除く、3D・4DXは追加料金が必要となります。）
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典③
            <Text margin={css.paragraphMargin}>
              ・
              映画を１回有料で鑑賞いただくと映画の割引クーポンが自動で発行され、劇場購入の場合1,200円、オンラインチケット購入の場合1,100円に割引となります。
              クーポンの有効期間は発行日から10日間です。
              （一部作品除く、3D・4DXは追加料金が必要となります。）
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典④
            <Text margin={css.paragraphMargin}>
              ・
              オンラインチケットで通常の３時間前から先行購入できます。（一部作品除く）
              <br />
              通常：上映日の２日前の０時より販売
              <br />
              会員：上映日の３日前の21時より販売
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典⑤
            <Text margin={css.paragraphMargin}>
              ・ 鑑賞履歴がマイページで確認できます。
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典⑥
            <Text margin={css.paragraphMargin}>
              ・ メルマガで最新情報をキャッチできます。
            </Text>
          </Text>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          2. マイページ未登録会員
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典①
            <Text margin={css.paragraphMargin}>
              ・
              入場料がいつでも会員割引となります。（劇場によって割引額が異なります）
              <br />・
              毎週月曜日は会員デイで割引となります。（劇場によって割引額が異なります）
              <br />・
              会員割引は会員ご本人様に加え、同伴の方５名様までご利用になれます。
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典②
            <Text margin={css.paragraphMargin}>
              ・
              映画１回有料鑑賞で１ポイント付与され、６ポイントで映画の無料鑑賞クーポンへの引き換えができます。
              （一部作品除く、3D・4DXは追加料金が必要となります。）
            </Text>
          </Text>
          <Text fontSize={css.fontSizeSmall} margin={css.paragraphMargin}>
            会員特典③
            <Text margin={css.paragraphMargin}>
              ・
              映画を１回有料で鑑賞いただくと映画の割引クーポンが自動で発行され、劇場購入の場合1,200円、オンラインチケット購入の場合1,100円に割引となります。
              クーポンの有効期間は発行日から10日間です。
              （一部作品除く、3D・4DXは追加料金が必要となります。）
            </Text>
          </Text>
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          ・
          会員特典および各種サービスの内容や各劇場の詳細は、当社ホームページを、各劇場の通常料金は、各劇場ホームページを、それぞれご確認ください。
          また、会員特典および各種サービス内容は、予告なく変更される場合がございますので、あらかじめご了承ください。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜ポイントの付与と諸条件＞</Enhancement>
          <br />
          ・
          会員が、劇場のチケット売場またはオンラインで、日時指定の有料鑑賞券をご購入された場合に、ご購入1回かつご本人のチケットについてのみ、1ポイントが付与されます。
          <br />
          ・ 無料鑑賞券の場合は、ポイントは付与されません。
          <br />
          ・
          「特別興行作品、当社が指定する一部の上映」では、ポイントの付与が無い場合もございます。
          なお、「特別興行作品」の上映とは、期間限定の旧作上映や、イベント上映など、鑑賞料金が通常料金と異なる作品などの上映をいいます。
          <br />
          ・ ポイントは、換金できません。
          <br />
          ・
          複数の会員証カード、デジタル会員証のポイントを合算することはできません。
          <br />
          ・
          会員特典のうち、「6ポイントで1回映画鑑賞無料」は、特別興行作品、当社が指定する一部の上映にはご利用いただけません。
          <br />・ ポイントは、会員の有効期間内でのみ、ご利用になれます。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員情報の変更＞</Enhancement>
          <br />·
          当社にご提供いただきました会員情報に変更があった場合には、当社ホームページのマイページから変更のお手続きをお願いします。
          変更のお手続きがなされなかったことによるお客様の不都合に関しては、当社では責任を負いかねますので、あらかじめご了承ください。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>
            ＜会員証カードの紛失·盗難·破損および再発行＞
          </Enhancement>
          <br />·
          会員証カードの紛失、盗難または破損の際は、劇場までご連絡ください。
          <br />·
          マイページ未登録会員は、会員証カードの再発行を受ける事は出来ません。
          入会金をお支払いいただき、マイページ登録会員又はマイページ未登録会員として再度ご入会いただきます。
          この場合、それまでに獲得した一切のポイント、特典を引き継ぐことはできず、全て失効するものとします。
          <br />·
          マイページ登録会員で会員証カードを無くされた場合は、再発行手数料500円（税込）で発行できますが、会員番号は変更になります。
          また、デジタル会員証の会員番号も変更されます。
          変更後は、有効期間、ポイント他、会員のデータは、そのまま継続されます。
          <br />· 会員証カードの再発行を希望される場合は、劇場窓口にて承ります。
          その際、会員ご本人のお名前やご登録のお電話番号などの該当会員番号を特定するための情報のご提示が必要です。
          また、ご本人確認のため身分証明書を確認させていただく場合がございます。
          <br />·
          会員番号が不明などの理由により該当カードを特定するための情報が不足している場合は、再発行など対応ができないことがあります。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員の有効期間と更新について＞</Enhancement>
          <br />
          ・ 有効期間は、入会（更新）から1年間です。
          <br />
          ・
          有効期間満了までに更新されない場合は、会員サービスはご利用できなくなります。
          <br />
          ・
          更新期間は、有効期間満了の3ヶ月前からです。有効期間満了後1年間は、更新手続きが可能です。有効期間満了後1年を過ぎますと、自動的に退会となります。
          <br />
          ・ 更新により、更新前の有効期限日より1年間、有効期間を延長いたします。
          <br />・
          更新手数料は400円（税込）です。更新手数料は、理由の如何を問わず、返金はいたしません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜退会＞</Enhancement>
          <br />
          ・
          退会を希望される場合は、当社ホームページのマイページから、所定の手続きをお取りください。
          <br />
          ・
          マイページ未登録会員のお客様は、当社で会員情報を保持しておりませんので、退会手続きはございません。
          <br />・
          理由の如何を問わず、退会となった場合は、それまでに貯められたポイントは失効し、マイページの参照は不可となります。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員資格の喪失、会員証の失効＞</Enhancement>
          <br />・
          会員が、次のいずれかに該当する場合には、自動的に会員資格を喪失し、退会となります。
          <Text margin={css.paragraphMargin}>
            ① 会員証が改竄、偽造、または変造されたものである場合
            <br /> ② 本規約に違反した場合
            <br /> ③ その他、会員証が不正に利用された場合
            <br /> ④ 会員が＜会員の表明・確約＞に違反していると当社が認めた場合
          </Text>
          ・
          会員資格喪失に関連して、会員は、当社に対して何らの請求もできないものとします。
          <br />・
          会員以外のお客様が、不正な方法により会員証を取得して、または会員証が不正な方法により取得されたことを知って会員証を使用した場合、
          会員証は失効いたしますので、あらかじめご了承ください。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜会員の表明・確約＞</Enhancement>
          <br />
          会員は、弊社に対し、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロおよびこれらに類する反社会的勢力並びにこれらと密接な交友関係にある者
          （以下「暴力団等反社会的勢力」といいます。）でないことを表明・保証するものとします。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜個人情報の取り扱いについて＞</Enhancement>
          <br />
          ・
          本規約でいう個人情報には、会員情報（会員番号およびこれに付随する氏名、性別、生年月日、電話番号、郵便番号、住所、メールアドレス）
          および弊社での鑑賞履歴、その他弊社サービスの利用履歴が含まれます。
          <br />
          ・
          当社は、会員のプライバシーを尊重し、当社の「個人情報保護方針」及び「個人情報の取り扱いについて」に基づき個人情報を適切に取り扱います。
          当社の「個人情報保護方針」及び「個人情報の取り扱いについて」は、当社ホームページに掲載しておりますのでご確認ください。
          <br />
          ・
          個人情報は、当社の責任において、「個人情報の取り扱いについて」に記載の目的（以下「利用目的」といいます。）
          で利用するものとし、これ以外の目的で利用することはありません。
          <br />・
          個人情報は、原則的にご本人の許可なく第三者に開示・提供いたしません。ただし、以下の場合には、必要な範囲で個人情報を開示・提供する場合があります。
          <Text margin={css.paragraphMargin}>
            ①
            「個人情報の取り扱いについて」に定めるほか、予め通知等するところに従って特定の第三者と共同利用する場合
            <br />
            ②
            当社と機密保持契約を締結している委託先に個人情報の取扱いを委託する場合
            <br />
            ③ 個人を特定できない形式に加工し、統計情報として開示する場合
            <br />④ 法令等に基づく場合
            <br />
            ⑤ お客様または公共の利益のために必要であると判断された場合
            <br />⑥
            館内の防犯管理、その他トラブル発生時の調査のために捜査機関へ提供する場合
            <br />⑦
            当社が提供するサービスにおいてクレジットカード等による代金決済を行う場合に、
            クレジットカード等の有効性や振込等を確認するために金融機関等との間で個人情報等を交換することを目的として、金融機関等へ提供する場合
          </Text>
          ・
          会員が、ご本人の個人情報について、変更、追加を求める場合は、当社ホームページの会員専用ページより所定の手続きにより変更が可能です。
          <br />・
          個人情報保護法およびその他の法令により認められている場合を除き、ご提供いただいた会員情報の一部、または全部の消去はお受けできません。
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          <Enhancement bold>＜その他＞</Enhancement>
          <br />
          ・
          当社は、本サービスの利用細則を随時定めることができ、当社が相当と認める方法でこれを公表するものとします。
          かかる利用細則は、本規約と一体のものとして、会員に適用されるものとします。
          <br />
          ・
          当社は、当社が必要と認める場合、会員に対する事前の通知をすることなく、本規約を変更することができるものとし、当社が相当と認める方法でこれを公表するものとします。
          なお、かかる変更は、当社が別段の定めをしない限り、上記に定める公表がなされた時点をもって効力を生じるものとします。
          <br />
          ・
          予告無しに、本規約の内容、会員特典及び本サービスを終了、中止できるものとし、会員はあらかじめその旨承認の上入会するものとします。
          <br />
          ・
          天災、その他の非常事態時には、会員への事前告知なしに、本サービスの利用の全部もしくは一部を休止させていただく場合があります。
          <br />・
          当社は、お客様の本規約に反する行為によって生じた損害について、一切責任を負いません
        </Text>
        <Text fontSize={css.fontSizeSmall} margin={css.textMargin}>
          2023年5月29日 設定
          <br />
          2023年6月6日 一部改訂
          <br />
          2024年5月28日 一部改訂
        </Text>
      </Panel>
      <Button
        colorPalette={colorPalette}
        width={css.buttonWidth}
        margin={css.buttonMargin}
        onClick={() => {
          if (history.length <= 1) {
            window.close();
          } else {
            history.back();
          }
        }}
      >
        戻る
      </Button>
    </Wrap>
  );
};
