/**
 * 会員情報パネル
 * - organism(有機体) では別の organism, molecule, atom および基本タグのみ使用できる
 * - organism(有機体) では固有の文言を含めたパーツを作成
 * - organism(有機体) では Redux のロジックと TypeScript による動作を組み込む
 */
import {
  ColorPalette,
  CSS_DEFAULT_PARAMETER,
  DisplaySize,
  hexToRgba,
  MemberCouponListDataTypes,
  UrlName,
  UrlPath,
  Z_INDEX,
} from 'commons';
import { Button, Link, Separator, Text } from 'components/atoms';
import { Modal, Panel } from 'components/molecules';
import { MessageModal } from 'components/organisms';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  colorPaletteSelector,
  deleteMember,
  displaySizeSelector,
  memberCouponListSelector,
  membershipDataSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 16px 32px;
`;

interface RecordProps {
  minHiehgt: string;
}
const Record = styled.div<RecordProps>`
  margin: 16px 0;
  min-height: ${({ minHiehgt }) => minHiehgt};
`;

interface PointArea {
  colorPalette: ColorPalette;
}
const PointArea = styled.div<PointArea>`
  background-color: ${({ colorPalette }) =>
    hexToRgba(colorPalette.grayTone, 0.3)};
  border-radius: 8px;
  padding: 8px 16px;
  margin: 0 0 16px;
  text-align: center;
`;

/**
 * 【有機体】 会員情報パネル
 * @returns コンポーネント
 */
export const ProfilePanel: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const membershipData = useTypeSelector(membershipDataSelector);
  const memberCouponList = useTypeSelector(memberCouponListSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const okCouponList = useMemo(() => {
    const newList: MemberCouponListDataTypes[] = [];
    memberCouponList.forEach((data) => {
      if (data.couponStatus === 'OK') {
        newList.push(data);
      }
    });
    return newList;
  }, [memberCouponList]);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          valueMargin: '0 0 0 36px',
          textMargin: '0 0 24px',
          minHeight: '72px',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          valueMargin: '0 0 0 16px',
          textMargin: '0 0 16px',
          minHeight: '56px',
        };
  }, [displaySize]);

  return (
    <Wrap>
      <Panel
        colorPalette={colorPalette}
        width={css.panelWidth}
        padding={css.panelPadding}
      >
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            お名前(カタカナ)
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.lastName || ''}
            <Separator width={`${css.fontSize}px`} />
            {membershipData.firstName || ''}({membershipData.lastKanaName || ''}
            <Separator width={`${css.fontSize}px`} />
            {membershipData.firstKanaName || ''})
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            メールアドレス
          </Text>
          <Text
            fontSize={css.fontSizeMiddle}
            margin={css.valueMargin}
            letterSpacing={0}
            wordBreakAll
          >
            {membershipData.email || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            電話番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.tel || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            誕生日
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.birthDate || ''}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            郵便番号
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.address?.postalCode}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            住所
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.address?.all}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            性別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.sex}
          </Text>
        </Record>
        <Record minHiehgt={css.minHeight}>
          <Text fontSize={css.fontSize} lineHeight={1} bold>
            顔PASS種別
          </Text>
          <Text fontSize={css.fontSizeMiddle} margin={css.valueMargin}>
            {membershipData.membership?.name}
          </Text>
        </Record>

        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto 0"
          onClick={() => navigate(UrlPath.PROFILE_EDIT)}
        >
          会員情報修正
        </Button>
        <Button
          colorPalette={colorPalette}
          fontSize={css.fontSize}
          width={css.buttonWidth}
          margin="32px auto"
          onClick={() => navigate(UrlPath.EMAIL_EDIT)}
        >
          メールアドレス変更
        </Button>
      </Panel>

      <Text fontSize={css.fontSize} margin="32px 0 0">
        <Link
          colorPalette={colorPalette}
          onClick={() => {
            navigate(UrlPath.CARD);
          }}
        >
          {UrlName.CARD}画面に戻る
        </Link>
      </Text>

      <Modal
        colorPalette={colorPalette}
        zIndex={Z_INDEX.modal}
        width={css.panelWidth}
        padding={css.modalPadding}
        opened={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Text
          fontSize={css.fontSizeLarge}
          textAlign="center"
          margin={css.textMargin}
          bold
        >
          退会
        </Text>
        <Text fontSize={css.fontSize} margin={css.textMargin}>
          退会すると現在所有している下記ポイントとクーポンが全て失効し、登録情報も全て削除されます。
          <br />
          ※一度退会した会員カードでは再登録できません。
        </Text>
        <Text fontSize={css.fontSize} margin={css.textMargin}>
          情報登録を間違えた方は、＜会員情報変更＞から修正してください。
        </Text>
        <Text fontSize={css.fontSize} margin={css.textMargin}>
          お名前、性別、生年月日を間違えた場合は、下記まで必要事項をご連絡ください。
          <br />
          件名：会員情報修正依頼
          <br />
          本文：会員番号・お名前・生年月日・修正する項目と内容
        </Text>
        <Text fontSize={css.fontSize} margin={css.textMargin}>
          いただきました内容を確認させていただく時間は原則的に土日祝日を除く平日10:00～18:00となります。
          <br />
          記入漏れがある場合、対応できかねる場合もございますので、あらかじめご了承ください。
        </Text>
        <Text fontSize={css.fontSize} margin={css.textMargin} bold>
          神戸市問い合わせ
        </Text>

        <PointArea colorPalette={colorPalette}>
          <Text fontSize={css.fontSize} bold>
            現在保有しているポイント
          </Text>
          <Text color={colorPalette.negative} fontSize={css.fontSizeLarge} bold>
            {membershipData.totalPoints?.toLocaleString('ja-JP')}pt
          </Text>
        </PointArea>
        <PointArea colorPalette={colorPalette}>
          <Text fontSize={css.fontSize} bold>
            現在保有しているクーポン
          </Text>
          <Text color={colorPalette.negative} fontSize={css.fontSize} bold>
            合計{okCouponList.length.toLocaleString('ja-JP')}枚
          </Text>
          {okCouponList.map((coupon) => (
            <Text
              key={`${coupon.couponId}${coupon.exchangeDate}`}
              color={colorPalette.negative}
              fontSize={css.fontSize}
              bold
            >
              ・{coupon.couponName}
            </Text>
          ))}
        </PointArea>
        <Text fontSize={css.fontSize} margin="16px 0 0" textAlign="center" bold>
          退会しますか？
        </Text>
        <Button
          colorPalette={colorPalette}
          color={colorPalette.negative}
          width={css.buttonWidth}
          margin="16px auto"
          onClick={() => {
            dispatch(deleteMember());
          }}
        >
          退会する
        </Button>
        <Button
          colorPalette={colorPalette}
          width={css.buttonWidth}
          margin="24px auto"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          退会しない
        </Button>
      </Modal>
      <MessageModal
        onClose={() => {
          if (process.env.REACT_APP_PORTAL_URI) {
            navigate(UrlPath.LOGOUT);
          } else {
            navigate(UrlPath.ENV_ERROR);
          }
        }}
      />
    </Wrap>
  );
};
