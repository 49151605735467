/**
 * チェックボックスフォーム
 * - molecule(分子) では atom および基本タグのみ使用できる
 * - molecule(分子) では汎用的に使用できるパーツを作成
 * - molecule(分子) では Redux を組み込まず、必要な値は props で受け取る
 */
import { ColorPalette, CSS_DEFAULT_PARAMETER, DisplaySize } from 'commons';
import { RequiredIcon, Text } from 'components/atoms';
import { CheckBox, CheckBoxItemDataType } from 'components/molecules';
import React, { ReactNode, useMemo } from 'react';
import {
  colorPaletteSelector,
  displaySizeSelector,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';

interface WrapProps {
  colorPalette: ColorPalette;
  margin?: string;
}
const Wrap = styled.div<WrapProps>`
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

interface CheckBoxFormProps {
  children?: ReactNode;
  valueList: CheckBoxItemDataType[];
  initialValue: string[] | null;
  validatorRules?: string[];
  validatorMessage?: { [key: string]: string };
  required?: boolean;
  onChange?: (value: string[], isError: boolean) => void;
  activeFlag?: boolean;
  disabled?: boolean;
}
/**
 * 【分子】 チェックボックスフォーム
 * @param {ReactNode} children - 【必須】 子要素
 * @param {ComboBoxItemDataType[]} valueList - 【必須】 選択リスト
 * @param {string[]} initialValue - 【必須】 初期値(nullで非表示)
 * @param {string[]} validatorRules - バリデーションルール
 * @param {{ [key: string]: string }} validatorMessage - バリデーションメッセージ(上書き)
 * @param {boolean} required - 必須項目指定
 * @param {(value: string, isError: boolean) => void} onChange - 変更処理
 * @param {boolean} activeFlag - 強制的にアクティブにする
 * @param {boolean} disabled - 非活性
 * @returns コンポーネント
 */
export const CheckBoxForm: React.FC<CheckBoxFormProps> = ({
  children,
  valueList,
  initialValue,
  validatorRules,
  validatorMessage,
  required,
  onChange,
  activeFlag,
  disabled = false,
}: CheckBoxFormProps) => {
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const displaySize = useTypeSelector(displaySizeSelector);
  const css = useMemo(() => {
    return displaySize === DisplaySize.LARGE
      ? {
          ...CSS_DEFAULT_PARAMETER.PC,
          padding: '0',
          margin: '24px 0 0',
        }
      : {
          ...CSS_DEFAULT_PARAMETER.SP,
          padding: '0',
          margin: '16px 0 0',
        };
  }, [displaySize]);

  return initialValue ? (
    <Wrap colorPalette={colorPalette} margin={css.margin}>
      <TitleWrap>
        {required && (
          <RequiredIcon
            colorPalette={colorPalette}
            fontSize={css.requiredFontSize}
            padding={css.requiredPadding}
            margin={css.requiredMargin}
          />
        )}
        <Text fontSize={css.fontSize} bold>
          {children}
        </Text>
      </TitleWrap>
      <CheckBox
        colorPalette={colorPalette}
        valueList={valueList}
        initialValue={initialValue}
        validatorRules={validatorRules}
        validatorMessage={validatorMessage}
        fontSize={css.fontSize}
        errorFontSize={css.fontSizeSmall}
        width="20px"
        height="20px"
        padding={css.padding}
        required={required}
        disabled={disabled}
        onChange={onChange}
        activeFlag={activeFlag}
      />
    </Wrap>
  ) : null;
};
