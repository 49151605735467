import { AppAeonCinema } from 'AppAeonCinema';
import { AppAlexCinemas } from 'AppAlexCinemas';
import { AppForumCinema } from 'AppForumCinema';
import { AppKobePortmuseum } from 'AppKobePortmuseum';
import { AppKoronaWorld } from 'AppKoronaWorld';
import { AppSskts } from 'AppSskts';
import { AppStarTheaters } from 'AppStarTheaters';
import { ColorPalette } from 'commons';
import {
  CheckBoxItemDataType,
  ComboBoxItemDataType,
} from 'components/molecules';
import React from 'react';

export interface CustomerConstsType {
  COLOR_PALETTE: ColorPalette;
  CHECK_BOX_VALUE_LIST_1: CheckBoxItemDataType[];
  COMBO_BOX_VALUE_LIST_1: ComboBoxItemDataType[];
  COMBO_BOX_VALUE_LIST_2: ComboBoxItemDataType[];
  COMBO_BOX_VALUE_LIST_3: ComboBoxItemDataType[];
  DISABLED_SMART_THEATER: boolean;
  DISABLED_VALID_PERIOD: boolean;
  DISABLED_FEE_LIST: boolean;
  REGISTRATION_FEE: number;
  RENEW_FEE: number;
  HISTORY_LENGTH: number;
  COUPON_NEW_DAYS: number;
  NAME_SEPARATED: boolean; //TODO: 全テナントがtrueになったら削除してコードをリファクタリング
}
export const CustomerConsts: CustomerConstsType = (() => {
  switch (process.env.REACT_APP_PROJECT_NAME) {
    case 'startheaters':
      return {
        COLOR_PALETTE: {
          concept: '#0D3FA5',
          conceptLight: '#B8CDF9',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#4F9909',
          negative: '#C6001C',
          warning: '#C49300',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#0D3FA5', '#93A50D', '#0DA59F', '#A5470D'],
        },
        CHECK_BOX_VALUE_LIST_1: [
          { label: 'シネマＱ', value: 'シネマＱ' },
          { label: 'ミハマ7プレックス', value: 'ミハマ7プレックス' },
          { label: 'サザンプレックス', value: 'サザンプレックス' },
          { label: 'シネマライカム', value: 'シネマライカム' },
          { label: 'シネマパレット', value: 'シネマパレット' },
        ],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          { label: 'ひとりで', value: 'ひとりで' },
          { label: 'カップル（ご夫婦）で', value: 'カップル（ご夫婦）で' },
          { label: '友人と', value: '友人と' },
          { label: 'お子様と', value: 'お子様と' },
          { label: 'その他', value: 'その他' },
        ],
        COMBO_BOX_VALUE_LIST_2: [
          { label: '', value: '' },
          { label: 'アクション', value: 'アクション' },
          { label: 'ＳＦ', value: 'ＳＦ' },
          { label: 'サスペンス', value: 'サスペンス' },
          { label: '恋愛もの', value: '恋愛もの' },
          { label: 'ヒューマン', value: 'ヒューマン' },
          { label: '音楽', value: '音楽' },
          { label: 'ドキュメンタリー', value: 'ドキュメンタリー' },
        ],
        COMBO_BOX_VALUE_LIST_3: [
          { label: '', value: '' },
          { label: '週１回以上', value: '週１回以上' },
          { label: '月２・３回', value: '月２・３回' },
          { label: '月１回程度', value: '月１回程度' },
          { label: '年に数回', value: '年に数回' },
          { label: '年１回程度', value: '年１回程度' },
        ],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 500,
        RENEW_FEE: 500,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: true,
      };
    case 'koronaworld':
      return {
        COLOR_PALETTE: {
          concept: '#0074BF',
          conceptLight: '#A3DAFE',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#04A569',
          negative: '#E6001A',
          warning: '#F9B900',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#4A8C08', '#006CAF', '#B7001B', '#B58700'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          { label: '小田原', value: '小田原' },
          { label: '小牧', value: '小牧' },
          { label: '中川', value: '中川' },
          { label: '安城', value: '安城' },
          { label: '豊川', value: '豊川' },
          { label: '大垣', value: '大垣' },
          { label: '福井', value: '福井' },
          { label: '金沢', value: '金沢' },
          { label: '福山', value: '福山' },
          { label: '小倉', value: '小倉' },
        ],
        COMBO_BOX_VALUE_LIST_2: [
          { label: '', value: '' },
          { label: '邦画', value: '邦画' },
          { label: '洋画', value: '洋画' },
          { label: 'アニメ', value: 'アニメ' },
        ],
        COMBO_BOX_VALUE_LIST_3: [
          { label: '', value: '' },
          { label: 'アクション', value: 'アクション' },
          { label: 'SF', value: 'SF' },
          { label: 'コメディ', value: 'コメディ' },
          { label: 'ドラマ', value: 'ドラマ' },
          { label: 'ミュージカル', value: 'ミュージカル' },
          { label: 'ホラー', value: 'ホラー' },
          { label: '歴史（時代劇）', value: '歴史（時代劇）' },
          { label: '恋愛', value: '恋愛' },
          { label: 'ファミリー', value: 'ファミリー' },
        ],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 500,
        RENEW_FEE: 500,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: true,
      };
    case 'sskts':
      return {
        COLOR_PALETTE: {
          concept: '#0074BF',
          conceptLight: '#A3DAFE',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#04A569',
          negative: '#E6001A',
          warning: '#F9B900',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#4A8C08', '#006CAF', '#B7001B', '#B58700'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          {
            label: 'グランドシネマサンシャイン 池袋',
            value: 'グランドシネマサンシャイン 池袋',
          },
          { label: '平和島', value: '平和島' },
          { label: 'ユーカリが丘', value: 'ユーカリが丘' },
          { label: '土浦', value: '土浦' },
          { label: '沼津', value: '沼津' },
          { label: 'ららぽーと沼津', value: 'ららぽーと沼津' },
          { label: 'かほく', value: 'かほく' },
          { label: '大和郡山', value: '大和郡山' },
          { label: '下関', value: '下関' },
          { label: '衣山', value: '衣山' },
          { label: '重信', value: '重信' },
          { label: 'エミフルMASAKI', value: 'エミフルMASAKI' },
          { label: '北島', value: '北島' },
          { label: '飯塚', value: '飯塚' },
          { label: '姶良', value: '姶良' },
          { label: 'サツゲキ', value: 'サツゲキ' },
          { label: 'ディノスシネマズ苫小牧', value: 'ディノスシネマズ苫小牧' },
          { label: 'ディノスシネマズ室蘭', value: 'ディノスシネマズ室蘭' },
        ],
        COMBO_BOX_VALUE_LIST_2: [],
        COMBO_BOX_VALUE_LIST_3: [],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 500,
        RENEW_FEE: 500,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: false,
      };
    case 'forumcinema':
      return {
        COLOR_PALETTE: {
          concept: '#007737',
          conceptLight: '#8DF4BC',
          wallpaper: '#FAF4EA',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#006AB6',
          negative: '#C6001C',
          warning: '#E3C700',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#2849A3', '#29A561', '#9B28A3', '#A3284D'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          { label: 'フォーラム盛岡', value: 'フォーラム盛岡' },
          {
            label: 'フォーラム山形/ソラリス',
            value: 'フォーラム山形/ソラリス',
          },
          { label: 'フォーラム東根', value: 'フォーラム東根' },
          {
            label: 'フォーラム仙台',
            value: 'フォーラム仙台',
          },
          { label: 'フォーラム福島', value: 'フォーラム福島' },
          { label: 'フォーラム那須塩原', value: 'フォーラム那須塩原' },
        ],
        COMBO_BOX_VALUE_LIST_2: [
          { label: '', value: '' },
          { label: '邦画', value: '邦画' },
          { label: '洋画', value: '洋画' },
          { label: 'アニメ', value: 'アニメ' },
        ],
        COMBO_BOX_VALUE_LIST_3: [
          { label: '', value: '' },
          { label: 'アクション', value: 'アクション' },
          { label: 'SF', value: 'SF' },
          { label: 'コメディ', value: 'コメディ' },
          { label: 'ドラマ', value: 'ドラマ' },
          { label: 'ミュージカル', value: 'ミュージカル' },
          { label: 'ホラー', value: 'ホラー' },
          { label: '歴史（時代劇）', value: '歴史（時代劇）' },
          { label: '恋愛', value: '恋愛' },
          { label: 'ファミリー', value: 'ファミリー' },
        ],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 400,
        RENEW_FEE: 400,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: true,
      };
    case 'kobeportmuseum':
      return {
        COLOR_PALETTE: {
          concept: '#0D6FB8',
          conceptLight: '#9ACFF8',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#4F9909',
          negative: '#E83015',
          warning: '#FFBF11',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#B70E49', '#0E82B7', '#B40EB7', '#0EB744'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_2: [],
        COMBO_BOX_VALUE_LIST_3: [],
        DISABLED_SMART_THEATER: true,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: false,
        REGISTRATION_FEE: 0,
        RENEW_FEE: 0,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: false,
      };
    case 'alexcinemas':
      return {
        COLOR_PALETTE: {
          concept: '#323232',
          conceptLight: '#A5A5A5',
          wallpaper: '#E5E5E5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#4F9909',
          negative: '#C6001C',
          warning: '#C49300',
          overlay: 'rgba(0, 0, 0, 0.6)',
          repeat: ['#0D3FA5', '#93A50D', '#0DA59F', '#A5470D'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          { label: '高槻アレックスシネマ', value: '高槻アレックスシネマ' },
          { label: '水口アレックスシネマ', value: '水口アレックスシネマ' },
          { label: '鯖江アレックスシネマ', value: '鯖江アレックスシネマ' },
          { label: '敦賀アレックスシネマ', value: '敦賀アレックスシネマ' },
        ],
        COMBO_BOX_VALUE_LIST_2: [],
        COMBO_BOX_VALUE_LIST_3: [],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 550,
        RENEW_FEE: 550,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: false,
      };
    case 'aeoncinema':
      return {
        COLOR_PALETTE: {
          concept: '#0D3FA5',
          conceptLight: '#B8CDF9',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#4F9909',
          negative: '#C6001C',
          warning: '#C49300',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#0D3FA5', '#93A50D', '#0DA59F', '#A5470D'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [
          { label: '', value: '' },
          { label: '江別', value: '江別' },
          { label: '小樽', value: '小樽' },
          { label: '北見', value: '北見' },
          { label: '旭川駅前', value: '旭川駅前' },
          { label: '釧路', value: '釧路' },
        ],
        COMBO_BOX_VALUE_LIST_2: [
          { label: '', value: '' },
          { label: '邦画', value: '邦画' },
          { label: '洋画', value: '洋画' },
          { label: 'アニメ', value: 'アニメ' },
        ],
        COMBO_BOX_VALUE_LIST_3: [
          { label: '', value: '' },
          { label: 'アクション', value: 'アクション' },
          { label: 'SF', value: 'SF' },
          { label: 'コメディ', value: 'コメディ' },
          { label: 'ドラマ', value: 'ドラマ' },
          { label: 'ミュージカル', value: 'ミュージカル' },
          { label: 'ホラー', value: 'ホラー' },
          { label: '歴史（時代劇）', value: '歴史（時代劇）' },
          { label: '恋愛', value: '恋愛' },
          { label: 'ファミリー', value: 'ファミリー' },
        ],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 500,
        RENEW_FEE: 500,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: true,
      };
    default:
      return {
        COLOR_PALETTE: {
          concept: '#0D3FA5',
          conceptLight: '#B8CDF9',
          wallpaper: '#F0F3F5',
          lightTone: '#FFFFFF',
          darkTone: '#0A0719',
          grayTone: '#B5B5B5',
          positive: '#4F9909',
          negative: '#C6001C',
          warning: '#C49300',
          overlay: 'rgba(0, 0, 0, 0.8)',
          repeat: ['#0D3FA5', '#93A50D', '#0DA59F', '#A5470D'],
        },
        CHECK_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_1: [],
        COMBO_BOX_VALUE_LIST_2: [],
        COMBO_BOX_VALUE_LIST_3: [],
        DISABLED_SMART_THEATER: false,
        DISABLED_VALID_PERIOD: false,
        DISABLED_FEE_LIST: true,
        REGISTRATION_FEE: 500,
        RENEW_FEE: 500,
        HISTORY_LENGTH: 10,
        COUPON_NEW_DAYS: 3,
        NAME_SEPARATED: true,
      };
  }
})();

/**
 * 【プロジェクト】 プロジェクト分岐モジュール
 * @returns コンポーネント
 */
export const App: React.FC = () => {
  switch (process.env.REACT_APP_PROJECT_NAME) {
    case 'startheaters':
      return <AppStarTheaters />;
    case 'koronaworld':
      return <AppKoronaWorld />;
    case 'sskts':
      return <AppSskts />;
    case 'forumcinema':
      return <AppForumCinema />;
    case 'kobeportmuseum':
      return <AppKobePortmuseum />;
    case 'alexcinemas':
      return <AppAlexCinemas />;
    case 'aeoncinema':
      return <AppAeonCinema />;
    default:
      return <>プロジェクト設定環境変数エラー</>;
  }
};
