/**
 * サイトの基本情報を管理・操作
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerConsts } from 'App';
import {
  BrowserTypes,
  ColorPalette,
  DisplaySize,
  Languages,
  LocalStorageKey,
  LoginStatus,
  postCustomerToken,
  UrlPath,
} from 'commons';
import { DispatchState, SelectorState } from 'stores';

export interface BasicState {
  browser: BrowserTypes;
  startPwaInstall: boolean;
  language: Languages;
  displaySize: DisplaySize;
  colorPalette: ColorPalette;
  mainMenuOpen: boolean;
  loadingMessage: string;
  errorMessage: string;
  authErrorMessage: string;
}

const initialState: BasicState = {
  browser: { name: '', version: '', os: { name: '', version: '' }, pwa: false },
  startPwaInstall: false,
  language: Languages.JA,
  displaySize: DisplaySize.LARGE,
  colorPalette: CustomerConsts.COLOR_PALETTE,
  mainMenuOpen: false,
  loadingMessage: '',
  errorMessage: '',
  authErrorMessage: '',
};

/**
 * カスタマーAPIトークン取得処理
 * @param {string} code - トークンコード
 */
export const getNewCustomerApiToken = createAsyncThunk<
  boolean,
  { code: string },
  { dispatch: DispatchState; state: SelectorState }
>('getNewCustomerApiToken/fetch', async (props, thunkApi) => {
  thunkApi.dispatch(BasicSlice.actions.changeLoadingMessage('認証処理中...'));
  await postCustomerToken({ code: props.code });
  return true;
});

export const BasicSlice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    changeBrowser: (state, action: PayloadAction<BrowserTypes>) => {
      state.browser = action.payload;
    },
    changeStartPwaInstall: (state, action: PayloadAction<boolean>) => {
      state.startPwaInstall = action.payload;
    },
    changeLanguage: (state, action: PayloadAction<Languages>) => {
      state.language = action.payload;
    },
    changeDisplaySize: (state, action: PayloadAction<DisplaySize>) => {
      state.displaySize = action.payload;
    },
    changeColorPalette: (state, action: PayloadAction<ColorPalette>) => {
      state.colorPalette = action.payload;
    },
    changeMainMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.mainMenuOpen = action.payload;
    },
    changeLoadingMessage: (state, action: PayloadAction<string>) => {
      state.loadingMessage = action.payload;
    },
    changeErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    changeAuthErrorMessage: (state, action: PayloadAction<string>) => {
      state.authErrorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewCustomerApiToken.fulfilled, (state) => {
      localStorage.setItem(LocalStorageKey.LOGIN_STATUS, LoginStatus.FINISH);
      window.location.href = location.origin + UrlPath.CARD;
      state.loadingMessage = '';
    });
    builder.addCase(getNewCustomerApiToken.rejected, (state, action) => {
      state.authErrorMessage = action.error.message || 'API Error';
      state.loadingMessage = '';
    });
  },
});
