/**
 * ローディング
 * - template(テンプレート) では organism, molecule, atom および基本タグのみ使用できる
 * - template(テンプレート) では固有の文言を含めた Page(ページ) で使用するパーツを作成
 * - template(テンプレート) では Redux のロジックと TypeScript による動作を組み込む
 */
import { ColorPalette, setModalOpenClassToBody, Z_INDEX } from 'commons';
import { Button, Overlay, Text } from 'components/atoms';
import React, { useEffect, useMemo, useState } from 'react';
import {
  approvalDeleteSelector,
  colorPaletteSelector,
  eventLoadingMessageSelector,
  EventSlice,
  loadingMessageSelector,
  pageLoadingMessageSelector,
  useTypeDispatch,
  useTypeSelector,
} from 'stores';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

interface LoadingAnimationProps {
  colorPalette: ColorPalette;
}
const LoadingAnimation = styled.div<LoadingAnimationProps>`
  &,
  &::before,
  &::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation: load7 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  & {
    color: ${({ colorPalette }) => colorPalette.lightTone};
    font-size: 6px;
    margin: 40px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }

  &::before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  &::after {
    left: 3.5em;
  }

  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin: 16px 0 0;
`;

/**
 * 【特殊コンポーネント】 ローディング
 * @returns コンポーネント
 */
export const Loading: React.FC = () => {
  const dispatch = useTypeDispatch();
  const colorPalette = useTypeSelector(colorPaletteSelector);
  const loadingMessage = useTypeSelector(loadingMessageSelector);
  const pageLoadingMessage = useTypeSelector(pageLoadingMessageSelector);
  const eventLoadingMessage = useTypeSelector(eventLoadingMessageSelector);
  const approvalDelete = useTypeSelector(approvalDeleteSelector);
  const [overlayRef, setOverlayRef] =
    useState<React.RefObject<HTMLDivElement>>();
  const [uniqueId] = useState<string>(uuidV4());
  const message = useMemo(() => {
    return loadingMessage || pageLoadingMessage || eventLoadingMessage || '';
  }, [loadingMessage, pageLoadingMessage, eventLoadingMessage]);

  useEffect(() => {
    // モーダル内のスクロールを先頭に戻す
    overlayRef?.current?.scrollTo(0, 0);
    // Bodyのスクロールを非表示にしてマウスホイールで動かないようにする
    setModalOpenClassToBody(message ? true : false, uniqueId);
    return () => {
      setModalOpenClassToBody(false, uniqueId);
    };
  }, [message]);

  return (
    <Overlay
      colorPalette={colorPalette}
      displayed={message ? true : false}
      zIndex={Z_INDEX.loading}
      setRef={setOverlayRef}
    >
      <Wrap>
        <LoadingAnimation colorPalette={colorPalette} />
        <Text
          color={colorPalette.lightTone}
          fontSize={16}
          textAlign="center"
          bold
        >
          {message}
        </Text>
        {approvalDelete && (
          <ButtonWrap>
            <Button
              colorPalette={colorPalette}
              color={colorPalette.negative}
              width="240px"
              onClick={() => {
                dispatch(EventSlice.actions.changeApprovalDelete(false));
                dispatch(
                  EventSlice.actions.changeEventLoadingMessage(
                    '退会キャンセル処理中...',
                  ),
                );
              }}
            >
              キャンセル
            </Button>
          </ButtonWrap>
        )}
      </Wrap>
    </Overlay>
  );
};
